// master list of policies (interface?)

const AvailablePolicies = [
  'email_blast',
  'manage_translations',
  'manage_queues',
  'private_api_key',
  'super_admin',
  'manage_email_templates',
];

export default AvailablePolicies;