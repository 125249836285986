import React from 'react'
import { ThemeProvider } from '@material-ui/styles';
import config from './config'
import { theme } from './theme';
import http from './http';
import SimpleBackdrop from './SimpleBackdrop';
import { Trans } from 'react-i18next'
import { withTranslation } from 'react-i18next'
import AvailablePolicies from './AvailablePolicies'
const AuthContext = React.createContext();

class AuthProviderComponent extends React.Component {

  constructor(props) {
    super(props);
    const user = this.getUser();

    let userObj = {
      initials: '',
      user_id: '',
      first_name: '',
      last_name: '',
      profile_pic: '',
      policies: '',
    }

    const initials = user && user.first_name && user.last_name ? `${user.first_name[0]}${user.last_name[0]}` : '??';
    if (user) {
      userObj = {
        initials: initials,
        user_id: user.user_id,
        first_name: user.first_name,
        last_name: user.last_name,
        profile_pic: user.profile_pic,
        policies: user.policies,
      }
    }

    this.state = {
      isAuth: user ? true : false,
      rightBarState: false,
      user: userObj,
      title: 'App',
      showBackDrop: false,
      portalData: {},
      breadcrumb: '',
      notificationCount: null
    }
    this.applicationState$ = 0
    this.isOnline = true

    // get portal data from host - set in state
    this.getPortalData();
  }

  componentDidMount() {
    // subscribe
    // this.applicationState();

    // local storage update
    window.addEventListener('storage', this.applicationState)
    // network online
    window.addEventListener('online', this.applicationState)
    // network online
    window.addEventListener('offline', this.applicationState)

    // verify token
    if (this.state.isAuth) {
      this.verifyToken()
    }
  }

  componentWillUnmount() {
    // unsubscribe
    // clearInterval(this.applicationState$);
    window.removeEventListener('storage', this.applicationState)
    window.removeEventListener('online', this.applicationState)
    window.removeEventListener('offline', this.applicationState)
  }

  setTitle = (title) => {
    this.setState({
      title: title
    });
    config.title(title);
  }

  setNotificationCount = async () => {
    await http.get('/tenant/notification_count')
      .then(
        success => this.setState(prev => ({ ...prev, notificationCount: success.data })),
        error => this.props.snackbar(<Trans i18nKey={error.data}></Trans>, error.data))
  }

  setBreadcrumb = breadcrumb => this.setState({ breadcrumb });

  backdrop = (status) => {
    this.setState(prevState => ({ ...prevState, showBackDrop: status }))
  }

  setRightBar = (status) => {
    // toggle then
    if (status === undefined) {
      status = !this.state.rightBarState
    }
    this.setState(prevState => ({ ...prevState, rightBarState: status }))
  }

  hasAccess = (policy) => {
    if (!policy) {
      return true;
    }

    // log missing policy

    if (policy instanceof Array) {
      policy.forEach((item => {
        if (!AvailablePolicies.includes(item)) {
          console.log(item);
          console.warn(`${item} not defined`)
        }
      }));
    }
    else if (!AvailablePolicies.includes(policy)) {
      console.log(policy);
      console.warn(`${policy} not defined`)
    }
    // log missing policy

    const userPolicies = this.state.user && this.state.user.policies ? this.state.user.policies : []
    // super admin has all access
    if (userPolicies.includes('super_admin')) {
      return true;
    }

    if (policy instanceof Array) {
      // array? then return true if one of them exists.
      return userPolicies.some(item => policy.includes(item))
    }

    return userPolicies.includes(policy)
  }


  hasPolicy = (policy) => {
    if (!policy) {
      return true;
    }
    policy = Array.isArray(policy) ? policy : [policy]

    const userPolicies = this.state.user && this.state.user.policies ? this.state.user.policies : []

    // return true if one of them exists
    return userPolicies.some(item => policy.includes(item))
  }

  applicationState = () => {
    console.log('applicationState', 'check');
    // Logged out from another tab
    if (this.state.isAuth && !this.getUser()) {
      console.log('Logged out from another tab')
      this.props.snackbar('Logged out from another tab')
      // window.location.reload();
      this.logout()
    }

    // Logged in from another tab
    if (!this.state.isAuth && this.getUser()) {
      console.log('Logged in from another tab')
      // window.location.reload();
      this.login(this.getUser())
    }

    if (this.isOnline !== navigator.onLine) {
      this.isOnline = navigator.onLine
      this.props.snackbar(<Trans i18nKey={`network.${this.isOnline}`}></Trans>, {
        variant: this.isOnline ? 'success' : 'error'
      })
    }
    // this.applicationState$ = setTimeout(this.applicationState, 2000);
  }

  verifyToken = async () => {
    const lease = this.getLease()
    const params = (lease) ? { property_id: lease.property_id, lease_id: lease.lease_id } : {}

    await http.get('/tenant/verify', { params })
      .then(
        success => {
          this.login(success.data)

          // fetch notification count
          this.setNotificationCount()
        },
        error => {
          console.log('Verify token failed', error)
          this.props.snackbar(<Trans i18nKey={error.data}></Trans>, {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
            variant: 'error'
          })
          this.logout()
        })
  }

  getUser() {
    try {
      return JSON.parse(localStorage.getItem('user'));
    } catch (error) {
      if (error instanceof SyntaxError) {
        // json malformed
        // @todo report
        localStorage.removeItem('user');
        return null;
      }
    }
  }

  getLease() {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user && user.metas && user.metas.lease) {
      const { lease_id, units } = user.metas.lease
      if (lease_id && units) {
        return units.find(unit => unit.lease_id === lease_id)
      }
    }
    return null
  }

  getUnits() {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user && user.metas && user.metas.lease && user.metas.lease.units) {
      return user.metas.lease.units
    }
    return []
  }

  // translate = this.props.t;

  login = (user) => {
    // only remove originalToken if not impersonating
    if (!localStorage.user) {
      if (localStorage.originalToken) {
        localStorage.removeItem('originalToken')
      }
    }

    localStorage.setItem('user', JSON.stringify(user));

    const initials = user && user.first_name && user.last_name ? `${user.first_name[0]}${user.last_name[0]}` : '??';
    this.setState({
      isAuth: true,
      user: {
        ...user,
        initials: initials,
        // user_id: user.user_id,
        // first_name: user.first_name,
        // last_name: user.last_name,
        // profile_pic: user.profile_pic,
        // policies: user.policies,
      },
    });
  }

  logout = () => {
    http.get('/auth/logout').then(() => {
      localStorage.removeItem('user')
      localStorage.removeItem('originalToken')
      localStorage.removeItem('column_settings_14jan2021')
      localStorage.removeItem('is_impersonating')
      this.setState({
        isAuth: false,
        user: {
          initials: '',
          user_id: '',
          first_name: '',
          last_name: '',
          profile_pic: '',
          policies: [],
        },
      });

      // when website opening from react native mobile app, send event to mobile for logout
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(JSON.stringify({ action: 'logout' }));
      }
    })
  }

  /**
   * check if user is impersonating or not
   */
  impersonating = () => {
    const originalToken = localStorage.getItem('originalToken');
    return originalToken ? true : false;
  }

  /**
   * get portal data from host
   */
  getPortalData = () => {
    const hostname = window.location.host
    const originName = window.location.origin

    if (hostname.startsWith('localhost')) {
      return
    }

    if (hostname.startsWith('127.0.0.1')) {
      return
    }

    const data = {}
    data.hostname = hostname
    if (typeof sessionStorage.propertyID !== 'undefined' && sessionStorage.propertyID) {
      data.property_id = sessionStorage.propertyID
    }
    http.post('/pms/hosts/resolve', data).then(response => {
      this.setState(prevState => ({ ...prevState, portalData: response.data }))
      localStorage.setItem('project_title', response.data.property_name ?? '')

      console.log('response.data: ', response.data);
      if (response && response.data && response.data.property_name) {
        let logoURL = originName + "/logo192.png";
        if (response.data.company_logo) {
          logoURL = response.data.company_logo
        }
        if (response.data.property_logo) {
          logoURL = response.data.property_logo
        }
        const stringManifest = JSON.stringify({
          "short_name": response.data.property_name,
          "name": response.data.property_name,
          // "screenshots": [
          //   {
          //     "src": originName + "/logo512.png",
          //     "sizes": "512x512",
          //     "type": "image/png",
          //     "form_factor": "narrow",
          //     "label": "Wonder Widgets"
          //   },
          //   {
          //     "src": originName + "/logo512.png",
          //     "sizes": "512x512",
          //     "type": "image/png",
          //     "form_factor": "wide",
          //     "label": "Wonder Widgets"
          //   },
          // ],
          "icons": [
            {
              "src": originName + "/favicon.ico",
              "sizes": "16x16",
              "type": "image/x-icon"
            },
            {
              "src": logoURL,
              "type": "image/png",
              "sizes": "192x192"
            },
            // {
            //   "src": originName + "/logo512.png",
            //   "type": "image/png",
            //   "sizes": "512x512"
            // }
          ],
          "start_url": originName,
          "display": "standalone",
          "theme_color": "#9c27b0",
          "background_color": "#ffffff"
        });
        const blob = new Blob([stringManifest], { type: 'application/json' });
        const manifestURL = URL.createObjectURL(blob);
        document.querySelector('#my-manifest-placeholder').setAttribute('href', manifestURL);
        document.querySelector('#apple-touch-icon-placeholder').setAttribute('href', logoURL);
        const dynamicScriptContent = ` 
              window.AddToHomeScreenInstance = new window.AddToHomeScreen(
                {
                  appName:"${response.data.property_name}",
                  appIconUrl:"${logoURL}",
                  assetUrl: 'https://cdn.jsdelivr.net/gh/philfung/add-to-homescreen@1.4/dist/assets/img/',
                  showErrorMessageForUnsupportedBrowsers: true,
                  allowUserToCloseModal: true,
                  maxModalDisplayCount: -1
                }
              );
              const isIos = () => {
                const userAgent = window.navigator.userAgent.toLowerCase();
                return /iphone|ipad|ipod/.test(userAgent);
              }
        
              const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);
              const isAppInstalledOnIos = isIos() && isInStandaloneMode();
              const isChromeOnAndroid = /chrome/i.test(navigator.userAgent) && /android/i.test(navigator.userAgent);
              const isChromeOnWindows = /chrome/i.test(navigator.userAgent) && /windows/i.test(navigator.userAgent);
              if (isAppInstalledOnIos) {
                console.log('App is installed on the home screen');
              } else {
                if (!isChromeOnAndroid && !isChromeOnWindows) {
                  window.AddToHomeScreenInstance.show(); // show "add-to-homescreen" instructions to the user, or do nothing if already added to homescreen
                }
                console.log('App is not installed on the home screen');
              } 
          `;
        const scriptElement = document.createElement('script');

        // Set the script content
        scriptElement.innerHTML = dynamicScriptContent;
        document.body.appendChild(scriptElement);

      } else {
        const stringManifest = JSON.stringify({
          "short_name": "GetCube",
          "name": "GetCube",
          // "screenshots": [
          //   {
          //     "src": originName + "/logo512.png",
          //     "sizes": "512x512",
          //     "type": "image/png",
          //     "form_factor": "narrow",
          //     "label": "Wonder Widgets"
          //   },
          //   {
          //     "src": originName + "/logo512.png",
          //     "sizes": "512x512",
          //     "type": "image/png",
          //     "form_factor": "wide",
          //     "label": "Wonder Widgets"
          //   },
          // ],
          "icons": [
            {
              "src": originName + "/favicon.ico",
              "sizes": "16x16",
              "type": "image/x-icon"
            },
            {
              "src": originName + "/logo192.png",
              "type": "image/png",
              "sizes": "192x192"
            },
            // {
            //   "src": originName + "/logo512.png",
            //   "type": "image/png",
            //   "sizes": "512x512"
            // }
          ],
          "start_url": originName,
          "display": "standalone",
          "theme_color": "#9c27b0",
          "background_color": "#ffffff"
        });
        const blob = new Blob([stringManifest], { type: 'application/json' });
        const manifestURL = URL.createObjectURL(blob);
        document.querySelector('#my-manifest-placeholder').setAttribute('href', manifestURL);
        document.querySelector('#apple-touch-icon-placeholder').setAttribute('href', originName + "/logo192.png");

        const dynamicScriptContent = ` 
                window.AddToHomeScreenInstance = new window.AddToHomeScreen(
                  {
                    appName: 'GetCube',
                    appIconUrl: "${originName + "/logo192.png"}",
                    assetUrl: 'https://cdn.jsdelivr.net/gh/philfung/add-to-homescreen@1.4/dist/assets/img/',
                    showErrorMessageForUnsupportedBrowsers: true,
                    allowUserToCloseModal: true,
                    maxModalDisplayCount: -1
                  }
                );
                const isIos = () => {
                  const userAgent = window.navigator.userAgent.toLowerCase();
                  return /iphone|ipad|ipod/.test(userAgent);
                }
          
                const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);
                const isAppInstalledOnIos = isIos() && isInStandaloneMode();
                const isChromeOnAndroid = /chrome/i.test(navigator.userAgent) && /android/i.test(navigator.userAgent);
                const isChromeOnWindows = /chrome/i.test(navigator.userAgent) && /windows/i.test(navigator.userAgent);
                if (isAppInstalledOnIos) {
                  console.log('App is installed on the home screen');
                } else {
                  if (!isChromeOnAndroid && !isChromeOnWindows) {
                    window.AddToHomeScreenInstance.show(); // show "add-to-homescreen" instructions to the user, or do nothing if already added to homescreen
                  }
                  console.log('App is not installed on the home screen');
                } 
            `;
        const scriptElement = document.createElement('script');

        // Set the script content
        scriptElement.innerHTML = dynamicScriptContent;
        document.body.appendChild(scriptElement);

      }
    })
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <SimpleBackdrop hidden={!this.state.showBackDrop} />
        <AuthContext.Provider
          value={{
            isAuth: this.state.isAuth,
            isImpersonating: this.impersonating(),
            user: this.state.user,
            hasAccess: this.hasAccess,
            hasPolicy: this.hasPolicy,
            setRightBar: this.setRightBar,
            rightBarState: this.state.rightBarState,
            setTitle: this.setTitle,
            title: this.state.title,
            setBreadcrumb: this.setBreadcrumb,
            breadcrumb: this.state.breadcrumb,
            login: this.login,
            logout: this.logout,
            snackbar: this.props.snackbar,
            backdrop: this.backdrop,
            getLease: this.getLease,
            getUnits: this.getUnits,

            portalData: this.state.portalData,
            notificationCount: this.state.notificationCount,
            setNotificationCount: this.setNotificationCount,
          }}>
          {this.props.children}
        </AuthContext.Provider>
      </ThemeProvider>
    )
  }
}

// functional components - translations
const translations = []

const AuthProvider = withTranslation(translations)(AuthProviderComponent)
// const AuthProvider = (AuthProviderComponent)
const AuthConsumer = AuthContext.Consumer;
export { AuthProvider, AuthConsumer, AuthContext };