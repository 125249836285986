import React from 'react'
import css from './MustRead.module.css'
import { compose } from 'recompose';

import { Trans, withTranslation } from 'react-i18next'

import config from '../../../../utils/config';

import MuiDialogTitle from '@material-ui/core/DialogTitle';
import {
  Grid,
  FormControlLabel,
  Checkbox,
  Typography,
  Dialog,
  Button,
  DialogActions,
  DialogContent
} from '@material-ui/core'
/**
 * @todo - do css
 * fetch data from API
 */
class MustRead extends React.Component {
  render() {
    const { props } = this.props
    return (
      <React.Fragment>
        <Dialog
          className='assign-dialog-box'
          disableEscapeKeyDown={true}
          open={true}
          maxWidth='sm'
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              this.props.onClose()
            }
          }}
          aria-labelledby="assign-dialog-title">
          <div className={css['popup-top-area']}>
            <MuiDialogTitle disableTypography className={css.change_assign_root}>
              <Typography variant="h6" className={css.assign_dialog_title}>
                <Trans ns='application' i18nKey='application.mustread.title'>Must Read</Trans>
              </Typography>
            </MuiDialogTitle>
          </div>
          <DialogContent>
            {/* must read content */}
            <div className='status-field'>
              <div className={css['must-read-con']}>
                <b>MUST READ - Online Application Process:</b>
                <div>
                  <p>1. Apply online</p>
                  <p>2. Complete payment</p>
                  <p>3. Landlord will contact you directly with the results. If you have not heard from your landlord/agent within 4 business day you may want to contact
                    them. We are committed to equal housing and we fully comply with the Federal Fair Housing Act (FFHA) We do not discriminate against persons because of race,
                    color, religion, sex, handicap. and familial status, gender of origin, national origin, or age. We also comply with all state and local fair housing laws. We offer
                    application forms to everyone who requests one. Each occupant over the age of 18 must complete an application and pay the application fee.</p>
                  <p>Please have the following information before proceeding:</p>
                  <ul>
                    <li>Residence History/Landlord Info: Name, phone, email</li>
                    <li>Employment History: Name, Contact, Start Date, Salary</li>
                    <li>Additional occupants information: Name, Email</li>
                    <li>Dependent Information: Name, age</li>
                    <li>Emergency Contact Information: Name, phone, email</li>
                  </ul>
                  <p> Approval is based on:</p>
                  <ul>
                    <li> Credit payment history</li>
                    <li>Employment verification/history</li>
                    <li>Income verification</li>
                    <li>Rental history</li>
                    <li>Criminal background check</li>
                    <li>Terrorist database search</li>
                  </ul>
                  <p>Acknowledgement and Representation:</p>
                  <p>1. Signing this application (electronic or otherwise) indicates that applicant has had the opportunity to review landlords tenant selection criteria, which is
                    listed above and available upon request. The Tenant selection criteria may included factors such as criminal history, credit history, current income and rental
                    history.</p>
                  <p>2. Applicant understand that providing inaccurate or incomplete information is grounds for rejection of this application and forfeiture of any application fee
                    and may be grounds to declare applicant in breach of any lease the applicant may sign.</p>
                  <p>3. Applicant represents that the statements made in this application are true and complete.</p>
                </div>
              </div>
            </div>


            {/* transunion checkbox */}
            <Grid container justify="flex-start">
              <div className={css.transunion_terms_of_service_checkbox}>
                <FormControlLabel
                  labelPlacement="end"
                  label={<Trans ns='application' i18nKey="label.transunion_terms_of_service">
                    You are indicating that you have read and agree to the transunion renter screening
                    solutions terms and service, which opened in your second tab
                  </Trans>}
                  control={<Checkbox
                    checked={props.values.transunion_terms_of_service}
                    name="transunion_terms_of_service"
                    onChange={(_event, newValue) => {
                      props.setFieldValue("transunion_terms_of_service", newValue)

                      if (newValue) {
                        window.open(`${config.publicUrl}/page/terms-applicant`)
                      }
                    }}
                    color="primary"
                  />} />
              </div>

            </Grid>
          </DialogContent>
          <DialogActions>
            {/* close modal on accept - */}
            <Button className={`${css.btn_accept} form-button`} disabled={!props.values.transunion_terms_of_service} variant='contained' onClick={this.props.onClose}>I accept</Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    )
  }
}

export default compose(
  withTranslation('application')
)(MustRead);