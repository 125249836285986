import React from 'react'
import { Trans, withTranslation } from 'react-i18next'
import { compose } from 'recompose'
import { connect } from 'react-redux';

import { AuthContext } from '../../../utils/AppContext'

import { Formik, Form } from 'formik';

import * as yup from 'yup'
import yupGeneric from '../../../utils/yupGeneric';
import moneyFormat from '../../../utils/moneyFormat';

import PlacePayApplication from '../../Shared/PlacePayApplication'
import FormikTextField from '../../Shared/FormikTextField';
import FormikSelect from '../../Shared/FormikSelect';
import FormikAutoComplete from '../../Shared/FormikAutoComplete';
import ProgressButtonSimple from '../../Shared/ProgressButtonSimple';

import { preLoadStateList } from '../../../store/stateListReducer';

import {
  Container,
  Typography,
  CssBaseline,
  Grid,
  Box,
  MenuItem,
  FormLabel,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Tooltip,
  Button,
} from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab';

import css from '../ApplicationForm/Application.module.css'
import ProcessPaymentConfirmDialog from './ProcessPaymentConfirmDialog';

// payment
export const CARD_FORM_DATA = {
  card_no: '',
  expiry_month: '',
  expiry_year: '',
  cvv: '',
  account_holders_name: '',
}

export const CHECK_FORM_DATA = {
  routing_number: '',
  account_number: '',
  account_holders_name: '',
  account_type: 'Checking', // Checking and Savings
}

/**
 * merchant type is APPLICATION_FEE here
 */
const MERCHANT_TYPE = 'APPLICATION_FEE'


class Payment extends React.Component {
  static contextType = AuthContext

  constructor(props) {
    super(props)

    this.cardAutofill = {
      card_no: '4100000000000001',
      expiry_month: '12',
      expiry_year: '2025',
      cvv: '4585',
      account_holders_name: 'Developer Tenant', // required for revo pay
    }

    this.checkAutofill = {
      routing_number: '021200025',
      account_number: '123123124',
      account_holders_name: 'Developer Tenant',
      account_type: 'Checking', // Checking and Savings
    }

    this.state = {
      confirm_process_payment_dialog_open: false,
      hidePlacepayPaymentButton: false,
      formData: this.props.formData,
      payment_gateways: this.props.payment_gateways,
      payment_type: 'CARD',
    }
  }


  /* confirm payment popup */
  confirmProcessPayment = async formikProps => {

    // after resetting form, errors blank - had to refill them manually below ( avoids procedding to payment )
    formikProps.errors = await formikProps.validateForm()

    // try touching all fields ( so that all invalids are red not just 1st one )
    try {
      Object.keys(formikProps.values).map(fieldName => {
        formikProps.setFieldTouched(fieldName, true);
        return null
      })
    } catch (error) {
      // do nothing
    }

    // set error messages if any - do not submit if invalid
    if (Object.keys(formikProps.errors).length > 0) {
      return await formikProps.setFieldTouched(Object.keys(formikProps.errors))
    }

    // show confirm box
    this.toggleProcessPaymentConfirmDialog(true)
  }

  toggleProcessPaymentConfirmDialog = toggle => {
    this.setState(prevState => {
      return {
        ...prevState,
        confirm_process_payment_dialog_open: toggle
      }
    })
  }

  // payment api called after successful placepay payment
  submitPlacepayPayment = (placepay_transaction_id) => {
    const { payment_type } = this.state
    const { applicant, dueInvoice } = this.props;

    this.setState(prevState => ({
      ...prevState,
      hidePlacepayPaymentButton: true
    }))

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        token: this.props.token
      },
      body: JSON.stringify({
        applicant_id: applicant.id,
        invoice_id: dueInvoice.id,
        make_payment_form: {
          placepay_transaction_id: placepay_transaction_id,
          payment_type: payment_type,
        }
      })
    };

    fetch(`${process.env.REACT_APP_ENDPOINT}/applicant/payment/placepay`, requestOptions)
      .then(_response => {
        this.context.snackbar(<Trans ns='application' i18nKey='payment.done'>
          Payment has been processed successfully. Please wait till we redirect you to next page.
          Please refresh if it's taking too long.
        </Trans>, {
          variant: 'success'
        })

        // redirected to exam pg
        this.props.redirect()
      }, error => {
        this.context.snackbar(error.data, {
          variant: 'error'
        })
      })
  }

  formValidation = () => {
    const isCard = {
      is: 'CARD',
      then: yup.string().required(yupGeneric)
    }

    const isCheck = {
      is: 'CHECKING',
      then: yup.string().required(yupGeneric)
    }

    return yup.object({
      amount: yup.number()
        .typeError(yupGeneric)
        .required(yupGeneric),
      address_line_1: yup.string()
        .trim().typeError(yupGeneric)
        .min(4, yupGeneric)
        .required(yupGeneric),
      address_line_2: yup.string().trim().typeError(yupGeneric).optional(),
      city: yup.string().typeError(yupGeneric).optional(),
      state_code: yup.string().typeError(yupGeneric).optional(),
      zip_code: yup.string()
        .typeError(yupGeneric)
        .matches(/^[0-9]+$/, yupGeneric) // only number
        .min(5, yupGeneric)
        .max(5, yupGeneric)
        .required(yupGeneric),
      payment_type: yup.string()
        .typeError(yupGeneric)
        .required(yupGeneric),
      card_no: yup.string() // @todo credit card validation
        .typeError(yupGeneric)
        .matches(/^[0-9]+$/, yupGeneric) // only number
        .when('payment_type', isCard),
      expiry_month: yup.string()
        .typeError(yupGeneric)
        .when('payment_type', isCard),
      expiry_year: yup.string()
        .typeError(yupGeneric)
        .when('payment_type', isCard),
      cvv: yup.string()
        .typeError(yupGeneric)
        .matches(/^[0-9]+$/, yupGeneric) // only number
        .min(3, yupGeneric)
        .max(4, yupGeneric)
        .when('payment_type', isCard),
      routing_number: yup.string()
        .typeError(yupGeneric)
        .matches(/^[0-9]+$/, yupGeneric) // only number
        .when('payment_type', isCheck),
      account_number: yup.string()
        .typeError(yupGeneric)
        .matches(/^[0-9]+$/, yupGeneric) // only number
        .when('payment_type', isCheck),
      account_holders_name: yup.string()
        .typeError(yupGeneric)
        .required(yupGeneric), // required for both CARD and CHECK
      account_type: yup.string()
        .typeError(yupGeneric)
        .when('payment_type', isCheck)
    })
  }

  render() {
    const {
      confirm_process_payment_dialog_open,
      formData,
      payment_gateways,
      hidePlacepayPaymentButton
    } = this.state
    const { applicant, dueInvoice, t, state_list } = this.props;

    formData.amount = dueInvoice && dueInvoice.invoice_due ? dueInvoice.invoice_due : 0
    /* ---------------------------------------- */
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    function addYears(date, n) {
      return new Date(date.setFullYear(date.getFullYear() + n));
    }
    let years = []
    for (let index = 1; index < 11; index++) {
      years.push(addYears(new Date(), index).getFullYear())
    }


    if (Object.keys(payment_gateways).length === 0) {
      return (
        <React.Fragment>
          <Box>
            <Grid item md={12}>
              <div>
                Loading...
              </div>
            </Grid>
          </Box>
        </React.Fragment>
      )
    }
    /* ---------------------------------------- */

    return <React.Fragment>
      <div className={`${css['application-wrap']} verify-landlord-main verify-application-main`}>

        <Container component="main" maxWidth="xl" className='login-contain-area'>
          <CssBaseline />
          <Grid container justify='center'>
            <Grid item>
              <div>
                {this.props.parent === 'applicant' && <Typography component="h2" variant="h5">
                  <Trans ns="application" i18nKey='header.payment'>Application Payment</Trans>
                </Typography>}

                {!applicant && <div className={css['applicant-payemt-plz-wait']}>
                  <Trans ns="application" i18nKey='payment.loading'>Please wait while we validate your payment status....</Trans>
                </div>}

                {applicant && !dueInvoice && <div className={css['applicant-payemt-plz-wait']}>
                  <Alert severity="info">
                    <AlertTitle><Trans ns='application' i18nKey='payment.completed.no_dues'>Info</Trans></AlertTitle>
                    <Trans ns="application" i18nKey='payment.completed'>You have no due invoices.. Validating your Credit Request..</Trans>
                  </Alert>
                </div>}
              </div>
            </Grid>
          </Grid>
        </Container>


        {/* make payment section here - */}
        {dueInvoice && <Container component="main" maxWidth="xl" className='login-contain-area'>
          {/* <CssBaseline /> */}
          <div className={css['applicant-payment-bg']}>
            <Formik
              enableReinitialize={true}
              initialValues={formData}
              validationSchema={this.formValidation()}
              validateOnMount={true}
              onSubmit={(values, action) => {
                const { applicant } = this.props

                const payment_type = values.payment_type
                const payment_type_data = payment_gateways[payment_type]
                const payment_gateway = payment_type_data.payment_gateway.toLowerCase()

                const data = JSON.parse(JSON.stringify(values))

                data.state_code = typeof data.state_code === 'object' ? data.state_code.id : data.state_code
                data.first_name = applicant.first_name
                data.last_name = applicant.last_name
                data.email = applicant.email
                data.invoice_id = this.props.invoice_id

                const requestOptions = {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                    token: this.props.token
                  },
                  body: JSON.stringify(data)
                };

                fetch(`${process.env.REACT_APP_ENDPOINT}/applicant/payment/${payment_gateway}`, requestOptions)
                  .then(async (response) => {

                    if (response.ok) {
                      this.context.snackbar(<Trans ns='application' i18nKey="payment.done">
                        Payment has been processed successfully. Please wait till we redirect you to next page.
                        Please refresh if it's taking too long.
                      </Trans>, {
                        variant: 'success'
                      })

                      // todo - commented for test
                      action.setSubmitting(false)
                      action.resetForm()

                      // redirected to exam pg
                      this.props.redirect()

                      return null
                    }

                    /* NOT OK - start checking error */
                    let errorResponse = await response.json()

                    if (errorResponse.includes('GATEWAY_ERROR')) {
                      // gateway failed - user needs to know
                      errorResponse = errorResponse.replace('GATEWAY_ERROR ', '')
                      this.context.snackbar(<Trans ns='application' i18nKey={errorResponse}>{errorResponse}</Trans>, {
                        variant: 'error'
                      })
                    } else {
                      // some other error, paisa kata but - show success
                      this.context.snackbar(<Trans ns='application' i18nKey="payment.processed">Payment has been processed.</Trans>, {
                        variant: 'success'
                      })
                      console.error(errorResponse, ' - payment API failed')
                    }

                    // todo - commented for test
                    action.setSubmitting(false)
                    action.resetForm()

                    // current address to be shown
                    this.setState(prevState => ({
                      ...prevState,
                      total_charge_amount: this.props.total_charge_amount,
                      formData: {
                        ...this.props.formData,
                        address_line_1: values.address_line_1,
                        address_line_2: values.address_line_2,
                        city: values.city,
                        state_code: values.state_code,
                        zip_code: values.zip_code,
                      }
                    }))
                  }).catch(error => {
                    console.error(error)
                  })
              }}>{props => {
                const { values } = props
                const { payment_type } = values
                let { amount } = values
                if (!amount) {
                  amount = 0
                }

                /** payment gateway data */
                const payment_type_data = payment_gateways[payment_type]
                const payment_gateway = payment_type_data.payment_gateway
                /** payment gateway data */

                return (
                  <React.Fragment>
                    <div className='form-wrap'>
                      <Form noValidate>

                        {/* payment amount - read only */}
                        <div className={`${css['payment-title']} form-field`}>
                          <Grid container justify='flex-start' alignItems="center" spacing={2}>
                            <Grid item>
                              <Grid container justify='flex-start' alignItems='center' spacing={1}>
                                <Grid item xs={12} sm={12} md='auto'>
                                  <div className={`${css['card-box']}`}>
                                    <Typography>
                                      Application Fees <span>:</span>
                                    </Typography>
                                  </div>
                                  <div className={css['card-box']}>
                                    <Typography component="h3">
                                      {moneyFormat(amount)}
                                    </Typography>
                                  </div>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item>
                            </Grid>
                          </Grid>
                        </div>

                        {/* payment type section */}
                        <div className={`${css['payment-type-box-content']} form-field`}>
                          <Grid container justify='flex-start' alignItems="center" spacing={2}>
                            <Grid item xs='auto' sm='auto' md='auto'>
                              <h3 className={css['payment-type-heading']}>
                                <Trans ns='application' i18nKey='payments.form.heading.payment_type'>
                                  Payment Type
                                </Trans>
                              </h3>
                            </Grid>
                          </Grid>
                          <Grid container justify='flex-start' alignItems="center" spacing={2}>
                            <Grid item xs='auto' sm={12} md={12} lg={12} xl={12}>
                              <div className={`radio-butn`}>
                                <FormControl component="fieldset">
                                  <RadioGroup
                                    row
                                    aria-label="payment_type"
                                    name="payment_type"
                                    value={props.values.payment_type}
                                    onChange={(_event, newValue) => props.setValues({
                                      ...props.values,
                                      payment_type: newValue,
                                      ...CARD_FORM_DATA,
                                      ...CHECK_FORM_DATA
                                    })}>

                                    <Grid container justify='center' alignItems="center" spacing={2}>
                                      <Grid item>
                                        <FormControlLabel className={props.values.payment_type === 'CARD' ? 'checked-amount-radio' : ''} value="CARD" control={<Radio color="primary" />} label="Card" />
                                      </Grid>
                                      <Grid item>
                                        <FormControlLabel className={props.values.payment_type === 'CHECKING' ? 'checked-amount-radio' : ''} value="CHECKING" control={<Radio color="primary" />} label="Check" />
                                      </Grid>

                                      {/* auto fill - */}
                                      {payment_gateway !== 'PLACEPAY' && <React.Fragment>
                                        <Grid item>
                                          {process.env.NODE_ENV === 'development' && <React.Fragment>
                                            <Tooltip title={<Trans ns='application' i18nKey='application.form.autofill.tooltip'>Click here to autofill</Trans>}>
                                              <Button
                                                data-testid="payments_autofill"
                                                color="primary"
                                                size='large'
                                                className={css['autofill-button']}
                                                onClick={() => {
                                                  if (payment_type === 'CARD') {
                                                    props.setValues({
                                                      ...props.values,
                                                      ...this.cardAutofill
                                                    })
                                                  }

                                                  if (payment_type === 'CHECKING') {
                                                    props.setValues({
                                                      ...props.values,
                                                      ...this.checkAutofill
                                                    })
                                                  }
                                                }}>
                                                <Trans ns='application' i18nKey='form.autofill.label'>Autofill</Trans>
                                              </Button>

                                            </Tooltip>
                                          </React.Fragment>}
                                        </Grid>
                                      </React.Fragment>}
                                    </Grid>
                                  </RadioGroup>
                                </FormControl>
                              </div>
                            </Grid>
                          </Grid>

                          <Grid container justify='flex-start' alignItems="center" spacing={2}>
                            {/* show make payment form, address, fee section only if payment gateway is one of the 'PRIORITYPAYMENTS', 'REVOPAY', 'NMI' */}
                            {(payment_gateway !== 'PLACEPAY') && <React.Fragment>

                              {/* payment type CARD section */}
                              {(payment_type === 'CARD') && <React.Fragment>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                  <Grid container alignItems="flex-start" spacing={2}>
                                    <Grid item xs={12} sm={12} md={12}>
                                      <div className={`${css['content-space']}`}>
                                        <Grid container alignItems="flex-start" spacing={2}>
                                          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                            <FormikTextField
                                              data-testid="payments_card_card_no"
                                              label={t('application:form.card_no', 'Card Number')}
                                              InputLabelProps={{
                                                shrink: true,
                                              }}
                                              ns='application'
                                              name="card_no"
                                              required
                                              type="year"
                                              placeholder={t('application:form.card_no', 'Card Number')}
                                              fullWidth
                                              variant="outlined"
                                              autoComplete="off" />
                                          </Grid>
                                          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                            <FormikTextField
                                              data-testid="payments_card_account_holders_name"
                                              label={t('application:form.account_holders_name', 'Account Holder\'s Name')}
                                              InputLabelProps={{
                                                shrink: true,
                                              }}
                                              ns='application'
                                              name="account_holders_name"
                                              required
                                              type="year"
                                              placeholder={t('application:form.account_holders_name', 'Account Holder\'s Name')}
                                              fullWidth
                                              variant="outlined"
                                              autoComplete="off" />
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                      <div className={css['content-space']}>
                                        <Grid container alignItems="flex-start" spacing={2}>
                                          <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                                            <div className={`${css['make-payment-expiry-month']} form-select-box`}>
                                              <FormikSelect
                                                data-testid="payments_card_expiry_month"
                                                name='expiry_month'
                                                label={t('payments:form.expiry_month', 'Expiry Month')}
                                                variant="outlined"
                                                required
                                                fullWidth>
                                                {monthNames.map((item, index) => <MenuItem value={index + 1} key={item}>{item}</MenuItem>)}
                                              </FormikSelect>
                                            </div>
                                          </Grid>
                                          <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                                            <div className={`${css['make-payment-expiry-month']} form-select-box`}>
                                              <FormikSelect
                                                data-testid="payments_card_expiry_year"
                                                name='expiry_year'
                                                label={t('payments:form.expiry_year', 'Expiry Year')}
                                                variant="outlined"
                                                required
                                                fullWidth>
                                                {years.map(item => <MenuItem value={item} key={item}>{item}</MenuItem>)}
                                              </FormikSelect>
                                            </div>
                                          </Grid>
                                          <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                                            <FormikTextField
                                              data-testid="payments_card_cvv"
                                              label={t('application:form.cvv', 'CVV')}
                                              InputLabelProps={{
                                                shrink: true,
                                              }}
                                              ns='application'
                                              name="cvv"
                                              required
                                              type="year"
                                              placeholder={t('application:form.cvv', 'CVV')}
                                              fullWidth
                                              variant="outlined"
                                              autoComplete="off" />
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </React.Fragment>}


                              {/* payment type CHECKING section */}
                              {(payment_type === 'CHECKING') && <React.Fragment>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                  <div className={`${css['content-space']} ${css['content-space-remove']} form-field`}>
                                    <Grid container alignItems="flex-start" spacing={2}>
                                      <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                        <FormikTextField
                                          data-testid="payments_check_routing_number"
                                          label={t('application:form.routing_number', 'Routing Number')}
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          ns='application'
                                          name="routing_number"
                                          required
                                          type="year"
                                          placeholder={t('application:form.routing_number', 'Routing Number')}
                                          fullWidth
                                          variant="outlined"
                                          autoComplete="off" />
                                      </Grid>
                                      <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                        <FormikTextField
                                          data-testid="payments_check_account_number"
                                          label={t('application:form.account_number', 'Account Number')}
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          ns='application'
                                          name="account_number"
                                          required
                                          type="year"
                                          placeholder={t('application:form.account_number', 'Account Number')}
                                          fullWidth
                                          variant="outlined"
                                          autoComplete="off" />
                                      </Grid>
                                      <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                        <FormikTextField
                                          data-testid="payments_check_account_holders_name"
                                          label={t('application:form.account_holders_name', 'Account Holder\'s Name')}
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          ns='application'
                                          name="account_holders_name"
                                          required
                                          type="year"
                                          placeholder={t('application:form.account_holders_name', 'Account Holder\'s Name')}
                                          fullWidth
                                          variant="outlined"
                                          autoComplete="off" />
                                      </Grid>
                                    </Grid>
                                  </div>

                                  <Grid container justify='flex-start' alignItems="flex-start" spacing={2}>
                                    <Grid item xs={12} sm={12} md={12}>
                                      <div className={css['radio-btn']}>
                                        <FormControl component="fieldset">
                                          <FormLabel component="legend">
                                            <h3>
                                              <Trans ns='application' i18nKey='form.account_type.formlabel'>Account Type</Trans>
                                            </h3>
                                          </FormLabel>
                                          <RadioGroup
                                            row
                                            aria-label="account_type"
                                            name="account_type"
                                            value={values.account_type}
                                            onChange={(_event, newValue) => props.setFieldValue('account_type', newValue)}>
                                            <Grid container alignItems="center" spacing={2}>
                                              <Grid item>
                                                <FormControlLabel
                                                  value="Checking"
                                                  className={values.account_type === 'Checking' ? 'checked-amount-radio' : ''}
                                                  control={<Radio color="primary" data-testid="payments_check_account_type_checking" />}
                                                  label="Checking" />
                                              </Grid>
                                              <Grid item>
                                                <FormControlLabel
                                                  value="Savings"
                                                  className={values.account_type === 'Savings' ? 'checked-amount-radio' : ''}
                                                  control={<Radio color="primary" data-testid="payments_check_account_type_savings" />}
                                                  label="Savings" />
                                              </Grid>
                                            </Grid>
                                          </RadioGroup>
                                        </FormControl>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </React.Fragment>}

                            </React.Fragment>}
                          </Grid>

                          {(payment_gateway !== 'PLACEPAY') && <React.Fragment>

                            <Grid container justify='flex-start' alignItems="center" spacing={2}>
                              <Grid item>
                                <h3>
                                  <Trans ns='application' i18nKey='application.form.heading.address'>
                                    Your Address
                                  </Trans>
                                </h3>
                              </Grid>
                            </Grid>

                            {/* address fields */}
                            <Grid container alignItems="flex-start" spacing={2}>
                              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <div className={css['content-space']}>
                                  <Grid container alignItems="flex-start" spacing={2}>
                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                      <FormikTextField
                                        data-testid="address_line_1"
                                        label={t('application:form.address1', 'Address Line1')}
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        ns='application'
                                        name="address_line_1"
                                        required
                                        type="text"
                                        placeholder={t('application:form.address1', 'Address Line1')}
                                        fullWidth
                                        variant="outlined"
                                        autoComplete="off" />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                      <FormikTextField
                                        data-testid="address_line_2"
                                        label={t('application:form.address2', 'Address Line2')}
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        ns='application'
                                        name="address_line_2"
                                        type="text"
                                        placeholder={t('application:form.address2', 'Address Line2')}
                                        fullWidth
                                        variant="outlined"
                                        autoComplete="off" />
                                    </Grid>
                                  </Grid>
                                </div>
                              </Grid>
                              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <div className={css['content-space']}>
                                  <Grid container alignItems="flex-start" spacing={2}>
                                    <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                                      <FormikTextField
                                        data-testid="city"
                                        label={t('application:form.city', 'City')}
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        ns='application'
                                        name="city"
                                        type="text"
                                        placeholder={t('application:form.city', 'City')}
                                        fullWidth
                                        variant="outlined"
                                        autoComplete="off" />
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                                      <FormikAutoComplete
                                        className={css['state-box']}
                                        required
                                        disableAdd={true}
                                        name="state_code"
                                        label={t('application:form.state_code', 'State Code')}
                                        options={Object.keys(state_list).map(item => ({ id: item, label: state_list[item] }))}
                                        onChange={(_event, newValue) => props.setFieldValue('state_code', newValue)}
                                        ns='application' />
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                                      <FormikTextField
                                        data-testid="zip_code"
                                        label={t('application:form.zip_code', 'Zip Code')}
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        ns='application'
                                        name="zip_code"
                                        required
                                        type="text"
                                        placeholder={t('application:form.zip_code', 'Zip Code')}
                                        fullWidth
                                        variant="outlined"
                                        autoComplete="off"
                                        onBlur={(event) => {
                                          let zip_code = event.target.value.toString()

                                          // prefix 0's | 10 -> 00010
                                          if (zip_code.length > 0 && zip_code.length < 5) {
                                            zip_code = ("00000" + zip_code).substring(zip_code.length);
                                            props.setFieldValue('zip_code', zip_code);
                                          } else {
                                            // show error
                                            props.setFieldTouched('zip_code', true)
                                          }
                                        }} />
                                    </Grid>
                                  </Grid>
                                </div>
                              </Grid>
                            </Grid>

                            {/* progress button - make payment */}
                            <Grid container justify="center" alignItems="center" spacing={1}>
                              <Grid item>
                                <ProgressButtonSimple
                                  data-testid="payments_submit"
                                  type="button"
                                  loading={props.isSubmitting ? "1" : "0"}
                                  color="primary"
                                  disabled={props.isSubmitting}
                                  className={`${css["submit-butn"]} form-button`}
                                  onClick={() => this.confirmProcessPayment(props)}>
                                  <Trans ns='application' i18nKey="application.form.button.make_payment">Make Payment</Trans>
                                </ProgressButtonSimple>
                              </Grid>
                            </Grid>
                          </React.Fragment>}

                        </div>
                      </Form>


                      {/* If the embed_container is or resides within a form, upon completion of a payment, the form will be automatically submitted. (page refresh - is liye bahar rakha hai Form ke) */}
                      {/* show placepay button if payment gateway is PlacePay */}
                      <Grid container justify='center' alignItems="center" spacing={2}>
                        <Grid item xs='auto' sm='auto' md='auto'>
                          {(!hidePlacepayPaymentButton && payment_gateway === 'PLACEPAY') && <React.Fragment>
                            {/* PlacePay button */}
                            <div className={`${css['content-space']} form-field`}>
                              <PlacePayApplication
                                color="primary"
                                className="form-button"
                                onPaid={(placepay_transaction_id) => {
                                  this.submitPlacepayPayment(placepay_transaction_id)
                                }}
                                data={{
                                  unit_id: applicant.unit_id,
                                  amount,
                                  payment_type,
                                  merchant_type: MERCHANT_TYPE,
                                  invoice_type: 'SCREENING_FEES',
                                  user: {
                                    email: applicant.email,
                                    first_name: applicant.first_name,
                                    last_name: applicant.last_name,
                                  }
                                }}>
                                <Trans ns='application' i18nKey="application.form.button.placepay">PlacePay Payment</Trans>
                              </PlacePayApplication>
                            </div>

                          </React.Fragment>}
                        </Grid>
                      </Grid>

                    </div>

                    {/* process payment confirm box */}
                    <ProcessPaymentConfirmDialog
                      dialogData={{ payment_type, amount: values.amount, applicant }}
                      open={confirm_process_payment_dialog_open}
                      onClose={() => this.toggleProcessPaymentConfirmDialog(false)}
                      onSuccess={() => {
                        this.toggleProcessPaymentConfirmDialog(false)
                        setTimeout(() => {
                          props.submitForm()
                        }, 200);
                      }} />
                  </React.Fragment>
                )
              }}</Formik>
          </div>
        </Container >}
      </div>

    </React.Fragment >
  }
}

export default compose(
  withTranslation('application'),
  connect(
    state => ({
      state_list: state.state_list.data
    }),
    dispatch => {
      dispatch(preLoadStateList(true));
      return { dispatch }
    }
  )
)(Payment);