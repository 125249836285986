import React, { Fragment } from 'react'
import { Grid } from '@material-ui/core'

import MaskedInput from '../../Shared/MaskedInput'
import FormikMaskedInput from '../../Shared/FormikMaskedInput'
import { Button } from 'react-bootstrap'
import { Form, Formik } from 'formik';
import * as yup from "yup";
import yupGeneric from '../../../utils/yupGeneric';


export default class MaskedPlayground extends React.Component {

  /* validation object */
  formValidation = yup.object({
    formik_masked_input: yup.string()
      .required(yupGeneric),
  })

  handleSubmit = (values, actions) => {
    // code here
  }

  render() {
    return (
      <Fragment>
        <div className='form-wrap'>
          <Grid container>
            <Grid item xs={12}>
              <h2>Simple Masked Input-</h2>
            </Grid>
            <Grid item>
              <MaskedInput
                required
                name='masked_input'
                type='text'
                label='Contact No.'
                mask_type='(999) 999-9999'
              />
            </Grid>
            <Grid item xs={12}>
              <h2>Formik Masked Input-</h2>
            </Grid>
            <Grid item>
              <Formik
                enableReinitialize={true}
                initialValues={{ formik_masked_input: '' }}
                validationSchema={this.formValidation}
                validateOnMount={true}
                onSubmit={this.handleSubmit}
              >
                {(props) => (
                  <Form className={`form-wrap`}>
                    <Grid container>
                      <Grid item>
                        <FormikMaskedInput
                          required
                          label='Contact No.'
                          name='formik_masked_input'
                          type='text'
                          mask_type='(999) 999-9999'
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Button disabled={!props.isValid} type='submit'>Submit Formik</Button>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Grid>
          </Grid>
        </div>
      </Fragment>
    )
  }
}