import React from 'react'
import css from '../Application.module.css'
import { withTranslation, Trans } from 'react-i18next'
import { compose } from 'recompose'

import FormikTextField from '../../../Shared/FormikTextField'
import FormikMaskedInput from '../../../Shared/FormikMaskedInput'

import {
  Grid,
  Chip,
  Typography,
  Divider,
  Link,
  Box
} from '@material-ui/core'
import MustRead from './MustRead'


class Applicant extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      mustRead: sessionStorage.getItem('is_mustRead') ? false : true, // FALSE if is_mustRead is present in sessionStorage else TRUE
    }

    if (!sessionStorage.getItem('is_mustRead')) {
      sessionStorage.setItem('is_mustRead', true)
    }
  }

  // closes the must read dialogue box
  closeHandle = () => {
    this.setState(prevState => {
      return {
        ...prevState,
        mustRead: false
      }
    })
  }

  render() {
    const {
      t,
      props,
      classes
    } = this.props
    const genders = ['Male', 'Female']

    const maritial_statuses = ['Married', 'Unmarried']

    const { applicants, applicant_screening_info } = props.values

    return (
      <React.Fragment>
        {/* must read here */}
        {this.state.mustRead && <MustRead onClose={this.closeHandle} props={props} />}

        <div className={`${css['form-grp']} form-wrap`}>
          <div className={css['heading']}>
            <Typography component="h1">
              <Trans ns="application" i18nKey='applicant.header'>Applicant</Trans>
            </Typography>
          </div>
          <Grid container alignItems='center' spacing={3}>
            {/* first name input text */}
            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
              <FormikTextField
                required
                ns="application"
                name="applicants.first_name"
                type="text"
                variant="outlined"
                label={<Trans ns='application' i18nKey="label.first_name">First Name</Trans>}
                placeholder={t('application:first_name', 'First name')}
                fullWidth
                className={classes.input}
                autoComplete="off" />
            </Grid>
            {/* last name input text */}
            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
              <FormikTextField
                required
                ns="application"
                name="applicants.last_name"
                type="text"
                variant="outlined"
                label={<Trans ns='application' i18nKey="label.last_name">Last Name</Trans>}
                placeholder={t('application:last_name', 'Last name')}
                fullWidth
                className={classes.input}
                autoComplete="off" />
            </Grid>
            {/* middle name input text */}
            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
              <FormikTextField
                required
                ns="application"
                name="applicants.middle_name"
                type="text"
                variant="outlined"
                label={<Trans ns='application' i18nKey="label.middle_name">Middle Name</Trans>}
                placeholder={t('application:middle_name', 'Middle name')}
                fullWidth
                className={classes.input}
                autoComplete="off" />
            </Grid>
            {/* email input text */}
            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
              <FormikTextField
                inputProps={{ readOnly: true }}
                required
                ns="application"
                name="applicants.email"
                type="email"
                variant="outlined"
                label={<Trans ns='application' i18nKey="label.email">Email</Trans>}
                placeholder={t('application:email', 'Email')}
                fullWidth
                className={classes.input}
                autoComplete="off" />
            </Grid>
            {/* contact no input text */}
            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
              <FormikMaskedInput
                required
                name='applicants.contact_no'
                type='text'
                label={t('application:label.contact_no', 'Contact No.')}
                mask_type='(999) 999-9999'
              />
            </Grid>
            {/* date_of_birth input date */}
            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
              <FormikTextField
                required
                InputLabelProps={{
                  shrink: true,
                }}
                ns="application"
                name="applicant_screening_info.date_of_birth"
                type="date"
                variant="outlined"
                label={<Trans ns='application' i18nKey="label.date_of_birth">DOB</Trans>}
                placeholder={t('application:date_of_birth', 'DOB')}
                fullWidth
                className={classes.input}
                autoComplete="off" />
            </Grid>
            {/* ssn input text */}
            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
              <FormikMaskedInput
                required
                name='applicant_screening_info.ssn'
                type='text'
                label={t('application:label.ssn', 'SSN')}
                mask_type='(999)-(99)-(9999)'
              />
            </Grid>
            {/* dl number input text */}
            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
              <FormikTextField
                ns="application"
                name="applicant_screening_info.dl_number"
                type="text"
                variant="outlined"
                label={<Trans ns='application' i18nKey="label.dl_number">DL Number</Trans>}
                placeholder={t('application:dl_number', 'DL Number')}
                fullWidth
                className={classes.input}
                autoComplete="off" />
            </Grid>
            {/* dl number input text */}
            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
              <FormikTextField
                ns="application"
                name="applicant_screening_info.annual_income"
                type="text"
                variant="outlined"
                label={<Trans ns='application' i18nKey="label.annual_income">Current annual income</Trans>}
                placeholder={t('application:annual_income', 'Current annual income')}
                fullWidth
                className={classes.input}
                autoComplete="off" />
            </Grid>
            {/* contact no 2 input text */}
            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
              <FormikMaskedInput
                name='applicants.contact_no_2'
                type='text'
                label={t('application:label.contact_no_2', 'Alternative Contact No #1')}
                mask_type='(999) 999-9999'
              />
            </Grid>
            {/* contact no 3 input text */}
            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
              <FormikMaskedInput
                name='applicants.contact_no_3'
                type='text'
                label={t('application:label.contact_no_3', 'Alternative Contact No #2')}
                mask_type='(999) 999-9999'
              />
            </Grid>
          </Grid>
          <Grid container alignItems='center' spacing={3}>
            {/* Gender */}
            <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
              <Grid container alignItems="center" justify='flex-start' spacing={2}>
                <Grid item md='auto'>
                  <Trans ns='application' i18nKey='application.form.gender'>
                    <span className={css['label-text']}>Gender</span>
                  </Trans>
                </Grid>
                {genders.map((item, i) => {
                  return (
                    <Grid item xs='auto' sm='auto' md='auto' lg='auto' xl='auto' key={i}>
                      <Chip
                        key={i}
                        color="default"
                        label={item}
                        size='medium'
                        className={`${applicants.gender === item.toUpperCase() ? css['chip-selected'] : css['chip-unselected']}`}
                        onClick={() => props.setFieldValue(`applicants.gender`, item.toUpperCase())}
                        variant='default'
                        name={`applicants.gender`}
                      />
                    </Grid>
                  )
                })}
              </Grid>
            </Grid>
            {/* Maritial Status */}
            <Grid item xs={12} sm={12} md='auto' lg='auto' xl='auto'>
              <Grid container alignItems="center" justify='flex-start' spacing={2}>
                <Grid item md='auto'>
                  <Trans ns='application' i18nKey='application.form.maritial_status'>
                    <span className={css['label-text']}>Maritial Status</span>
                  </Trans>
                </Grid>
                {maritial_statuses.map((item, i) => {
                  return (
                    <Grid item xs='auto' sm='auto' md='auto' lg='auto' xl='auto' key={i}>
                      <Chip
                        key={i}
                        color="default"
                        label={item}
                        className={`${applicant_screening_info.maritial_status === item.toUpperCase() ? css['chip-selected'] : css['chip-unselected']}`}
                        onClick={(e) => {
                          props.setFieldValue(`applicant_screening_info.maritial_status`, item.toUpperCase())
                        }}
                        variant='default'
                      />
                    </Grid>
                  )
                })}
              </Grid>
            </Grid>
          </Grid>
          <Box mt={3} className={css['must-read']}>
            <Link onClick={() => this.setState(prevState => ({ ...prevState, mustRead: true }))}>
              Must Read - Online Application Process
            </Link>
          </Box>
          <Divider className={css['form-divider']} />
        </div>
      </React.Fragment >
    )
  }
}

export default compose(
  withTranslation('application')
)(Applicant)