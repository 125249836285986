import React from 'react'

import { Button } from '@material-ui/core';
import PlacePay from '../../Shared/PlacePayButton'


class PlacePayPlayground extends React.Component {
  componentDidMount() {
    // this.openPlacePay()
  }

  loadPlacePayScript = () => {
    const script = document.createElement("script");
    script.src = "https://placepay.com/plugins/pay.js";
    script.async = true;
    script.onload = () => this.openPlacePay()

    document.body.appendChild(script);
  }

  openPlacePay = () => {
    if (!window.Place || typeof window.Place === 'undefined') {
      return this.loadPlacePayScript()
    }

    // close if already open
    window.Place.pay.close()

    // create new
    const options = {
      key: 'test_public_key_CVWTUkFhQM7LcBpjFpKbQw3z',
      access_token: 'access_token_L5xyL5cg0vKGQ_CX8alj9gl3sOz',
      email: 'akshay.gaikwad@conspecture.com',
      full_name: 'Akshay Gaikwad',
      amount: 13.96,
      description: 'Akshay Gaikwad description',
      custom_fields: {
        billing_account_number: '7878785',
      },
      autopay: false, // envoi true
      on: {
        processing: function (data) {
          console.log("processing", data);
        },
        paid: function (data) {
          console.log("paid", data);

          const transactionId = data.transaction_id;
          console.log(transactionId);
          alert("Your transaction has been processed. This page will refresh in 5 seconds")
          setTimeout(function () {
            window.location.reload();
          }, 5000)
        },
        declined: function (data) {
          console.log("declined", data);
        },
        canceled: function (data) {
          console.log("canceled", data);
        },
        autopay_changed: function (data) {
          console.log("autopay_changed", data);
        },
        loaded: function (data) {
          console.log("loaded", data);
        },
        error: function (data) {
          console.log("error", data);
        },
      }
    };

    window.Place.pay.open(options)
  }

  render() {
    return (
      <React.Fragment>
        {false && <Button variant="contained" color="primary" onClick={this.openPlacePay}>
          Make Payment
        </Button>}

        <PlacePay />
      </React.Fragment>
    )
  }
}

export default PlacePayPlayground