import React, { useContext } from 'react'
import { Trans, withTranslation } from 'react-i18next'
import { compose } from 'recompose'
import { Link } from 'react-router-dom';

import { Form, Formik } from 'formik'

import { getQuery } from '../../../utils/searchParams'
import { AuthContext } from '../../../utils/AppContext'
import http from '../../../utils/http'

import { getUnmaskedNumber } from '../../Shared/FormikMaskedInput'

import css from './Application.module.css'

import { Alert } from '@material-ui/lab'

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

// Tab 1 -
import Applicant from './Form/Applicant'
import CoApplicant from './Form/CoApplicant'

// Tab 2 -
import Address from './Form/Address'

// Tab 3 -
import Employer from './Form/Employer'
import OtherIncome from './Form/OtherIncome'

// Tab 4 -
import Pet from './Form/Pet'
import Vehicle from './Form/Vehicle'

// Tab 5 -
import Reference from './Form/Reference'
import EmergencyContact from './Form/EmergencyContact'
import SignatureDialog from './Form/SignatureDialog'
import Esignature from '../../Esign/Esignature'


import {
  APPLICANT_ADDRESS,
  CO_APPLICANT,

  APPLICANT_EMPLOYER,
  APPLICANT_OTHER_INCOME,

  APPLICANT_PET,
  APPLICANT_VEHICLE,

  APPLICANT_REFERENCE,
  APPLICANT_EMERGENCY_CONTACT,
} from './ApplicantHelpers'
import dummy from './dummy'

import { ConfirmBox, AlertBox } from '../../../utils/DialogHelpers';

import {
  Container,
  Typography,
  CssBaseline,
  createStyles,
  withStyles,
  Grid,
  Button,
  FormControlLabel,
  Checkbox,
  FormControl,
  RadioGroup,
  Radio,
  FormHelperText,
  Divider,
  LinearProgress,
  Box
} from '@material-ui/core'

import Logo from '../../Shared/Logo'
import CheckIcon from '@material-ui/icons/Check';
import InfoIcon from '@material-ui/icons/Info';

import formTabs from './formTabs'
import { getFormValidations } from './formValidation';

// FORM DATA SKELETON, PARTS FETCHED FROM THIS FOR STATE FORMDATA
const formDataTemplate = {
  transunion_terms_of_service: false,

  // -----basic info-----
  applicants: {
    first_name: '',
    last_name: '',
    middle_name: '',
    email: '',
    contact_no: '',
    // signature_ip: null,
    // signature_date: null,
    // signature_data: null,
    applicant_type: ''
  },
  // -----basic info-----

  // ----- applicant_screening_info -----
  applicant_screening_info: {
    ssn: '',
    dl_number: '',
    date_of_birth: '',
    annual_income: null,
    screening_request_renter_uid: null, // only generated when its been submitted
    maritial_status: ''
  },
  // ----- applicant_screening_info -----

  // -----address-----
  applicant_addresses: [
    {
      ...APPLICANT_ADDRESS,
      is_current: true,
    }
  ],
  // -----address-----

  // -----employer-----
  applicant_employers: [
    {
      ...APPLICANT_EMPLOYER,
      is_current: true,
    }
  ],
  // -----employer-----

  // -----other_incomes-----
  applicant_other_incomes: [APPLICANT_OTHER_INCOME],
  // -----other_incomes-----

  // -----pets-----
  applicant_pets: [APPLICANT_PET],
  // -----pets-----

  // -----vehicles-----
  applicant_vehicles: [APPLICANT_VEHICLE],
  // -----vehicles-----

  // -----references-----
  applicant_references: [APPLICANT_REFERENCE],
  // -----references-----

  // -----emergency_contacts-----
  applicant_emergency_contacts: [APPLICANT_EMERGENCY_CONTACT],
  // -----emergency_contacts-----

  // ------ co applicant -----
  co_applicants: [CO_APPLICANT],
  // ------ co applicant -----

  signature_n_approval: {
    // 1. sign checkbox
    signature_data: {},
    signature_date: '',
    signature_ip: '',
    application_consent_to_share_data: false,
    // 2. data consent
    credit_data_approval: '0',
    // 3. terms cond
    application_terms_and_condition: false
  }

  // ...dummy,
}

// @todo - move to dedicated file if  reusing.
/**
 * returns error objects recursively for setTouched()
 * Used on "Submit" click to check all errors and then mark them as touched
 * so that they are printing errors below the inputs.
 * @param {*} errors 
 * @returns 
 */
// const getTouchedObj = (errors) => {
//   if (!errors) {
//     return null;
//   }
//   const touched = {}
//   Object.keys(errors).map(key => {
//     if (Array.isArray(errors[key])) {
//       errors[key].map((val, index) => {
//         if (!touched[key]) {
//           touched[key] = [];
//         }
//         touched[key].push(getTouchedObj(val));
//         return null;
//       });
//     } else {
//       touched[key] = true;
//     }
//     return null;
//   });

//   return touched;
// };

const FILE_SIZE_LIMIT = 5000000; // 5000000 bytes -> 5 MB

class ApplicationForm extends React.Component {
  static contextType = AuthContext

  constructor(props) {
    super(props)

    const getParams = getQuery(this.props.location.search)
    const token = getParams.access_token
    if (!token) {
      // @todo - what to do if no token?
      // this.props.history.push('/404')
    }

    // TAB
    let selectedTab = props.match.params.tab
    if (!selectedTab) {
      selectedTab = 'applicants'
    }
    let tabIndex = formTabs.findIndex(item => item.tabId === selectedTab)
    if (tabIndex === -1) {
      tabIndex = 0
      props.history.push(`/auth/applicant?access_token=${token}`)
    }

    this.delete = {}   // ids of deleted rows

    // dynamic form validations depending on current tab - send to each child component for their formik
    const formValidation = getFormValidations(selectedTab)

    this.state = {
      formSubmitted: false,     // flag telling if form already submitted once
      submitForm: false,        // flag to check whether to submit
      validateOnBlur: false,    //flag to validate on blur/change of inputs

      progress_data: {},
      allow_edit: 0,        // form to be editable or not depending on applicants column

      // progress-
      progressPercent: 0,   // percent of fields attempted && valid
      tabsDone: [],          // keys of tabs that are done n fully valid

      formValidation,
      tabIndex, // 0,1,1...
      streetTypeById: {},
      streetTypes: [],
      statesById: {},
      states: [],
      signDialog: false,
      token,
      mustRead: true,
      hideScrollBtn: true,
      validationState: false,
      saveAsDraftLoading: false,
      activeSection: 'applicants',
      formData: this.getFormData(selectedTab)
    }

    // for div scrolls
    this.sections = {
      applicants: 'Personal Details',
      applicant_addresses: 'Address',
      applicant_employers: 'Employer Details',
      applicant_other_incomes: 'Other Income',
      applicant_pets: 'Pets',
      applicant_vehicles: 'Vehicles',
      applicant_references: 'References',
      applicant_emergency_contacts: 'Emergency Contacts',
    }
    this.prevScroll = 0;
  }

  /* formdata depends on current tab - valid data structure fetched n returned for state's formData */
  getFormData = (tab) => {
    const formData = {
      // applicant id null/id
      applicant_id: null,
    }

    // this.formDataTemplate
    let tabIndex = formTabs.findIndex(item => item.tabId === tab)
    if (tabIndex < 0) {
      tabIndex = 0
    }
    const forms = formTabs[tabIndex].forms

    let obj = {}
    forms.forEach(form => {
      obj[form] = formDataTemplate[form]
    });

    return {
      ...formData,
      ...obj
    }
  }

  componentDidMount() {

    sessionStorage.removeItem('is_mustRead') // remove is_mustRead from sessionStorage on refresh

    this.prevScroll = window.scrollY; // to check scoll direction

    this.context.setTitle('Application Form')

    // states/street dropdown data
    this.getMeta()

    // verify token
    this.verifyToken()

    window.addEventListener('scroll', this.scrollHandlers)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollHandlers)
  }

  // tabs position fixed for mobile view
  scrollHandlers = () => {
    // return if not mobile view
    if (window.innerWidth > 599) {
      return
    }
    const stickyDiv = document.querySelector(`.${css['application-form-header']}`)
    const fixedDiv = document.querySelector(`.${css['fixed-wrap']}`)
    const bottomOffset = document.querySelector(`.${css['progress-bar']}`).getBoundingClientRect().bottom
    if (bottomOffset < 0) {
      fixedDiv.classList.add(`${css['fixed-div']}`)
      stickyDiv.style.position = 'unset';
    } else {
      fixedDiv.classList.remove(`${css['fixed-div']}`)
      stickyDiv.style.position = 'sticky';
    }
  }



  getMeta = () => {
    this.getStates()
    this.getStreetTypes()
  }

  getStates = () => {
    http.get(`/list/states`)
      .then(response => {
        const states = response.data

        const statesById = {};
        states.map(item => statesById[item.id] = { code: item.code, name: item.name });

        this.setState(prevState => ({
          ...prevState,
          statesById,
          states
        }))
      })
  }

  getStreetTypes = () => {
    http.get(`/list/street_types`)
      .then(response => {
        const streetTypes = response.data

        const streetTypeById = {};
        streetTypes.map(item => streetTypeById[item.id] = item.street_suffix);

        this.setState(prevState => ({
          ...prevState,
          streetTypeById,
          streetTypes
        }))
      })
  }

  /* formats data - according to current tab */
  formatResponse = (responseData) => {
    const { statesById, streetTypeById } = this.state
    const currentTab = responseData.currentTab
    // const currentTab = formTabs[tabIndex].tabId

    if (responseData.applicant_screening_info && typeof responseData.applicant_screening_info !== 'undefined') {

      // ssn empty
      responseData.applicant_screening_info.ssn = ''

      // maritial status
      responseData.applicant_screening_info.maritial_status = responseData.applicant_screening_info.maritial_status ?? 'MARRIED'

    }

    /* note - applicants always sent for now but still IF dala */
    if (responseData.applicants && typeof responseData.applicants !== 'undefined') {
      // default values if not sent
      responseData.applicants.gender = responseData.applicants.gender ?? 'MALE'

      responseData.applicants.contact_no_2 = responseData.applicants.contact_no_2 ?? ''
      responseData.applicants.contact_no_3 = responseData.applicants.contact_no_3 ?? ''

      // not needed
      delete responseData.applicants.id
      delete responseData.applicants.property_id
      delete responseData.applicants.property_name

      // no data found? add empty fields, current tab should be 1st one else false is pushed to draft
      if (currentTab === 'applicants' && typeof responseData.transunion_terms_of_service === 'undefined') {
        responseData.transunion_terms_of_service = formDataTemplate.transunion_terms_of_service
      }
    }

    // for emergency conatct && refernces
    const relationship = {
      'SIBLING': 'Sibling',
      'SPOUSE': 'Spouse',
      'CHILD': 'Child',
      'PARENT': 'Parent',
      'NOT_DIRECTLY_RELATED': 'Not directly related',
      'FRIEND': 'Friend',
      'OTHER': 'Other',
      'CO_SIGNER': 'Co-signer'
    }

    /* ---- FORMAT_CHANGES_FOR : ADDRESSES ---- */
    if (currentTab === 'addresses') {
      // state & street
      for (let i = 0; i < responseData.applicant_addresses.length; i++) {
        const address = responseData.applicant_addresses[i];

        // obj to update
        let tempAddressObj = {
          landlord_contact_no: address.landlord_contact_no ?? '',
          landlord_contact_no_2: address.landlord_contact_no_2 ?? '',
          landlord_contact_no_3: address.landlord_contact_no_3 ?? '',
        }

        //  NEED FOLLOWING CODE WHEN ID IS NOT OBJ (for autocomplete)
        if (typeof address.street_type_id !== 'object') {
          tempAddressObj = {
            ...tempAddressObj,
            street_type_id: {
              id: address.street_type_id,
              label: streetTypeById[address.street_type_id] ? streetTypeById[address.street_type_id] : ''
            }
          }
        }
        if (typeof address.state_id !== 'object') {
          tempAddressObj = {
            ...tempAddressObj,
            state_id: {
              id: address.state_id,
              label: (statesById[address.state_id]) ? statesById[address.state_id].name : ''
            }
          }
        }

        responseData.applicant_addresses[i] = {
          ...responseData.applicant_addresses[i],
          ...tempAddressObj
        }
      }

      // address if empty
      responseData.applicant_addresses = (responseData.applicant_addresses.length > 0) ? responseData.applicant_addresses : this.state.formData.applicant_addresses

      // no data found? add empty fields
      if (typeof responseData.applicant_addresses === 'undefined') {
        responseData.applicant_addresses = formDataTemplate.applicant_addresses
      }

      // not needed other than 1st tab
      delete responseData.applicants
    }

    /* ---- FORMAT_CHANGES_FOR : EMPLOYERS & INCOMES ---- */
    if (currentTab === 'employers_n_other_incomes') {

      /* NOTE : `from_draft` will have "applicant_employers", "applicant_other_incomes" */

      // employer
      for (let i = 0; i < responseData.applicant_employers.length; i++) {
        const employer = responseData.applicant_employers[i];

        // for state
        let tempEmployerObj = {}

        /* STATES IN EMPLOYERS ( db + draft has code, UI has whole obj ) */
        if (typeof employer.state_code !== 'undefined') {
          // draft has statecode
          const stateObj = this.getStateByCode(employer.state_code)
          tempEmployerObj = {
            ...tempEmployerObj,
            state_id: {
              id: stateObj.id,
              label: stateObj.name,
              code: stateObj.code
            }
          }
        }

        responseData.applicant_employers[i] = {
          ...responseData.applicant_employers[i],
          ...tempEmployerObj
        }
      }

      // applicant_employers if empty
      responseData.applicant_employers = (responseData.applicant_employers.length > 0) ? responseData.applicant_employers : this.state.formData.applicant_employers

      // applicant_other_incomes if empty
      responseData.no_other_income = responseData.applicant_other_incomes.length === 0
      responseData.applicant_other_incomes = responseData.no_other_income ? [] : responseData.applicant_other_incomes

      // not needed other than 1st tab
      delete responseData.applicants

      // no data found? add empty fields
      if (typeof responseData.applicant_employers === 'undefined') {
        responseData.applicant_employers = formDataTemplate.applicant_employers
      }
      if (typeof responseData.applicant_other_incomes === 'undefined') {
        responseData.applicant_other_incomes = formDataTemplate.applicant_other_incomes
      }
    }

    /* ---- FORMAT_CHANGES_FOR : PETS & VEHICLES ---- */
    if (currentTab === 'pets_n_vehicles') {

      /* NOTE : `from_draft` will have "applicant_pets", "applicant_vehicles" */

      // applicant_pets if empty
      responseData.no_pets = responseData.applicant_pets.length === 0
      responseData.applicant_pets = responseData.no_pets ? [] : responseData.applicant_pets

      // applicant_vehicles if empty
      responseData.applicant_vehicles = (responseData.applicant_vehicles.length > 0) ? responseData.applicant_vehicles : []

      // not needed other than 1st tab
      delete responseData.applicants

      // no data found? add empty fields
      if (typeof responseData.applicant_pets === 'undefined') {
        responseData.applicant_pets = formDataTemplate.applicant_pets
      }
      if (typeof responseData.applicant_vehicles === 'undefined') {
        responseData.applicant_vehicles = formDataTemplate.applicant_vehicles
      }
    }

    /* ---- FORMAT_CHANGES_FOR : REFERENCES & EMERGENCY CONTACTS ---- */
    if (currentTab === 'references_n_emergency_contacts') {

      // undefined? make it []
      responseData.applicant_references = (typeof responseData.applicant_references !== 'undefined') ? responseData.applicant_references : []
      responseData.applicant_emergency_contacts = (typeof responseData.applicant_emergency_contacts !== 'undefined') ? responseData.applicant_emergency_contacts : []

      // emergency contacts
      for (let i = 0; i < responseData.applicant_emergency_contacts.length; i++) {
        const em_contact = responseData.applicant_emergency_contacts[i];

        // need following code when data comes from db, not draft
        let tempEmergencyObj = {}
        if (em_contact.relationship) {
          tempEmergencyObj = {
            ...tempEmergencyObj,
            relationship: {
              id: em_contact.relationship,
              label: relationship[em_contact.relationship]
            },
          }
        }
        /* STATES IN EMPLOYERS ( db + draft has code, UI has whole obj ) */
        if (typeof em_contact.state_code !== 'undefined') {
          // draft has statecode
          const stateObj = this.getStateByCode(em_contact.state_code)
          tempEmergencyObj = {
            ...tempEmergencyObj,
            state_id: {
              id: stateObj.id,
              label: stateObj.name,
              code: stateObj.code
            }
          }
        }

        responseData.applicant_emergency_contacts[i] = {
          ...responseData.applicant_emergency_contacts[i],
          ...tempEmergencyObj
        }
      }

      // references
      for (let i = 0; i < responseData.applicant_references.length; i++) {
        const reference = responseData.applicant_references[i];

        // need following code when data comes from db, not draft
        let tempReferenceObj = {}
        if (reference.relationship) {
          tempReferenceObj = {
            ...tempReferenceObj,
            relationship: {
              id: reference.relationship,
              label: relationship[reference.relationship]
            },
          }
        }

        responseData.applicant_references[i] = {
          ...responseData.applicant_references[i],
          ...tempReferenceObj
        }
      }

      // applicant_references if empty
      responseData.applicant_references = (responseData.applicant_references.length > 0) ? responseData.applicant_references : this.state.formData.applicant_references

      // applicant_emergency_contacts if empty
      responseData.applicant_emergency_contacts = (responseData.applicant_emergency_contacts.length > 0) ? responseData.applicant_emergency_contacts : this.state.formData.applicant_emergency_contacts

      /*
        signature_n_approval - key added for last tab
        to be used to store and save data of -
          1. signature
          2. radio (approve sharing data)
          3. checkbox (terms n cond)
      */
      if (typeof responseData.signature_n_approval === 'undefined') {
        responseData.signature_n_approval = {}
      }
      responseData.signature_n_approval = Object.assign(formDataTemplate['signature_n_approval'], responseData.signature_n_approval)

      if (responseData.applicant_screening_info && typeof responseData.applicant_screening_info !== 'undefined') {
        responseData.signature_n_approval.screening_request_renter_uid = responseData.applicant_screening_info.screening_request_renter_uid
      }
      if (!responseData.signature_n_approval.screening_request_renter_uid || typeof responseData.signature_n_approval.screening_request_renter_uid === 'undefined') {
        responseData.signature_n_approval.screening_request_renter_uid = null
      }

      responseData.signature_n_approval.applicant_full_name = `${responseData.applicants.first_name} ${responseData.applicants.last_name}`

      // not needed other than 1st tab
      delete responseData.applicants

      // no data found? add empty fields
      if (typeof responseData.applicant_references === 'undefined') {
        responseData.applicant_references = formDataTemplate.applicant_references
      }
      if (typeof responseData.applicant_emergency_contacts === 'undefined') {
        responseData.applicant_emergency_contacts = formDataTemplate.applicant_emergency_contacts
      }
    }

    return responseData
  }

  /**
   * searching states bby code
   * returning state { id:xx , name: xx, code: xx }
   */
  getStateByCode = (code) => {
    const { states } = this.state

    const filtered = states.filter(item => item.code === code)
    if (!filtered.length) {
      return null
    }

    return filtered[0]
  }

  /* getData */
  verifyToken = async () => {
    const { token } = this.state
    http.interceptors.request.use(config => {
      config.headers['token'] = token
      return config
    })

    const queryParams = { tab: formTabs[this.state.tabIndex].tabId }

    http.get(`/applicant/application`, {
      params: queryParams,
      headers: { token: this.state.token }
    })
      .then(async (response) => {
        const applicant_id = response.data.applicants.id

        let applicant_first_name = ''
        if (response.data.applicants) {
          applicant_first_name = response.data.applicants.first_name
        }

        const progress_data = response.data.applicants.progress_data
        const allow_edit = response.data.applicants.allow_edit

        // check if draft data available
        if (response.data.applicants.draft_data) {
          const draft_data = JSON.parse(JSON.stringify(response.data.applicants.draft_data))
          delete draft_data.applicants.draft_data
          response.data = draft_data // no need to format
        } else {
          delete response.data.applicants.draft_data // no need of this in  formik formdata
          response.data = await this.formatResponse(response.data)
        }

        this.setState(prevState => {

          return {
            ...prevState,
            allow_edit,
            progress_data,
            validationState: {
              type: 'success',
              message: `user.verified`,
              user: {
                name: applicant_first_name
              },
            },
            formSubmitted: (typeof response.data.formSubmitted !== 'undefined') ? response.data.formSubmitted : false,
            formData: {
              // ...prevState.formData, // commented cuz only present tab data needed
              applicant_id: applicant_id,
              ...response.data
            }
          }
        })

        setTimeout(() => {
          this.showProgress(progress_data)
        }, 500);

      }, error => {
        this.context.snackbar(<Trans ns='applicants' i18nKey={error.data}>{error.data}</Trans>, {
          variant: 'error'
        })

        this.setState(prevState => ({
          ...prevState,
          validationState: {
            type: 'error',
            message: error.data
          }
        }))
      })
  }

  componentDidUpdate(prevProps) {
    // if form was submitting, but now is not submitting because it is invalid
    if (prevProps.isSubmitting && !this.props.isSubmitting && !this.props.isValid) {
      // and assume you've added refs to each input: `ref={ i => this[name] = i}` , then...
      this[Object.keys(this.props.errors)[0]].focus()
      // or do other imperative stuff that DOES NOT SET STATE 
      // smoothScroll(Object.keys(this.props.errors)[0].<offsetY | or whatever>)
    }
  }

  /**
   * 1. API will save formdata in draft data of this applicantId
   * 2. if state has submitForm, final submit API called
   * @param "current tab's form data"
   */
  saveAsDraft = async (draft_data, redirectToNextTab = false) => {
    const { t } = this.props
    const confirm = await ConfirmBox.show({
      title: t('application:save_as_draft.confirm.title', 'Save Tab Data'),
      message: t('application:save_as_draft.confirm.message', 'Save this tab data before proceeding ?'),
    })

    if (!confirm) {
      return false
    }

    this.setState(prevState => {
      return {
        ...prevState,
        saveAsDraftLoading: true
      }
    })

    draft_data = this.formatBeforeDraft(draft_data)

    const progress_data = await this.getTabErrorCounts(draft_data)

    http.post(`/applicant/application/draft`, { draft_data, progress_data }).then(_response => {

      // go to next tab if possible
      const nextTabIndex = this.getTabErrorIndex()
      if (nextTabIndex < formTabs.length) {

        if (redirectToNextTab) {
          // redirect to next tab if save_n_continue && not lastTab
          this.redirectToTab(nextTabIndex)
        }

        this.scrollToTop()
      }

      // if submit request -> trigger submit api (else just draft)
      if (this.state.submitForm) {
        this.submitForm()
      }
    }, error => {
      this.context.snackbar(<Trans ns='application' i18nKey={error.data}></Trans>, {
        variant: 'error'
      })
    }).finally(() => {
      this.setState(prevState => {
        return {
          ...prevState,
          saveAsDraftLoading: false,
          validateOnBlur: false
        }
      })
    })
  }

  /* present tab ka draft data se error count n return */
  getTabErrorCounts = async (draft_data) => {
    const count_inputs = document.querySelectorAll('input[required]').length

    const { tabIndex } = this.state
    await this.childComponentFormikProps.validateForm();
    const errors = this.childComponentFormikProps.errors

    // let fieldsCount = 0  // not reqd anymore as dom inputs are counted
    let invalidFieldsCount = 0

    for (const section of formTabs[tabIndex].forms) {

      const draft_values = draft_data[section] // applicant/addreses etc ka values
      const error_values = errors[section] // formik errors
      const errors_present = !(Object.keys(errors) === 0)

      if (typeof draft_values === 'undefined') {
        // extra keys, not needed to check
        continue
      }

      // 1. object, not array
      if (typeof draft_values === 'object' && !Array.isArray(draft_values)) {
        // loop through each field, check if error present
        for (const field of Object.keys(draft_values)) {
          if (errors_present && typeof error_values !== 'undefined' && typeof error_values[field] !== 'undefined') {
            // ye fields ko error hai
            invalidFieldsCount++
          }
          // fieldsCount++
        }
      }

      // 2. object, array
      if (typeof draft_values === 'object' && Array.isArray(draft_values)) {
        for (const index in draft_values) {
          // Below eg - nth row of addresses {addr1:'', city:''}
          const n_draft = draft_values[index]
          // Below eg - nth key of errors for addresses (null if no error) [null,{"street_name": "applicant_addresses[i].street_name.required"}]
          const n_error = (typeof error_values !== 'undefined') ? error_values[index] : {}

          // assuming obj rahega each row values
          if (typeof n_draft === 'object') {

            // each field
            for (const field of Object.keys(n_draft)) {
              if (errors_present && typeof n_error !== 'undefined' && typeof n_error[field] !== 'undefined') {
                // ye fields ko error hai
                invalidFieldsCount++
              }

              // fieldsCount++
            }

          } else {
            console.error(`${section}[${index}] is not object, it's : `, n_draft)
          }
        }
      }

      // 3. boolean
      if (typeof draft_values === 'boolean') {
        if (typeof error_values !== 'undefined' && typeof error_values[section] !== 'undefined') {
          // ye fields ko error hai
          invalidFieldsCount++
        }

        // fieldsCount++
      }
    }

    const obj = {}
    obj[formTabs[tabIndex].tabId] = {
      total: count_inputs,
      invalids: invalidFieldsCount
    }
    return obj
  }

  // get index of tab having error or next tab
  getTabErrorIndex = () => {
    const { progress_data, tabIndex } = this.state
    let startTabIndex = tabIndex
    let nextTab = formTabs.length - 1 // intialise so if all tabs valid then return last tab

    // if progress_data is empty
    if (!progress_data) {
      if (tabIndex === formTabs.length - 1) {
        // current tab is last then return same tab
        return tabIndex
      } else {
        // return next tab
        return tabIndex + 1
      }
    }

    // current tab is last then loop from first tab
    if (tabIndex === formTabs.length - 1) {
      startTabIndex = -1
    }

    // loop through next tabs
    for (let i = startTabIndex + 1; i < formTabs.length; i++) {
      // if tab is present in progress_data and is invalid
      // OR
      // if tab is not present in progress_data
      // then return same tab
      const tabName = progress_data[formTabs[i].tabId]
      if ((tabName && tabName.invalids > 0) || (!tabName)) {
        nextTab = i
        break
      }
    }

    return nextTab
  }

  /**
   * show progress percent and success ticks
   * @param progress_data (json)
   */
  showProgress = (progress_data) => {
    progress_data = progress_data ?? {}

    const validTabs = []
    let total = 0
    let invalids = 0

    /*
      for the tabs that are not yet attempted (not present in db progress_data),
      we're considering that all the fields are invalid
      how - counting all fields of such tabs
    */
    const { other_total, other_invalids } = this.findOtherTabFields(progress_data)
    total += other_total
    invalids += other_invalids


    // current tab ka required inputs
    const { tabIndex } = this.state

    if (!Object.keys(progress_data).includes(formTabs[tabIndex].tabId)) {
      // this tab not yet in progress data, add fields
      const count_inputs = document.querySelectorAll('input[required]').length
      total += count_inputs
      invalids += count_inputs
    }

    /*
      now looping through db progress data to get
    */
    Object.keys(progress_data).map(tabId => {

      const tabProgress = progress_data[tabId]

      // 1. valid tabs
      if (tabProgress.invalids === 0) {
        validTabs.push(tabId)
      }

      // 2. counts of total, invalids for all-tabs ka percent
      total += tabProgress.total
      invalids += tabProgress.invalids

      // 3. present tab ka percent (in-case needed)
      // const tabValids = tabProgress.total - tabProgress.invalids
      // const tabPercent = (tabValids / tabProgress.total) * 100

      return null
    })

    const valids = (total - invalids)
    const allFieldsPercent = Math.round((valids / total) * 100)
    // console.log(`....ALL TABS ka percent : ${allFieldsPercent}`)

    this.setState(prevState => ({
      ...prevState,
      progressPercent: allFieldsPercent,
      tabsDone: validTabs,
    }))
  }

  /* other tabs fields count when count is not present in progress_data */
  findOtherTabFields = (progress_data) => {

    const { tabIndex } = this.state

    let other_total = 0
    let other_invalids = 0

    formTabs.map((form, index) => {
      if (index !== tabIndex && !Object.keys(progress_data).includes(form.tabId)) {
        // other tabs? add counts

        /* NOTE : reqdFields is static in formtabs file */
        const reqdFields = formTabs[index].reqdFields
        other_total += reqdFields
        other_invalids += reqdFields
      }

      return null
    })

    /* commented below - counts form data ka all fields, not just reqd ones */
    /* for (const form of formTabs) {
      for (const section of form.forms) {
        if (typeof formDataTemplate[section] !== 'undefined') {
          if (!Object.keys(progress_data).includes(section)) {
            // abtak attempt nahi hua hai ye tab - add keys + all invalid

            let section_type = typeof formDataTemplate[section]
            if (Array.isArray(formDataTemplate[section])) {
              section_type = 'array'
            }

            // single field, Ignore as the key is already present in one of the keys
            // if (type === 'boolean') {}

            // object keys are fields, loop thru them
            if (section_type === 'object') {
              const fieldsObj = formDataTemplate[section]

              Object.keys(fieldsObj).map(_field => {
                // console.log(field)
                // all fields to be considered invalid
                other_total++
                other_invalids++

                return null
              })
            }

            // array could be more than the ones in default formDataTemplate, loop thru them
            if (section_type === 'array') {
              const fieldsArr = formDataTemplate[section]
              fieldsArr.map(fieldsObj => {
                Object.keys(fieldsObj).map(field => {
                  // all fields to be considered invalid
                  other_total++
                  other_invalids++

                  return null
                })
                return null
              })
            }
          }
        } else {
          console.error(section, ' section not found in formDataTemplate')
        }

      }
    } */

    return { other_total, other_invalids }
  }

  // redirects to 0th,1st,2nd tab
  redirectToTab = (nextTabIndex) => {
    const nextTab = formTabs[nextTabIndex].tabId

    // update validations before the form is rendered
    const formValidation = getFormValidations(nextTab)

    // url updates
    this.props.history.push(`/auth/applicant/${nextTab}?access_token=${this.state.token}`)

    // update tab data
    this.setState(prevState => ({
      ...prevState,
      formValidation,
      validateOnBlur: true,
      tabIndex: nextTabIndex
    }), () => {
      this.verifyToken()
    })
  }

  // formatiing data before it's saved as draft, like ssn mask etc
  formatBeforeDraft = (draft_data) => {
    const data = JSON.parse(JSON.stringify(draft_data))

    // delete these ids
    data.delete = this.delete

    if (typeof data.applicants !== 'undefined') {
      // applicants
      data.applicants.contact_no = getUnmaskedNumber(data.applicants.contact_no)
      data.applicants.contact_no_2 = getUnmaskedNumber(data.applicants.contact_no_2)
      data.applicants.contact_no_3 = getUnmaskedNumber(data.applicants.contact_no_3)

      try {
        delete data.applicants.signature_data
        delete data.applicants.signature_ip
        delete data.applicants.signature_date
        delete data.applicants.progress_data
      } catch (_e) { }
    }

    if (typeof data.applicant_screening_info !== 'undefined') {
      // applicant_screening_info
      data.applicant_screening_info.ssn = getUnmaskedNumber(data.applicant_screening_info.ssn)

      if (data.applicant_screening_info.dl_number) {
        data.applicant_screening_info.dl_number = '' + data.applicant_screening_info.dl_number
      }
    }

    if (typeof data.applicant_addresses !== 'undefined') {
      // applicant_addresses
      data.applicant_addresses.forEach(applicant_address => {
        // applicant_address.street_type_id = applicant_address.street_type_id.id
        // applicant_address.state_id = applicant_address.state_id.id
        applicant_address.landlord_contact_no = getUnmaskedNumber(applicant_address.landlord_contact_no)
        applicant_address.landlord_contact_no_2 = getUnmaskedNumber(applicant_address.landlord_contact_no_2)
        applicant_address.landlord_contact_no_3 = getUnmaskedNumber(applicant_address.landlord_contact_no_3)

        if (typeof applicant_address.street_type_id === 'object' && applicant_address.street_type_id.id !== undefined) {
          applicant_address.street_type_id = applicant_address.street_type_id.id
        }
        if (typeof applicant_address.state_id === 'object' && applicant_address.state_id.id !== undefined) {
          applicant_address.state_id = applicant_address.state_id.id
        }

        try {
          delete applicant_address.street_type
        } catch (_) {
          // nothing
        }
      })
    }

    if (typeof data.applicant_employers !== 'undefined') {
      // applicant_employers
      data.applicant_employers.forEach((applicant_employer, index) => {
        applicant_employer.supervisor_contact_no = getUnmaskedNumber(applicant_employer.supervisor_contact_no)
        applicant_employer.supervisor_contact_no_2 = getUnmaskedNumber(applicant_employer.supervisor_contact_no_2)
        applicant_employer.supervisor_contact_no_3 = getUnmaskedNumber(applicant_employer.supervisor_contact_no_3)

        // 1st employer is current employer
        applicant_employer.is_current = (index === 0) ? true : false
        if (index === 0) {
          applicant_employer.end_date = ''
          applicant_employer.reason_for_leaving = ''
        }

        if (typeof applicant_employer.state_id === 'object' && applicant_employer.state_id.code !== undefined) {
          applicant_employer.state_code = applicant_employer.state_id.code
          delete applicant_employer.state_id
        }
      })
    }

    if (typeof data.applicant_other_incomes !== 'undefined') {
      // applicant_other_incomes - no_other_income
      if (data.no_other_income === true) {
        data.applicant_other_incomes = [];
      }
      delete data.no_other_income;
    }

    if (typeof data.applicant_pets !== 'undefined') {
      // applicant_pets - no_pets
      if (data.no_pets === true) {
        data.applicant_pets = [];
      }
      delete data.no_pets;
    }

    if (typeof data.applicant_references !== 'undefined') {
      // applicant_references
      data.applicant_references.forEach(applicant_reference => {
        applicant_reference.contact_no = getUnmaskedNumber(applicant_reference.contact_no)

        // relationships always saved n fetched ID
        applicant_reference.relationship = (applicant_reference.relationship && typeof applicant_reference.relationship !== 'undefined') ? applicant_reference.relationship.id : null
      })
    }

    if (typeof data.applicant_emergency_contacts !== 'undefined') {
      // applicant_emergency_contacts
      data.applicant_emergency_contacts.forEach(applicant_emergency_contact => {
        applicant_emergency_contact.contact_no = getUnmaskedNumber(applicant_emergency_contact.contact_no)

        if (typeof applicant_emergency_contact.relationship !== 'undefined') {
          // relationships always saved n fetched ID
          applicant_emergency_contact.relationship = applicant_emergency_contact.relationship.id
        }

        if (typeof applicant_emergency_contact.state_id === 'object' && applicant_emergency_contact.state_id.code !== undefined) {
          applicant_emergency_contact.state_code = applicant_emergency_contact.state_id.code
          delete applicant_emergency_contact.state_id
        }
      })
    }

    // signature_n_approval
    if (typeof data.signature_n_approval !== 'undefined') {
      delete data.signature_n_approval.applicant_full_name
    }

    Object.keys(data).map(item => {
      delete data[item].from_draft
      return null
    })

    // not needed in db draft_data
    delete data.from_draft
    delete data.formSubmitted
    if (typeof data.co_applicants !== 'undefined') {
      delete data.co_applicants
    }

    return data
  }

  /**
   * table: applicant_addresses, id: 12
   * preparing array of IDs to be deleted from each table types
   */
  deleteRecord = (table, id) => {
    if (typeof this.delete[table] === 'undefined') {
      this.delete[table] = []
    }
    this.delete[table].push(id)
  }

  /**
   * scrolls to top
  */
  scrollToTop = () => {
    window.scrollTo({
      behavior: "smooth",
      top: 0
    })
  }

  handleChangeTabs = (event, value) => {
    let data = { tabIndex: value }
    const selectedTab = formTabs[value].tabId
    this.delete = {} // made empty for each tab, else keeps appending

    // update validations before the form is rendered
    const formValidation = getFormValidations(selectedTab)

    this.setState(prevState => ({
      ...prevState,
      validateOnBlur: false,
      formValidation,
      ...data
    }), () => {
      // get && update data
      this.verifyToken()
      this.scrollToTop()
    }
    )
  }

  /*
    validates and saves as draft

    NOTE : rn full formik props is binded - incase we need error keys etc - can be customised,
            reference : https://stackoverflow.com/a/53383909
    SUBMIT used cuz - submit touches + validates form fields (shows red error)
    tried only validateForm, it doesn't touch the fields so red error was not shown
  */
  validateForm = async () => {

    if (this.childComponentFormikProps) {
      await this.childComponentFormikProps.submitForm();

      const errors = this.childComponentFormikProps.errors

      if (!Object.keys(errors).length) {
        // all valid? save current tab adta in draft
        this.saveAsDraft(this.childComponentFormikProps.values, true)
      } else {
        // submit but some error - show formik error
        console.error(this.childComponentFormikProps.errors)
      }
    } else {
      console.error('FormikProps not ready.')
    }
  }

  /**
   * tries to focus to first error field
   */
  focusToFormikError = () => {
    if (!this.childComponentFormikProps) {
      return false
    }

    setTimeout(() => {
      const props = this.childComponentFormikProps
      const errorsArr = Object.keys(props.errors)

      // touches field(s)
      // const getTouched = getTouchedObj(props.errors)
      // props.setTouched(getTouched)

      if (errorsArr.length) {
        let firstErr = errorsArr[0]

        let defaultError = 'Something went wrong'
        try {
          defaultError = props.errors[firstErr]

          if (typeof (defaultError) === "object") {
            // defaultError (used for snackbar) - needs to be string
            const first_key_arr = Object.values(defaultError)
            defaultError = first_key_arr[0]

            // eg - 2nd address has error, first key is undefined.. keep finding valid error obj if any
            if (typeof defaultError === 'undefined' && first_key_arr.length > 1) {
              first_key_arr.forEach(item => {
                if (typeof item !== 'undefined') {
                  defaultError = item
                }
              })
            }
          }
          let selector = ''
          if (typeof (props.errors[firstErr]) === 'object') {
            // get first index
            const firstChild = Object.keys(props.errors[firstErr])[0];

            if (Array.isArray(props.errors[firstErr])) {
              // applicant_addresses[0].zip
              const firstChildIndex = Object.keys(props.errors[firstErr][0])[0]
              selector = `[name="${firstErr}[${firstChild}].${firstChildIndex}"]`;
            } else {
              // applicants.middle_name
              selector = `[name="${firstErr}.${firstChild}"]`;
            }
          } else {
            selector = `[name="${firstErr}"]`;
          }
          document.querySelector(selector).focus()
        } catch (err) {
          if (typeof (defaultError) === "object") {
            defaultError = 'please.revisit.error.fields'
          }
          this.context.snackbar(<Trans ns='application' i18nKey={defaultError}>{defaultError}</Trans>, {
            variant: 'error'
          })
        }
      }
    }, 500);
  }

  /**
   * simply saves tab data as draft
   */
  saveTabForm = async () => {
    if (this.childComponentFormikProps) {
      this.saveAsDraft(this.childComponentFormikProps.values, false)
    } else {
      console.error('FormikProps not ready.')
    }
  }

  /**
   * save and submit clicked (last tab)
   * validate present form, save in draft
   * then trigger submit api
   */
  saveSubmit = async () => {

    this.setState(prevState => ({
      ...prevState,
      submitForm: true,
      validateOnBlur: true,
    }), this.validateForm)

    // focus errors
    this.focusToFormikError()
  }

  /**
   * confirms if cancel needed,
   * hit cancel api
   * redirecting to finish when done
   */
  cancelApplication = async () => {
    const { t } = this.props
    const confirm = await ConfirmBox.show({
      title: t('application:cancel_application.confirm.title', 'Cancel Application'),
      message: t('application:cancel_application.confirm.message',
        "You are about to cancel this application. You'll not have access to any links of this application. Cancel this application?"),
    })

    if (!confirm) {
      return false
    }

    http.post(`applicant/cancel-application`)
      .then(response => {

        this.context.snackbar(<Trans ns='application' i18nKey='applicant.cancelled'>Application Cancelled. Redirecting soon.</Trans>, {
          variant: 'success'
        })

        setTimeout(() => {
          const { token } = this.state
          this.props.history.push(`/applicant/application-cancelled?access_token=${token}`)
        }, 2000);

      }, error => {
        this.context.snackbar(<Trans ns='application' i18nKey={error.data}>{error.data}</Trans>, {
          variant: 'error'
        })
      })
  }

  /**
   * triggers submit api
   * called after validateForm IFF state has submitForm true
   */
  submitForm = () => {
    const { t } = this.props

    http.post(`applicant/save-submit`)
      .then(async (response) => {

        await AlertBox.show({
          title: 'Success',
          message: t('application:application.submitted', 'Successfully submitted!')
        })

        const { token } = this.state
        this.props.history.push(`/applicant/payment?access_token=${token}`)

      }, error => {
        if (typeof error.data.joi_error !== 'undefined') {

          this.context.snackbar(<Trans ns='application' i18nKey={error.data.joi_error}>{error.data.joi_error}</Trans>, {
            variant: 'error'
          })

          // JOI invalid? redirect to tab
          const arr = error.data.joi_error.split(',')

          let tabIdx = 0
          let tabName = ''
          if (arr.length > 1) {
            tabName = arr[0]

          } else {
            const temp = error.data.joi_error.split('.')
            if (temp.length > 1)
              tabName = temp[0]
          }

          // find tab index to redirect to
          formTabs.map((item, idx) => {
            if (item.forms.includes(tabName)) {
              tabIdx = idx
            }
            return null
          })

          // redirect
          this.redirectToTab(tabIdx)

          // trigger validation of that tab
          setTimeout(async () => {
            if (this.childComponentFormikProps) {
              await this.childComponentFormikProps.submitForm();
            }
          }, 500);
        } else {
          this.context.snackbar(<Trans ns='application' i18nKey={error.data}>{error.data}</Trans>, {
            variant: 'error'
          })
        }
      }).finally(() => {
        // revert flag to false, else next draftSave shall trigger submit again
        this.setState(prevState => ({ ...prevState, submitForm: false }))
      })
  }

  // will hold access to formikProps, to trigger form submission outside of the form
  childComponentFormikProps = null;

  // method to bind formik ka submit
  bindFormikProps = (validateForm) => {
    this.childComponentFormikProps = validateForm;
  };

  render() {
    const { validationState, formSubmitted, formData, tabIndex, token, formValidation, validateOnBlur, progressPercent, tabsDone, allow_edit } = this.state
    const { classes } = this.props

    return (
      <React.Fragment>

        <div className={css['application-wrap']}>

          <Grid container>
            <Grid item xs={12} sm={4} md={3} lg={2} xl={2}>
              <div className={css['application-form-header']}>
                <div className={css['login-logo']}>
                  <Logo />
                </div>
                <Typography component="h1" variant="h5">
                  <Trans ns="application" i18nKey='application.form'>Application</Trans>
                </Typography>

                {/* Progress Bar */}
                <div className={css['progress-bar']}>
                  <Box width="100%">
                    <LinearProgress variant="determinate" value={progressPercent} />
                  </Box>
                  <Box display="flex" alignItems="center" justifyContent="space-between" mt={1}>
                    <Box className={css['progress-label']}>
                      Completed
                    </Box>
                    <Box className={css['progress-count']}>
                      {progressPercent}%
                    </Box>
                  </Box>
                </div>

                <div className={css['fixed-wrap']}>
                  {/* Progress Info */}
                  {formSubmitted && !allow_edit && <div className={css['progress-info']}>
                    <Box display="flex" alignItems="flex-start" justifyContent="flex-start">
                      <Box display="flex" mr={1}>
                        <InfoIcon />
                      </Box>
                      <Box className={css['info']}>
                        <Trans ns="application" i18nKey='progress.info.form.disabled'>The form is disabled because it is already submitted.
                          Kindly contact the Property Manager if there are any changes.</Trans>
                      </Box>
                    </Box>
                  </div>}

                  {(validationState.message && validationState.type === 'success') && (
                    <React.Fragment>
                      <Tabs
                        aria-label="application_form_tabs"
                        value={tabIndex}
                        onChange={this.handleChangeTabs}
                        variant='fullWidth'
                        scrollButtons="auto"
                        className={css['form-tabs-container']}
                        orientation={(window.innerWidth > 599) ? 'vertical' : 'horizontal'}
                        TabIndicatorProps={{
                          style: {
                            display: "none",
                          },
                        }}
                      >
                        {formTabs.map((item, index) => {
                          return (
                            <Tab
                              className={css['form-tabs']}
                              label={
                                <>
                                  <div className={css['tab-label']}>{item.tabLabel}</div>
                                  {tabsDone.includes(item.tabId) && <CheckIcon />}
                                </>
                              }
                              component={Link}
                              key={index}
                              to={`/auth/applicant/${item.tabId}?access_token=${token}`} />)
                        })}
                      </Tabs>
                    </React.Fragment>
                  )}
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={8} md={9} lg={10} xl={10}>
              {/* before data loading / error msgs */}
              <div className={css['please-wait']}>
                <div className={css['please-wait-con']}>

                  {!validationState && <div>
                    <Trans ns="application" i18nKey='verification.loading'>Please wait while we validate your invitation....</Trans>
                  </div>
                  }

                  {(validationState.message && validationState.type === 'error') &&
                    <Alert
                      severity={validationState.type}>
                      <Trans ns="application" values={validationState.user} i18nKey={validationState.message}></Trans>
                    </Alert>
                  }
                </div>
              </div>

              {/* Main container */}
              <div className={css['application-container']}>

                {(validationState.message && validationState.type === 'success') && (
                  <React.Fragment>

                    {/* right - application formdiv starts - */}
                    <div className={`${css['application-form']} ${allow_edit} ${!allow_edit ? css['disable-form-edit'] : ''}`}>
                      <Container component="main" maxWidth="xl">
                        <CssBaseline />

                        <div className={`${classes.paper} ${css['form-height']}`}>

                          {/* APPLICATION FORMIK starts - */}
                          {formData.tab_name !== 'undefined' && <React.Fragment>

                            {/* ---- Tab 1 : Applicants ---- */}
                            {(formTabs[tabIndex].tabId === 'applicants' && formData.currentTab === 'applicants') &&
                              <React.Fragment>
                                <FormikForm
                                  validateOnBlur={validateOnBlur}
                                  selectedTab={formTabs[tabIndex].tabId}
                                  components={[Applicant]}
                                  props={formData}
                                  bindFormikProps={this.bindFormikProps}
                                  formValidation={formValidation}
                                  classes={classes}
                                />

                                {/* ---- co applicant section ---- */}
                                {(formData.applicants.applicant_type === 'PRIMARY') &&
                                  <div className={`${classes.form}`} id='co_applicants'>
                                    <CoApplicant props={formData} allow_edit={allow_edit} />
                                  </div>}
                              </React.Fragment>
                            }

                            {/* ---- Tab 2 : Addresses ---- */}
                            {(formTabs[tabIndex].tabId === 'addresses' && formData.currentTab === 'addresses') &&
                              <FormikForm
                                validateOnBlur={validateOnBlur}
                                selectedTab={formTabs[tabIndex].tabId}
                                components={[Address]}
                                props={formData}
                                bindFormikProps={this.bindFormikProps}
                                formValidation={formValidation}
                                classes={classes}
                                streetTypes={this.state.streetTypes}
                                states={this.state.states}
                                deleteRecord={this.deleteRecord}
                              />
                            }

                            {/* ---- Tab 3 : Employers, Incomes ---- */}
                            {(formTabs[tabIndex].tabId === 'employers_n_other_incomes' && formData.currentTab === 'employers_n_other_incomes') &&
                              <FormikForm
                                validateOnBlur={validateOnBlur}
                                selectedTab={formTabs[tabIndex].tabId}
                                components={[Employer, OtherIncome]}
                                deleteRecord={this.deleteRecord}
                                props={formData}
                                bindFormikProps={this.bindFormikProps}
                                formValidation={formValidation}
                                classes={classes}
                                states={this.state.states}
                              />
                            }

                            {/* ---- Tab 4 : Pets, Vehicles ---- */}
                            {(formTabs[tabIndex].tabId === 'pets_n_vehicles' && formData.currentTab === 'pets_n_vehicles') &&
                              <FormikForm
                                validateOnBlur={validateOnBlur}
                                selectedTab={formTabs[tabIndex].tabId}
                                components={[Pet, Vehicle]}
                                deleteRecord={this.deleteRecord}
                                props={formData}
                                bindFormikProps={this.bindFormikProps}
                                formValidation={formValidation}
                                classes={classes}
                              />
                            }

                            {/* ---- Tab 5 : References, Emergency contacts ---- */}
                            {(formTabs[tabIndex].tabId === 'references_n_emergency_contacts' && formData.currentTab === 'references_n_emergency_contacts') &&
                              <FormikForm
                                validateOnBlur={validateOnBlur}
                                selectedTab={formTabs[tabIndex].tabId}
                                components={[Reference, EmergencyContact]}
                                deleteRecord={this.deleteRecord}
                                props={formData}
                                bindFormikProps={this.bindFormikProps}
                                formValidation={formValidation}
                                signDialog={this.state.signDialog}
                                classes={classes}
                                states={this.state.states}
                                handleOnSign={(data, props) => {
                                  const newValues = { ...props.values }
                                  newValues.signature_n_approval.signature_ip = data.ipAddress;
                                  newValues.signature_n_approval.signature_date = data.timeStamp;
                                  newValues.signature_n_approval.signature_data = {
                                    type: data.type,
                                    content: data.image ? data.image : data.font
                                  };
                                  newValues.signature_n_approval.application_consent_to_share_data = true;

                                  props.setValues(newValues)

                                  console.log("Signed", data)
                                  this.setState({ signDialog: false })
                                }}
                                handleCancel={(props) => {
                                  const newValues = { ...props.values }
                                  newValues.signature_n_approval.signature_ip = null;
                                  newValues.signature_n_approval.signature_date = null;
                                  newValues.signature_n_approval.signature_data = {};
                                  newValues.signature_n_approval.application_consent_to_share_data = false;

                                  props.setValues(newValues)

                                  console.log("Cancelled")
                                  this.setState({ signDialog: false })
                                }}
                                handleConsent={(props) => {
                                  const uid = props.values.signature_n_approval.screening_request_renter_uid;
                                  // screening request already generated and it is checked.
                                  // we will allow changing it only if it was not ticked or application is still not initialted

                                  if (!!uid && props.values.signature_n_approval.application_consent_to_share_data) {
                                    // @todo change UI
                                    this.context.snackbar(<Trans ns='applicants' i18nKey='consent_after_application_initiated'>
                                      Application process initiated. Modifications are not allowed
                                    </Trans>, {
                                      variant: 'error'
                                    })
                                    return;
                                  }

                                  this.setState({ signDialog: true })
                                }}
                              />
                            }
                          </React.Fragment>}
                        </div>

                        {/* --- buttons --- */}
                        {/* NOTE : submit-butn-main class used for allow-edit logic too (css)! */}
                        <div className={css['submit-butn-main']}>
                          <Grid container alignItems='center' justify='center'>
                            {/* SAVE - saves in draft w/o validatn, not visible if already submitted once */}
                            {tabIndex !== 0 && <Grid item>
                              <Box component="span">
                                <Button
                                  // loading={saveAsDraftLoading ? "1" : "0"}
                                  color="primary"
                                  variant='contained'
                                  onClick={() => {
                                    // redirect to prev tab
                                    this.redirectToTab(tabIndex - 1)
                                  }}
                                  // disabled={saveAsDraftLoading}
                                  className='white-bg-button'>

                                  <Trans ns="application" i18nKey="button.previous">Previous</Trans>
                                </Button>
                              </Box>
                            </Grid>}

                            {/* SAVE - saves in draft w/o validatn, not visible if already submitted once */}
                            {!formSubmitted && <Grid item>
                              <Box component="span" ml={(tabIndex !== 0) ? 2 : 0}>
                                <Button
                                  // loading={saveAsDraftLoading ? "1" : "0"}
                                  color="primary"
                                  variant='contained'
                                  onClick={this.saveTabForm}
                                  // disabled={saveAsDraftLoading}
                                  className='white-bg-button'>

                                  <Trans ns="application" i18nKey="button.save">Save</Trans>
                                </Button>
                              </Box>
                            </Grid>}


                            {/* SAVE CONTINUE - saves in draft + goes next tab */}
                            {((tabIndex + 1) < formTabs.length) && <Grid item>
                              {/* validate and next, always visible */}
                              <Box component="span" ml={2}>
                                <Button
                                  // loading={saveAsDraftLoading ? "1" : "0"}
                                  color="primary"
                                  variant='contained'
                                  onClick={() => {
                                    this.setState(prevState => ({
                                      ...prevState,
                                      validateOnBlur: true
                                    }), this.validateForm)

                                    // focus errors
                                    this.focusToFormikError()

                                  }}
                                  // disabled={saveAsDraftLoading}
                                  className='form-button'>
                                  <Trans ns="application" i18nKey="button.save_n_continue">Save & Continue</Trans>
                                </Button>
                              </Box>
                            </Grid>}

                            {/* Save & Submit - only last */}
                            {(tabIndex + 1 >= formTabs.length) && <Grid item>
                              <Box component="span" ml={2}>
                                <Button
                                  // loading={saveAsDraftLoading ? "1" : "0"}
                                  color="primary"
                                  variant='contained'
                                  onClick={this.saveSubmit}
                                  // disabled={saveAsDraftLoading}
                                  className='form-button'>
                                  <Trans ns="application" i18nKey="button.submit_application">Submit Application</Trans>
                                </Button>
                              </Box>
                            </Grid>}

                            {/* cancel application btn only after once submitted */}
                            {(formSubmitted) && <Grid item>
                              <Box component="span" ml={2}>
                                <Button
                                  color="inherit"
                                  variant='contained'
                                  onClick={this.cancelApplication}
                                  className={`${css['override-disable-form-edit']} ${css['cancel-app-btn']}`}>
                                  <Trans ns="application" i18nKey="button.cancel_application">Cancel Application</Trans>
                                </Button>
                              </Box>
                            </Grid>}
                          </Grid>
                        </div>
                      </Container>
                    </div>
                  </React.Fragment>
                )}
              </div>

            </Grid>
          </Grid>

        </div>
      </React.Fragment >
    )
  }
}

// @todo remove this
const useStyles = createStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(1, 0, 2),
  },
  input: {
    margin: theme.spacing(1, 0, 2),
  },
}))


export default compose(
  withStyles(useStyles),
  withTranslation('application')
)(ApplicationForm);


/**
 * common method to create form for each tab
 * formik tags here && child component(s) called inside them
 */
function FormikForm(formProps) {
  const context = useContext(AuthContext);

  const {
    components,
    props,
    selectedTab,
    formValidation,
    bindFormikProps,
    deleteRecord,
    streetTypes,
    states,
    validateOnBlur,
    signDialog,
    handleOnSign,
    handleCancel,
    handleConsent,
    classes
  } = formProps

  let sectionArr = []
  formTabs.map(obj => {
    if (obj.tabId === selectedTab) {
      sectionArr = obj.forms
    }
    return null
  })

  // for file upload references
  let applicantEmployersFilesRef = []
  let applicantPetsFilesRef = []
  let applicantOtherIncomesFilesRef = []

  /*
    onChange validation for last tab (checkboxes hai)
    rest tabs only 'validateOnBlur' checked for speed issue
  */
  let validateOnChange = false
  if (selectedTab === 'references_n_emergency_contacts') {
    validateOnChange = (typeof validateOnBlur !== 'undefined') ? validateOnBlur : false
  }

  // get file path from api
  const handleFileUpload = (tab_section, tab_index) => {
    const refMap = {
      pets: applicantPetsFilesRef,
      employers: applicantEmployersFilesRef,
      other_incomes: applicantOtherIncomesFilesRef
    }

    // file reference of tab section
    const fileRef = refMap[tab_section][tab_index]

    let error = ''

    const upload_data = new FormData()

    for (let index = 0; index < fileRef.current.files.length; index++) {
      const file = fileRef.current.files[index]

      if (file.size > FILE_SIZE_LIMIT) {
        error = "Files with a large size are skipped.";
        continue;
      }
      upload_data.append(`file[${index}]`, file)
    }

    if (error) {
      context.snackbar(<Trans ns='application' i18nKey={`${tab_section}.file.size.error`}>{error}</Trans>, {
        variant: 'error'
      })
    }

    // post files to get file info
    const files = http.post(`/applicant/upload-docs`, upload_data).then(
      response => response.data,
      error => {
        context.snackbar(<Trans ns='application' i18nKey={error.data}></Trans>, {
          variant: 'error'
        })
        return []
      })
    return files
  }

  return (
    <React.Fragment>
      <Formik
        initialValues={props}
        validationSchema={formValidation}
        validateOnMount={false}
        validateOnBlur={(typeof validateOnBlur !== 'undefined') ? validateOnBlur : false}
        validateOnChange={validateOnChange}
        onSubmit={async (values, action) => {
          action.setSubmitting(false)
        }}
      >
        {props => {
          const { values } = props
          // create references for files
          if (values.applicant_pets && !!values.applicant_pets.length) {
            applicantPetsFilesRef = values.applicant_pets.map(() => React.createRef())
          }
          if (values.applicant_employers && !!values.applicant_employers.length) {
            applicantEmployersFilesRef = values.applicant_employers.map(() => React.createRef())
          }
          if (values.applicant_other_incomes && !!values.applicant_other_incomes.length) {
            applicantOtherIncomesFilesRef = values.applicant_other_incomes.map(() => React.createRef())
          }

          /*
            reference (submit example, we are binding formik-props instead) : https://stackoverflow.com/a/53383909
            bind the props remotely
          */
          bindFormikProps(props);
          return (
            <React.Fragment>
              {(['development', 'staging'].includes(process.env.REACT_APP_ENV)) && <Grid container className={css['auto-fill-div']} justify='flex-end'>
                <Grid item>
                  {/* NOTE : className auto-fill also used for allow-edit logic too! */}
                  <div className={css['auto-fill']} onClick={() => {
                    const applyDummy = window.confirm('Autofill the form with dummy data for demo?')
                    if (applyDummy) {
                      let newValues = {
                        ...props.values
                      }
                      sectionArr.map(section => {
                        newValues[section] = dummy[section]
                        return null
                      })

                      // need applicants keys
                      newValues = {
                        ...newValues,
                        applicants: {
                          ...props.values.applicants,
                          ...dummy.applicants,
                        },
                        signature_n_approval: {
                          ...props.values.signature_n_approval,
                        }
                      }

                      try {
                        // todo - check code before draft to see what all to be removed
                        // delete newValues.from_draft
                        // delete newValues.currentTab
                        delete newValues.formSubmitted
                        delete newValues.applicant_id
                      } catch (error) {
                        // nothing
                      }

                      props.setValues(newValues)
                    }
                  }}>Auto Fill</div>
                </Grid>
              </Grid>}

              <Form className={`${classes.form}`} noValidate={true} id={selectedTab}>
                {components.map((Component, index) => {
                  return (<React.Fragment key={index}>
                    <Component

                      // file references and function to return current files used for Income and Pets-Vehicles tabs
                      applicantPetsFilesRef={applicantPetsFilesRef}
                      applicantEmployersFilesRef={applicantEmployersFilesRef}
                      applicantOtherIncomesFilesRef={applicantOtherIncomesFilesRef}
                      handleFileUpload={handleFileUpload}

                      // street + state only used for address
                      streetTypes={streetTypes}
                      states={states}

                      // delete record used for all except 1st tab (applicants)
                      deleteRecord={deleteRecord}

                      props={props}
                      classes={classes}
                    />
                  </React.Fragment>)
                })}

                {/* last tab ? show sign, consent etc */}
                {(selectedTab === 'references_n_emergency_contacts' && typeof props.values.signature_n_approval !== 'undefined') &&
                  <div className={css['form-grp']}>
                    <React.Fragment>
                      <div className={css['spacing-bottom']}>
                        {/* 1. signature */}
                        <Grid container>
                          {/* signature popup */}
                          {signDialog && <SignatureDialog
                            applicantName={props.values.signature_n_approval.applicant_full_name}
                            onSign={(data) => {
                              handleOnSign(data, props)
                            }}
                            onCancel={() => {
                              handleCancel(props)
                            }} />}
                          {/* checkbox that opens sign */}
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <div className={css['checkbox']}>
                              <FormControlLabel
                                labelPlacement="end"
                                label={<React.Fragment>
                                  <span style={{ color: 'red' }}>* </span>
                                  <Trans ns='application' i18nKey="label.application_consent_to_share_data">
                                    I hereby give my consent to share my data for landlord and employer verifications
                                  </Trans>
                                </React.Fragment>}
                                control={<Checkbox
                                  required
                                  checked={values.signature_n_approval.application_consent_to_share_data}
                                  name="signature_n_approval.application_consent_to_share_data"
                                  onChange={(_event, _value) => {
                                    handleConsent(props)
                                  }}
                                  color="primary"
                                />} />
                            </div>

                            {/* error - */}
                            {props.getFieldMeta('signature_n_approval.application_consent_to_share_data').touched && props.getFieldMeta('signature_n_approval.application_consent_to_share_data').error && <React.Fragment>
                              <FormHelperText error={true}>
                                <Trans ns='application' i18nKey={props.getFieldMeta('signature_n_approval.application_consent_to_share_data').error} />
                              </FormHelperText>
                            </React.Fragment>}
                          </Grid>
                          {props.values.signature_n_approval.signature_data && !!Object.keys(props.values.signature_n_approval.signature_data).length && <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Esignature signature_data={{
                              type: props.values.signature_n_approval.signature_data.type,
                              full_name: props.values.signature_n_approval.applicant_full_name,
                              content: props.values.signature_n_approval.signature_data.content,
                              signature_date: props.values.signature_n_approval.signature_date,
                              signature_ip: props.values.signature_n_approval.signature_ip
                            }} />
                          </Grid>}
                        </Grid>

                        <Divider className={css['form-divider']} />
                      </div>

                      <div className={css['spacing-bottom']}>
                        {/* 2. approve data share */}
                        <Grid container justify="flex-start">
                          <FormControl component="fieldset">
                            <RadioGroup
                              aria-label="credit_data_approval"
                              name="signature_n_approval.credit_data_approval"
                              value={values.signature_n_approval.credit_data_approval}
                              onChange={(_event, newValue) => {
                                props.setFieldValue(`signature_n_approval.credit_data_approval`, newValue)
                              }}>

                              {/* radio 1 : approve */}
                              <div className={css['radio-btn']}>
                                <FormControlLabel
                                  value="1"
                                  control={<Radio color="primary" />}
                                  label={<Trans ns='application' i18nKey="label.credit_data_approval.approve_text">
                                    You approve we send your credit data to landlord
                                  </Trans>} />
                              </div>
                              {props.getFieldMeta('signature_n_approval.credit_data_approval').touched && props.getFieldMeta('signature_n_approval.credit_data_approval').error && <React.Fragment>
                                <FormHelperText error={true}>
                                  <Trans ns='application' i18nKey={props.getFieldMeta('signature_n_approval.credit_data_approval').error} />
                                </FormHelperText>
                              </React.Fragment>}

                              {/* radio 2 : deny */}
                              <Box mt={1} className={css['radio-btn']}>
                                <FormControlLabel
                                  value="0"
                                  control={<Radio color="primary" />}
                                  label={<Trans ns='application' i18nKey="label.credit_data_approval.reject_text">
                                    You reject we send your credit data to landlord
                                  </Trans>} />
                              </Box>

                            </RadioGroup>

                            <Box mt={1}>
                              <FormHelperText>
                                <Trans ns='application' i18nKey="label.credit_data_approval.helper_text">
                                  Note: If you reject, we cannot process your form.
                                </Trans>
                              </FormHelperText>
                            </Box>

                          </FormControl>
                        </Grid>

                        {/* 3. terms n conditions */}
                        <Grid container justify="flex-start">
                          <Box mt={1} className={css['checkbox']}>
                            <FormControlLabel
                              labelPlacement="end"
                              label={<React.Fragment>
                                <span style={{ color: 'red' }}>* </span>
                                <Trans ns='application' i18nKey="label.application_terms_and_condition">Terms and Conditions</Trans>
                              </React.Fragment>}
                              control={<Checkbox
                                required
                                checked={values.signature_n_approval.application_terms_and_condition}
                                name="signature_n_approval.application_terms_and_condition"
                                onChange={(_event, newValue) => props.setFieldValue("signature_n_approval.application_terms_and_condition", newValue)}
                                color="primary"
                              />} />
                          </Box>
                          {props.getFieldMeta('signature_n_approval.application_terms_and_condition').error && <React.Fragment>
                            <FormHelperText error={true}>
                              <Trans ns='application' i18nKey={props.getFieldMeta('signature_n_approval.application_terms_and_condition').error} />
                            </FormHelperText>
                          </React.Fragment>}
                        </Grid>
                      </div>
                    </React.Fragment>
                  </div>}
              </Form>
            </React.Fragment>
          )
        }}
      </Formik>
    </React.Fragment>
  )
}