import React from 'react'

import { AuthContext } from '../../utils/AppContext';

class Logo extends React.Component {
  static contextType = AuthContext

  render() {
    let property_logo = process.env.REACT_APP_PUBLIC_LOGO

    if (this.context.portalData.company_logo) {
      property_logo = this.context.portalData.company_logo
    }

    if (typeof this.context.portalData.property_logo !== 'undefined' && this.context.portalData.property_logo !== '') {
      property_logo = this.context.portalData.property_logo
    }

    return <img src={property_logo} alt='Logo' />
  }
}

export default Logo