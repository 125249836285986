import yupGeneric from '../../../../utils/yupGeneric'
import * as yup from 'yup'
import { getUnmaskedNumber } from '../../../Shared/FormikMaskedInput'

const today = new Date().setHours(0, 0, 0, 0) // get date only without time to matches with current date

export const GuestcardContactformValidation = (appointment_date, show_lease_terms, show_marketing_sources) =>

  yup.object(
    {
      first_name: yup.string()
        .typeError(yupGeneric)
        .min(2, yupGeneric)
        .max(50, yupGeneric)
        .required(yupGeneric),
      middle_name: yup.string()
        .typeError(yupGeneric)
        .min(1, yupGeneric)
        .max(1, yupGeneric),
      last_name: yup.string()
        .typeError(yupGeneric)
        .min(2, yupGeneric)
        .max(50, yupGeneric)
        .required(yupGeneric),
      email: yup.string()
        .typeError(yupGeneric)
        .max(50, yupGeneric)
        .required(yupGeneric)
        .email(yupGeneric),
      contact_no: yup.string()
        .typeError(yupGeneric)
        .test('Contact no',
          "applicants.contact_no.min",
          (value) => {
            if (!value) {
              return false;
            }
            const clean = getUnmaskedNumber(value)
            if (!clean) {
              return false;
            }
            // length check
            return clean.length === 10
          },
        )
        .required(yupGeneric),
      appointment_date:
        appointment_date ?
          yup.string()
            .test('appointment_date',
              "applicants.appointment_date.min",
              (value) => {
                if (!value) {
                  return false;
                }
                const appointment_date = new Date(value).getTime()
                return appointment_date > today
              },
            )
            .typeError(yupGeneric)
            .required(yupGeneric) :
          yup.string()
            .test('appointment_date',
              "applicants.appointment_date.min",
              (value) => {
                if (!value) {
                  return true;
                }
                const appointment_date = new Date(value).getTime()
                return appointment_date > today
              },
            )
            .typeError(yupGeneric)
            .optional(yupGeneric),
      date_needed: yup
        .string()
        .test('movein_date',
          "applicants.movein_date.min",
          (value) => {
            if (!value) {
              return false;
            }
            const movein_date = new Date(value).getTime()
            return movein_date >= today
          },
        )
        .typeError(yupGeneric)
        .required(yupGeneric),
      lease_term: show_lease_terms ? yup.string().typeError(yupGeneric).required(yupGeneric) : yup.string().typeError(yupGeneric).optional(yupGeneric),
      address1: yup
        .string()
        .typeError(yupGeneric)
        .required(yupGeneric),
      address2: yup.string()
        .typeError(yupGeneric)
        .optional(yupGeneric),
      address3: yup.string().optional().nullable().typeError(yupGeneric),
      city: yup.string()
        .typeError(yupGeneric)
        .max(50, yupGeneric)
        .required(yupGeneric),
      state_code: yup.string()
        .typeError(yupGeneric)
        .required(yupGeneric),
      zip_code: yup.string()
        .typeError(yupGeneric)
        .matches(/^[0-9]+$/, yupGeneric) // only number
        .min(5, yupGeneric)
        .max(5, yupGeneric)
        .length(5, yupGeneric)
        .required(yupGeneric),
      community_code: yup
        .string()
        .typeError(yupGeneric),
      price_range: yup
        .string()
        .typeError(yupGeneric),
      marketing_source: show_marketing_sources ? yup.string().typeError(yupGeneric).required(yupGeneric) :
        yup.string().typeError(yupGeneric).optional(yupGeneric),
      leasing_agent: yup
        .string()
        .typeError(yupGeneric)
        .optional(yupGeneric),
      moving_reason: yup
        .string()
        .typeError(yupGeneric),
      comments: yup
        .string()
        .typeError(yupGeneric),
    })

export default GuestcardContactformValidation