import React from 'react'
import { Trans } from 'react-i18next';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText
} from '@material-ui/core';

class ResponseDialog extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      invite_url: "",
      response_dialog_open: false,
    }
  }

  componentDidMount() {
    this.setState({
      invite_url: this.props.invite_url,
      response_dialog_open: this.props.response_dialog_open
    })
  }

  componentDidUpdate() {
    if (this.state.response_dialog_open !== this.props.response_dialog_open) {
      this.setState({
        invite_url: this.props.invite_url,
        response_dialog_open: this.props.response_dialog_open
      })
    }
  }

  render() {
    const {
      invite_url,
      response_dialog_open
    } = this.state

    return (
      <React.Fragment>
        <Dialog
          open={response_dialog_open}
          onClose={this.props.toggleResponseDialog}>
          <DialogTitle>
            <Trans ns="application" i18nKey='applicant.dialogtitle'>
              Guestcard submitted successfully.
            </Trans>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please Click &nbsp;
              <a href={invite_url} target="_blank" rel="noopener noreferrer">
                here
              </a>
              &nbsp; to complete your application.
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </React.Fragment>
    )
  }
}

export default ResponseDialog