import { combineReducers } from "redux";
import { rolesReducer } from './rolesReducer'
import { columnsReducer } from "./columnsReducer";
import { policiesReducer } from "./policiesReducer";
import { stateListReducer } from "./stateListReducer";

export default combineReducers({
  roles: rolesReducer,
  columns: columnsReducer,
  allPolicies: policiesReducer,
  state_list: stateListReducer,
});
