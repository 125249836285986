import React from 'react'

import DateFormat from '../../utils/DateFormat';
import { Typography } from '@material-ui/core';
// import css from '../Applicants/Applicant.module.css'
import css from '../Applicant/ApplicantSign.module.css'

class Esignature extends React.Component {

  render() {

    const { signature_data } = this.props

    return (
      <React.Fragment>
        <div className={css['signature-container']}>
          {signature_data.type === 'type' && <>
            <Typography component="h2"
              className={css['signature-font']}
              style={{ fontFamily: signature_data.content }}>
              {signature_data.full_name}
            </Typography>
          </>}

          {signature_data.type === 'draw' && <>
            <div className='signature-image-div'>
              <img src={signature_data.content} className={css['signature-image-div-img']} alt='signature' />
            </div>
          </>}

          <Typography component="div" className={css['signature-note']}>
            Signed digitally on <DateFormat format={'m/d/Y H:i:s'}>{signature_data.signature_date}</DateFormat> from IP address {signature_data.signature_ip}
          </Typography>
        </div>
      </React.Fragment>
    )
  }
}

export default Esignature