import yupGeneric from '../../../../utils/yupGeneric'
import * as yup from 'yup'

const makePaymentFormValidation = (params) => {
  const { payment_gateways } = params;

  const isCard = {
    is: 'CARD',
    then: payment_gateways['CARD']?.payment_gateway === 'PLACEPAY' ? yup.string().optional() : yup.string().required(yupGeneric)
  }

  const isCheck = {
    is: 'CHECKING',
    then: payment_gateways['CHECKING']?.payment_gateway === 'PLACEPAY' ? yup.string().optional() : yup.string().required(yupGeneric)
  }

  return yup.object({
    address1: yup.string().trim().typeError(yupGeneric).min(4).required(yupGeneric),
    address2: yup.string().typeError(yupGeneric).optional(),
    city: yup.string().typeError(yupGeneric).optional(),
    state_code: yup.string().typeError(yupGeneric).optional(),
    zip_code: yup.string()
      .typeError(yupGeneric)
      .matches(/^[0-9]+$/, yupGeneric) // only number
      .min(5, yupGeneric)
      .max(5, yupGeneric)
      .required(yupGeneric),

    payment_type: yup.string()
      .typeError(yupGeneric)
      .required(yupGeneric),
    card_no: yup.string() // @todo credit card validation
      .typeError(yupGeneric)
      .matches(/^[0-9]+$/, yupGeneric) // only number
      .when('payment_type', isCard),
    expiry_month: yup.string()
      .typeError(yupGeneric)
      .when('payment_type', isCard),
    expiry_year: yup.string()
      .typeError(yupGeneric)
      .when('payment_type', isCard),
    cvv: yup.string()
      .typeError(yupGeneric)
      .matches(/^[0-9]+$/, yupGeneric) // only number
      .min(3, yupGeneric)
      .max(4, yupGeneric)
      .when('payment_type', isCard),
    routing_number: yup.string()
      .typeError(yupGeneric)
      .matches(/^[0-9]+$/, yupGeneric) // only number
      .when('payment_type', isCheck),
    account_number: yup.string()
      .typeError(yupGeneric)
      .matches(/^[0-9]+$/, yupGeneric) // only number
      .when('payment_type', isCheck),
    account_holders_name: yup.string()
      .typeError(yupGeneric)
      .when('payment_type', isCard)
      .when('payment_type', isCheck),
    account_type: yup.string()
      .typeError(yupGeneric)
      .when('payment_type', isCheck)
  })
}

export default makePaymentFormValidation;