import React from 'react'
import { Trans, withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import FormikTextField from '../../../Shared/FormikTextField'
import FormikMaskedInput from '../../../Shared/FormikMaskedInput'
import FormikSelect from '../../../Shared/FormikSelect';
import FormikAutoComplete from '../../../Shared/FormikAutoComplete'
import FormikDateField from '../../../Shared/FormikDateField'

import { ConfirmBox } from '../../../../utils/DialogHelpers';

import css from './GuestcardContactForm.module.css'
import dummy from './dummy'

import { preLoadStateList } from '../../../../store/stateListReducer';

import {
  Grid,
  MenuItem
} from '@material-ui/core';


class GuestcardContactForm extends React.Component {
  constructor(props) {
    super(props)

    const appointment_date_exceptions = this.props.data.guestcard_contact_form_data.appointment_date_exceptions

    this.state = {
      appointment_date_exceptions: appointment_date_exceptions
    }
  }


  toggleResponseDialog = () => this.setState(prevState => ({ ...prevState, response_dialog_open: !prevState.response_dialog_open }))

  autofill = async () => {
    const confirm = await ConfirmBox.show({
      title: 'Autofill',
      message: 'This will autofill this form with dummy data, Are you sure?'
    })

    if (!confirm) {
      return false
    }

    const { data } = this.props

    const { formikProps } = data

    formikProps.setValues({
      ...formikProps.values,
      guestcard_contact_form: {
        ...formikProps.values.guestcard_contact_form,
        ...dummy
      }
    })
  }


  disableDaysofWeek = (date) => {
    const { appointment_date_exceptions } = this.state

    return appointment_date_exceptions.includes(date.weekday())
  }

  render() {
    const { t, data, state_list } = this.props
    const { guestcard_contact_form_data, formikProps } = data

    const {
      community_codes,
      marketing_sources,
      moving_reasons,
      price_ranges,
      show_appointment_date,
      leasing_agents,
      show_lease_term
    } = guestcard_contact_form_data

    const lease_terms = Array.from({ length: 19 }, (_, i) => i + 6) // range(6, 24)

    return (
      <React.Fragment>
        <div className={css['guestcard-contact-form']}>

          <div className='form-field'>
            <Grid container justify='center' alignItems="center">
              <Grid item>
                <Grid container alignItems="flex-start" spacing={2}>
                  {/* first name input text */}
                  <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                    <FormikTextField
                      required
                      ns="application"
                      name="guestcard_contact_form.first_name"
                      type="text"
                      variant="outlined"
                      label={<Trans ns='application' i18nKey="label.first_name">First Name</Trans>}
                      placeholder={t('application:first_name', 'First name')}
                      fullWidth
                      autoComplete="off" />
                  </Grid>
                  {/* middle name input text */}
                  <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                    <FormikTextField
                      ns="application"
                      name="guestcard_contact_form.middle_name"
                      type="text"
                      variant="outlined"
                      label={<Trans ns='application' i18nKey="label.middle_initial">Middle Initial</Trans>}
                      placeholder={t('application:middle_initial', 'Middle Initial')}
                      fullWidth
                      autoComplete="off" />
                  </Grid>
                  {/* last name input text */}
                  <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                    <FormikTextField
                      required
                      ns="application"
                      name="guestcard_contact_form.last_name"
                      type="text"
                      variant="outlined"
                      label={<Trans ns='application' i18nKey="label.last_name">Last Name</Trans>}
                      placeholder={t('application:last_name', 'Last name')}
                      fullWidth
                      autoComplete="off" />
                  </Grid>
                  {/* email input text */}
                  <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                    <FormikTextField
                      required
                      ns="application"
                      name="guestcard_contact_form.email"
                      type="email"
                      variant="outlined"
                      label={<Trans ns='application' i18nKey="label.email">Email</Trans>}
                      placeholder={t('application:email', 'Email')}
                      fullWidth
                      autoComplete="off" />
                  </Grid>
                  {/* contact no input text */}
                  <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                    <div className={css['error-label']}>
                      <FormikMaskedInput
                        required
                        ns="application"
                        name="guestcard_contact_form.contact_no"
                        type='text'
                        label={t('application:label.contact_no', 'Contact No.')}
                        placeholder={t('application:label.contact_no', 'Contact No.')}
                        mask_type='(999) 999-9999'
                      />
                    </div>
                  </Grid>
                  {/* appointment_date input date */}
                  <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                    <div className='date-filter'>
                      <FormikDateField
                        ns="application"
                        name='guestcard_contact_form.appointment_date'
                        label={<Trans ns='application' i18nKey="label.appointment_date">Appointment Date</Trans>}
                        type="date"
                        placeholder={t('application:appointment_date', 'Appointment Date')}
                        fullWidth
                        shouldDisableDate={this.disableDaysofWeek}
                        required={show_appointment_date}
                      />
                    </div>
                  </Grid>
                  {/* move in date input date */}
                  <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                    <div className='date-filter'>
                      <FormikDateField
                        ns="application"
                        name="guestcard_contact_form.date_needed"
                        label={<Trans ns='application' i18nKey="label.date_needed">Move in Date</Trans>}
                        type="date"
                        placeholder={t('application:date_needed', 'Move in Date')}
                        fullWidth
                        required
                      />
                    </div>
                  </Grid>
                  {/* lease_term dropdown */}
                  {show_lease_term && <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                    <div className={`${css['select-dropdown']} form-select-box`}>
                      <FormikSelect
                        name='guestcard_contact_form.lease_term'
                        label={<Trans ns='application' i18nKey="label.lease_term">Lease Term</Trans>}
                        variant="outlined"
                        required
                        fullWidth
                        autoWidth={true}>
                        {lease_terms.map(item => <MenuItem value={item} key={item}>{item}</MenuItem>)}
                      </FormikSelect>
                    </div>
                  </Grid>}
                  {/* community_codes dropdown */}
                  {(community_codes && (community_codes).length > 0) && <React.Fragment>
                    <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                      <div className={`${css['select-dropdown']} form-select-box`}>
                        <FormikSelect
                          name='guestcard_contact_form.community_code'
                          label={<Trans ns='application' i18nKey="label.community_code">Community Code</Trans>}
                          variant="outlined"
                          fullWidth
                          autoWidth={true}>
                          {(community_codes).map((item, index) => <MenuItem value={item.code} key={index}>{item.description}</MenuItem>)}
                        </FormikSelect>
                      </div>
                    </Grid>
                  </React.Fragment>}

                  {/* leasing_agents dropdown */}
                  {(leasing_agents && (leasing_agents).length > 0) && <React.Fragment>
                    <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                      <div className={`${css['select-dropdown']} form-select-box`}>
                        <FormikSelect
                          name='guestcard_contact_form.leasing_agent'
                          label={<Trans ns='application' i18nKey="label.leasing_agent">Leasing Agent</Trans>}
                          variant="outlined"
                          required
                          fullWidth
                          autoWidth={true}>
                          {(leasing_agents).map((item, index) => <MenuItem value={item.code} key={index}>{item.description}</MenuItem>)}
                        </FormikSelect>
                      </div>
                    </Grid>
                  </React.Fragment>}

                  {/* marketing_sources dropdown */}
                  {(marketing_sources && (marketing_sources).length > 0) && <React.Fragment>
                    <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                      <div className={`${css['select-dropdown']} form-select-box`}>
                        <FormikSelect
                          name='guestcard_contact_form.marketing_source'
                          label={<Trans ns='application' i18nKey="label.marketing_source">Marketing Source</Trans>}
                          variant="outlined"
                          required
                          fullWidth
                          autoWidth={true}>
                          {(marketing_sources).map((item, index) => <MenuItem value={item.code} key={index}>{item.description}</MenuItem>)}
                        </FormikSelect>
                      </div>
                    </Grid>
                  </React.Fragment>}

                  {/* moving_reasons dropdown */}
                  {(moving_reasons && (moving_reasons).length > 0) && <React.Fragment>
                    <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                      <div className={`${css['select-dropdown']} form-select-box`}>
                        <FormikSelect
                          name='guestcard_contact_form.moving_reason'
                          label={<Trans ns='application' i18nKey="label.moving_reason">Moving Reason</Trans>}
                          variant="outlined"
                          fullWidth
                          autoWidth={true}>
                          {(moving_reasons).map((item, index) => <MenuItem value={item.code} key={index}>{item.description}</MenuItem>)}
                        </FormikSelect>
                      </div>
                    </Grid>
                  </React.Fragment>}

                  {/* price_ranges dropdown */}
                  {(price_ranges && Object.keys(price_ranges).length > 0) && <React.Fragment>
                    <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                      <div className={`${css['select-dropdown']} form-select-box`}>
                        <FormikSelect
                          name='guestcard_contact_form.price_range'
                          label={<Trans ns='application' i18nKey="label.price_range">Price Range</Trans>}
                          variant="outlined"
                          fullWidth
                          autoWidth={true}>
                          {Object.keys(price_ranges).map((code, index) => <MenuItem value={code} key={index}>{price_ranges[code]}</MenuItem>)}
                        </FormikSelect>
                      </div>
                    </Grid>
                  </React.Fragment>}
                </Grid>

                <Grid container alignItems="flex-start" spacing={2}>
                  {/* Address 1 */}
                  <Grid item xs={12} sm={12} md={6}>
                    <FormikTextField
                      required
                      multiline
                      rows={2}
                      ns="application"
                      name="guestcard_contact_form.address1"
                      type="text"
                      variant="outlined"
                      label={<Trans ns='application' i18nKey="label.address1">Address Line 1</Trans>}
                      placeholder={t('application:address1', 'Address1')}
                      fullWidth
                      autoComplete="off" />
                  </Grid>
                  {/* Address 2 */}
                  <Grid item xs={12} sm={12} md={6}>
                    <FormikTextField
                      multiline
                      rows={2}
                      ns="application"
                      name="guestcard_contact_form.address2"
                      type="text"
                      variant="outlined"
                      label={<Trans ns='application' i18nKey="label.address2">Address Line 2</Trans>}
                      placeholder={t('application:address2', 'Address2')}
                      fullWidth
                      autoComplete="off" />
                  </Grid>
                </Grid>

                <Grid container alignItems="flex-start" spacing={2}>

                  {/* City */}
                  <Grid item xs={12} sm={4} md={4} lg={4} xl={3}>
                    <FormikTextField
                      required
                      ns="application"
                      name="guestcard_contact_form.city"
                      type="text"
                      variant="outlined"
                      label={<Trans ns='application' i18nKey="label.city">City</Trans>}
                      placeholder={t('application:city', 'City')}
                      fullWidth
                      autoComplete="off" />
                  </Grid>

                  {/* State */}
                  <Grid item xs={12} sm={4} md={4} lg={4} xl={3}>

                    <FormikAutoComplete
                      className={css['state-box']}
                      required
                      disableAdd={true}
                      name='guestcard_contact_form.state_code'
                      label={<Trans ns='application' i18nKey="label.state_code">State</Trans>}
                      options={Object.keys(state_list).map(item => ({ id: item, label: state_list[item] }))}
                      onChange={(_event, newValue) => {
                        formikProps.setFieldValue('guestcard_contact_form.state_code', newValue)
                      }}
                      ns='application' />
                  </Grid>

                  {/* Zip */}
                  <Grid item xs={12} sm={4} md={4} lg={4} xl={3}>
                    <FormikTextField
                      required
                      ns="application"
                      name="guestcard_contact_form.zip_code"
                      type="text"
                      variant="outlined"
                      label={<Trans ns='application' i18nKey="label.zip_code">Zip Code</Trans>}
                      placeholder={t('application:zip_code', 'Zip Code')}
                      fullWidth
                      autoComplete="off" />
                  </Grid>

                </Grid>

                <Grid container alignItems="center" spacing={3}>
                  {/* comment */}
                  <Grid item xs={12} sm={12} md={12}>
                    <FormikTextField
                      multiline
                      rows={2}
                      ns="application"
                      name="guestcard_contact_form.comments"
                      type="text"
                      variant="outlined"
                      label={<Trans ns='application' i18nKey="label.comments">Comment</Trans>}
                      placeholder={t('application:comments', 'Comment')}
                      fullWidth
                      className={css['comment-box']}
                      autoComplete="off" />
                  </Grid>
                </Grid>

              </Grid>
            </Grid>
          </div>

        </div>

      </React.Fragment>
    )
  }
}


export default compose(
  withTranslation('application'),
  connect(
    state => ({
      state_list: state.state_list.data
    }),
    dispatch => {
      dispatch(preLoadStateList(true));
      return { dispatch }
    },
  )
)(GuestcardContactForm);