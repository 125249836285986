import React from 'react'
import { Trans } from 'react-i18next'

import http from '../../../utils/http'
import { getQuery } from '../../../utils/searchParams'

import { AuthContext } from '../../../utils/AppContext'

import { Form, Formik } from 'formik'

import yupGeneric from '../../../utils/yupGeneric'
import * as yup from 'yup'

import ProgressButton from '../../Shared/ProgressButton'
import css from './ApplicantExam.module.css'


import {
  Container,
  Grid,
  FormControlLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  LinearProgress,
  Typography,
  Button,
  Box
} from '@material-ui/core'
import Logo from '../../Shared/Logo'

class ApplicantExam extends React.Component {
  static contextType = AuthContext

  constructor(props) {
    super(props)

    this.interval = null
    this.do = 0
    this.tries = 10

    const getParams = getQuery(this.props.location.search)
    const token = getParams.access_token
    const regenerate = getParams.regenerate
    if (!token) {
      // @todo - what to do if no token?
      this.props.history.push('/404')
    }

    this.state = {
      loading: false,
      failed: false,
      error_data: '',
      token,
      regenerate, // true if link sent by admin (regenerate report)
      authentication_questions: [],
      formData: {
        exam_id: '',
        authentication_answers: []
      },
      applicant: {},
      manual_verification: false
    }
  }

  async componentDidMount() {
    this.context.setTitle('Applicant - Exam')

    // cleare interval if already there
    clearInterval(this.interval)

    // get exam
    await this.getExam()
  }

  componentDidUpdate() {
    // cleare interval if already there
    clearInterval(this.interval)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  getExam = async () => {
    this.do = this.do + 1

    this.setState({ loading: true })
    const { token, regenerate } = this.state

    http.interceptors.request.use(config => {
      config.headers['token'] = token
      return config
    })

    try {
      let endPoint = `/applicant/exam`
      if (regenerate) {
        endPoint += `?regenerate=1`
      }
      const response = await http.get(endPoint)

      const { exams, applicant } = response.data;

      let authentication_answers = {}
      exams.authenticationQuestions.map(q => authentication_answers[q.questionKeyName] = '')
      const is_manual_verification_required = (typeof exams.result !== 'undefined' && exams.result === 'ManualVerificationRequired') ? true : false

      this.setState(prevState => {
        return {
          ...prevState,
          authentication_questions: exams.authenticationQuestions,
          formData: {
            ...prevState.formData,
            exam_id: exams.examId,
            authentication_answers
          },
          applicant: applicant,
          manual_verification: is_manual_verification_required
        }
      })

      this.setState({ loading: false })
    } catch (error) {
      this.setState({
        error_data: error.data
      })
      clearInterval(this.interval)

      // applicant has not made payments yet.
      if (error.data === 'payment.missing') {
        // redirect to payment
        this.props.history.push(`/applicant/payment?access_token=${this.state.token}`)
      } else if (error.data === 'applicant.notfound') {
        // applicant.notfound then dont call API again
        this.do = this.tries;
      } else if (error.data === 'exam.passed') {
        // redirect to payment
        this.props.history.push(`/applicant/finish?access_token=${this.state.token}`)
      } else {
        if (this.do < this.tries) {
          this.interval = setInterval(() => {
            this.getExam()
          }, 5000)
        }
      }

      if (this.do === this.tries) {
        this.setState({
          loading: false,
          failed: true,
        })
      }
    }
  }

  tryAgain = async () => {
    this.setState({
      loading: true,
      failed: false,
    })


    this.do = 0

    // cleare interval if already there
    clearInterval(this.interval)

    // get exam
    await this.getExam()
  }

  render() {
    const { formData, loading, authentication_questions, failed, error_data, applicant, manual_verification } = this.state

    let yupValidation = {}
    for (const key in formData.authentication_answers) {
      yupValidation[key] = yup.string().required().typeError(yupGeneric)
    }

    const validationSchema = yup.object({ authentication_answers: yup.object(yupValidation) })

    return (
      <React.Fragment>
        <Container maxWidth="md">
          <div className={css['applicant-exam-main']}>
            {loading &&
              <Container component="main" className='login-contain-area'>
                <div>
                  <Typography component="h3" variant="h4">
                    <Trans ns="applicant_exam" i18nKey='exam.loading'>Please wait while we fetch your questionnaire....</Trans>
                  </Typography>
                  <LinearProgress />
                </div>
              </Container>}

            {failed && <React.Fragment>
              {error_data === 'applicant.notfound' ?
                <Container component="main" maxWidth="xl" className='login-contain-area'>
                  {/* Applicant not found */}
                  <div>
                    <Typography component="h3" variant="h4">
                      <Trans ns="applicant_exam" i18nKey='appplicant.notfound.title'>
                        Something went wrong
                      </Trans>
                    </Typography>
                    <Typography component="h4" variant="h6">
                      <Trans ns="applicant_exam" i18nKey='appplicant.notfound.info'>
                        We are unable to fetch your data at this moment, Contact to landlord for more information!
                      </Trans>
                    </Typography>
                  </div>
                </Container> :
                <Container component="main" maxWidth="xl" className='login-contain-area'>
                  {/* something enother error */}
                  <div>
                    <Typography component="h3" variant="h4">
                      <Trans ns="applicant_exam" i18nKey='exam.failed.title'>
                        Something went wrong
                      </Trans>
                    </Typography>
                    <Typography component="h4" variant="h6">
                      <Trans ns="applicant_exam" i18nKey='exam.failed.info'>
                        We are unable to fetch your questionnaire at this moment, We will get back to you when your questionnaire is ready.
                      </Trans>
                    </Typography>
                    <Typography component="h4" variant="caption">
                      <Trans ns="applicant_exam" i18nKey='exam.failed.try_again'>
                        or you can click this button to try again now.
                      </Trans>
                    </Typography>
                  </div>
                  <div>
                    <Grid container style={{ marginTop: 20 }} justify="center" alignItems="center">
                      <Grid item>
                        <Button
                          type="button"
                          fullWidth
                          color="primary"
                          onClick={() => this.tryAgain()}
                          className="form-button">
                          <Trans ns="applicant_exam" i18nKey="button.try_again">Try Again</Trans>
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                </Container>}
            </React.Fragment>}

            {(!loading && !failed) && <React.Fragment>

              {/* Show Applicants renterUid & ScreeningRequestRenterUid */}
              <Box p={3}>
                <div className={css['login-logo']}>
                  <Logo />
                </div>
                <div className={css['applicant_info']}>
                  <Grid container justify='flex-start' alignItems='flex-start' spacing={2}>
                    <Grid item xs={12} sm='auto' md='auto' lg='auto' xl='auto'>
                      <div className={`${css.detail_main}`}>
                        <div className={`${css.detail_label}`}><Trans ns='applicant_exam' i18nKey='label.renter_uid'>Renter ID</Trans></div>
                        <div className={`${css.detail_info}`}>{applicant.renter_uid}</div>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm='auto' md='auto' lg='auto' xl='auto'>
                      <div className={`${css.detail_main}`}>
                        <div className={`${css.detail_label}`}><Trans ns='applicant_exam' i18nKey='label.screening_request_renter_uid'>Screening Request Renter ID</Trans></div>
                        <div className={`${css.detail_info}`}>{applicant.screening_request_renter_uid}</div>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm='auto' md='auto' lg='auto' xl='auto'>
                      <div className={`${css.detail_main}`}>
                        <div className={`${css.detail_label}`}><Trans ns='applicant_exam' i18nKey='label.screening_request_uid'>Screening Request ID</Trans></div>
                        <div className={`${css.detail_info}`}>{applicant.screening_request_uid}</div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Box>

              {manual_verification &&
                <Box p={3}>
                  <div className={css['applicant_info']}>
                    <Grid container>
                      <Grid item className={`${css.detail_main}`}>
                        <div className={`${css.detail_label}`}>
                          We are unable to verify your identity online. Please call TransUnion customer support at (833) 458-6338
                          for assistance with completing your screening over the phone.
                          For reference, your TransUnion screening request ID is:
                          {applicant.screening_request_renter_uid}
                        </div>
                      </Grid>
                      <Grid container style={{ marginTop: 20 }} justify="center" alignItems="center">
                        <Grid item xs={3}>
                          <ProgressButton
                            type="submit"
                            fullWidth
                            color="primary"
                            className={`form-button`}>
                            Ok
                          </ProgressButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                </Box>}

              {/* Exam Form */}
              <Formik
                initialValues={formData}
                validationSchema={validationSchema}
                validateOnMount={true}
                onSubmit={async (values, action) => {
                  try {
                    // format values
                    const authentication_answers = Object.keys(values.authentication_answers).map(questionKeyName => ({ questionKeyName, selectedChoiceKeyName: values.authentication_answers[questionKeyName] }))


                    await http.post(`/applicant/exam`, { regenerate: this.state.regenerate, exam_id: values.exam_id, authentication_answers })

                    this.context.snackbar(<Trans ns='applicant_exam' i18nKey='exam.submit'>Questionnaire submitted!</Trans>, {
                      variant: 'success'
                    })

                    action.setSubmitting(false)
                    action.resetForm()

                    const { token } = this.state
                    this.props.history.push(`/applicant/finish?access_token=${token}`)
                  } catch (error) {
                    this.context.snackbar(error.data, {
                      variant: 'error'
                    })
                    action.setSubmitting(false)

                    // reload questionneire
                    if (error.data === 'Unverified') {
                      this.tryAgain()
                    }
                  }

                }}>{props => {
                  return (
                    <Form>
                      <React.Fragment>
                        {authentication_questions.map((authentication_question, index) => {
                          const { questionKeyName, questionDisplayName, choices } = authentication_question
                          const { values } = props
                          const { authentication_answers } = values

                          return (
                            <React.Fragment key={index}>
                              <Container component="main">
                                <div className={css['question-answer-main']}>
                                  <Grid container justify="flex-start">
                                    <FormControl>
                                      <FormLabel>
                                        {questionDisplayName}
                                      </FormLabel>
                                      <RadioGroup
                                        aria-label="quiz"
                                        name={`authentication_answers[${questionKeyName}]`}
                                        value={authentication_answers[questionKeyName]}
                                        onChange={(_event, newValue) => {
                                          props.setFieldValue(`authentication_answers[${questionKeyName}]`, newValue)
                                        }}>
                                        {choices.map((choice, choiceIndex) => {
                                          return (
                                            <FormControlLabel
                                              key={choiceIndex}
                                              value={choice.choiceKeyName}
                                              control={<Radio color="primary" />}
                                              label={choice.choiceDisplayName} />
                                          )
                                        })}
                                      </RadioGroup>
                                    </FormControl>
                                  </Grid>
                                </div>
                              </Container>
                            </React.Fragment>
                          )
                        })}
                      </React.Fragment>

                      {!manual_verification &&
                        <Container component="main" maxWidth="xl" className={`${css[`exam-button`]} login-contain-area`}>
                          <Grid container justify="center" alignItems="center">
                            <Grid item>
                              <ProgressButton
                                type="submit"
                                fullWidth
                                color="primary"
                                disabled={!props.isValid || props.isSubmitting}
                                className={`form-button`}>
                                <Trans ns="applicant_exam" i18nKey="button.submit">Submit</Trans>
                              </ProgressButton>
                            </Grid>
                          </Grid>
                        </Container>}
                    </Form>
                  )
                }}
              </Formik>
            </React.Fragment>}
          </div>
        </Container>
      </React.Fragment>
    )
  }
}

export default ApplicantExam
