import React from 'react';
import ReactDOM from 'react-dom';
import './page.css';
// import './index.css';
import './dashboard_icons.css';
import './i18n';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter, HashRouter, Route } from 'react-router-dom';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { StylesProvider } from '@material-ui/core';

if (module.hot) {
  module.hot.accept()
}

const Router = window.sessionStorage.hashRoute ? HashRouter : BrowserRouter;

ReactDOM.render(
  <React.Fragment>
    {/* <React.StrictMode> */}
    <StylesProvider injectFirst>
      <SnackbarProvider
        maxSnack={2}
        autoHideDuration={10000}
        disableWindowBlurListener={true}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        action={key => <CloseButton id={key} />}>

        <Router basename={window.sessionStorage.baseRoute ? window.sessionStorage.baseRoute : ''}>
          <Route path="/" component={App} />
        </Router>

        <ToastContainer
          position="top-right"
          autoClose={10000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover
        />
      </SnackbarProvider>
    </StylesProvider>
    {/* </React.StrictMode> */}
  </React.Fragment>
  ,
  document.getElementById('resport-resident-app')
);


const CloseButton = ({ id }) => {
  const { closeSnackbar } = useSnackbar();
  return (
    <IconButton
      aria-label="Close notification"
      color="inherit"
      onClick={() => closeSnackbar(id)}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );
};


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
