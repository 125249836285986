import React from 'react'
import { Trans, withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { Formik, Form } from 'formik';

import { getQuery } from '../../../utils/searchParams';
import { AuthContext } from '../../../utils/AppContext';
import http from '../../../utils/http';
import { ConfirmBox } from '../../../utils/DialogHelpers';
import date from '../../../utils/date';


import ProgressButton from '../../Shared/ProgressButton';
import { getUnmaskedNumber } from '../../Shared/FormikMaskedInput'

import css from './PreApplicationForm.module.css'
import formValidation from './formValidation'
import ResponseDialog from './ResponseDialog';

import GuestcardContactForm from './GuestcardContactForm/GuestcardContactForm';
import dummy from './GuestcardContactForm/dummy'

import {
  Typography,
  Box,
  Grid,
  Button,
  Tabs,
  Tab,
  Link
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import MakePaymentForm, { CARD_FORM_DATA, CHECK_FORM_DATA } from './MakePaymentForm/MakePaymentForm';
import ChangeUnitDialog from './ChangeUnitDialog';

/**
 * merchant type is APPLICATION_FEE for pre application payment
 */
const MERCHANT_TYPE = 'APPLICATION_FEE'


class PreApplicationForm extends React.Component {
  static contextType = AuthContext

  constructor(props) {
    super(props)

    const queryParams = getQuery(this.props.location.search)

    const unit_id = queryParams.unit_id
    const token = queryParams.access_token

    this.state = {
      token,
      already_paid: false,  // true when invoice or verify api says so
      make_payment_link: false,  // true when mail link opens
      applicant_id: false,
      tabIndex: 0,
      unit_id: unit_id,
      loading: true,
      pre_app_fee_payments: [],
      guestcard_contact_form_data: {},
      invite_url: "",
      response_dialog_open: false,

      // for unit change case (rare)
      unit_is_locked: false,
      property_id: '',
      unit_property_id: '',
      change_unit_dialog_open: false,

      make_payment_form_loading: false,
      unit_settings: null,
      payment_gateways: {},
      unit: {},
      invalid_unit: false,
      formData: {
        guestcard_contact_form: {
          unit_id: unit_id,
          first_name: '',
          middle_name: '',
          last_name: '',
          email: '',
          contact_no: '',
          appointment_date: '',
          date_needed: '',
          lease_term: '',
          address1: '',
          address2: '',
          city: '',
          state_code: '',
          zip_code: '',
          community_code: '',
          price_range: '',
          marketing_source: '',
          leasing_agent: '',
          moving_reason: '',
          comments: '',
          // ...dummy // @TODO remove this
        },
        make_payment_form: {
          // for placepay
          placepay_transaction_id: "",


          // addresss
          address1: '',
          address2: '',
          city: '',
          state_code: '',
          zip_code: '',

          // payment type details
          payment_type: 'CARD',
          ...CARD_FORM_DATA,
          ...CHECK_FORM_DATA
        }
      }
    }
  }

  componentDidMount() {
    const { token } = this.state

    this.context.setTitle('Guestcard Contact Form');

    if (token) {
      // mail link opened, get applucant data
      this.verify()
    } else {
      // GC form opened
      this.getGuestcardFormData()
    }

    if (process.env.REACT_APP_ENV !== 'production') {
      console.table({
        'card-number-1': '4100000000000001',
        'card-number-2': '4242424242424242',
        'card-number-3': '5555555555554444',
        'routing-number': '021200025',
        'account-number': '123123124',
        'account-holder-name': 'Developer Tenant'
      })
    }
  }

  /**
   * CASE : user opened paymnet link from mail (only 2ndd tab acceesiible)
   * data from token ferched and form-data populated
   */
  verify = () => {
    http.get(`/applicant/guestcard-verify`, {
      headers: { token: this.state.token }
    })
      .then(response => {
        const responseData = response.data

        const pre_application_charges = responseData.pre_application_charges

        if (pre_application_charges > 0) {
          this.getPaymentFormData();
          this.getGuestcardFormData();
        }

        this.setState(prevState => ({
          ...prevState,
          applicant_id: responseData.applicant_id,
          already_paid: responseData.already_paid ?? false,
          tabIndex: 1,
          loading: false,
          pre_application_charges,
          make_payment_link: true,
          unit: responseData.unit,
          unit_property_id: responseData.unit.property_id,
          invalid_unit: (responseData.unit) ? false : true,
          // pre filling formdata for submit
          formData: {
            ...prevState.formData,
            guestcard_contact_form: {
              ...prevState.formData.guestcard_contact_form,
              ...responseData.guestcard_contact_form
            },
            make_payment_form: {
              ...prevState.formData.make_payment_form,
              ...responseData.make_payment_form
            }
          }
        }))
      }, _error => this.setState(prevState => ({ ...prevState, loading: false, invalid_unit: true })))
  }

  /**
   * CASE : user filling GC form
   */
  getGuestcardFormData = () => {
    if (!this.state.unit_id) {
      return this.setState(prevState => ({ ...prevState, loading: false, invalid_unit: true }))
    }

    http.get(`applicant/unit/${this.state.unit_id}/guestcard`)
      .then(response => {
        const data = response.data
        const { unit, pre_app_fee_payments } = data
        if (pre_app_fee_payments.length) {
          this.getPaymentFormData();
        }

        const leasing_agents = this.getMetaDataBySort(data.leasing_agents)

        // Preselected leasing agent from property edit page (if not selected then default first one show selected)
        let leasing_agent = data.property_leasing_agent ? data.property_leasing_agent : ''
        if (!leasing_agent) {
          leasing_agent = leasing_agents && leasing_agents.length ? leasing_agents[0].code : ''
        }

        this.setState(prevState => ({
          ...prevState,
          unit,
          loading: (pre_app_fee_payments.length) ? true : false, // loading till all apis
          pre_app_fee_payments,
          guestcard_contact_form_data: {
            community_codes: this.getMetaDataBySort(data.community_codes),
            marketing_sources: this.getMetaDataBySort(data.marketing_sources),
            leasing_agents,
            moving_reasons: this.getMetaDataBySort(data.moving_reasons),
            price_ranges: data.price_ranges,
            appointment_date_exceptions: (data.unit_settings && data.unit_settings.appointment_date_exceptions) ? data.unit_settings.appointment_date_exceptions : [],
            show_appointment_date: data.show_appointment_date,
            show_lease_term: data.lease_term
          },
          unit_settings: data.unit_settings,
          unit_property_id: unit.property_id,
          formData: {
            ...prevState.formData,
            guestcard_contact_form: {
              ...prevState.formData.guestcard_contact_form,
              leasing_agent
            }
          }
        }))
      }, _error => this.setState(prevState => ({ ...prevState, loading: false, invalid_unit: true })))
  }

  getMetaDataBySort(meta_data) {
    const meta_data_sort = []
    Object.keys(meta_data).map((key) => {
      meta_data_sort.push({ code: key, description: meta_data[key] })
      return null
    })
    meta_data_sort.sort((a, b) => (a.description.toLowerCase() > b.description.toLowerCase()) ? 1 : -1)
    return meta_data_sort;
  }

  getPaymentFormData = _ => {
    http.get(`applicant/unit/${this.state.unit_id}/payment`)
      .then(response => {
        this.setState(prevState => ({ ...prevState, loading: false, payment_gateways: response.data.payment_gateways }))
      }, _error => this.setState(prevState => ({ ...prevState, loading: false, invalid_unit: true })))
  }

  postGuestcardFormData = (values, action) => {
    this.setState(prevState => ({ ...prevState, make_payment_form_loading: true }))

    let data = JSON.parse(JSON.stringify(values))

    data.guestcard_contact_form.contact_no = getUnmaskedNumber(data.guestcard_contact_form.contact_no.toString())
    data.guestcard_contact_form.state_code = data.guestcard_contact_form.state_code ? data.guestcard_contact_form.state_code.id : ''
    data.make_payment_form.state_code = data.make_payment_form.state_code ? data.make_payment_form.state_code.id : ''
    data.guestcard_contact_form.lease_term = this.state.guestcard_contact_form_data.show_lease_term ? data.guestcard_contact_form.lease_term : ''
    this.context.backdrop(true)

    const { pre_application_charges, payment_gateways, unit_id } = this.state;

    let endpoint = `applicant/unit/${unit_id}/guestcard`;

    if (pre_application_charges > 0) {
      const payment_type = data.make_payment_form.payment_type;

      const payment_type_data = payment_gateways[payment_type];
      const payment_gateway = payment_type_data.payment_gateway.toLowerCase();

      endpoint = `applicant/unit/${unit_id}/payments/${payment_gateway}`;
    }

    if (this.state.applicant_id) {
      data.applicant_id = this.state.applicant_id
    }

    http.post(endpoint, data)
      .then(
        response => {
          action.setSubmitting(false)
          action.resetForm()

          this.setState(prevState => ({
            ...prevState,

            // fields for application-form url
            invite_url: response.data.d.invite_url,
            response_dialog_open: (response.data.d.unit_is_locked) ? false : true,

            // fields for unit change -
            applicant_id: response.data.d.applicant_id ?? false,
            property_id: response.data.d.property_id ?? '',
            unit_is_locked: response.data.d.unit_is_locked ?? false,
            change_unit_dialog_open: (response.data.d.unit_is_locked) ? true : false,
          }))
        },
        error => {
          action.setSubmitting(false)
          this.context.snackbar(<Trans ns='application' i18nKey={error.data}>{error.data}</Trans>, {
            variant: 'error'
          })
        }).finally(() => {
          // open guestcard_contact_form and disable make_payment_form
          this.setState(prevState => ({ ...prevState, make_payment_form_loading: false, tabIndex: 0 }))

          setTimeout(() => {
            action.validateForm()
          }, 200);

          this.context.backdrop(false)
        })
  }

  toggleResponseDialog = () => this.setState(prevState => ({ ...prevState, response_dialog_open: !prevState.response_dialog_open }))

  handleChangeTabs = (_event, value) => this.setState(prevData => ({ ...prevData, tabIndex: value }));

  autofill = async (formikProps) => {
    const { community_codes, marketing_sources, leasing_agents, moving_reasons, price_ranges } = this.state.guestcard_contact_form_data
    const priceRangesFirstChild = Object.keys(price_ranges)
    const confirm = await ConfirmBox.show({
      title: 'Autofill',
      message: 'This will autofill this form with dummy data, Are you sure?'
    })

    if (!confirm) {
      return false
    }

    formikProps.setValues({
      ...formikProps.values,
      guestcard_contact_form: {
        ...formikProps.values.guestcard_contact_form,
        ...dummy,
        community_code: (community_codes && community_codes.length > 0) ? community_codes[0].code : '',
        marketing_source: (marketing_sources && marketing_sources.length > 0) ? marketing_sources[0].code : '',
        leasing_agent: (leasing_agents && leasing_agents.length > 0) ? leasing_agents[0].code : '',
        moving_reason: (moving_reasons && moving_reasons.length > 0) ? moving_reasons[0].code : '',
        price_range: (priceRangesFirstChild && priceRangesFirstChild.length > 0) ? priceRangesFirstChild[0] : ''
      }
    })
  }

  check_allowable_movein = (movein) => {
    let allowed_movein_daterange = ''
    const { unit_settings } = this.state

    if (!unit_settings || !unit_settings.allow_movein_days || !unit_settings.vacant_date) {
      return allowed_movein_daterange
    }

    const { unit_status_vacant_days, occupancy_status, vacant_date, allow_movein_days } = unit_settings
    const unitstatus_vacant_days = JSON.parse(unit_status_vacant_days)
    const unit_available_days = unitstatus_vacant_days[occupancy_status]
    if (unit_available_days === undefined) { // here might come 0 so checked undefined
      return allowed_movein_daterange
    }

    const days_in_milisecond = 24 * 60 * 60 * 1000
    const current_date = new Date().setHours(0, 0, 0, 0)
    const unit_available_date = new Date(vacant_date).getTime() + (unit_available_days * days_in_milisecond) // unit's vacant date + available days

    let start_date = current_date
    let end_date = current_date + (allow_movein_days * days_in_milisecond) // current date + allow movein days
    if (unit_available_date > current_date) {
      start_date = unit_available_date
      end_date = new Date(vacant_date).getTime() + ((unit_available_days + allow_movein_days) * days_in_milisecond) // unit's vacant's date + available days + movein days
    }

    const selected_movein_date = new Date(movein).getTime()

    // Error if movein is lower than unit's available date
    if (selected_movein_date < unit_available_date) {
      allowed_movein_daterange = `${date('d M, Y', start_date)} - ${date('d M, Y', end_date)}`
      return allowed_movein_daterange
    }

    // Error if movein is higher than allowed daterange
    if (selected_movein_date > end_date) {
      allowed_movein_daterange = `${date('d M, Y', start_date)} - ${date('d M, Y', end_date)}`
      return allowed_movein_daterange
    }

    return allowed_movein_daterange
  }

  patchAddress(formikProps) {
    const gc_address = formikProps.values.guestcard_contact_form;
    const payment_address = formikProps.values.make_payment_form;

    formikProps.setValues({
      ...formikProps.values,
      make_payment_form: {
        ...payment_address,
        address1: payment_address.address1 === '' ? gc_address.address1 : payment_address.address1,
        address2: payment_address.address2 === '' ? gc_address.address2 : payment_address.address2,
        city: payment_address.city === '' ? gc_address.city : payment_address.city,
        state_code: payment_address.state_code === '' ? gc_address.state_code : payment_address.state_code,
        zip_code: payment_address.zip_code === '' ? gc_address.zip_code : payment_address.zip_code,
      }
    });
  }

  render() {
    const {
      already_paid,
      make_payment_link,
      unit_id,
      loading,
      invalid_unit,
      formData,
      guestcard_contact_form_data,
      pre_app_fee_payments,
      invite_url,
      unit_is_locked,
      property_id,
      unit_property_id,
      applicant_id,
      change_unit_dialog_open,
      response_dialog_open,
      make_payment_form_loading,
      tabIndex,
      payment_gateways,
      unit
    } = this.state
    const { show_appointment_date, show_lease_term, marketing_sources } = guestcard_contact_form_data
    const show_marketing_source = marketing_sources && marketing_sources.length ? true : false

    if (loading) {
      return (
        <React.Fragment>Loading...</React.Fragment>
      )
    }

    /* Temporarily for spectra pms guestcard from not showing */
    if (["SPECTRA"].includes(unit.pms_type)) {
      return (
        <React.Fragment>
          <div className='form-wrap'>
            <Box mt={2}>
              <Grid container justifyContent="center" spacing={2}>
                <Grid item xs={11} md={11} lg={11}>
                  <Alert severity="warning">
                    <AlertTitle>Warning</AlertTitle>
                    <Trans ns="application" i18nKey='applicant.warning.spectra.temporary.disabled'>Guestcard feature is temporarily disabled for Spectra. Please contact property manager for more information.</Trans>
                  </Alert>
                </Grid>
              </Grid>
            </Box>
          </div>
        </React.Fragment>
      )
    }

    /* Temporarily for none pms guestcard form not showing */
    if (["NONE"].includes(unit.pms_type)) {
      return (
        <React.Fragment>
          <div className='form-wrap'>
            <Box mt={2}>
              <Grid container justifyContent="center" spacing={2}>
                <Grid item xs={11} md={11} lg={11}>
                  <Alert severity="warning">
                    <AlertTitle>Warning</AlertTitle>
                    <Trans ns="application" i18nKey='applicant.warning.none_pms.temporary.disabled'>Guestcard feature is temporarily disabled for None pms. Please contact property manager for more information.</Trans>
                  </Alert>
                </Grid>
              </Grid>
            </Box>
          </div>
        </React.Fragment>
      )
    }

    if (already_paid) {
      return (
        <React.Fragment>
          <div className='form-wrap'>
            <div className='form-field'>
              <div className={css['invalid-unit']}>
                <Trans ns="application" i18nKey='applicant.already_paid'>Payment already done!</Trans>
              </div>
            </div>
          </div>
        </React.Fragment>
      )
    }

    if (!unit_id) {
      return (
        <React.Fragment>
          <div className='form-wrap'>
            <div className='form-field'>
              <div className={css['invalid-unit']}>
                <Trans ns="application" i18nKey='applicant.warning.unit.required'>Unit required</Trans>
              </div>
            </div>
          </div>
        </React.Fragment>
      )
    }

    if (invalid_unit) {
      return (
        <React.Fragment>
          <div className='form-wrap'>
            <div className='form-field'>
              <div className={css['invalid-unit']}>
                <Trans ns="application" i18nKey='applicant.warning.unit.error'>Invalid Unit</Trans>
              </div>
            </div>
          </div>
        </React.Fragment>
      )
    }


    /* no fees? show only GC form */
    if (!pre_app_fee_payments.length) {
      return (
        <React.Fragment>
          {/* all ok, show qlp invite link */}
          <ResponseDialog
            invite_url={invite_url}
            response_dialog_open={response_dialog_open}
            toggleResponseDialog={this.toggleResponseDialog}
            {...this.props} />

          <div className={css['guestcard-bg']}></div>
          <div className={css['guestcard-wrap']}>
            <Formik
              enableReinitialize={true}
              initialValues={formData}
              validationSchema={formValidation({ show_appointment_date, show_lease_term, show_marketing_source })}
              validateOnMount={true}
              onSubmit={(values, action) => {

                const allowed_movein_daterange = this.check_allowable_movein(values.guestcard_contact_form.date_needed)
                if (allowed_movein_daterange !== '') {
                  this.context.snackbar(<Trans ns='application' i18nKey="allow.movein.date.exceed">Please select move in date within {{ allowed_movein_daterange }}.</Trans>, {
                    variant: 'error'
                  })
                  action.setSubmitting(false)
                  return
                }

                this.postGuestcardFormData(values, action)
              }}>{props => {
                return (
                  <React.Fragment>

                    <div className="form-wrap">
                      <Form noValidate>

                        {/* Heading */}
                        <div className={css['property-container']}>
                          <Grid container alignItems="center" justify="center" spacing={1}>
                            <Grid item>
                              <Typography variant="h1" component="h2" className={css['property-title']}>
                                {unit.property_name}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <span className={css['divide']}>|</span> <span className={css['unit-label']}>Unit : {unit.unit_uid} / {unit.building_uid}</span>
                            </Grid>
                          </Grid>
                        </div>

                        <div className={css['preappform-contact-form']}>
                          <Grid container justify="space-between" alignItems="center" spacing={2}>
                            <Grid item>
                              <Typography variant="h1" component="h2" className={css['form-title']}>
                                <Trans ns="application" i18nKey='applicant.header'>Guestcard Contact Form</Trans>
                              </Typography>
                            </Grid>
                            <Grid item>
                              <div>
                                {process.env.REACT_APP_ENV !== 'production' && <React.Fragment>
                                  <Button variant='contained'
                                    color="primary"
                                    className={`${css["autofill-butn"]} form-button`}
                                    onClick={() => this.autofill(props)}>
                                    <Trans ns='application' i18nKey='button.autofill'>Autofill</Trans>
                                  </Button>
                                </React.Fragment>}
                              </div>
                            </Grid>
                          </Grid>

                          {/* info alert */}
                          {process.env.REACT_APP_ENV !== 'production' && <Grid container justify="space-between" alignItems="center" spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                              <Alert severity='info'>
                                <AlertTitle>
                                  <Trans ns='application' i18nKey='payment.guestcard_form_suggestions.heading'>Suggestions (to avoid failures while testing)</Trans>
                                </AlertTitle>
                                <Trans ns="application" i18nKey='payment.guestcard_form_suggestions.description'>
                                  <p><b>If you're this filling form for -</b></p>
                                  <p><strong>1. QLP:</strong> Please make sure the name is either 'Hank Mess' or 'Joe Kleen'.</p>

                                  <p><strong>2. System Screening:</strong> Please make sure the name here matches the name in the application form (to be filled after this form).</p>
                                </Trans>
                              </Alert>
                            </Grid>
                          </Grid>}
                        </div>
                        <GuestcardContactForm data={{ guestcard_contact_form_data, formikProps: props }} />

                        {/* Submit Button */}
                        <Grid container justify="center" alignItems="center">
                          <Grid item>
                            <ProgressButton
                              type="submit"
                              loading={props.isSubmitting ? "1" : "0"}
                              color="primary"
                              disabled={props.isSubmitting}
                              className={`${css["submit-butn"]} form-button`}>
                              <Trans ns='application' i18nKey="application.form.button.submit">Submit</Trans>
                            </ProgressButton>
                          </Grid>
                        </Grid>

                      </Form>
                    </div>

                  </React.Fragment>
                )
              }
              }
            </Formik>
          </div>

        </React.Fragment>
      )
    }

    /* pre appl fees present? tab form ( GC form + payment ) */
    return (
      <React.Fragment>
        <div className={css['guestcard-bg']}></div>
        <div className={css['guestcard-wrap']}>
          <Formik
            enableReinitialize={true}
            initialValues={formData}
            validationSchema={formValidation({ pre_app_fee_payments, payment_gateways, show_appointment_date, show_lease_term, show_marketing_source })}
            validateOnMount={true}
            validateOnBlur={true} // call after setFieldTouched
            onSubmit={(values, action) => {
              this.postGuestcardFormData(values, action)
            }}>{props => {
              let disable_second_tab = true
              disable_second_tab = ((typeof props.errors.guestcard_contact_form !== 'undefined') || make_payment_form_loading)
              if (make_payment_link) {
                // mail link opened? showing 2nd tab directly
                disable_second_tab = false
              }
              return (
                <React.Fragment>
                  <ResponseDialog
                    invite_url={invite_url}
                    response_dialog_open={response_dialog_open}
                    toggleResponseDialog={this.toggleResponseDialog}
                    {...this.props} />

                  <div className="form-wrap">
                    <div className={css['property-container']}>
                      <Grid container alignItems="center" justify="center" spacing={1}>
                        <Grid item>
                          <Typography variant="h1" component="h2" className={css['property-title']}>
                            {unit.property_name}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <span className={css['divide']}>|</span> <span className={css['unit-label']}> Unit : {unit.unit_uid} / {unit.building_uid}</span>
                        </Grid>
                      </Grid>
                    </div>
                    <div className={css['tab-wrap']}>
                      <Tabs
                        aria-label="guestcard edit tabs"
                        value={tabIndex}
                        onChange={(_event, value) => {
                          const allowed_movein_daterange = this.check_allowable_movein(props.values.guestcard_contact_form.date_needed)
                          if (allowed_movein_daterange !== '') {
                            this.context.snackbar(<Trans ns='application' i18nKey="allow.movein.date.exceed">Please select move in date within {{ allowed_movein_daterange }}.</Trans>, {
                              variant: 'error'
                            })
                            return
                          }

                          this.handleChangeTabs(_event, value);

                          // patch address data
                          this.patchAddress(props);
                        }}
                        className={`${css['tabs-indicator']} tab-style-button`}>

                        <Tab
                          label={<Trans ns='application' i18nKey='tab.label.guestcard_contact_form'>Guestcard Contact Form</Trans>}
                          disabled={this.state.applicant_id !== false}
                          component={Link}
                          key={0}
                        />

                        <Tab
                          label={<Trans ns='application' i18nKey='tab.label.make_payment_form'>Make Payment</Trans>}
                          disabled={disable_second_tab}
                          component={Link}
                          key={1}
                        />
                      </Tabs>

                      {(tabIndex === 0) && <React.Fragment>
                        <div>
                          {process.env.REACT_APP_ENV !== 'production' && <React.Fragment>
                            <Button variant='contained'
                              color="primary"
                              className={`${css["autofill-butn"]} form-button`}
                              onClick={() => this.autofill(props)}>
                              <Trans ns='application' i18nKey='button.autofill'>Autofill</Trans>
                            </Button>
                          </React.Fragment>}
                        </div>
                      </React.Fragment>}


                    </div>

                    {/* Basic Info */}
                    {(tabIndex === 0) &&
                      <React.Fragment>
                        <GuestcardContactForm data={{ guestcard_contact_form_data, formikProps: props }} />
                        <Grid container justify='center' alignItems="center">
                          <Grid item>
                            <Button
                              type="button"
                              color="primary"
                              className={`${css["submit-butn"]} form-button`}
                              onClick={() => {

                                const allowed_movein_daterange = this.check_allowable_movein(props.values.guestcard_contact_form.date_needed)
                                if (allowed_movein_daterange !== '') {
                                  this.context.snackbar(<Trans ns='application' i18nKey="allow.movein.date.exceed">Please select move in date within {{ allowed_movein_daterange }}.</Trans>, {
                                    variant: 'error'
                                  })
                                  return
                                }

                                if (typeof props.errors.guestcard_contact_form === 'undefined') {
                                  // no errors in 1st tab form..
                                  this.context.backdrop(true)

                                  let data = JSON.parse(JSON.stringify(props.values))

                                  data.guestcard_contact_form.contact_no = getUnmaskedNumber(data.guestcard_contact_form.contact_no.toString())
                                  data.guestcard_contact_form.state_code = data.guestcard_contact_form.state_code ? data.guestcard_contact_form.state_code.id : ''
                                  data.make_payment_form.state_code = data.make_payment_form.state_code ? data.make_payment_form.state_code.id : ''

                                  // adding invoice before mk paymnet
                                  http.post(`applicant/unit/${this.state.unit_id}/invoice`, data)
                                    .then(response => {

                                      const applicant_id = response.data.d.applicant_id
                                      const invoiceInsertedSuccessfully = response.data.d.invoiceInsertedSuccessfully
                                      const pre_application_charges = response.data.d.pre_application_charges
                                      const already_paid = response.data.d.already_paid

                                      if (!invoiceInsertedSuccessfully) {
                                        this.context.snackbar(<Trans ns='application' i18nKey={`Invoices Not Found`}>Invoices not found</Trans>, {
                                          variant: 'error'
                                        })
                                      }

                                      // invoice added? go to next tab..
                                      this.setState(prevState => ({
                                        ...prevState,
                                        tabIndex: 1,
                                        applicant_id,
                                        already_paid,
                                        pre_application_charges
                                      }));

                                      // patch address data
                                      this.patchAddress(props);

                                    }, error => {
                                      // something went wrong -
                                      this.context.snackbar(<Trans ns='application' i18nKey={error.data}>{error.data}</Trans>, {
                                        variant: 'error'
                                      })
                                    }).finally(() => {
                                      this.context.backdrop(false)
                                    })


                                } else {
                                  // show validation error message manually
                                  for (const name in props.errors.guestcard_contact_form) {
                                    props.setFieldTouched(`guestcard_contact_form.${name}`, true)
                                  }
                                }
                              }}>
                              <Trans ns='application' i18nKey="application.form.button.submit">Submit</Trans>
                            </Button>
                          </Grid>
                        </Grid>
                      </React.Fragment>}

                    {/*  Payment */}
                    {(tabIndex === 1) &&
                      <MakePaymentForm data={{
                        formikProps: props,
                        unit_id,
                        property_id: unit_property_id,
                        pre_app_fee_payments,
                        payment_type: 'CARD',
                        merchant_type: MERCHANT_TYPE,
                        payment_gateways: payment_gateways,
                        user: {
                          email: props.values.guestcard_contact_form.email,
                          first_name: props.values.guestcard_contact_form.first_name,
                          last_name: props.values.guestcard_contact_form.last_name,
                        }
                      }} />}


                  </div>

                </React.Fragment>
              )
            }
            }
          </Formik>
        </div>

        {/* unit was locked but add gc + pay done, show popup to change unit */}
        {change_unit_dialog_open && <ChangeUnitDialog
          invite_url={invite_url}
          unit_is_locked={unit_is_locked}
          applicant_id={applicant_id}
          property_id={property_id}
          change_unit_dialog_open={change_unit_dialog_open}
          onSuccess={(values, action) => {
            const data = Object.assign({}, values)
            const unit_id = data.unit_id.id

            http.post(`applicant/${applicant_id}/update-unit/${unit_id}`).then(_response => {
              action.setSubmitting(false)
              // unit changed, close this popup, show invite url popup
              this.setState(prevState => ({
                ...prevState,
                change_unit_dialog_open: false,
                response_dialog_open: true
              }))
            }, error => {
              action.setSubmitting(false)
              this.context.snackbar(<Trans ns='application' i18nKey={error.data}>{error.data}</Trans>, {
                variant: 'error'
              })
            })
          }}
          toggleUnitDialog={() => {
            this.setState(prevState => ({
              ...prevState,
              response_dialog_open: false
            }))
          }}
        />}

      </React.Fragment>
    )
  }
}

export default compose(
  withTranslation('application')
)(PreApplicationForm);
