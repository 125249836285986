import React from 'react'
import { compose } from 'recompose';
import { Trans, withTranslation } from 'react-i18next';

import http from '../../utils/http';
import { AuthContext } from '../../utils/AppContext';

import ProgressButtonSimple from './ProgressButtonSimple';
import config from '../../utils/config';

class PlacePayApplication extends React.Component {
  static contextType = AuthContext

  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      access_token: '',
      public_key: '',
      merchant_id: '',
      unit_id: '',
      payment_type: 'CARD'
    }
  }

  componentDidMount() {
    const { data } = this.props
    const { unit_id } = data

    this.setState(prevState => ({ ...prevState, unit_id: unit_id, payment_type: data.payment_type }))
  }

  componentDidUpdate() {
    const { data } = this.props
    const { unit_id } = data

    if (this.state.unit_id !== unit_id) {
      this.setState(prevState => ({ ...prevState, unit_id: unit_id }))
    }


    if (data.payment_type !== this.state.payment_type) {
      this.setState(prevState => ({ ...prevState, payment_type: data.payment_type }))
    }
  }

  /**
   * get PlacePay public key and access token for lease
   */
  getMeta = () => {
    // prevent user from clicking on placepay button
    // because without public key, place pay pop up can not get loaded
    // so make sure public key is loaded first
    this.context.backdrop(true)

    const { data } = this.props
    const { user, payment_type, merchant_type, invoice_type } = data

    http.post(`applicant/unit/${this.state.unit_id}/payments/placepay/meta`, { ...user, payment_type, merchant_type, invoice_type: invoice_type ? invoice_type : '' })
      .then(response => {
        this.setState(prevState => {
          return {
            ...prevState,
            access_token: response.data.access_token,
            public_key: response.data.public_key,
            merchant_id: response.data.merchant_id
          }
        })

        this.openPlacePay()
      }, error => {
        this.context.snackbar(<Trans ns='placepay' i18nKey={error.data}>{error.data}</Trans>, {
          variant: 'error'
        })
      }).finally(() => this.context.backdrop(false))
  }

  loadPlacePayScript = () => {
    const script = document.createElement("script");
    script.src = "https://placepay.com/plugins/pay.js";
    script.async = true;
    script.onload = () => this.openPlacePay()

    document.body.appendChild(script);
  }

  openPlacePay = () => {
    if (!window.Place || typeof window.Place === 'undefined') {
      return this.loadPlacePayScript()
    }

    // close if already open
    window.Place.pay.close()

    // amount from props
    const { data } = this.props
    const { amount, user, payment_type } = data

    // get lease info from context
    const full_name = `${user.first_name} ${user.last_name}`

    const { access_token, public_key, merchant_id } = this.state

    // create new
    const options = {
      key: public_key,
      access_token: access_token,
      email: user.email,
      full_name: full_name,
      amount: amount,
      description: full_name,
      custom_fields: {
        take_application_fees: this.props.data.take_application_fees ? true : false,
        applicantInfo: {
          ...user,
          property_id: this.context.portalData.property_id
        },
        server_ip: config.endPoint,
        payment_type,
        merchant_type: this.props.data.merchant_type
      },
      autopay: false, // envoi true
      cards: payment_type === 'CARD',
      ach: payment_type === 'CHECKING',
      deposit_account_id: merchant_id,
      on: {
        processing: data => {
          console.log('processing ', data)

          this.toggleLoading()
        },
        declined: data => console.log('declined ', data),
        canceled: data => console.log('canceled ', data),
        loaded: data => console.log('loaded ', data),
        paid: data => {
          console.log('paid ', data)

          this.onPaid(data.transaction_id)

          this.toggleLoading()
        },
        error: data => console.log('error', data),
      }
    };
    // console.log("PLACEPAY OPTIONS ---> ", options)
    window.Place.pay.open(options)
  }

  onPaid = placepay_transaction_id => {
    const { onPaid } = this.props

    if (onPaid) {
      onPaid(placepay_transaction_id)
    }
  }

  toggleLoading = () => this.setState(prevState => ({ ...prevState, loading: !prevState.loading }))

  render() {
    const { t, tReady, onPaid, data, ...props } = this.props
    const { make_payment_form_loading } = data

    return (
      <React.Fragment>
        <ProgressButtonSimple
          onClick={this.getMeta}
          loading={make_payment_form_loading ? "1" : "0"}
          disabled={make_payment_form_loading}
          {...props}>
          {props.children}
        </ProgressButtonSimple>
      </React.Fragment>
    )
  }
}

export default compose(
  withTranslation('placepay')
)(PlacePayApplication);