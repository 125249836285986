import React from 'react'
import { compose } from 'recompose';
import { Trans, withTranslation } from 'react-i18next';

import http from '../../utils/http';
import { AuthContext } from '../../utils/AppContext';

import ProgressButtonSimple from './ProgressButtonSimple';

import config from '../../utils/config';
import { Alert } from '@material-ui/lab';

class PlacePayButton extends React.Component {
  static contextType = AuthContext

  constructor(props) {
    super(props)

    this.state = {
      throttlePayment: false,
      access_token: '',
      public_key: '',
      merchant_id: '',
      amount: 0, // just to update placepay with new amount
      payment_type: 'CARD'
    }
  }

  componentDidMount() {
    const { data } = this.props
    this.setState(prevState => ({ ...prevState, amount: data.amount, payment_type: data.payment_type }))
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.disabled !== this.props.disabled;
  }

  componentDidUpdate() {
    // if amount changed then update placepay
    const { data } = this.props
    if (parseFloat(data.amount) !== parseFloat(this.state.amount)) {
      this.setState(prevState => ({ ...prevState, amount: parseFloat(data.amount) }))
    }

    if (data.payment_type !== this.state.payment_type) {
      this.setState(prevState => ({ ...prevState, payment_type: data.payment_type }))
    }
  }

  /**
   * get PlacePay public key and access token for lease
   */
  getMeta = () => {
    const { data } = this.props
    const params = {
      payment_type: this.state.payment_type,
      merchant_type: data.merchant_type
    }

    this.context.backdrop(true)

    http.post('tenant/payments/placepay/meta', params)
      .then(response => {
        this.setState(prevState => {
          return {
            ...prevState,
            access_token: response.data.access_token,
            public_key: response.data.public_key,
            merchant_id: response.data.merchant_id
          }
        }, this.openPlacePay)
      }, error => {
        this.context.snackbar(<Trans ns='placepay' i18nKey={error.data}>{error.data}</Trans>, {
          variant: 'error'
        })
      })
  }

  loadPlacePayScript = () => {
    const script = document.createElement("script");
    script.src = "https://placepay.com/plugins/pay.js";
    script.async = true;
    script.onload = () => this.openPlacePay()

    document.body.appendChild(script);
  }

  openPlacePay = () => {
    if (!window.Place || typeof window.Place === 'undefined') {
      return this.loadPlacePayScript()
    }

    // close if already open
    window.Place.pay.close()

    // amount from state
    const { amount, payment_type } = this.state

    // get lease info from context
    const lease = this.context.getLease()
    const full_name = `${lease.user_first_name} ${lease.user_last_name}`

    const { access_token, public_key, merchant_id } = this.state

    // create new
    const options = {
      key: public_key,
      access_token: access_token,
      email: lease.user_email,
      full_name: full_name,
      amount: amount,
      description: full_name,
      custom_fields: { leaseInfo: lease, server_ip: config.endPoint, payment_type, merchant_type: this.props.data.merchant_type },
      autopay: false, // envoi true
      cards: payment_type === 'CARD',
      ach: payment_type === 'CHECKING',
      deposit_account_id: merchant_id,
      on: {
        processing: data => console.log('processing ', data),
        declined: data => console.log('declined ', data),
        canceled: data => console.log('canceled ', data),
        loaded: data => {
          this.context.backdrop(false)

          console.log('loaded ', data)
        },
        paid: data => {
          console.log('paid ', data)

          this.onPaid(data.transaction_id)
        },
        error: data => console.log('error', data),
      }
    };

    window.Place.pay.open(options)
  }

  onPaid = (placepay_transaction_id) => {
    const { onPaid } = this.props
    const { public_key } = this.state

    this.context.backdrop(true)

    http.post('tenant/payments/placepay', { placepay_transaction_id, public_key }).then(response => {
      this.context.snackbar(<Trans ns='payments' i18nKey={response.data}>{response.data}</Trans>, {
        variant: 'success'
      })

      if (onPaid) {
        onPaid()
      }
    }, error => {
      if (error.data === 'payment.failed') {
        // pms failed - user need not know, show success msg and repeat success actions
        this.context.snackbar(<Trans ns='payments' i18nKey='payment.processed'>Payment has been processed.</Trans>, {
          variant: 'success'
        })
      } else {
        // probably payment gateway error
        this.context.snackbar(<Trans ns='payments' i18nKey={error.data}>{error.data}</Trans>, {
          variant: 'error'
        })
      }

      // hide button
      this.setState({ throttlePayment: true })

      // show btn after 5 min
      setTimeout(() => {
        this.setState({ throttlePayment: false })
      }, 5 * 60 * 1000);
    }).finally(() => this.context.backdrop(false))
  }

  render() {
    const { t, tReady, onPaid, ...props } = this.props
    const { throttlePayment } = this.state

    return (
      <React.Fragment>
        {/* throttlePayment? show msg */}
        {throttlePayment &&
          <Alert severity='info'>
            <Trans ns='placepay' i18nKey='throttlePayment.msg'>We are processing your payment. If amount has been debited from account then wait for some time to reflect on the system.</Trans>
          </Alert>}

        {/* placepay btn - */}
        {!throttlePayment && <ProgressButtonSimple
          onClick={this.getMeta}
          {...props}>
          {props.children}
        </ProgressButtonSimple>}
      </React.Fragment>
    )
  }
}

export default compose(
  withTranslation('placepay')
)(PlacePayButton);
