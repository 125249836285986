import React from 'react'
import { makeStyles, Button, CircularProgress } from '@material-ui/core';
import { green } from '@material-ui/core/colors';

const buttonStyles = makeStyles((theme) => ({
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -16,
    marginLeft: -12,
  }
}));


const ProgressButtonSimple = (props) => {
  const classes = buttonStyles();
  const { loading } = props;

  return (
    <div className={classes.wrapper}>
      <Button {...props}>{props.children}</Button>
      {(loading === "1") && <CircularProgress size={24} className={classes.buttonProgress} />}
    </div>
  )
}

export default ProgressButtonSimple;