import React from 'react'
import { AuthContext } from '../../../utils/AppContext'
import { withTranslation } from 'react-i18next'
import { compose } from 'recompose'

import http from '../../../utils/http';
import { getQuery } from '../../../utils/searchParams';

import Payment from './Payment'

class ApplicantPayment extends React.Component {
  static contextType = AuthContext
  constructor(props) {
    super(props)

    const getParams = getQuery(this.props.location.search)
    const token = getParams.access_token
    if (!token) {
      // @todo - what to do if no token?
      this.props.history.push('/404')
    }

    this.cardFormData = {
      card_no: '',
      expiry_month: '',
      expiry_year: '',
      cvv: '',
      account_holders_name: '',
    }

    this.checkFormData = {
      routing_number: '',
      account_number: '',
      account_holders_name: '',
      account_type: 'Checking', // Checking and Savings
    }

    this.state = {
      token: token,
      is_shareable_enabled: true,
      applicant: null,
      invoices: null,
      invoice_id: null, // for now fetchng 1st one as only invoice per applicant is there
      payments: null,

      payment_gateways: {},

      formData: {
        amount: '',

        billing_address_id: '',
        address_line_1: '',
        address_line_2: '',
        city: '',
        state_code: '',
        zip_code: '',

        payment_type: 'CARD',
        ...this.cardFormData,
        ...this.checkFormData,
      },
    }
  }

  componentDidMount() {
    this.context.setTitle('Application Payment')
    this.context.backdrop(true)

    this.getData()

    if (process.env.REACT_APP_ENV !== 'production') {
      console.table({
        'card-number-1': '4100000000000001',
        'card-number-2': '4242424242424242',
        'card-number-3': '5555555555554444',
        'routing-number': '021200025',
        'account-number': '123123124',
        'account-holder-name': 'Developer Tenant'
      })
    }
  }

  getData = () => {
    http.get('/applicant/payment', {
      headers: {
        token: this.state.token
      }
    }).then(response => {
      const { is_shareable_enabled, applicant, payments, payment_gateways, address } = response.data
      let { invoice_id, formData } = this.state

      let { invoices } = response.data
      invoices = invoices.map(invoiceItem => {

        if (!invoice_id) {
          // fetching 1st id as invoice id
          invoice_id = invoiceItem.id
        }

        // below - l2l mein bhi commented
        // invoiceItem.payments = payments.filter(paymentItem => paymentItem.invoice_id === invoiceItem.id);

        // sum total paid
        let paymentAmounts = payments
          .filter(paymentItem => parseInt(paymentItem.invoice_id) === parseInt(invoiceItem.id))
          .map(item => item.amount)

        // remove all undefined
        paymentAmounts = paymentAmounts.filter(item => item !== undefined)

        invoiceItem.invoice_paid = paymentAmounts.length ? paymentAmounts.reduce((a, b) => a + b) : 0

        invoiceItem.invoice_due = (invoiceItem.invoice_amount - invoiceItem.invoice_paid)

        return invoiceItem
      })

      if (address) {
        // address found in db - mostly shall be found as payment is After form submit - update formData to prefill address
        formData = {
          ...formData,
          address_line_1: address.address1 ?? '',
          address_line_2: address.address2 ?? '',
          city: address.city ?? '',
          state_code: address.state_code ?? '',
          zip_code: address.zip_code ?? '',
        }
      }

      this.setState(prevState => ({
        ...prevState,
        is_shareable_enabled,
        applicant,
        invoices,
        invoice_id,
        payments,
        payment_gateways,
        formData
      }))
    }, error => {
      // something went wrong - take user to application form
      this.props.history.push(`/auth/applicant?access_token=${this.state.token}`)
    }).finally(() => this.context.backdrop(false))
  }

  getDueInvoice = () => {
    const dueInvoices = this.state.invoices ? this.state.invoices.filter(item => item.invoice_due > 0) : [];

    // note: always 1st wala invoice used
    if (dueInvoices.length) {
      return dueInvoices[0]
    }

    return null
  }

  // clear timer for duplicate
  timer = null;

  // redirect to exam
  redirect = () => {
    clearTimeout(this.timer);
    const { is_shareable_enabled } = this.state

    this.timer = setTimeout(() => {
      // Redirect to Exam
      if (is_shareable_enabled) {
        this.props.history.push(`/applicant/exam?access_token=${this.state.token}`)
      } else {
        this.props.history.push(`/applicant/finish?access_token=${this.state.token}`)
      }
    }, 4000)
  }

  render() {
    const { applicant, token, payment_gateways, formData, invoice_id } = this.state

    // first due invoice
    const dueInvoice = this.getDueInvoice();

    // if no due invoice after fetching, redirect to exam
    if (applicant && !dueInvoice) {
      this.redirect();
    }

    if (!Object.keys(payment_gateways).length) {
      // abtak data nahi aaya
      return null
    }

    return <Payment
      formData={formData}
      invoice_id={invoice_id}
      payment_gateways={payment_gateways}
      parent='applicant'
      token={token}
      redirect={this.redirect}
      dueInvoice={dueInvoice}
      applicant={applicant}
      onSuccess={() => this.getData()} />
  }
}

export default compose(
  withTranslation('application')
)(ApplicantPayment)