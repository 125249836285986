import * as yup from 'yup'
import { GuestcardContactformValidation } from './GuestcardContactForm/guestcardContactformValidation'
import makePaymentFormValidation from './MakePaymentForm/makePaymentFormValidation'

const formValidation = (params) => {
  let appointment_date = false
  let show_lease_terms = false
  let show_marketing_sources = false
  if (typeof params !== 'undefined') {
    const { show_appointment_date, show_lease_term, show_marketing_source } = params
    appointment_date = show_appointment_date ? show_appointment_date : false
    show_lease_terms = show_lease_term ? show_lease_term : false
    show_marketing_sources = show_marketing_source ? true : false
  }
  let validationSchemaObject = {
    guestcard_contact_form: GuestcardContactformValidation(appointment_date, show_lease_terms, show_marketing_sources)
  }

  if (typeof params !== 'undefined') {
    const { pre_app_fee_payments, payment_gateways } = params;
    if (pre_app_fee_payments && pre_app_fee_payments.length) {
      validationSchemaObject = {
        ...validationSchemaObject,
        make_payment_form: makePaymentFormValidation({ payment_gateways })
      }
    }
  }

  return yup.object(validationSchemaObject);
}

export default formValidation