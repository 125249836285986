import React from 'react'
import { TextField } from '@material-ui/core';
import { useField } from 'formik';
import { Trans } from 'react-i18next';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
const filter = createFilterOptions();

const FormikAutoComplete = (props) => {
  const [field, meta, helper] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : "";
  // const errorText = meta.error ? meta.error : "";
  const noTouchClass = meta.touched ? '' : ' no-touch';
  const { options, onChange, label, className, disableAdd, disabled, renderOption, disableClearable, required } = props

  return (
    <Autocomplete
      closeIcon={<i className='icon dashboardicon-close'></i>}
      popupIcon={<i className='icon dashboardicon-down'></i>}
      options={options}
      value={field.value}
      onBlur={() => {
        helper.setTouched(true)
      }}
      disabled={disabled && disabled === true ? true : false}
      onChange={onChange}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        if (params.inputValue !== '' && !filtered.length && !disableAdd) {
          filtered.push({
            inputValue: params.inputValue,
            label: `Add "${params.inputValue}"`,
          });
        }

        return filtered;
      }}
      // options={contacts}
      getOptionSelected={option => {
        const row = options.find(item => item.id === option.id)
        return row;
      }}
      getOptionLabel={(option) => {
        // e.g value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        if (option.inputValue) {
          return option.inputValue;
        }

        return `${option.label}`;
      }}
      clearOnEscape={true}
      renderOption={renderOption ? renderOption : (option) => `${option.label}`}
      // renderOption={(option) => `${option.label}`}
      renderInput={(params) => (
        <TextField
          required={!!required}
          label={label}
          className={`${className} ${noTouchClass}`}
          {...params}
          helperText={<Trans ns={props.ns ? props.ns : 'translation'} i18nKey={errorText}></Trans>}
          error={!!errorText}
          variant="outlined" />
      )}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      disableClearable={disableClearable ? disableClearable : false} />
  );
};

export default FormikAutoComplete;