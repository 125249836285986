const config = {
  appName: process.env.REACT_APP_APPNAME || "ResPort",
  endPoint: process.env.REACT_APP_ENDPOINT || "http://localhost:3333",
  publicUrl: process.env.REACT_APP_PUBLIC_URL || "https://cdn.module1.envoi.ai",
  title: (title) => {
    const project_title = localStorage.project_title ? localStorage.project_title : config.appName
    document.title = `${title} | ${project_title}`;
  }
};

export default config;
