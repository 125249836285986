import React from 'react';
import { Link } from "react-router-dom";

/**
 * Child class component for Breadcrumb's item
 */
class Crumb extends React.Component {
  render() {
    const { path, children, active } = this.props;
    return (
      <li className={`breadcrumb-item ${active ? 'disabled' : ''}`}>
        <Link to={path}>
          {children}
        </Link>
      </li>
    );
  }
}

export default Crumb;