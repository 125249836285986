import React from 'react';
import { AuthContext } from '../../utils/AppContext';

/**
 * Parent class component for Breadcrumb
 */
class Breadcrumb extends React.Component {
  static contextType = AuthContext
  componentDidMount() {
    this.context.setBreadcrumb(this.props.children)
  }

  componentDidUpdate(previousProps) {
    let update = false
    if (!(previousProps.children instanceof Array)) {
      return 0
    }
    previousProps.children.map((item, key) => {
      const prevProp = item.props
      const newProp = this.props.children[key].props

      if ((typeof newProp === 'undefined') || (typeof prevProp === 'undefined')) {
        // sometimes due to lack of data, crumb is not loaded
        return 0
      }

      // not companrable
      if (prevProp.children instanceof Array || newProp.children instanceof Array) {
        return 0
      }

      if ((prevProp.children !== newProp.children) || (prevProp.path !== newProp.path)) {
        // any change in link or name of crumb? update
        update = true
      }

      return 0
    })
    if (update) {
      this.context.setBreadcrumb(this.props.children)
    }
    /* NOTE: comparing previousProps and this.props did not help as every time it was diff && caused infinite loop */
  }
  render() {
    return null
  }
}

export default Breadcrumb;