import React from 'react'
import css from '../Application.module.css'
import { withTranslation, Trans } from 'react-i18next'
import { compose } from 'recompose'
import { ConfirmBox } from '../../../../utils/DialogHelpers';
import http from '../../../../utils/http'
import { AuthContext } from '../../../../utils/AppContext'
import ProgressButtonSimple from '../../../Shared/ProgressButtonSimple';
import AddCoApplicant from './AddCoApplicant';

import {
  Grid,
  Typography,
  Button
} from '@material-ui/core'

class CoApplicant extends React.Component {
  static contextType = AuthContext
  constructor(props) {
    super(props)

    this.state = {
      addCoAppState: false,
      coApplicantType: '',
      deleteLoadingId: '',
      co_applicants: [],
      guarantor: [],
    }

    this.getCoApplicants()
  }

  getCoApplicants = () => {
    http.get('/co-applicant').then(response => {

      const co_applicants = []
      const guarantor = []
      response.data.map(item => {
        if (item.applicant_type === 'CO-APPLICANT') {
          co_applicants.push(item)
        }

        if (item.applicant_type === 'GUARANTOR') {
          guarantor.push(item)
        }
        return item
      })
      this.setState(prev => ({ ...prev, co_applicants, guarantor }))
    })
  }

  deleteCoApplicant = async (applicant_type, applicant_id) => {

    const confirm = await ConfirmBox.show({
      title: `Delete ${applicant_type.replace('_', ' ')}`,
      message: `Are you sure to remove the ${applicant_type.replace('_', ' ')}?`
    })

    if (!confirm) {
      return false
    }

    this.setState(prev => { return { ...prev, deleteLoadingId: applicant_id } })

    http.delete(`/co-applicant/${applicant_id}`,).then(response => {
      this.context.snackbar(<Trans ns='co_applicant' i18nKey={`${applicant_type}.delete.sucess`}>
        {applicant_type.replace('_', ' ')} removed successfully!
      </Trans>, {
        variant: 'success'
      })
      this.getCoApplicants()
    }, error => {
      this.context.snackbar(<Trans ns='co_applicant' i18nKey={error.data}></Trans>, {
        variant: 'error'
      })
    }).finally(() => {
      this.setState(prev => { return { ...prev, deleteLoadingId: '' } })
    })
  }

  render() {
    const { deleteLoadingId, co_applicants, guarantor } = this.state
    const { allow_edit } = this.props

    return (
      <React.Fragment>
        {/* add co applicant */}
        <AddCoApplicant
          onClose={() => {
            this.setState(prevState => {
              return { ...prevState, addCoAppState: false }
            })
          }}
          onSuccess={() => this.getCoApplicants()}
          open={this.state.addCoAppState}
          applicant_type={this.state.coApplicantType}
        />
        {/* add co applicant end */}
        <div className={`${!allow_edit ? css['override-disable-form-edit'] : ''} form-wrap ${css['form-grp']} ${css['spacing-bottom']}`}>
          <Grid container justify="flex-end" alignItems="center" spacing={3}>
            <Grid item>
              <Button
                type="button"
                fullWidth
                className={css['add-more-button']}
                // className="save-draft-buttton"
                onClick={() => {
                  this.setState(prevState => {
                    return { ...prevState, addCoAppState: true, coApplicantType: "CO-APPLICANT" }
                  })
                }}
              >
                + Add Co-Applicant
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="button"
                fullWidth
                className={css['add-more-button']}
                // className="save-draft-buttton"
                onClick={() => {
                  this.setState(prevState => {
                    return { ...prevState, addCoAppState: true, coApplicantType: "GUARANTOR" }
                  })
                }}
              >
                + Add Guarantor
              </Button>
            </Grid>
          </Grid>
          {/* co applicant lisitng */}
          {co_applicants.length > 0 && <div className={css['co-applicant-list']}>
            <React.Fragment>
              <Typography component="h1">
                <Trans ns="co_applicant" i18nKey='co_applicant.header'>Co-Applicant</Trans>
              </Typography>

              {co_applicants.map((row, index) => {
                let invited_status = '-'
                if (row.is_responsible) {
                  if (row.invited_status === 'Invited') {
                    invited_status = <strong style={{ color: 'green' }}>{row.invited_status}</strong>
                  } else {
                    invited_status = row.invited_status
                  }
                }

                const minorText = row.is_responsible ? '' : 'Minor'
                return (
                  <div className={css['co-applicant-list-view']} key={index}>
                    <Grid container alignItems='center' spacing={1}>
                      <Grid item xs={2} md={2}>
                        {row.first_name} {row.last_name}
                      </Grid>
                      <Grid item xs={4} md={4}>
                        {row.email}
                      </Grid>

                      {/* responsible? show invite status */}
                      <Grid item xs={2} md={2}>
                        {invited_status}
                      </Grid>

                      {/* NOT responsible? show minor status */}
                      <Grid item xs={2} md={2}>
                        {minorText}
                      </Grid>

                      <Grid item xs={2} md={2}>
                        <ProgressButtonSimple
                          loading={deleteLoadingId === row.id ? "1" : "0"}
                          color="primary"
                          className={`delete-icon`}
                          onClick={() => this.deleteCoApplicant('co_applicant', row.id)}
                          disabled={deleteLoadingId === row.id ? true : false}
                        >
                          <Trans ns='co_applicant' i18nKey='button.delete_co_applicant'><i className='icon dashboardicon-delete'></i></Trans>
                        </ProgressButtonSimple>
                      </Grid>
                    </Grid></div>)
              })}
            </React.Fragment>
          </div>}

          {/* guarantor listing */}
          {guarantor.length > 0 && <div className={css['co-applicant-list']}>
            <React.Fragment>
              <Typography component="h1">
                <Trans ns="co_applicant" i18nKey='guarantor.header'>Guarantor</Trans>
              </Typography>
              {guarantor.map((row, index) => {
                let invited_status = '-'
                if (row.is_responsible) {
                  if (row.invited_status === 'Invited') {
                    invited_status = <strong style={{ color: 'green' }}>{row.invited_status}</strong>
                  } else {
                    invited_status = row.invited_status
                  }
                }

                const minorText = row.is_responsible ? '' : 'Minor'
                return (
                  <div className={css['co-applicant-list-view']} key={index}>
                    <Grid container alignItems='center' spacing={1}>
                      <Grid item xs={2} md={2}>
                        {row.first_name} {row.last_name}
                      </Grid>
                      <Grid item xs={4} md={4}>
                        {row.email}
                      </Grid>

                      {/* responsible? show invite status */}
                      <Grid item xs={2} md={2}>
                        {invited_status}
                      </Grid>

                      {/* NOT responsible? show minor status */}
                      <Grid item xs={2} md={2}>
                        {minorText}
                      </Grid>

                      <Grid item xs={2} md={2}>
                        <ProgressButtonSimple
                          loading={deleteLoadingId === row.id ? "1" : "0"}
                          color="primary"
                          className={`delete-icon`}
                          onClick={() => this.deleteCoApplicant('guarantor', row.id)}
                          disabled={deleteLoadingId === row.id ? true : false}
                        >
                          <Trans ns='co_applicant' i18nKey='button.delete_co_applicant'><i className='icon dashboardicon-delete'></i></Trans>
                        </ProgressButtonSimple>
                      </Grid>
                    </Grid></div>)
              })}
            </React.Fragment>
          </div>}
        </div>
      </React.Fragment>
    )
  }
}

export default compose(
  withTranslation('co-applicant')
)(CoApplicant)