import React, { Fragment } from 'react'
import { withTranslation, Trans } from 'react-i18next'
import { compose } from 'recompose'
import { Link } from 'react-router-dom';

import { AuthContext } from '../../utils/AppContext'
import http from '../../utils/http';
import moneyFormat from '../../utils/moneyFormat';

import {
  Grid,
} from '@material-ui/core';

import { Alert, AlertTitle } from '@material-ui/lab';

import Breadcrumb from '../Breadcrumb/Breadcrumb';
import Crumb from '../Breadcrumb/Crumb';


class Dashboard extends React.Component {
  static contextType = AuthContext
  constructor(props) {
    super(props)

    this.state = {
      dashboard: null
    }
  }

  componentDidMount() {
    this.context.setTitle('Dashboard')

    this.getData()

    // fetch notification count
    this.context.setNotificationCount()
  }

  componentDidUpdate(prevProps) {
    try {
      if (prevProps.location.state !== this.props.location.state) {
        this.getData()
      }
    } catch (err) {
      console.log('Dashboard refresh failed', err)
    }
  }

  getData = () => {
    // reset all when unit switched, so that componentDidUpdate starts afresh
    this.setState({ dashboard: null });

    http.get('/tenant/dashboard').then(response => this.setState(prevState => ({ ...prevState, dashboard: response.data })))
  }

  render() {
    const { dashboard } = this.state
    const blocked_modules = this.context.user.metas?.blocked_modules
    const leaseInfo = this.context.getLease()
    const { pms_type } = leaseInfo

    /** context - resolve API taking time to get portal data */

    if (!dashboard) {
      return (
        <React.Fragment>
          Loading...
        </React.Fragment>
      )
    }

    return (
      <Fragment>
        <Breadcrumb>
          <Crumb path="/" active>Dashboard</Crumb>
        </Breadcrumb>

        <div className="content-wrapper">

          {(dashboard.modules.total_charge_amount === null && pms_type !== 'NONE') && <React.Fragment>
            <Alert severity="warning">
              <AlertTitle>Warning</AlertTitle>
              <Trans ns='dashboard' i18nKey='dashboard.alert.total_charge_amount.null'>
                Unable to fetch the latest due amount, Please try after some time OR contact your property manager for further assistance.
              </Trans>
            </Alert>
          </React.Fragment>}


          <div className='pro-add-acc-no-main'>
            <Grid container justify="flex-start" alignItems="flex-start">
              {!["NONE_PMS_UNIT_123", "SPECTRA_101"].includes(leaseInfo.unit_uid) && <Grid item xs={12} sm={4} md='auto' lg={4} xl={3}>
                <div className="acc-no">
                  {!["NONE_PMS_BLDG_123"].includes(leaseInfo.building_uid) &&
                    <>
                      <h2><Trans ns='dashboard' i18nKey='dashboard.title.building.unit'>Building/Unit</Trans></h2>
                      <p>{leaseInfo.building_uid}/{leaseInfo.unit_uid}</p>
                    </>
                  }
                  {["NONE_PMS_BLDG_123"].includes(leaseInfo.building_uid) &&
                    <>
                      <h2><Trans ns='dashboard' i18nKey='dashboard.title.unit'>Unit</Trans></h2>
                      <p>{leaseInfo.unit_uid}</p>
                    </>
                  }
                </div>
              </Grid>}
              <Grid item xs={12} sm={4} md='auto' lg={4} xl={3}>
                <div className="pro-add-acc-no">
                  <h2><Trans ns='dashboard' i18nKey='dashboard.title.property_address'>Property Address</Trans></h2>
                  <p>{dashboard.property_address}</p>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md='auto' lg={4} xl={3}>
                <div className="acc-no">
                  <h2>Account no:</h2>
                  <p>{dashboard.billing_account_number}</p>
                </div>
              </Grid>
            </Grid>
          </div>
          <Grid container justify="flex-start" alignItems="flex-start" spacing={5}>
            {/* Due amount */}
            {!blocked_modules.includes("MAKE_PAYMENTS") && <React.Fragment>
              <Grid item xs={12} sm={12} md={6} lg="auto" xl="auto">
                <div className="paynow-without-autopay">
                  {leaseInfo.pms_type !== 'NONE' &&
                    <>
                      <p>Amount Due</p>
                      <h2>{dashboard.modules.total_charge_amount === null ? <React.Fragment>N/A</React.Fragment> : <React.Fragment>{moneyFormat(dashboard.modules.total_charge_amount)}</React.Fragment>} </h2>
                    </>
                  }
                  {leaseInfo.pms_type === 'NONE' &&
                    <>
                      <p>Make Payment</p>
                      <div className='none-payment-block'>
                        <Trans ns='dashboard' i18nKey='dashboard.pay.details'>
                          .
                        </Trans>
                      </div>
                    </>
                  }
                  <Link className="pay-now-butn" to="/tenant/make-payment" data-testid="dashboard_pay_now">
                    {pms_type !== 'NONE' && <Trans ns='dashboard' i18nKey='dashboard.button.paynow'>Pay Now</Trans>}
                    {pms_type === 'NONE' && <Trans ns='dashboard' i18nKey='dashboard.button.one_time_payemnt'>One Time Payment</Trans>}
                  </Link>

                  {pms_type !== 'NONE' && <Link className="bill-details" to="/tenant/make-payment" data-testid="dashboard_bill_detail"><Trans ns='dashboard' i18nKey='dashboard.button.bill_detail'> Bill Detail </Trans></Link>}
                  {pms_type === 'NONE' && <Link className="bill-details" to="/tenant/autopays" data-testid="dashboard_bill_detail"><Trans ns='dashboard' i18nKey='dashboard.button.auto_pay'> Auto Pay </Trans></Link>}
                </div>
              </Grid>
            </React.Fragment>}
            {/* Due amount */}

            {/* Open Workorder count */}
            {!blocked_modules.includes("WORKORDERS") && <React.Fragment>
              <Grid item xs={12} sm={12} md={6} lg="auto" xl="auto">
                <div className="maintenance-requests-box">
                  <p>Workorders</p>
                  <h2>{dashboard.modules.open_wo_count}</h2>

                  <Link className="pay-now-butn" to="/workorders" data-testid="dashboard_workorders_view_now">
                    <Trans ns='dashboard' i18nKey='dashboard.button.workorders.viewnow'>
                      View Now
                    </Trans>
                  </Link>

                  {(leaseInfo && leaseInfo.allow_workorder) && <Link className="pay-now-butn" to="/workorders/add" data-testid="dashboard_workorders_new_request">
                    <Trans ns='dashboard' i18nKey='dashboard.button.workorders.make_a_new_request'>
                      Make a new request
                    </Trans>
                  </Link>}
                </div>
              </Grid>
            </React.Fragment>}
            {/* Open Workorder count */}

            {/* Pending Parcel count */}
            {!blocked_modules.includes("PARCELS") && <React.Fragment>
              <Grid item xs={12} sm={12} md={6} lg="auto" xl="auto">
                <div className="maintenance-requests-box">
                  <p>Parcels</p>
                  <h2>{dashboard.modules.pending_parcel_count}</h2>

                  <Link className="pay-now-butn" to="/parcels" data-testid="dashboard_parcels_view_now">
                    <Trans ns='dashboard' i18nKey='dashboard.button.parcels.viewnow'>
                      View Now
                    </Trans>
                  </Link>
                </div>
              </Grid>
            </React.Fragment>}
            {/* Pending Parcel count */}

            {/* Unread by resident count */}
            {!blocked_modules.includes("MESSAGES") && <React.Fragment>
              <Grid item xs={12} sm={12} md={6} lg="auto" xl="auto">
                <div className="maintenance-requests-box">
                  <p>Messages</p>
                  <h2>{dashboard.modules.unread_by_resident_message_count === null ? 0 : dashboard.modules.unread_by_resident_message_count}</h2>

                  <Link className="pay-now-butn" to="/messages" data-testid="dashboard_messages_view_now">
                    <Trans ns='dashboard' i18nKey='dashboard.button.messages.viewnow'>
                      View Now
                    </Trans>
                  </Link>
                </div>
              </Grid>
            </React.Fragment>}
            {/* Unread by resident count */}

          </Grid>
        </div>
      </Fragment >
    )
  }
}

export default compose(
  withTranslation('dashboard'),
)(Dashboard)