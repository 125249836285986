import React from 'react'
import { TextField } from '@material-ui/core';
import { useField } from 'formik';
import { Trans } from 'react-i18next';

const FormikTextField = (props) => {
  const [field, meta] = useField(props);

  // TextField error when value is null
  if (field.value === null) {
    field.value = ''
  }
  const errorText = meta.error && meta.touched ? meta.error : "";
  // const errorText = meta.error ? meta.error : "";
  return (
    <TextField
      {...field} {...props}
      helperText={<Trans ns={props.ns} i18nKey={errorText}></Trans>}
      error={!!errorText}
    />
  );
};

export default FormikTextField;