export const APPLICANT_ADDRESS = {
  address_type: 'RENT',
  apartment_no: '',
  street_no: '',
  street_name: '',
  street_type_id: '',
  city: '',
  state_id: '',
  zip_code: '',
  reason_for_moving: '',
  move_in_date: '',
  lease_expiration_date: '',
  landlord_name: '',
  landlord_contact_no: '',
  landlord_contact_no_2: '',
  landlord_contact_no_3: '',
  landlord_fax: '',
  landlord_email: '',
}

export const APPLICANT_EMPLOYER = {
  business_name: '',
  apartment_no: '',
  address_line_1: '',
  address_line_2: '',
  city: '',
  state_code: '',
  zip_code: '',
  position: '',
  income: '',
  income_frequency: 'ANNUALLY',
  reason_for_leaving: '', // Current
  supervisor_name: '',
  supervisor_contact_no: '',
  supervisor_email: '',
  start_date: '',
  end_date: '',
  type: 'FULL_TIME',
  self_employed: false,
}

export const APPLICANT_OTHER_INCOME = {
  source: '',
  amount: '',
  income_frequency: 'ANNUALLY',
}

export const APPLICANT_PET = {
  type: '',
  name: '',
  coloration: '',
  age: '',
  weight: '',
  weight_unit: 'LBS',
  sex: 'MALE',
  is_fixed: true
}

export const APPLICANT_VEHICLE = {
  make: '',
  model: '',
  year: '',
}

export const APPLICANT_REFERENCE = {
  first_name: '',
  last_name: '',
  contact_no: '',
  email: '',
  relationship: '',
}

export const APPLICANT_EMERGENCY_CONTACT = {
  first_name: '',
  last_name: '',
  contact_no: '',
  email: '',
  relationship: '',
  address_line_1: '',
  address_line_2: '',
  city: '',
  state_code: '',
  zip_code: '',
}

export const CO_APPLICANT = {
  first_name: '',
  last_name: '',
  contact_no: '',
  email: '',
  applicant_type: '',
}
