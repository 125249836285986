export default function yupGeneric(error) {
  let errorType = 'required'
  if (error.min) {
    errorType = 'min'
  }
  else if (error.max) {
    errorType = 'max'
  }
  else if (error.values) {
    errorType = 'valid'
  }
  else if (error.regex) {
    errorType = 'regex'
  }
  else if (error.type) {
    errorType = error.type
  }

  // replace array to [i] (items[0].amount -> items[i].amount)
  const path = error.path.replace(/\[([0-9]+)\]/g, '[i]')

  return `${path}.${errorType}`;
}