import React from 'react'
import { Trans } from 'react-i18next'
import { compose } from 'recompose'
import { withTranslation } from 'react-i18next'

import { AuthContext } from '../../../utils/AppContext'
import { getQuery } from '../../../utils/searchParams'
import css from '../ApplicationForm/Application.module.css'
import {
  Typography,
  Container,
  CssBaseline,
  createStyles,
  withStyles,
} from '@material-ui/core'

class ApplicationFormFinish extends React.Component {
  static contextType = AuthContext

  leaseSigned = null
  constructor(props) {
    super(props)

    const getParams = getQuery(this.props.location.search)
    const token = getParams.access_token
    if (!token) {
      // @todo - what to do if no token?
      this.props.history.push('/404')
    }

    // is /applicant/signed route? show different message
    this.leaseSigned = this.props.location.pathname === "/applicant/signed";
  }

  componentDidMount() {
    this.context.setTitle('Applicant - Thank You')
  }


  render() {
    const { classes } = this.props

    return (
      <React.Fragment>
        <div className={css['application-wrap']}>
          <div className={css['application-container']}>
            <Container component="main">
              <CssBaseline />

              <div className={classes.paper}>
                <div className={css['thank-you']}></div>
                <Typography component="h1" variant="h1">
                  {this.leaseSigned ?
                    <Trans ns="application" i18nKey='application_signed.header'>Lease Signed</Trans>
                    :
                    <Trans ns="application" i18nKey='application_finish.header'>Thank you</Trans>
                  }
                </Typography>

                <Typography component="h4" variant="h4">

                  {this.leaseSigned ?
                    <Trans ns="application" i18nKey='application_signed.message'>
                      Your lease application has been signed successfully, we have notififed the team and one of our associates will get back to you!
                    </Trans>
                    :
                    <Trans ns="application" i18nKey='application_finish.message'>
                      Your application has been submitted successfully, one of our associates will get back to you!
                    </Trans>
                  }
                </Typography>
              </div>
            </Container>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const useStyles = createStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }
}))

export default compose(
  withStyles(useStyles),
  withTranslation('application')
)(ApplicationFormFinish)
