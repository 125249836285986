import React from 'react'
import css from '../Application.module.css'
import { withTranslation, Trans } from 'react-i18next'
import { compose } from 'recompose'
import { FieldArray } from 'formik'

import FormikTextField from '../../../Shared/FormikTextField'

import { APPLICANT_VEHICLE } from '../ApplicantHelpers'

import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import {
  createStyles,
  withStyles,
  Typography,
  Grid,
  Button,
  Divider,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core'

class Vehicle extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      no_vehicle: false
    }
  }

  componentDidMount = () => {
    const { props } = this.props
    if (!props.values.applicant_vehicles.length) {
      this.setState({
        no_vehicle: true
      })
    }
  }
  // if checkbox = true and auto fill select  then set data = []
  componentDidUpdate = () => {
    if (this.state.no_vehicle) {
      const { props } = this.props
      if (props.values.applicant_vehicles.length) {
        props.values.applicant_vehicles = []
      }
    }
  }
  render() {
    const {
      t,
      deleteRecord,
      classes,
      props,
    } = this.props

    return (
      <React.Fragment>
        <div className={`${css['form-grp']} form-wrap`}>
          <div className={css['spacing-bottom']}>
            <div className={css['heading']}>
              <Typography component="h1">
                <Trans ns="application" i18nKey='vehicle.header'>Vehicle</Trans>
              </Typography>
            </div>
            <Grid container justify='flex-start' alignItems='center'>
              {/* No Vehicle */}
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className={css['checkbox']}>
                  <FormControlLabel
                    labelPlacement="end"
                    label={<Trans ns='application' i18nKey="label.no_vehicle">No Vehicle</Trans>}
                    control={<Checkbox
                      checked={this.state.no_vehicle}
                      name={`no_vehicle`}
                      onChange={(_event, newValue) => {
                        this.setState({
                          no_vehicle: newValue
                        })

                        // checkbox == false added Pet #1
                        if (newValue === false) {
                          if (!props.values.applicant_vehicles.length) {
                            props.values.applicant_vehicles.push(APPLICANT_VEHICLE)
                          }
                        } else {
                          // checkbox == true then no pets
                          props.values.applicant_vehicles = []
                        }
                      }}
                      color="primary"
                    />} />
                </div>
              </Grid>
            </Grid>
          </div>
        </div>

        <FieldArray name="applicant_vehicles">
          {({ push, form, remove }) => {
            const { values, errors } = form
            const { applicant_vehicles } = values

            return (
              <React.Fragment>
                {!this.state.no_vehicle &&
                  <div className={`${css['form-grp']} form-wrap`}>
                    {applicant_vehicles.map((_applicant_vehicle, index) => {
                      return (
                        <React.Fragment key={index}>
                          <div className={css['spacing-bottom']}>

                            <div className={css['heading']}>
                              <Grid container justify='space-between' alignItems='center'>
                                <Grid item>
                                  <Typography component="h1">
                                    <Trans ns="application" i18nKey={`applicant_vehicles.${index}.header`}>{`Vehicle #${index + 1}`}</Trans>
                                  </Typography>
                                </Grid>
                                {(index > 0) && <Grid item>
                                  <Button className={css['remove-button']} variant='contained' color='primary'
                                    onClick={() => {
                                      if (typeof applicant_vehicles[index] !== 'undefined') {
                                        // db wala record..
                                        deleteRecord('applicant_vehicles', applicant_vehicles[index].id)
                                      }
                                      remove(index)
                                    }}>
                                    <Trans ns='application' i18nKey='button.remove.applicant_vehicles'>- Remove Vehicle</Trans>
                                  </Button>
                                </Grid>}
                              </Grid>
                            </div>

                            <Grid container alignItems='center' spacing={3}>
                              {/* make input box */}
                              <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                <FormikTextField
                                  required
                                  ns="application"
                                  name={`applicant_vehicles[${index}].make`}
                                  type="text"
                                  variant="outlined"
                                  label={<Trans ns='application' i18nKey="label.make">Make</Trans>}
                                  placeholder={t('application:make', 'Make')}
                                  fullWidth
                                  className={classes.input}
                                  autoComplete="off" />
                              </Grid>
                              {/* model input box */}
                              <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                <FormikTextField
                                  required
                                  ns="application"
                                  name={`applicant_vehicles[${index}].model`}
                                  type="text"
                                  variant="outlined"
                                  label={<Trans ns='application' i18nKey="label.model">Model</Trans>}
                                  placeholder={t('application:model', 'Model')}
                                  fullWidth
                                  className={classes.input}
                                  autoComplete="off" />
                              </Grid>
                              {/* year input box */}
                              <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                {/* non formik year picker */}
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                  {/* todo - console warning : when first time year typed */}
                                  {/* Deprecation warning: value provided is not in a recognized RFC2822 or ISO format. moment construction falls back to js Date(), which is not reliable across all browsers and versions */}
                                  <KeyboardDatePicker
                                    views={["year"]}
                                    value={applicant_vehicles[index].year}
                                    onChange={(_dateObj, value) => form.setFieldValue(`applicant_vehicles[${index}].year`, value)}
                                    onBlur={event => form.handleBlur(event)}
                                    maxDate={new Date()} // max year is current year
                                    helperText={<Trans ns="application" i18nKey={errors?.applicant_vehicles?.[index]?.year}></Trans>}
                                    error={!!errors?.applicant_vehicles?.[index]?.year}
                                    autoOk
                                    required
                                    name={`applicant_vehicles[${index}].year`}
                                    ns="application"
                                    label={<Trans ns='application' i18nKey="label.year">Year</Trans>}
                                    placeholder={t('application:year', 'Year')}
                                    fullWidth
                                    className={`${classes.input} ${css['year-picker']}`}
                                    autoComplete="off"
                                  />
                                </MuiPickersUtilsProvider>
                              </Grid>
                              {/* License Plate Number input box */}
                              <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                <FormikTextField
                                  ns="application"
                                  name={`applicant_vehicles[${index}].license_plate_number`}
                                  type="text"
                                  variant="outlined"
                                  label={<Trans ns='application' i18nKey="label.license_plate_number">License Plate Number</Trans>}
                                  placeholder={t('application:license_plate_number', 'License Plate Number')}
                                  fullWidth
                                  className={classes.input}
                                  autoComplete="off" />
                              </Grid>
                            </Grid>
                            <Grid container justify='flex-end' alignItems='center' spacing={2} className={css['container-spacing-3']}>
                              {(applicant_vehicles.length === index + 1) && <Grid item>
                                <Button className={css['add-more-button']} variant='contained' color='primary'
                                  onClick={() => push(APPLICANT_VEHICLE)}>
                                  <Trans ns='application' i18nKey='button.add.applicant_vehicles'>+ Add Vehicle</Trans>
                                </Button>
                              </Grid>}
                            </Grid>

                            {(applicant_vehicles.length !== index + 1) && <Divider className={css['form-divider']} />}
                          </div>
                        </React.Fragment>
                      )
                    })}

                  </div>
                }
              </React.Fragment >
            )
          }}
        </FieldArray >
      </React.Fragment >
    )
  }
}

const useStyles = createStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(1, 0, 2),
  },
  input: {
    margin: theme.spacing(1, 0, 2),
  },
}))


export default compose(
  withStyles(useStyles),
  withTranslation('application')
)(Vehicle)