import React from 'react'
import css from '../Application.module.css'
import { withTranslation, Trans } from 'react-i18next'
import { compose } from 'recompose'
import { FieldArray } from 'formik'

import FormikTextField from '../../../Shared/FormikTextField'
import ApplicantFiles from '../../../Shared/ApplicantFiles';

import { APPLICANT_OTHER_INCOME } from '../ApplicantHelpers'

import {
  createStyles,
  withStyles,
  Typography,
  Grid,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Divider,
  Box,
  Checkbox
} from '@material-ui/core'

class OtherIncome extends React.Component {
  handleApplicantOtherIncomesChange = async index => {
    const { handleFileUpload, props } = this.props
    // get current uploaded files
    const files = await handleFileUpload('other_incomes', index)

    // add existing files to current files if present
    if (props.values.applicant_other_incomes[index].files && !!props.values.applicant_other_incomes[index].files.length) {
      files.push(...props.values.applicant_other_incomes[index].files)
    }

    // set files in key
    props.setFieldValue(`applicant_other_incomes[${index}].files`, files)
  }

  render() {
    const {
      t,
      deleteRecord,
      classes,
      props,
      applicantOtherIncomesFilesRef
    } = this.props

    const { no_other_income } = props.values;


    return (
      <React.Fragment>
        <div className={`${css['form-grp']} form-wrap`}>
          <div className={css['spacing-bottom']}>
            <div className={css['heading']}>
              <Typography component="h1">
                <Trans ns="application" i18nKey='other_income.header'>Other Income</Trans>
              </Typography>
            </div>
            <Grid container justify='flex-start' alignItems='center' >
              {/* No pets  */}
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                <div className={css['checkbox']}>
                  <FormControlLabel
                    labelPlacement="end"
                    label={<Trans ns='application' i18nKey="label.no_other_income">No other income</Trans>}
                    control={<Checkbox
                      checked={no_other_income}
                      name="no_other_income"
                      onChange={(_event, newValue) => {
                        props.setFieldValue('no_other_income', newValue)

                        props.setFieldValue('applicant_other_incomes', newValue === true ? [] : [APPLICANT_OTHER_INCOME])
                      }}
                      color="primary"
                    />} />
                </div>
              </Grid>
            </Grid>
          </div>
        </div>

        <FieldArray name="applicant_other_incomes">
          {({ push, form, remove }) => {
            const { values } = form
            const { applicant_other_incomes } = values

            return (
              <React.Fragment>
                {!no_other_income &&
                  <div className={`${css['form-grp']} form-wrap`}>
                    {applicant_other_incomes.map((_applicant_employer, index) => {
                      return (
                        <React.Fragment key={index}>
                          <div className={css['spacing-bottom']}>
                            <div className={css['heading']}>
                              <Grid container justify='space-between' alignItems='center'>
                                <Grid item>
                                  <Typography component="h1">
                                    <Trans ns="application" i18nKey={`applicant_other_incomes.${index}.header`}>{`Other Income #${index + 1}`}</Trans>
                                  </Typography>
                                </Grid>
                                {(index > 0) && <Grid item>
                                  <Button className={css['remove-button']} variant='contained' color='primary'
                                    onClick={() => {
                                      if (typeof applicant_other_incomes[index] !== 'undefined') {
                                        // db wala record..
                                        deleteRecord('applicant_other_incomes', applicant_other_incomes[index].id)
                                      }
                                      remove(index)
                                    }}>
                                    <Trans ns='application' i18nKey='button.remove.other_incomes'>- Remove Income</Trans>
                                  </Button>
                                </Grid>}
                              </Grid>
                            </div>

                            <Grid container alignItems='center' spacing={3}>
                              {/* source input text */}
                              <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                <FormikTextField
                                  required
                                  ns="application"
                                  name={`applicant_other_incomes[${index}].source`}
                                  type="text"
                                  variant="outlined"
                                  label={<Trans ns='application' i18nKey="label.source">Source</Trans>}
                                  placeholder={t('application:source', 'Source')}
                                  fullWidth
                                  className={classes.input}
                                  autoComplete="off" />
                              </Grid>
                              {/* amount input text */}
                              <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                <FormikTextField
                                  required
                                  ns="application"
                                  name={`applicant_other_incomes[${index}].amount`}
                                  type="text"
                                  variant="outlined"
                                  label={<Trans ns='application' i18nKey="label.amount">Amount</Trans>}
                                  placeholder={t('application:amount', 'Amount')}
                                  fullWidth
                                  className={classes.input}
                                  autoComplete="off" />
                              </Grid>
                              {/* income frequency radio button */}
                              <Grid item xs={12} sm={12} md='auto' lg='auto' xl='auto'>
                                <div className={css['radio-btn']}>
                                  <FormControl>
                                    <FormLabel>
                                      <Trans ns='application' i18nKey="label.income_frequency">
                                        Income frequency *
                                      </Trans>
                                    </FormLabel>
                                    <RadioGroup
                                      row
                                      aria-label="income_frequency"
                                      name={`applicant_other_incomes[${index}].income_frequency`}
                                      value={applicant_other_incomes[index].income_frequency}
                                      onChange={(_event, newValue) => {
                                        props.setFieldValue(`applicant_other_incomes[${index}].income_frequency`, newValue)
                                      }}>
                                      <FormControlLabel value="ANNUALLY" control={<Radio color="primary" />} label={<Trans ns='application' i18nKey="label.annually">Annually</Trans>} />
                                      <FormControlLabel value="MONTHLY" control={<Radio color="primary" />} label={<Trans ns='application' i18nKey="label.monthly">Monthly</Trans>} />
                                    </RadioGroup>
                                  </FormControl>
                                </div>
                              </Grid>
                            </Grid>

                            {/* upload files */}
                            <div className={css['upload-files-section']}>
                              <Grid container justify='flex-start' alignItems='center' className={css['container-spacing-3']}>
                                <Grid item>
                                  <Box className={css['sub-heading']} mr={2}><Trans ns='application' i18nKey="label.income_uploads">Income Uploads</Trans></Box>
                                </Grid>
                                <Grid item>
                                  <Button className={css['upload-files-button']}
                                    onClick={() => document.getElementById(`applicant_other_incomes[${index}].files`).click()}>
                                    <Trans ns='application' i18nKey='button.other_incomes.uploadfiles'>+ Upload Files</Trans>
                                  </Button>
                                  <input
                                    ref={applicantOtherIncomesFilesRef[index]}
                                    type="file" id={`applicant_other_incomes[${index}].files`}
                                    onChange={() => this.handleApplicantOtherIncomesChange(index)}
                                    multiple
                                    hidden
                                  // accept="image/*"
                                  />
                                </Grid>
                              </Grid>
                            </div>
                            {/* display uploaded files */}
                            {(props.values.applicant_other_incomes[index].files && !!props.values.applicant_other_incomes[index].files.length) && <React.Fragment>
                              <ApplicantFiles files={props.values.applicant_other_incomes[index].files} />
                            </React.Fragment>}

                            <Grid container justify='flex-end' alignItems='center' spacing={2} className={css['container-spacing-3']}>
                              {(applicant_other_incomes.length === index + 1) && <Grid item>
                                <Button className={css['add-more-button']} variant='contained' color='primary'
                                  onClick={() => push(APPLICANT_OTHER_INCOME)}>
                                  <Trans ns='application' i18nKey='button.add.other_incomes'>+ Add Other Income</Trans>
                                </Button>
                              </Grid>}
                            </Grid>
                            {(applicant_other_incomes.length !== index + 1) && <Divider className={css['form-divider']} />}
                          </div>
                        </React.Fragment>
                      )
                    })}
                  </div>
                }
              </React.Fragment>
            )
          }}
        </FieldArray>
      </React.Fragment>
    )
  }
}

const useStyles = createStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(1, 0, 2),
  },
  input: {
    margin: theme.spacing(1, 0, 2),
  },
}))


export default compose(
  withStyles(useStyles),
  withTranslation('application')
)(OtherIncome)