const dummy = {
  "transunion_terms_of_service": true,
  "credit_data_approval": "1",
  "application_terms_and_condition": false,
  "applicants": {
    "first_name": "Jacfirst",
    "last_name": "Beclast",
    "middle_name": "A",
    "contact_no": "(844) 635-9337",
    "contact_no_2": "(766) 632-6288",
    "contact_no_3": "(854) 695-2586",
    "gender": "FEMALE",
  },
  "applicant_screening_info": {
    "ssn": "(666)-(62)-(2631)",
    "date_of_birth": "1920-01-01",
    "dl_number": "787875",
    "annual_income": "1200",
    "maritial_status": "MARRIED",
  },
  "applicant_addresses": [
    {
      "address_type": "RENT",
      "apartment_no": "304",
      "street_no": "19",
      "street_name": "Church Road",
      "street_type_id": {
        "id": 9,
        "label": "BLUFFS"
      },
      "city": "Florida",
      "state_id": {
        "id": 33,
        "label": "New York"
      },
      "zip_code": "85412",
      "reason_for_moving": "Current",
      "move_in_date": "2021-06-01",
      "lease_expiration_date": "2022-01-01",
      "landlord_name": "Kantilal",
      "landlord_contact_no": "(963) 254-8562",
      "landlord_contact_no_2": "(745) 632-8596",
      "landlord_contact_no_3": "(985) 325-7456",
      "landlord_fax": "9632548545",
      "landlord_email": "developer+exlandlord1@conspecture.com",
      "is_current": true
    }
  ],
  "applicant_employers": [
    {
      "business_name": "Immenso",
      "address_line_1": "Plot 123",
      "address_line_2": "",
      "city": "Portland",
      "state_id": {
        "id": 33,
        "label": "New York",
        "code": "NY"
      },
      "zip_code": "97217",
      "position": "Asst. Manager",
      "income": "200",
      "income_frequency": "ANNUALLY",
      "reason_for_leaving": "",
      "supervisor_name": "Hank Mess",
      "supervisor_contact_no": "(126) 258-5644",
      "supervisor_contact_no_2": "(965) 123-7415",
      "supervisor_contact_no_3": "(456) 123-1212",
      "supervisor_email": "developer+employer1@conspecture.com",
      "start_date": "2021-07-06",
      "end_date": "",
      "type": "PART_TIME",
      "self_employed": false,
      "is_current": true
    }
  ],
  "applicant_other_incomes": [
    {
      "source": "Alimony",
      "amount": "50",
      "income_frequency": "MONTHLY"
    },
    {
      "source": "Land",
      "amount": "30",
      "income_frequency": "ANNUALLY"
    }
  ],
  "applicant_pets": [
    {
      "type": "Dog",
      "name": "Tommny",
      "coloration": "Red",
      "age": "10",
      "weight": "50",
      "weight_unit": "LBS",
      "sex": "MALE",
      "is_fixed": true
    },
    {
      "type": "Cat",
      "name": "Samy",
      "coloration": "Blue",
      "age": "1",
      "weight": "20",
      "weight_unit": "KG",
      "sex": "FEMALE",
      "is_fixed": false
    }
  ],
  "applicant_vehicles": [
    {
      "make": "Car",
      "model": "Suzuki",
      "year": "2021",
      "license_plate_number": "7KB-N47"
    },
    {
      "make": "Bike",
      "model": "Yamaha",
      "year": "2018",
      "license_plate_number": "FVC1162"
    }
  ],
  "applicant_references": [
    {
      "first_name": "John",
      "last_name": "Doe",
      "contact_no": "(253) 658-5625",
      "email": "developer+reference1@conspecture.com",
      "relationship": {
        "id": "PARENT",
        "label": "Parent"
      }
    },
    {
      "first_name": "Joe",
      "last_name": "Mess",
      "contact_no": "(632) 547-8569",
      "email": "developer+reference2@conspecture.com",
      "relationship": {
        "id": "CO_SIGNER",
        "label": "Co-signer"
      }
    }
  ],
  "applicant_emergency_contacts": [
    {
      "first_name": "Foo",
      "last_name": "Bar",
      "email": "foobar@conspecture.com",
      "contact_no": "(963) 254-8575",
      "relationship": {
        "id": "PARENT",
        "label": "Parent"
      },
      "address_line_1": "Plot 123",
      "address_line_2": "",
      "city": "Portland",
      "state_id": {
        "id": 33,
        "label": "New York",
        "code": "NY"
      },
      "zip_code": "97217",
    },
    {
      "first_name": "May",
      "last_name": "Young",
      "email": "may@conspecture.com",
      "contact_no": "(363) 259-8745",
      "relationship": {
        "id": "SPOUSE",
        "label": "Spouse"
      },
      "address_line_1": "Plot 123",
      "address_line_2": "",
      "city": "Portland",
      "state_id": {
        "id": 33,
        "label": "New York",
        "code": "NY"
      },
      "zip_code": "97217",
    }
  ],
  // Not required for autofill
  // "signature_n_approval": {
  //   "signature_data": {},
  //   "signature_date": null,
  //   "signature_ip": null,
  //   "application_consent_to_share_data": false,
  //   "credit_data_approval": "1",
  //   "application_terms_and_condition": false,
  //   "screening_request_renter_uid": null
  // }
}

export default dummy