import React from 'react'
import { Route } from 'react-router-dom'
import { AuthConsumer } from './../../../utils/AppContext'

// Routes accessible regardless of login state
const Layout = ({ component: Component, ...rest }) => {

  return (
    <AuthConsumer>
      {({ isAuth }) => {

        return (
          <Route
            render={props => {
              const root = document.getElementById('resport-resident-app')
              const routePattern = props.match.path.replace(/([^a-z])/ig, '-').replace(/([-]{2,})/g, '-').replace(/^-+|-+$/g, '')
              // const routeUrl = props.match.url.replace(/([^a-z])/ig, '-').replace(/([-]{2,})/g, '-').replace(/^-+|-+$/g, '')

              root.setAttribute('data-route-pattern', routePattern);
              // root.setAttribute('data-route-url', routeUrl);

              return <React.Fragment>
                <Component {...props} />
              </React.Fragment>
            }
            }
            {...rest}
          />
        )
      }}
    </AuthConsumer>
  )
}

export { Layout };