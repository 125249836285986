import { Grid } from '@material-ui/core'
import React, { Fragment } from 'react'
import RawHtml from '../../Shared/RawHtml'
import { Controlled as CodeMirror } from 'react-codemirror2'
import axios from 'axios'
import { AuthContext } from '../../../utils/AppContext';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import 'codemirror/mode/lua/lua';
import config from '../../../utils/config'

const CancelToken = axios.CancelToken;
let cancel;

class MjmlPlayground extends React.Component {
  static contextType = AuthContext

  constructor(props) {
    super(props)

    this.state = {
      mjmlContent: this.defaultContent(),
      htmlContent: 'Rendering...',
      errors: [],
      json: JSON.stringify({
        "name": "Developer",
        "age": 30,
        "hobbies": [
          "singing",
          "writing",
          "cricket"
        ]
      }, null, 2)
    }
  }

  componentDidMount() {
    this.context.setTitle('MJML Playground');
    this.refreshHtml();
  }

  refreshHtml() {
    const { mjmlContent, json } = this.state

    if (cancel !== undefined) {
      cancel();
    }

    // pass mjml content and json body
    axios.post(`${config.endPoint}/mjml`, {
      mjml: mjmlContent,
      json: JSON.parse(json)
    }, {
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    })
      .then((response) => {
        this.setState({ htmlContent: response.data.html, errors: response.data.errors })
      })
      .catch((error) => {
        if (error.response) {
          this.setState({ errors: [error.response.data] })
        }
      });
  }

  // initial default content
  defaultContent() {
    const content = `
<mjml>
  <mj-body>
    <mj-wrapper padding="0px" border="1px solid #a1a7ab">
      <mj-section padding="0px">
        <mj-column>
          <mj-text>Hey <strong>{{name}}</strong></mj-text>

          @if(age > 20)
          	<mj-text>You are getting <u>old</u>.</mj-text>
          @endif
          @if(age <= 20)
         	 	<mj-text>You are still <u>young</u>.</mj-text>
          @endif

    			@if(!hobbies || !hobbies.length)
      			<mj-text>You have no hobbies</mj-text>
	      	@endif
          @if(hobbies && hobbies.length)
            <mj-text padding-bottom="0">Your hobbies are as follows</mj-text>
            <mj-text>
              <ul>
              @each(hobby in hobbies)
                <li> {{ hobby }} </li>
              @end
              </ul>
            </mj-text>
          @endif
        </mj-column>
      </mj-section>
    </mj-wrapper>
  </mj-body>
</mjml>
      `
    return content.trim();
  }

  render() {
    const { mjmlContent, json, htmlContent, errors } = this.state

    return (
      <Fragment>
        <Grid container justify='flex-end'>
          <Grid item>
            <a href='https://documentation.mjml.io/#standard-body-components' target='_blank' rel="noopener noreferrer">Documentation</a>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CodeMirror
              value={mjmlContent}
              options={{
                mode: 'lua',
                theme: 'material',
                lineNumbers: true,
                tabSize: '2',
              }}
              onBeforeChange={(editor, data, mjmlContent) => {
                this.setState({ mjmlContent });
                this.refreshHtml()
              }}
              onChange={(editor, data, mjmlContent) => {
                this.setState({ mjmlContent });
                this.refreshHtml()
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <textarea
              rows={20}
              cols={80}
              value={json}
              style={{
                width: '100%',
                height: '100%'
              }}
              onBlur={(e) => {
                try {
                  const validate = JSON.parse(e.target.value)

                  // refresh if valid
                  this.setState({ json: JSON.stringify(validate, null, 2), errors: [] })
                  this.refreshHtml();
                } catch (err) {
                  this.setState({ json: e.target.value, errors: ["JSON:" + err.message] })
                }
              }}
              onChange={(e) => {
                this.setState({ json: e.target.value })

                try {
                  JSON.parse(e.target.value)
                  // refresh if valid
                  this.refreshHtml();
                } catch (err) {
                  this.setState({ errors: ["JSON:" + err.message] })
                }
              }}></textarea>
            {/* <CodeMirror
              value={json}
              options={{
                mode: 'javascript',
                theme: 'material',
                lineNumbers: true,

              }}
              onBeforeChange={(editor, data, json) => {
                this.setState({ json });
                this.refreshHtml()
              }}
              onChange={(editor, data, json) => {
                this.setState({ json });
                this.refreshHtml()
              }}
            /> */}
          </Grid>
          <Grid item xs={8}>
            <RawHtml>{htmlContent}</RawHtml>
          </Grid>
          <Grid item xs={12}>
            {!!errors.length && <pre>{JSON.stringify(errors, null, 2)}</pre>}
            {!!!errors.length && 'No errors'}
          </Grid>
        </Grid>
      </Fragment>
    )
  }
}

export default MjmlPlayground
