import { createTheme } from '@material-ui/core/styles';

// Ref - https://material-ui.com/customization/theming/


const theme = createTheme({
  palette: {
    primary: {
      light: '#757ce8',
      main: '#3f50b5',
      dark: '#002884',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000',
    },
  },
  typography: {
    button: {
      textTransform: 'inherit',
    },
  },
});

export { theme }