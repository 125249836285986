import http from "../utils/http";

export function rolesReducer(state = { init: false, data: [] }, action) {
  switch (action.type) {
    case 'LOAD_ROLE': {
      return { ...state, ...action.payload }
    }
    default:
      return state;
  }
}

export function preLoadRoles(force = false) {
  return async (dispatch, getState) => {
    // preloaded? ignore this
    if (getState().roles.init === true && !force) {
      return Promise.resolve()
    }
    const response = await http.get(`/admin/roles`);
    return dispatch({
      type: 'LOAD_ROLE',
      payload: {
        init: true,
        data: response.data
      }
    });
  }
}