import React from 'react'
import css from '../Application.module.css'
import { withTranslation, Trans } from 'react-i18next'
import { compose } from 'recompose'
import { FieldArray } from 'formik'

import FormikTextField from '../../../Shared/FormikTextField'
import FormikAutoComplete from '../../../Shared/FormikAutoComplete'
import FormikMaskedInput from '../../../Shared/FormikMaskedInput'

import { APPLICANT_EMERGENCY_CONTACT } from '../ApplicantHelpers'

import {
  createStyles,
  withStyles,
  Typography,
  Grid,
  Button,
  Divider,
  Box
} from '@material-ui/core'

class EmergencyContact extends React.Component {
  render() {
    const {
      t,
      deleteRecord,
      classes,
      props,
      states
    } = this.props

    const relationship = [
      { id: 'SIBLING', label: 'Sibling' },
      { id: 'SPOUSE', label: 'Spouse' },
      { id: 'CHILD', label: 'Child' },
      { id: 'PARENT', label: 'Parent' },
      { id: 'NOT_DIRECTLY_RELATED', label: 'Not directly related' },
      { id: 'FRIEND', label: 'Friend' },
      { id: 'OTHER', label: 'Other' },
    ]

    return (
      <React.Fragment>
        <FieldArray name="applicant_emergency_contacts">
          {({ push, form, remove }) => {
            const { values } = form
            const { applicant_emergency_contacts } = values

            return (
              <React.Fragment>
                <div className={`${css['form-grp']} form-wrap`}>
                  {applicant_emergency_contacts.map((applicant_emergency_contact, index) => {
                    return (
                      <React.Fragment key={index}>
                        <div className={css['spacing-bottom']}>
                          <div className={css['heading']}>
                            <Grid container justify='space-between' alignItems='center'>
                              <Grid item>
                                <Typography component="h1">
                                  <Trans ns="application" i18nKey={`applicant_emergency_contacts.${index}.header`}>{`Emergency Contact #${index + 1}`}</Trans>
                                </Typography>
                              </Grid>
                              {(index > 0) && <Grid item>
                                <Button className={css['remove-button']} variant='contained' color='primary'
                                  onClick={() => {
                                    if (typeof applicant_emergency_contacts[index] !== 'undefined') {
                                      // db wala record..
                                      deleteRecord('applicant_emergency_contacts', applicant_emergency_contacts[index].id)
                                    }
                                    remove(index)
                                  }}>
                                  <Trans ns='application' i18nKey='button.remove.applicant_emergency_contacts'>- Remove Contact</Trans>
                                </Button>
                              </Grid>}
                            </Grid>
                          </div>

                          <Grid container alignItems='center' spacing={3}>
                            {/* first name input text */}
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                              <FormikTextField
                                required
                                ns="application"
                                name={`applicant_emergency_contacts[${index}].first_name`}
                                type="text"
                                variant="outlined"
                                label={<Trans ns='application' i18nKey="label.first_name">First Name</Trans>}
                                placeholder={t('application:first_name', 'First name')}
                                fullWidth
                                className={classes.input}
                                autoComplete="off" />
                            </Grid>
                            {/* last name input text */}
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                              <FormikTextField
                                required
                                ns="application"
                                name={`applicant_emergency_contacts[${index}].last_name`}
                                type="text"
                                variant="outlined"
                                label={<Trans ns='application' i18nKey="label.last_name">Last Name</Trans>}
                                placeholder={t('application:last_name', 'Last name')}
                                fullWidth
                                className={classes.input}
                                autoComplete="off" />
                            </Grid>
                            {/* contact no input text */}
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                              <FormikMaskedInput
                                required
                                name={`applicant_emergency_contacts[${index}].contact_no`}
                                type='text'
                                label={t('application:label.contact_no', 'Contact No.')}
                                mask_type='(999) 999-9999'
                              />
                            </Grid>
                            {/* email input text */}
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                              <FormikTextField
                                ns="application"
                                name={`applicant_emergency_contacts[${index}].email`}
                                type="email"
                                variant="outlined"
                                label={<Trans ns='application' i18nKey="label.email">Email</Trans>}
                                placeholder={t('application:email', 'Email')}
                                fullWidth
                                className={classes.input}
                                autoComplete="off" />
                            </Grid>
                            {/* relationship input text */}
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                              <FormikAutoComplete
                                required
                                disableAdd={true}
                                name={`applicant_emergency_contacts[${index}].relationship`}
                                label={<Trans ns='application' i18nKey="label.relationship">Relationship</Trans>}
                                options={relationship.map(item => ({ id: item.id, label: item.label }))}
                                onChange={(_event, newValue) => {
                                  if (typeof newValue === 'string') {
                                    // timeout to avoid instant validation of the dialog's form.
                                    setTimeout(() => {
                                      this.addAutoComplete(`applicant_emergency_contacts[${index}].relationship`, newValue)
                                    });
                                  } else if (newValue && newValue.inputValue) {
                                    this.addAutoComplete(`applicant_emergency_contacts[${index}].relationship`, newValue)
                                  } else {
                                    props.setFieldValue(`applicant_emergency_contacts[${index}].relationship`, newValue)
                                  }
                                }}
                                ns='application' />
                            </Grid>
                            {/* address line1 input text */}
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                              <FormikTextField
                                required
                                ns="application"
                                name={`applicant_emergency_contacts[${index}].address_line_1`}
                                type="text"
                                variant="outlined"
                                label={<Trans ns='application' i18nKey="label.address_line_1">Address line 1</Trans>}
                                placeholder={t('application:address_line_1', 'Address line 1')}
                                fullWidth
                                className={classes.input}
                                autoComplete="off" />
                            </Grid>
                            {/* address line2 input text */}
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                              <FormikTextField
                                ns="application"
                                name={`applicant_emergency_contacts[${index}].address_line_2`}
                                type="text"
                                variant="outlined"
                                label={<Trans ns='application' i18nKey="label.address_line_2">Address line 2</Trans>}
                                placeholder={t('application:address_line_2', 'Address line 2')}
                                fullWidth
                                className={classes.input}
                                autoComplete="off" />
                            </Grid>
                            {/* city input text */}
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                              <FormikTextField
                                required
                                ns="application"
                                name={`applicant_emergency_contacts[${index}].city`}
                                type="text"
                                variant="outlined"
                                label={<Trans ns='application' i18nKey="label.city">City</Trans>}
                                placeholder={t('application:city', 'City')}
                                fullWidth
                                className={classes.input}
                                autoComplete="off" />
                            </Grid>
                            {/* state select box */}
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                              <FormikAutoComplete
                                required
                                disableAdd={true}
                                name={`applicant_emergency_contacts[${index}].state_id`}
                                label={<Trans ns='application' i18nKey="label.state_id">State</Trans>}
                                options={states.map(item => ({ id: item.id, label: item.name, code: item.code }))}
                                onChange={(_event, newValue) => {
                                  if (typeof newValue === 'string') {
                                    // timeout to avoid instant validation of the dialog's form.
                                    setTimeout(() => {
                                      this.addAutoComplete(`applicant_emergency_contacts[${index}].state_id`, newValue)
                                    });
                                  } else if (newValue && newValue.inputValue) {
                                    this.addAutoComplete(`applicant_emergency_contacts[${index}].state_id`, newValue)
                                  } else {
                                    props.setFieldValue(`applicant_emergency_contacts[${index}].state_id`, newValue)
                                  }
                                }}
                                ns='application' />
                            </Grid>
                            {/* zip_code input box */}
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                              <FormikTextField
                                required
                                ns="application"
                                name={`applicant_emergency_contacts[${index}].zip_code`}
                                type="text"
                                variant="outlined"
                                label={<Trans ns='application' i18nKey="label.zip_code">ZIP</Trans>}
                                placeholder={t('application:zip_code', 'Zip')}
                                fullWidth
                                className={classes.input}
                                onBlur={(event) => {
                                  let zip_code = event.target.value.toString()

                                  // prefix 0's | 10 -> 00010
                                  if (zip_code.length > 0 && zip_code.length < 5) {
                                    zip_code = ("00000" + zip_code).substring(zip_code.length);
                                    props.setFieldValue('zip_code', zip_code);
                                  } else {
                                    // show error
                                    props.setFieldTouched(`applicant_emergency_contacts[${index}].zip_code`, true)
                                  }
                                }}
                                autoComplete="off" />
                            </Grid>
                          </Grid>

                          <Grid container justify='flex-end' alignItems='center' spacing={2} className={css['container-spacing-3']}>
                            {(applicant_emergency_contacts.length === index + 1) && <Grid item>
                              <Box mt={1}>
                                <Button className={css['add-more-button']} variant='contained' color='primary'
                                  onClick={() => push(APPLICANT_EMERGENCY_CONTACT)}>
                                  <Trans ns='application' i18nKey='button.add.applicant_emergency_contacts'>+ Add Emergency Contact</Trans>
                                </Button>
                              </Box>
                            </Grid>}
                          </Grid>
                          <Divider className={css['form-divider']} />
                        </div>
                      </React.Fragment>
                    )
                  })}
                </div>
              </React.Fragment>
            )
          }}
        </FieldArray>
      </React.Fragment>
    )
  }
}

const useStyles = createStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(1, 0, 2),
  },
  input: {
    margin: theme.spacing(1, 0, 2),
  },
}))


export default compose(
  withStyles(useStyles),
  withTranslation('application'),
)(EmergencyContact)