import React from 'react'
import { compose } from 'recompose';

import { Trans, withTranslation } from 'react-i18next';

import { Form, Formik } from 'formik'
import ProgressButton from '../../Shared/ProgressButton';
import http from '../../../utils/http';
import css from '../PreApplicationForm/GuestcardContactForm/GuestcardContactForm.module.css'


import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography
} from '@material-ui/core';
import FormikAutoComplete from '../../Shared/FormikAutoComplete';

class ChangeUnitDialog extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      invite_url: this.props.invite_url,
      unit_is_locked: this.props.unit_is_locked,
      applicant_id: this.props.applicant_id,
      property_id: this.props.property_id,
      change_unit_dialog_open: this.props.change_unit_dialog_open,
      units: [],
      formData: {
        unit_id: '',
      }
    }
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    const { property_id, applicant_id } = this.state
    http.get(`plugin/guestcard/${property_id}/units`, { params: { applicant_id } })
      .then(response => {
        // list of accessible units to change to
        this.setState(prevState => ({
          ...prevState,
          units: response.data,
          loading: false
        }))
      })
  }

  render() {
    const {
      loading,
      invite_url,
      formData,
      change_unit_dialog_open,
      units
    } = this.state

    return (
      <React.Fragment>
        <Dialog
          open={change_unit_dialog_open}
          onClose={this.props.toggleUnitDialog}>
          <DialogTitle>
            <Trans ns="application" i18nKey='change_unit_popup.title'>
              Change Unit
            </Trans>
          </DialogTitle>
          <Formik
            initialValues={formData}
            onSubmit={(values, action) => this.props.onSuccess(values, action)}
          >
            {props => {
              return (<Form>
                <div className={css['guestcard-popup']}>
                  <DialogContent>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div className={css['guestcard-text-opps']}>
                          <Typography>
                            <Trans ns='application' i18nKey="change_unit_popup.info">
                              Oops! Looks like the unit you applied to is no longer available.
                            </Trans>
                          </Typography>
                        </div>
                      </Grid>

                      {/* units found - dropdown show */}
                      {!!units.length &&
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Typography>
                            <Trans ns='application' i18nKey="change_unit_popup.change_unit">
                              Here are some units available. Please select one and submit.
                            </Trans>
                          </Typography>
                          <div className={`${css['popup-select-box']} form-select-box`}>
                            <FormikAutoComplete
                              disableAdd={true}
                              name='unit_id'
                              label={<Trans ns='application' i18nKey="change_unit_popup.label.units">Units</Trans>}
                              options={units.map(item => ({ id: item.id, label: `${item.building_no} / ${item.unit_no}`, url: item.select_plan_link }))}
                              renderOption={(option) => <>
                                <div>
                                  {option.label}
                                  {(option.url !== '') && <a target='_blank' rel='noopener noreferrer' href={option.url} className='template-preview'>
                                    <i className='icon dashboardicon-view'></i>
                                  </a>}
                                </div>
                              </>}
                              ns='application'
                              onChange={(event, value) => {
                                props.setFieldValue("unit_id", value)
                              }}
                              disableClearable />
                          </div>
                        </Grid>}

                      {/* no available units? show invite */}
                      {(!loading && !units.length) && <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography>
                          <Trans ns='application' i18nKey="change_unit_popup.no_units">
                            Unfortunately there are no other units available. Please contact manager for further assistance.
                          </Trans>
                        </Typography>
                        <Typography>
                          Please
                          <a href={invite_url} target="_blank" rel="noopener noreferrer">
                            Click here
                          </a>
                          to complete your application.
                        </Typography>
                      </Grid>}
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    {!!units.length && <ProgressButton
                      type="submit"
                      loading={props.isSubmitting ? "1" : "0"}
                      color="primary"
                      disabled={props.isSubmitting}
                      className={`${css["submit-butn"]} form-button`}>
                      <Trans ns='application' i18nKey="change_unit_popup.button.submit">Submit</Trans>
                    </ProgressButton>}
                  </DialogActions>
                </div>
              </Form>)
            }}
          </Formik>
        </Dialog>
      </React.Fragment>
    )
  }
}

export default compose(
  withTranslation('application')
)(ChangeUnitDialog);