import React from 'react'
import { compose } from 'recompose';
import { Trans, withTranslation } from 'react-i18next';

import moneyFormat from '../../../../utils/moneyFormat';
import { AuthContext } from '../../../../utils/AppContext';

import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button
} from '@material-ui/core';
import css from '../GuestcardContactForm/GuestcardContactForm.module.css'


class ProcessPaymentConfirmDialog extends React.Component {
  static contextType = AuthContext

  render() {
    const { open, onClose, onSuccess, dialogData } = this.props
    const { payment_type, amount, first_name, last_name, fee_amount, total_amount } = dialogData


    return (
      <React.Fragment>
        <Dialog
          maxWidth="md"
          fullWidth={true}
          open={open}>
          <DialogTitle>
            <Trans ns='application' i18nKey='application.dialog.confirm_process_payment.title'>Process Payment?</Trans>
          </DialogTitle>
          <DialogContent dividers>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <h3 className={css['dialog-title']}>
                  <Trans ns='application' i18nKey='application.dialog.confirm_process_payment.heading'>
                    Please Confirm Payment Details:
                  </Trans>
                </h3>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container spacing={3}>
                  <Grid item xs={6} sm={4} md={2} lg={2} xl={2}>
                    <h3 className={css['dialog-label']}><Trans ns='application'>Name</Trans></h3>
                    <div className={css['dialog-data']}>
                      <Typography variant="subtitle2">
                        {first_name} {last_name}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={6} sm={4} md={2} lg={2} xl={2}>
                    <h3 className={css['dialog-label']}><Trans ns='application'>Payment Type</Trans></h3>
                    <div className={css['dialog-data']}>
                      <Typography variant="subtitle2">
                        {payment_type ? payment_type : ''}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={6} sm={4} md={2} lg={2} xl={2}>
                    <h3 className={css['dialog-label']}><Trans ns='application'>Amount</Trans></h3>
                    <div className={css['dialog-data']}>
                      <Typography variant="subtitle2">
                        {amount ? moneyFormat(amount) : ''}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={6} sm={4} md={2} lg={2} xl={2}>
                    <h3 className={css['dialog-label']}><Trans ns='payments'>Fee</Trans></h3>
                    <div className={css['dialog-data']}>
                      <Typography variant="subtitle2" >
                        {fee_amount ? moneyFormat(fee_amount) : '$0'}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={6} sm={4} md={2} lg={2} xl={2}>
                    <h3 className={css['dialog-label']}><Trans ns='payments'>Total Amount</Trans></h3>
                    <div className={css['dialog-data']}>
                      <Typography variant="subtitle2">
                        <strong>{total_amount ? moneyFormat(total_amount) : ''}</strong>
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <div className={css['dialog-footer']}>
              <Grid container justify='flex-end' alignItems='center' spacing={2}>
                <Grid item>
                  <Button autoFocus onClick={() => { if (onClose) onClose() }} color="primary">
                    <Trans ns='application' i18nKey='application.dialog.confirm_process_payment.button.cancel'>
                      Cancel
                    </Trans>
                  </Button>
                </Grid>
                <Grid item>
                  <Button onClick={() => { if (onSuccess) onSuccess() }} color="primary" className="form-button">
                    <Trans ns='application' i18nKey='application.dialog.confirm_process_payment.button.ok'>
                      Ok
                    </Trans>
                  </Button>
                </Grid>
              </Grid>
            </div>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    )
  }
}

export default compose(
  withTranslation('application')
)(ProcessPaymentConfirmDialog);