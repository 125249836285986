import React, { useState, useContext } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { AuthConsumer } from './../../../utils/AppContext'
import { Header } from './Header'
// bootstrap
// import 'bootstrap/dist/css/bootstrap.min.css';
import { makeStyles } from '@material-ui/core'
import { Paper, Box } from '@material-ui/core'
import { AuthContext } from "../../../utils/AppContext";

import PropertyNotFound from '../../Shared/PropertyNotFound';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
  },
}));

// Routes accessible only if logged in
const Layout = ({ component: Component, policy, ...rest }) => {
  const context = useContext(AuthContext);
  const bannerList = context.portalData.banner ? context.portalData.banner : []
  const current_date = new Date().getTime()
  const banner = bannerList.filter(item => (current_date >= item.from && current_date <= item.to))

  const units = context.getUnits()
  const [drawerState, setDrawerState] = useState(true)
  const classes = useStyles();

  return (
    <AuthConsumer>
      {({ isAuth, hasAccess, rightBarState, portalData }) => {
        // console.log('PrivateRoute', rest);
        let redirectPath = `?redirect=${encodeURIComponent(rest.location.pathname + rest.location.search)}`;
        if (rest.path === '/auth/logout' || rest.path === '/') {
          redirectPath = '';
        }
        if (!isAuth) {
          console.log('Redirecting to', redirectPath);
          return (
            <Redirect to={`/auth/login${redirectPath}`} />
          )
        }

        // If property not active
        if (portalData.subscription_active === false) {
          return <PropertyNotFound errorKey="subscription_inactive" />
        }

        // If property soft deleted
        if (portalData.property_deleted) {
          return <PropertyNotFound errorKey="property_deleted" />
        }

        return (
          <Route
            render={props => {
              const root = document.getElementById('resport-resident-app')
              const routePattern = props.match.path.replace(/([^a-z])/ig, '-').replace(/([-]{2,})/g, '-').replace(/^-+|-+$/g, '')
              // const routeUrl = props.match.url.replace(/([^a-z])/ig, '-').replace(/([-]{2,})/g, '-').replace(/^-+|-+$/g, '')

              root.setAttribute('data-route-pattern', routePattern);
              // root.setAttribute('data-route-url', routeUrl);

              return <React.Fragment>
                <div className={`${classes.root} ${units.length > 1 ? 'select-unit-display' : ''} tab-mobile-display-block`}>
                  <div className='bg-color'> </div>
                  <Header {...props} onDrawerToggle={state => {
                    setDrawerState(state)
                  }} />
                  <main className={`${classes.content} contenter-main ${banner.length ? 'banner-open' : ''} ${rightBarState ? 'right-sidebar-active' : ''} ${drawerState ? 'drawer-open' : 'drawer-close'}`} >
                    {/* <div className={`${classes.toolbar} header-menu-min-height`} /> */}
                    <Paper className='contant-box' elevation={2}>
                      <Box component="div" p={1} m={1}>
                        {(!hasAccess(policy)) ? (
                          <div>Access Denied</div>
                        ) : (
                          <Component {...props} />
                        )}
                      </Box>
                    </Paper>
                  </main>
                </div>

              </React.Fragment>
            }
            }
            {...rest}
          />
        )
      }}
    </AuthConsumer>
  )
}

export { Layout };