import React from 'react'
import css from '../Application.module.css'
import { withTranslation, Trans } from 'react-i18next'
import { compose } from 'recompose'
import { FieldArray } from 'formik'

import FormikTextField from '../../../Shared/FormikTextField'
import FormikAutoComplete from '../../../Shared/FormikAutoComplete'
import FormikMaskedInput from '../../../Shared/FormikMaskedInput'
import ApplicantFiles from '../../../Shared/ApplicantFiles';

import { APPLICANT_EMPLOYER } from '../ApplicantHelpers'

import {
  Grid,
  Typography,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  FormControlLabel,
  Checkbox,
  Button,
  Divider,
  Box,
} from '@material-ui/core'

class Employer extends React.Component {

  handleApplicantEmployersFilesChange = async index => {
    const { handleFileUpload, props } = this.props
    // get current uploaded files
    const files = await handleFileUpload('employers', index)

    // add existing files to current files if present
    if (props.values.applicant_employers[index].files && !!props.values.applicant_employers[index].files.length) {
      files.push(...props.values.applicant_employers[index].files)
    }

    // set files in key
    props.setFieldValue(`applicant_employers[${index}].files`, files)
  }

  render() {
    const {
      t,
      deleteRecord,
      classes,
      props,
      applicantEmployersFilesRef,
      states
    } = this.props

    return (
      <React.Fragment>
        <FieldArray name="applicant_employers">
          {({ push, form, remove }) => {
            const { values } = form
            const { applicant_employers } = values

            return (
              <React.Fragment>
                <div className={`${css['form-grp']} form-wrap`}>
                  {applicant_employers.map((_applicant_employer, index) => {
                    /* console error fix */
                    _applicant_employer.supervisor_contact_no_2 = _applicant_employer.supervisor_contact_no_2 ?? ''
                    _applicant_employer.supervisor_contact_no_3 = _applicant_employer.supervisor_contact_no_3 ?? ''
                    /* console error fix */

                    /* IMP - 1st entry is current empl, do not change - used in validatn */
                    _applicant_employer.is_current = (index === 0) ? true : false

                    /* if type is self-employment, empty supervisor details */
                    if (_applicant_employer.self_employed) {
                      _applicant_employer.supervisor_name = ''
                      _applicant_employer.supervisor_email = ''
                      _applicant_employer.supervisor_contact_no = ''
                      _applicant_employer.supervisor_contact_no_2 = ''
                      _applicant_employer.supervisor_contact_no_3 = ''
                    }

                    return (
                      <React.Fragment key={index}>
                        <div className={css['spacing-bottom']}>
                          <div className={css['heading']}>
                            <Grid container justify='space-between' alignItems='center'>
                              <Grid item>
                                <Typography component="h1">
                                  {/* 1st entry -> Current Employer */}
                                  {(index === 0) && <Trans ns="application" i18nKey={`applicant_employers.current_employer.header`}>Current Employer</Trans>}

                                  {/* entries after 1st -> Previous Employer */}
                                  {(index > 0) && <Trans ns="application" i18nKey={`applicant_employers.previous_employer.${index}.header`}>{`Previous Employer #${index}`}</Trans>}
                                </Typography>
                              </Grid>
                              {(index > 0) && <Grid item>
                                <Button className={css['remove-button']} variant='contained' color='primary'
                                  onClick={() => {
                                    if (typeof applicant_employers[index] !== 'undefined') {
                                      // db wala record..
                                      deleteRecord('applicant_employers', applicant_employers[index].id)
                                    }
                                    remove(index)
                                  }}>
                                  <Trans ns='application' i18nKey='button.remove.employer'>- Remove Employer</Trans>
                                </Button>
                              </Grid>}
                            </Grid>
                          </div>

                          <Grid container alignItems='center' spacing={3}>
                            {/* self employed checkbox */}
                            <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                              <div className={css['checkbox']}>
                                <FormControlLabel
                                  labelPlacement="end"
                                  label={<Trans ns='application' i18nKey="label.self_employed">Self Employed?</Trans>}
                                  control={<Checkbox
                                    checked={applicant_employers[index].self_employed}
                                    name={`applicant_employers[${index}].self_employed`}
                                    onChange={(_event, newValue) => {
                                      props.setFieldValue(`applicant_employers[${index}].self_employed`, newValue)
                                    }}
                                    color="primary"
                                  />} />
                              </div>
                            </Grid>
                            {/* employer type radio button */}
                            <Grid item xs='auto' sm='auto' md='auto' lg='auto' xl='auto'>
                              <div className={css['radio-btn']}>
                                <FormControl>
                                  <FormLabel>
                                    <Trans ns='application' i18nKey="label.employer_type">
                                      Employer type
                                    </Trans>
                                  </FormLabel>
                                  <RadioGroup
                                    row
                                    aria-label="type"
                                    name={`applicant_employers[${index}].type`}
                                    value={applicant_employers[index].type}
                                    onChange={(_event, newValue) => {
                                      props.setFieldValue(`applicant_employers[${index}].type`, newValue)
                                    }}>
                                    <FormControlLabel value="FULL_TIME" control={<Radio color="primary" />} label={<Trans ns='application' i18nKey="label.full_time">Full time</Trans>} />
                                    <FormControlLabel value="PART_TIME" control={<Radio color="primary" />} label={<Trans ns='application' i18nKey="label.part_time">Part time</Trans>} />
                                  </RadioGroup>
                                </FormControl>
                              </div>
                            </Grid>
                          </Grid>
                          {/* fields */}
                          <Grid container alignItems='center' spacing={3} className={css['container-spacing-3']}>
                            {/* business name input text */}
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                              <FormikTextField
                                ns="application"
                                name={`applicant_employers[${index}].business_name`}
                                type="text"
                                variant="outlined"
                                label={<Trans ns='application' i18nKey="label.business_name">Business name</Trans>}
                                placeholder={t('application:business_name', 'Business name')}
                                fullWidth
                                className={classes.input}
                                autoComplete="off" />
                            </Grid>
                            {/* address line1 input text */}
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                              <FormikTextField
                                ns="application"
                                required
                                name={`applicant_employers[${index}].address_line_1`}
                                type="text"
                                variant="outlined"
                                label={<Trans ns='application' i18nKey="label.address_line_1">Address line 1</Trans>}
                                placeholder={t('application:address_line_1', 'Address line 1')}
                                fullWidth
                                className={classes.input}
                                autoComplete="off" />
                            </Grid>
                            {/* address line2 input text */}
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                              <FormikTextField
                                ns="application"
                                name={`applicant_employers[${index}].address_line_2`}
                                type="text"
                                variant="outlined"
                                label={<Trans ns='application' i18nKey="label.address_line_2">Address line 2</Trans>}
                                placeholder={t('application:address_line_2', 'Address line 2')}
                                fullWidth
                                className={classes.input}
                                autoComplete="off" />
                            </Grid>
                            {/* city input text */}
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                              <FormikTextField
                                required
                                ns="application"
                                name={`applicant_employers[${index}].city`}
                                type="text"
                                variant="outlined"
                                label={<Trans ns='application' i18nKey="label.city">City</Trans>}
                                placeholder={t('application:city', 'City')}
                                fullWidth
                                className={classes.input}
                                autoComplete="off" />
                            </Grid>
                            {/* state select box */}
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                              <FormikAutoComplete
                                required
                                disableAdd={true}
                                name={`applicant_employers[${index}].state_id`}
                                label={<Trans ns='application' i18nKey="label.state_id">State</Trans>}
                                options={states.map(item => ({ id: item.id, label: item.name, code: item.code }))}
                                onChange={(_event, newValue) => {
                                  if (typeof newValue === 'string') {
                                    // timeout to avoid instant validation of the dialog's form.
                                    setTimeout(() => {
                                      this.addAutoComplete(`applicant_employers[${index}].state_id`, newValue)
                                    });
                                  } else if (newValue && newValue.inputValue) {
                                    this.addAutoComplete(`applicant_employers[${index}].state_id`, newValue)
                                  } else {
                                    props.setFieldValue(`applicant_employers[${index}].state_id`, newValue)
                                  }
                                }}
                                ns='application' />
                            </Grid>
                            {/* zip_code input box */}
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                              <FormikTextField
                                required
                                ns="application"
                                name={`applicant_employers[${index}].zip_code`}
                                type="text"
                                variant="outlined"
                                label={<Trans ns='application' i18nKey="label.zip_code">ZIP</Trans>}
                                placeholder={t('application:zip_code', 'Zip')}
                                fullWidth
                                className={classes.input}
                                onBlur={(event) => {
                                  let zip_code = event.target.value.toString()

                                  // prefix 0's | 10 -> 00010
                                  if (zip_code.length > 0 && zip_code.length < 5) {
                                    zip_code = ("00000" + zip_code).substring(zip_code.length);
                                    props.setFieldValue('zip_code', zip_code);
                                  } else {
                                    // show error
                                    props.setFieldTouched(`applicant_employers[${index}].zip_code`, true)
                                  }
                                }}
                                autoComplete="off" />
                            </Grid>
                            {/* position input box */}
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                              <FormikTextField
                                required
                                ns="application"
                                name={`applicant_employers[${index}].position`}
                                type="text"
                                variant="outlined"
                                label={<Trans ns='application' i18nKey="label.position">Position</Trans>}
                                placeholder={t('application:position', 'Position')}
                                fullWidth
                                className={classes.input}
                                autoComplete="off" />
                            </Grid>
                            {/* reason for leaving radio button (not reqd for 1st/current empl) */}
                            {(index > 0) && <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                              <FormikTextField
                                required={index > 0}
                                ns="application"
                                name={`applicant_employers[${index}].reason_for_leaving`}
                                type="text"
                                variant="outlined"
                                label={<Trans ns='application' i18nKey="label.reason_for_leaving">Reason for leaving</Trans>}
                                placeholder={t('application:reason_for_leaving', 'Reason for leaving')}
                                fullWidth
                                className={classes.input}
                                autoComplete="off" />
                            </Grid>}

                            {/* supervisor fields only if not self employment */}
                            {(!applicant_employers[index].self_employed) && <>
                              {/* supervisor_name input box */}
                              <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                <FormikTextField
                                  required
                                  ns="application"
                                  name={`applicant_employers[${index}].supervisor_name`}
                                  type="text"
                                  variant="outlined"
                                  label={<Trans ns='application' i18nKey="label.supervisor_name">Supervisor name</Trans>}
                                  placeholder={t('application:supervisor_name', 'Supervisor name')}
                                  fullWidth
                                  className={classes.input}
                                  autoComplete="off" />
                              </Grid>
                              {/* supervisor email input text */}
                              <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                <FormikTextField
                                  ns="application"
                                  name={`applicant_employers[${index}].supervisor_email`}
                                  type="text"
                                  variant="outlined"
                                  label={<Trans ns='application' i18nKey="label.supervisor_email">Supervisor email</Trans>}
                                  placeholder={t('application:supervisor_email', 'Supervisor email')}
                                  fullWidth
                                  className={classes.input}
                                  autoComplete="off" />
                              </Grid>
                              {/* supervisor contact no input text */}
                              <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                <FormikMaskedInput
                                  required
                                  name={`applicant_employers[${index}].supervisor_contact_no`}
                                  type='text'
                                  label={t('application:label.supervisor_contact_no_1', 'Supervisor contact no.')}
                                  mask_type='(999) 999-9999'
                                />
                              </Grid>
                              {/* supervisor contact no 2 input text */}
                              <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                <FormikMaskedInput
                                  name={`applicant_employers[${index}].supervisor_contact_no_2`}
                                  type='text'
                                  label={t('application:label.supervisor_contact_no_2', 'Alternative Supervisor Contact #1')}
                                  mask_type='(999) 999-9999'
                                />
                              </Grid>
                              {/* supervisor contact no 3 input text */}
                              <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                <FormikMaskedInput
                                  name={`applicant_employers[${index}].supervisor_contact_no_3`}
                                  type='text'
                                  label={t('application:label.supervisor_contact_no_3', 'Alternative Supervisor Contact #2')}
                                  mask_type='(999) 999-9999'
                                />
                              </Grid>
                            </>}

                            {/* start date input date */}
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                              <FormikTextField
                                required
                                ns="application"
                                name={`applicant_employers[${index}].start_date`}
                                type="date"
                                variant="outlined"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                label={<Trans ns='application' i18nKey="label.start_date">Start date</Trans>}
                                placeholder={t('application:start_date', 'Start date')}
                                fullWidth
                                className={classes.input}
                                autoComplete="off" />
                            </Grid>
                            {/* end date input date (not for 1st/current entry) */}
                            {(index > 0) && <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                              <FormikTextField
                                required={index > 0}
                                disabled={applicant_employers[index].is_current}
                                ns="application"
                                name={`applicant_employers[${index}].end_date`}
                                type="date"
                                variant="outlined"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                label={<Trans ns='application' i18nKey="label.end_date">End date</Trans>}
                                placeholder={t('application:end_date', 'End date')}
                                fullWidth
                                className={`${classes.input} ${applicant_employers[index].is_current ? css.disabled : ''}`}
                                autoComplete="off" />
                            </Grid>}
                            {/* income input box */}
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                              <FormikTextField
                                required
                                ns="application"
                                name={`applicant_employers[${index}].income`}
                                type="text"
                                variant="outlined"
                                label={<Trans ns='application' i18nKey="label.income">Income</Trans>}
                                placeholder={t('application:income', 'Income')}
                                fullWidth
                                className={classes.input}
                                autoComplete="off" />
                            </Grid>
                            {/* income frequency radio button */}
                            <Grid item xs={12} sm={12} md='auto' lg='auto' xl='auto'>
                              <div className={css['radio-btn']}>
                                <FormControl>
                                  <FormLabel>
                                    <Trans ns='application' i18nKey="label.income_frequency">
                                      Income frequency
                                    </Trans>
                                  </FormLabel>
                                  <RadioGroup
                                    row
                                    aria-label="income_frequency"
                                    name={`applicant_employers[${index}].income_frequency`}
                                    value={applicant_employers[index].income_frequency}
                                    onChange={(_event, newValue) => {
                                      props.setFieldValue(`applicant_employers[${index}].income_frequency`, newValue)
                                    }}>
                                    <FormControlLabel value="ANNUALLY" control={<Radio color="primary" />} label={<Trans ns='application' i18nKey="label.annually">Annually</Trans>} />
                                    <FormControlLabel value="MONTHLY" control={<Radio color="primary" />} label={<Trans ns='application' i18nKey="label.monthly">Monthly</Trans>} />
                                  </RadioGroup>
                                </FormControl>
                              </div>
                            </Grid>
                          </Grid>

                          {/* upload files */}
                          <div className={css['upload-files-section']}>
                            <Grid container justify='flex-start' alignItems='center' className={css['container-spacing-3']}>
                              <Grid item>
                                <Box className={css['sub-heading']} mr={2}><Trans ns='application' i18nKey="label.pay_stubs">Pay Stubs</Trans></Box>
                              </Grid>
                              <Grid item>
                                <Button className={css['upload-files-button']}
                                  onClick={() => document.getElementById(`applicant_employers[${index}].files`).click()}>
                                  <Trans ns='application' i18nKey='button.employers.uploadfiles'>+ Upload Files</Trans>
                                </Button>
                                <input
                                  ref={applicantEmployersFilesRef[index]}
                                  type="file"
                                  id={`applicant_employers[${index}].files`}
                                  onChange={() => this.handleApplicantEmployersFilesChange(index)}
                                  multiple
                                  hidden />
                              </Grid>
                            </Grid>
                          </div>
                          {/* display uploaded files */}
                          {(props.values.applicant_employers[index].files && !!props.values.applicant_employers[index].files.length) && <React.Fragment>
                            <ApplicantFiles files={props.values.applicant_employers[index].files} />
                          </React.Fragment>}

                          <Grid container justify='flex-end' alignItems='center' spacing={2} className={css['container-spacing-3']}>
                            {(applicant_employers.length === index + 1) && <Grid item>
                              <Button className={css['add-more-button']} variant='contained' color='primary'
                                onClick={() => push({
                                  ...APPLICANT_EMPLOYER,
                                  is_current: false,
                                })}>
                                <Trans ns='application' i18nKey='button.add.employer'>+ Add Previous Employer</Trans>
                              </Button>
                            </Grid>}
                          </Grid>
                          <Divider className={css['form-divider']} />
                        </div>
                      </React.Fragment>

                    )
                  })}

                </div>
              </React.Fragment>
            )
          }
          }
        </FieldArray >
      </React.Fragment >
    )
  }
}

export default compose(
  withTranslation('application'),
)(Employer)