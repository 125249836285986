import React from 'react'
import css from '../Application.module.css'
import { withTranslation, Trans } from 'react-i18next'
import { compose } from 'recompose'
import { FieldArray } from 'formik'

import FormikTextField from '../../../Shared/FormikTextField'
import FormikAutoComplete from '../../../Shared/FormikAutoComplete'
import FormikMaskedInput from '../../../Shared/FormikMaskedInput'

import { APPLICANT_ADDRESS } from '../ApplicantHelper'

import {
  Grid,
  createStyles,
  withStyles,
  FormControlLabel,
  Radio,
  FormLabel,
  FormControl,
  RadioGroup,
  Button,
  Typography,
  Divider
} from '@material-ui/core'


class Address extends React.Component {
  render() {
    const {
      t,
      deleteRecord,
      states,
      streetTypes,
      classes,
      props,
    } = this.props

    return (
      <React.Fragment>
        <div className={css.address_main}>
          <FieldArray name="applicant_addresses">
            {({ push, form, remove }) => {
              const { values } = form
              const { applicant_addresses } = values

              return (
                <React.Fragment>
                  <div className={`${css['form-grp']} form-wrap`}>

                    {applicant_addresses.map((_applicant_addresse, index) => {

                      /* IMP - 1st entry is current empl, do not change - used in validatn */
                      _applicant_addresse.is_current = (index === 0) ? true : false

                      /* if type is own, empty landlord details */
                      if (applicant_addresses[index].address_type === 'OWN') {
                        applicant_addresses[index].landlord_name = ''
                        applicant_addresses[index].landlord_contact_no = ''
                        applicant_addresses[index].landlord_contact_no_2 = ''
                        applicant_addresses[index].landlord_contact_no_3 = ''
                        applicant_addresses[index].landlord_fax = ''
                        applicant_addresses[index].landlord_email = ''
                      }

                      // label to be 'Move Out' for prev. addresses
                      let lease_expiration_date_label = t('application:label.lease_expiration_date', 'Lease expiration date')
                      if (index > 0) {
                        lease_expiration_date_label = t('application:label.move_out', 'Move out date')
                      }

                      return (
                        <React.Fragment key={index}>
                          <div className={css['spacing-bottom']}>
                            <div className={css['heading']}>
                              <Grid container justify='space-between' alignItems='center'>
                                <Grid item>
                                  <Typography component="h1">
                                    {/* 1st entry -> Current Employer */}
                                    {(index === 0) && <Trans ns="application" i18nKey={`applicant_addresses.current_address.header`}>Current Address</Trans>}

                                    {/* entries after 1st -> Previous Employer */}
                                    {(index > 0) && <Trans ns="application" i18nKey={`applicant_addresses.previous_address.${index}.header`}>{`Previous Address #${index}`}</Trans>}
                                  </Typography>
                                </Grid>
                                {(index > 0) && <Grid item>
                                  <Button className={css['remove-button']} variant='contained'
                                    onClick={() => {
                                      if (typeof applicant_addresses[index] !== 'undefined') {
                                        // db wala record..
                                        deleteRecord('applicant_addresses', applicant_addresses[index].id)
                                      }
                                      remove(index)
                                    }}>
                                    <Trans ns='application' i18nKey='button.remove.address'>- Remove Address</Trans>
                                  </Button>
                                </Grid>}
                              </Grid>
                            </div>

                            <Grid container alignItems='center' spacing={2}>
                              {/* address type radio button */}
                              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <div className={css['radio-btn']}>
                                  <FormControl>
                                    <FormLabel>
                                      <Trans ns='application' i18nKey="label.address_type">
                                        Address Type
                                      </Trans>
                                    </FormLabel>
                                    <RadioGroup
                                      row
                                      aria-label="address_type"
                                      name={`applicant_addresses[${index}].address_type`}
                                      value={applicant_addresses[index].address_type}
                                      onChange={(_event, newValue) => {
                                        props.setFieldValue(`applicant_addresses[${index}].address_type`, newValue)
                                      }}>
                                      <FormControlLabel value="RENT" control={<Radio color="primary" />} label={<Trans ns='application' i18nKey="label.rent">Rent</Trans>} />
                                      <FormControlLabel value="OWN" control={<Radio color="primary" />} label={<Trans ns='application' i18nKey="label.own">Own</Trans>} />
                                      <FormControlLabel value="FAMILY" control={<Radio color="primary" />} label={<Trans ns='application' i18nKey="label.family">Family</Trans>} />
                                    </RadioGroup>
                                  </FormControl>
                                </div>
                              </Grid>
                            </Grid>

                            <Grid container alignItems='center' spacing={3} className={css['container-spacing-3']}>
                              {/* apartment no input text */}
                              <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                <FormikTextField
                                  ns="application"
                                  name={`applicant_addresses[${index}].apartment_no`}
                                  type="text"
                                  variant="outlined"
                                  label={<Trans ns='application' i18nKey="label.apartment_no">Apartment name/no.</Trans>}
                                  placeholder={t('application:apartment_no', 'Apartment name/no.')}
                                  fullWidth
                                  className={classes.input}
                                  autoComplete="off" />
                              </Grid>
                              {/* street no input text */}
                              <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                <FormikTextField
                                  ns="application"
                                  name={`applicant_addresses[${index}].street_no`}
                                  type="text"
                                  variant="outlined"
                                  label={<Trans ns='application' i18nKey="label.street_no">Street No.</Trans>}
                                  placeholder={t('application:street_no', 'Street No.')}
                                  fullWidth
                                  className={classes.input}
                                  autoComplete="off" />
                              </Grid>
                              {/* street name input text */}
                              <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                <FormikTextField
                                  required
                                  ns="application"
                                  name={`applicant_addresses[${index}].street_name`}
                                  type="text"
                                  variant="outlined"
                                  label={<Trans ns='application' i18nKey="label.street_name">Street Name</Trans>}
                                  placeholder={t('application:street_name', 'Street Name')}
                                  fullWidth
                                  className={classes.input}
                                  autoComplete="off" />
                              </Grid>
                              {/* street type select box */}
                              <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                <FormikAutoComplete
                                  required
                                  disableAdd={true}
                                  name={`applicant_addresses[${index}].street_type_id`}
                                  label={<Trans ns='application' i18nKey="label.street_type_id">Street Type</Trans>}
                                  options={streetTypes.map(item => ({ id: item.id, label: item.street_suffix }))}
                                  onChange={(event, newValue) => {
                                    if (typeof newValue === 'string') {
                                      // timeout to avoid instant validation of the dialog's form.
                                      setTimeout(() => {
                                        this.addAutoComplete(`applicant_addresses[${index}].street_type_id`, newValue)
                                      });
                                    } else if (newValue && newValue.inputValue) {
                                      this.addAutoComplete(`applicant_addresses[${index}].street_type_id`, newValue)
                                    } else {
                                      props.setFieldValue(`applicant_addresses[${index}].street_type_id`, newValue)
                                    }
                                  }}
                                  ns='application' />
                              </Grid>
                              {/* city input text */}
                              <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                <FormikTextField
                                  required
                                  ns="application"
                                  name={`applicant_addresses[${index}].city`}
                                  type="text"
                                  variant="outlined"
                                  label={<Trans ns='application' i18nKey="label.city">City</Trans>}
                                  placeholder={t('application:city', 'City')}
                                  fullWidth
                                  className={classes.input}
                                  autoComplete="off" />
                              </Grid>
                              {/* state select box */}
                              <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                <FormikAutoComplete
                                  required
                                  disableAdd={true}
                                  name={`applicant_addresses[${index}].state_id`}
                                  label={<Trans ns='application' i18nKey="label.state_id">State</Trans>}
                                  options={states.map(item => ({ id: item.id, label: item.name }))}
                                  onChange={(event, newValue) => {
                                    if (typeof newValue === 'string') {
                                      // timeout to avoid instant validation of the dialog's form.
                                      setTimeout(() => {
                                        this.addAutoComplete(`applicant_addresses[${index}].state_id`, newValue)
                                      });
                                    } else if (newValue && newValue.inputValue) {
                                      this.addAutoComplete(`applicant_addresses[${index}].state_id`, newValue)
                                    } else {
                                      props.setFieldValue(`applicant_addresses[${index}].state_id`, newValue)
                                    }
                                  }}
                                  ns='application' />
                              </Grid>
                              {/* zip_code input box */}
                              <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                <FormikTextField
                                  required
                                  ns="application"
                                  name={`applicant_addresses[${index}].zip_code`}
                                  type="text"
                                  variant="outlined"
                                  label={<Trans ns='application' i18nKey="label.zip_code">ZIP</Trans>}
                                  placeholder={t('application:zip_code', 'Zip')}
                                  fullWidth
                                  className={classes.input}
                                  onBlur={(event) => {
                                    let zip_code = event.target.value.toString()

                                    // prefix 0's | 10 -> 00010
                                    if (zip_code.length > 0 && zip_code.length < 5) {
                                      zip_code = ("00000" + zip_code).substring(zip_code.length);
                                      props.setFieldValue('zip_code', zip_code);
                                    } else {
                                      // show error
                                      props.setFieldTouched(`applicant_addresses[${index}].zip_code`, true)
                                    }
                                  }}
                                  autoComplete="off" />
                              </Grid>
                              {/* reason for moving input text */}
                              <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                <FormikTextField
                                  ns="application"
                                  name={`applicant_addresses[${index}].reason_for_moving`}
                                  type="text"
                                  variant="outlined"
                                  label={<Trans ns='application' i18nKey="label.reason_for_moving">Reason for moving</Trans>}
                                  placeholder={t('application:reason_for_moving', 'Reason for moving')}
                                  fullWidth
                                  className={classes.input}
                                  autoComplete="off" />
                              </Grid>
                              {/* move in date input date */}
                              <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                <FormikTextField
                                  ns="application"
                                  name={`applicant_addresses[${index}].move_in_date`}
                                  type="date"
                                  variant="outlined"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  label={<Trans ns='application' i18nKey="label.move_in_date">Move in date</Trans>}
                                  placeholder={t('application:move_in_date', 'Move in date')}
                                  fullWidth
                                  className={classes.input}
                                  autoComplete="off" />
                              </Grid>
                              {/* lease expiration date input date */}
                              <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                <FormikTextField
                                  ns="application"
                                  name={`applicant_addresses[${index}].lease_expiration_date`}
                                  type="date"
                                  variant="outlined"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  label={lease_expiration_date_label}
                                  fullWidth
                                  className={classes.input}
                                  autoComplete="off" />
                              </Grid>

                              {/* showing landlord fields only if type is not OWN */}
                              {(applicant_addresses[index].address_type !== 'OWN') && <>
                                {/* landlord name input text */}
                                <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                  <FormikTextField
                                    required
                                    ns="application"
                                    name={`applicant_addresses[${index}].landlord_name`}
                                    type="text"
                                    variant="outlined"
                                    label={<Trans ns='application' i18nKey="label.landlord_name">Landlord name</Trans>}
                                    placeholder={t('application:landlord_name', 'Landlord name')}
                                    fullWidth
                                    className={classes.input}
                                    autoComplete="off" />
                                </Grid>
                                {/* landlord contact no input text */}
                                <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                  <FormikMaskedInput
                                    required
                                    name={`applicant_addresses[${index}].landlord_contact_no`}
                                    type='text'
                                    label={t('application:landlord_contact_no_1', 'Landlord contact no.')}
                                    mask_type='(999) 999-9999'
                                  />
                                </Grid>
                                {/* landlord contact no 2 input text */}
                                <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                  <FormikMaskedInput
                                    name={`applicant_addresses[${index}].landlord_contact_no_2`}
                                    type='text'
                                    label={t('application:landlord_contact_no_2', 'Alternative Landlord Contact #1')}
                                    mask_type='(999) 999-9999'
                                  />
                                </Grid>
                                {/* landlord contact no 3 input text */}
                                <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                  <FormikMaskedInput
                                    name={`applicant_addresses[${index}].landlord_contact_no_3`}
                                    type='text'
                                    label={t('application:landlord_contact_no_3', 'Alternative Landlord Contact #2')}
                                    mask_type='(999) 999-9999'
                                  />
                                </Grid>
                                {/* landlord fax input text */}
                                <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                  <FormikTextField
                                    ns="application"
                                    name={`applicant_addresses[${index}].landlord_fax`}
                                    type="text"
                                    variant="outlined"
                                    label={<Trans ns='application' i18nKey="label.landlord_fax">Landlord fax</Trans>}
                                    placeholder={t('application:landlord_fax', 'Landlord fax')}
                                    fullWidth
                                    className={classes.input}
                                    autoComplete="off" />
                                </Grid>
                                {/* landlord email input text */}
                                <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                  <FormikTextField
                                    ns="application"
                                    name={`applicant_addresses[${index}].landlord_email`}
                                    type="email"
                                    variant="outlined"
                                    label={<Trans ns='application' i18nKey="label.landlord_email">Landlord email</Trans>}
                                    placeholder={t('application:landlord_email', 'Landlord email')}
                                    fullWidth
                                    className={classes.input}
                                    autoComplete="off" />
                                </Grid>
                              </>}

                            </Grid>

                            <Grid container justify='flex-end' spacing={2} className={css['container-spacing-3']}>
                              {(applicant_addresses.length === index + 1) && <Grid item>
                                <Button className={css['add-more-button']} variant='contained'
                                  onClick={() => push({
                                    ...APPLICANT_ADDRESS,
                                    is_current: false,
                                  })}>
                                  <Trans ns='application' i18nKey='button.add.address'>+ Add Previous Address</Trans>
                                </Button>
                              </Grid>}
                            </Grid>

                            {(applicant_addresses.length !== index + 1) && <Divider className={css['form-divider']} />}

                          </div>
                        </React.Fragment>
                      )

                    })}
                  </div>
                </React.Fragment>
              )
            }}
          </FieldArray>

        </div>
      </React.Fragment >
    )
  }
}

const useStyles = createStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(1, 0, 2),
  },
  input: {
    margin: theme.spacing(1, 0, 2),
  },
}))


export default compose(
  withStyles(useStyles),
  withTranslation('application'),
)(Address)
