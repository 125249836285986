import React from 'react'
import http from '../../../utils/http';
import XGridTable, { escapeRegExp } from '../../Shared/XGridTable';
import DateFormat from '../../../utils/DateFormat';
import { getQuery, toQuery } from '../../../utils/searchParams';
import date from '../../../utils/date';
import {
  TextField,
  InputAdornment,
  FormControl,
  Select,
  MenuItem,
  Box,
  Grid,
  InputLabel,
  Button,
  Tooltip,
  IconButton
} from '@material-ui/core';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';

class ClientSide extends React.Component {

  constructor(props) {
    super(props)

    this.apiRef = React.createRef();

    const queryParams = getQuery(this.props.location.search)

    const filterParams = {
      ...this.defaultFilterParams,
      ...queryParams
    }

    this.state = {
      loading: true,
      rows: [], // unfiltered rows
      filteredRows: [],
      filterParams
    }
  }

  defaultFilterParams = {
    orderBy: 'id',
    sortBy: 'desc',
    page: 1,
    perPage: 15,
    q: '',
    is_verified: '',
    ...this.setDate(),
  }

  // from/to date
  setDate() {
    const today = new Date()
    const toDate = date('Y-m-d', today.getTime());

    // before 25 years
    const beforeDate = new Date(today.getTime() - (9125 * 24 * 60 * 60 * 1000))
    const fromDate = date('Y-m-d', beforeDate.getTime())

    return { toDt: toDate, fromDt: fromDate }
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    http.get('https://faker.immensotech.com/people?perPage=1000')
      .then(response => {
        this.setState(prevState => {
          return {
            ...prevState,
            loading: false,
            rows: response.data.data
          }
        })
      }).finally(() => this.applyFilters())
  }

  getColumns = () => {
    return [
      {
        field: 'id',
        headerName: '#',
        minWidth: 50
      },
      {
        field: 'first_name',
        headerName: 'First Name',
        minWidth: 150
      },
      {
        field: 'last_name',
        headerName: 'Last Name',
        minWidth: 150
      },
      {
        field: 'email',
        headerName: 'Email',
        minWidth: 300,
        description: 'Some description'
      },
      {
        field: 'is_verified',
        headerName: 'Verified',
        minWidth: 150
      },
      {
        field: 'date_of_birth',
        headerName: 'Date Of Birth',
        minWidth: 200,
        renderCell: (params) => <DateFormat>{params.value}</DateFormat>
      },
    ]
  }

  onChangeQ = (event) => {
    const filterParams = this.state.filterParams;
    const name = event.target.name
    filterParams[name] = event.target.value;
    filterParams.page = 1;

    this.setState(prevState => {
      return { ...prevState, ...filterParams }
    })
  }

  onEnterQ = (event) => {
    if (event.keyCode === 13) {
      const filterParams = this.state.filterParams;
      filterParams.page = 1;
      this.props.history.push(toQuery(filterParams));

      this.applyFilters()
    }
  }

  resetFilters = () => {
    this.props.history.push(toQuery(this.defaultFilterParams));

    const filterParams = this.defaultFilterParams;
    filterParams.page = 1;

    this.setState(prevState => {
      return {
        ...prevState,
        filterParams
      }
    })

    setTimeout(this.applyFilters, 100);
  }

  applyFilters = () => {
    const { rows } = this.state
    const { q, is_verified, toDt, fromDt } = this.state.filterParams

    let filteredRows = rows

    // input search q
    if (q !== '') {
      const searchRegex = new RegExp(escapeRegExp(q), 'i');
      filteredRows = filteredRows.filter(row => Object.keys(row).some(field => searchRegex.test(row[field] ? row[field].toString() : '')))
    }

    // is_verified selectbox
    if (is_verified !== '') {
      filteredRows = filteredRows.filter(row => row.is_verified === is_verified)
    }

    // date range
    if (fromDt !== '' && toDt !== '') {
      const fromDate = (new Date(fromDt)).getTime()
      const toDate = (new Date(toDt)).getTime()
      filteredRows = filteredRows.filter(row => (fromDate <= (new Date(row.date_of_birth)).getTime() && (new Date(row.date_of_birth)).getTime() <= toDate))
    }

    this.setState(prevState => {
      return {
        ...prevState,
        loading: false,
        filteredRows
      }
    })
  }

  handleSort = (orderBy, sortBy, updatedUrl = true) => {
    // prevent multiple clicks.
    if (this.state.loading) {
      return;
    }

    let { filterParams } = this.state;

    if (!sortBy) {
      sortBy = 'asc';
      // order change on same column? toggle current order then
      if (filterParams.orderBy === orderBy) {
        sortBy = filterParams.sortBy === 'asc' ? 'desc' : 'asc'
      }
    }

    filterParams.orderBy = orderBy
    filterParams.sortBy = sortBy

    this.setState(prevState => ({
      ...prevState,
      filterParams
    }))

    // update URL only when sort by clicking column not by getData
    if (updatedUrl) {
      this.props.history.push(toQuery({ ...filterParams }));
    }
  }

  render() {
    // Define rows
    const { filteredRows, loading, filterParams } = this.state

    // Define columns
    const columns = this.getColumns()

    return (
      <React.Fragment>
        <div className='content-wrapper-inner'>
          <Box p={3} className='filter-area'>
            <Grid container justify="space-between" alignItems="center">
              <Grid item>
                <Grid container justify="space-between" alignItems="center" spacing={1}>
                  <Grid item>
                    <FormControl className="filter-input">
                      <TextField
                        value={filterParams.q}
                        name="q"
                        InputProps={{
                          endAdornment:
                            <InputAdornment position="end">
                              <i className='icon dashboardicon-search'></i>
                            </InputAdornment>
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={this.onChangeQ}
                        onKeyDown={this.onEnterQ}
                        placeholder="Search" />
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <FormControl className="select-box">
                      <InputLabel shrink>Verified</InputLabel>
                      <Select
                        value={filterParams.is_verified}
                        name='is_verified'
                        onChange={(event) => {
                          this.onChangeQ(event)
                          // update Url
                          this.props.history.push(toQuery(this.state.filterParams));
                          this.applyFilters()
                        }}
                        displayEmpty>
                        <MenuItem value=''>Any</MenuItem>
                        {['YES', 'NO'].map((item, index) => (
                          <MenuItem value={item} key={index}>{item}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <div className='date-filter'>
                      <TextField
                        label="From Dt."
                        placeholder="From Dt."
                        value={filterParams.fromDt}
                        name="fromDt"
                        type="date"
                        onChange={this.onChangeQ} />
                    </div>
                  </Grid>
                  <Grid item>
                    <div className='date-filter'>
                      <TextField
                        label="To Dt."
                        placeholder="To Dt."
                        value={filterParams.toDt}
                        name="toDt"
                        type="date"
                        onChange={this.onChangeQ} />
                    </div>
                  </Grid>
                  <Grid className='filter-input serverlog-top-field' item>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        const filterParams = this.state.filterParams;
                        this.props.history.push(toQuery(filterParams));
                        this.applyFilters()
                      }}
                      className='add-butn'>
                      Submit
                    </Button>
                  </Grid>
                  <Grid item>
                    <Tooltip title="Reset">
                      <IconButton className='reset' color="inherit" aria-label="refresh"
                        onClick={() => this.resetFilters()}>
                        <RotateLeftIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <XGridTable
            apiRef={this.apiRef}
            tableId="x_grid_client"
            loading={loading}
            rows={filteredRows}
            columns={columns}
            handleSort={this.handleSort}
            sortModel={[{
              field: filterParams.orderBy,
              sort: filterParams.sortBy,
            }]} />
        </div>
      </React.Fragment>
    )
  }
}

export default ClientSide