import React, { Fragment, useContext, useEffect, useState } from "react"
import { Trans, useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom'
import Avatar from '@material-ui/core/Avatar';
import {
  makeStyles, useTheme,
  CssBaseline, AppBar, Toolbar,
  IconButton, Typography, Drawer,
  List, ListItem, ListItemText, ListItemIcon, Tooltip, Grid,

  Hidden,
  FormControl,
  Select,
  InputLabel,
  ClickAwayListener,
  Grow,
  Paper,
  MenuList,
  Popper,
} from '@material-ui/core';
import { AuthContext } from "../../../utils/AppContext";
import clsx from "clsx";
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import http from '../../../utils/http';
import Logo from "../../Shared/Logo";

const drawerWidth = 200;

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  mobileMenuRightToplink: {
    color: 'inherit',
    textDecoration: 'none',
    '&:hover': {
      color: 'inherit',
      textDecoration: 'none',
    }
  },
  nestedListMenu: {
    paddingLeft: theme.spacing(4),
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('xs')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  avatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    fontSize: 10
  },
}));

// +15435678943 => +1(543)567-8943
const MaskPhoneNumber = (value) => {
  if (!value) {
    return ' - '
  }

  const phone_number = value.slice(-10);
  const phone_code = value.replace(phone_number, "")

  const matches = phone_number.match(/^(\d{3})(\d{3})(\d{4})$/)
  if (matches) {
    return `${phone_code}(${matches[1]})${matches[2]}-${matches[3]}`
  }
  return value;
}

export function Header(props) {
  useTranslation('tenant_dashboard')
  const context = useContext(AuthContext);

  let drawerDefaultState = window.screen.availWidth > 425;
  if (!localStorage.getItem('drawerDefaultState')) {
    localStorage.setItem('drawerDefaultState', drawerDefaultState)
  }
  drawerDefaultState = JSON.parse(localStorage.getItem('drawerDefaultState'))

  useEffect(() => {
    props.onDrawerToggle(drawerDefaultState)
    // run for first time (let parent know what the drawer state is from localstorage if exists)
    // eslint-disable-next-line 
  }, [])

  const [drawerState, setDrawerState] = useState(drawerDefaultState);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const classes = useStyles();
  const theme = useTheme();

  const toggleDrawer = () => {
    localStorage.setItem('drawerDefaultState', !drawerState)

    if (props.onDrawerToggle) {
      props.onDrawerToggle(!drawerState)
    }

    setDrawerState(!drawerState)
  }

  const handleMobileMenuClose = () => {
    console.log("handleMobileMenuClose");
    setMobileMoreAnchorEl(null);
  };

  const drawerMenus = [];

  drawerMenus.push({
    label: 'Dashboard',
    to: '/',
    icon: <i className='icon dashboardicon-dashboard'></i>
  })

  drawerMenus.push({
    label: 'Make Payment',
    to: '/tenant/make-payment',
    icon: <i className='icon dashboardicon-card'></i>
  })

  drawerMenus.push({
    label: 'Auto-Payment',
    to: '/tenant/autopays',
    startsWith: '/tenant/autopays',
    icon: <i className='icon dashboardicon-timesheet'></i>
  })

  drawerMenus.push({
    label: 'Payment Methods',
    to: '/tenant/payment-methods',
    icon: <i className='icon dashboardicon-payment-log'></i>
  })

  drawerMenus.push({
    label: 'Transactions',
    to: '/transactions',
    startsWith: '/transactions',
    icon: <i className='icon dashboardicon-total-amount'></i>,
  })

  drawerMenus.push({
    label: 'Workorders',
    to: '/workorders',
    startsWith: '/workorders',
    icon: <i className='icon dashboardicon-settings'></i>,
    count: context.notificationCount?.workorders
  })

  // drawerMenus.push({
  //   label: 'My Emails',
  //   to: '/mails',
  //   startsWith: '/mails',
  //   icon: <i className='icon dashboardicon-mail'></i>,
  // })

  drawerMenus.push({
    label: 'Messages',
    to: '/messages',
    startsWith: '/messages',
    icon: <i className='icon dashboardicon-mail'></i>,
    count: context.notificationCount?.messages
  })

  drawerMenus.push({
    label: 'Parcels',
    to: '/parcels',
    startsWith: '/parcels',
    icon: <i className='icon dashboardicon-closed-order'></i>,
    count: context.notificationCount?.parcel
  })

  drawerMenus.push({
    label: 'Documents',
    to: '/documents',
    startsWith: '/documents',
    icon: <i className='icon dashboardicon-document'></i>,
    count: context.notificationCount?.documents
  })

  drawerMenus.push({
    label: 'Guided Inspections',
    to: '/tenant/guided-inspection',
    startsWith: '/guided-inspection',
    icon: <i className='dashboardicon-image-search'></i>,
  })

  const menuId = 'primary-search-account-menu';

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
      onClick={handleMobileMenuClose}>
      <MenuItem>
        <NavLink to='/profile' className={classes.mobileMenuRightToplink}>
          <IconButton
            aria-label="account of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit">
            <AccountCircle />
          </IconButton>
          <span>Profile</span>
        </NavLink>
      </MenuItem>
      <MenuItem>
        <NavLink to='/auth/logout' className={classes.mobileMenuRightToplink}>
          <IconButton color="inherit">
            <PowerSettingsNewIcon />
          </IconButton>
          <span>Logout</span>
        </NavLink>
      </MenuItem>
    </Menu>
  );

  // responsive
  const [mobileOpen, setMobileOpen] = React.useState(false);
  function toggleMobileDrawer() {
    setMobileOpen(!mobileOpen)
  }

  /*
    blocked_modules: blocked for this property, hide sidemenu
  */
  const blocked_modules = context.user.metas?.blocked_modules

  const list = (mobile = false) => (
    <React.Fragment>
      <List className='sidemenu-basic' onClick={mobile ? toggleMobileDrawer : null}>
        {drawerMenus.map((item, index) => {

          let keyBlock = (item.label).replace(' ', '_').replace('-', '_').toUpperCase()
          if (keyBlock === 'TRANSACTIONS' || keyBlock === 'MAKE_PAYMENT' || keyBlock === 'AUTO_PAYMENT' || keyBlock === 'PAYMENT_METHODS') {
            keyBlock = 'MAKE_PAYMENTS'
          }

          if (typeof blocked_modules !== 'undefined' && blocked_modules.indexOf(keyBlock) >= 0) {
            // blocked? no need to show sidemenu
            return <React.Fragment key={index}></React.Fragment>
          }

          const Icon = item.icon
          let Count = ''
          if (item.count > 0) {
            Count = item.count > 9999 ? (item.count / 1000).toFixed(0) + 'k' : item.count
          }

          const { policies } = item
          const hideMenu = (policies && !context.hasAccess(policies)) ? true : false

          let selected = false;
          if (props.match.path === item.to) {
            // route exact match
            selected = true;
          } else if (item.route && props.match.path === item.route) {
            // route pattern match
            selected = true;
          } else if (props.match.path.startsWith(item.startsWith)) {
            // route startes with (nested)
            selected = true;
          }
          return (
            <React.Fragment key={index}>
              {!hideMenu &&
                <ListItem button to={item.to} component={NavLink}
                  selected={selected}>
                  <Tooltip title={item.label}>
                    <ListItemIcon>{Icon}</ListItemIcon>
                  </Tooltip>
                  <ListItemText primary={item.label} />
                  {Count && <ListItemIcon>
                    <div className='count-div'>
                      <span className='sidemenu-count'>{Count}</span>
                    </div>
                  </ListItemIcon>}
                </ListItem>}
            </React.Fragment>
          )
        })}
      </List>

      <List className='logout-menu'>
        <ListItem button key={'Logout'} component={NavLink} to='/auth/logout'>
          <i className='icon dashboardicon-logout'></i>
          <ListItemText primary='Logout' />
        </ListItem>
      </List>
    </React.Fragment>
  )

  // show unit selected, get lease
  const lease = context.getLease()

  // get units of lease
  const units = context.getUnits()



  /* to manage profile/settings/logout dropdown */
  const [profileDropMenuState, setProfileDropMenuState] = useState(false);
  const [profileDropMenuAnchor, setProfileDropMenuAnchor] = useState('');

  // handle change unit
  const switchUnit = lease_id => {
    // find property_id from lease_id
    const unitFromContext = units.find(unit => unit.lease_id === lease_id);
    const propertyIdFromContext = unitFromContext.property_id;

    context.backdrop(true)
    http.post('tenant/auth/switch_unit',
      { lease_id, property_id: propertyIdFromContext },
      { headers: { token: context.user.token } })
      .then(response => {
        const tokenResponse = response.data;
        const { metas, token } = tokenResponse;
        const newLease = metas.lease;
        const newUnits = newLease.units;
        const newLeaseId = newLease.lease_id;
        const unit = newUnits.find(unit => unit.lease_id === newLeaseId);
        const { property_host } = unit;
        const magic_url = `${property_host}/auth/lease_impersonate?access_token=${token}`
        setTimeout(() => {
          if (unit.property_id !== lease.property_id) {
            window.open(magic_url, '_self')
            return false;
          }

          context.login(response.data)
          // passing timestamp to compare in didUpdate to refresh
          props.history.push('/tenant', { timestamp: Date.now() }) // redirect refreshed to dashboard to reflect latest data (charges and all)
        }, 300);
      })
      .finally(() => { context.backdrop(false) })
  }

  const property_contact = (context.portalData && context.portalData.property_contact) ? MaskPhoneNumber(context.portalData.property_contact) : ''

  const payment_support_contact_no = (context.portalData && context.portalData.payment_support_contact_no) ? MaskPhoneNumber(context.portalData.payment_support_contact_no) : ''

  const bannerList = context.portalData.banner ? context.portalData.banner : []
  const current_date = new Date().getTime()
  const banner = bannerList.filter(item => (current_date >= item.from && current_date <= item.to))

  return (
    <React.Fragment>
      <CssBaseline />

      {/* for mobile */}
      <Hidden mdUp implementation="css">
        <Fragment>
          <div className='appbar-main mb-appbar-main'>
            <AppBar
              position="fixed"
              className={clsx(classes.appBar, {
                [classes.appBarShift]: drawerState,
                'appbar-shift': drawerState,
              })}>
              <Toolbar>
                <div className='mb-logo'>
                  <Logo />
                </div>
                <IconButton
                  onClick={toggleMobileDrawer}
                  edge="start"
                  color="inherit"
                  aria-label="toggle drawer">
                  <MenuIcon />
                </IconButton>
              </Toolbar>
              <React.Fragment>
                {banner.map((item) => {
                  return <Grid item md={12}>
                    <div className='banner'>
                      <p>{item.message}</p>
                    </div>
                  </Grid>
                })}
              </React.Fragment>
              {(units && units.length > 1) && <React.Fragment>
                <Grid container justify="center">
                  <Grid item xs={12} sm={12} md={12}>
                    <FormControl className="sel-units">
                      <InputLabel id="units">Selected Unit</InputLabel>
                      <Select
                        labelId="units"
                        id="unit-select"
                        value={lease.lease_id}
                        label="Select Units"
                        onChange={(e) => switchUnit(e.target.value)}
                      >
                        {units.map((item, index) =>
                          <MenuItem key={index} value={item.lease_id}>
                            {item.pms_type === 'NONE' ?
                              `${item.lease_status} - ${item.lease_uid} - ${item.property_name}` :
                              `${item.lease_status} - ${item.building_uid}/${item.unit_uid}/${item.lease_uid} - ${item.property_name}`}
                          </MenuItem>)}
                      </Select>
                    </FormControl>
                  </Grid>
                  {/* <Grid item md={12}>
                      <Typography className='page-title' variant="h6" noWrap>{context.title}</Typography>
                    </Grid> */}
                </Grid>
              </React.Fragment>}
            </AppBar>
            {renderMobileMenu}
            <Drawer
              variant="temporary"
              anchor={theme.direction === 'rtl' ? 'right' : 'left'}
              open={mobileOpen}
              onClose={toggleMobileDrawer}
              className={clsx({
                [classes.drawerOpen]: mobileOpen,
                [classes.drawerClose]: !mobileOpen,
                'menu-drawer-open': mobileOpen,
                'menu-drawer-close': !mobileOpen,
              })}
              classes={{
                paper: clsx({
                  [classes.drawerOpen]: mobileOpen,
                  [classes.drawerClose]: !mobileOpen,
                  'menu-drawer-open': mobileOpen,
                  'menu-drawer-close': !mobileOpen,
                }),
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}>
              <IconButton
                onClick={toggleMobileDrawer}
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                color="inherit"
                component={NavLink} to='/profile' className={`${classes.mobileMenuRightToplink} mb-profile-pic`}>
                <Avatar className={classes.avatar} src={context.user.profile_pic}>{context.user.initials}</Avatar>
                <Typography className='username-tx' variant="inherit">
                  {lease.occupant_first_name} {lease.occupant_last_name}
                </Typography>
              </IconButton>

              <IconButton onClick={toggleMobileDrawer} className={`${classes.closeMenuButton} mb-menu-close`}>
                <i className='icon dashboardicon-close'></i>
              </IconButton>
              {list(true)}
              {(context.portalData && context.portalData.property_contact) && <div className="need-help">
                <div className="need-help-right-text">
                  {context.portalData.show_contact_no && <p>
                    <Trans ns="tenant_dashboard" i18nKey="sidebar.footer.need_help">Need Help? Call your property manager <br />
                      <span className="number">{{ property_contact }}</span>
                    </Trans>
                  </p>}

                  {(blocked_modules && !blocked_modules.includes('MAKE_PAYMENTS')) && payment_support_contact_no &&
                    <p>
                      <Trans ns="tenant_dashboard" i18nKey="sidebar.footer.support_info">
                        For Help Making a Payment / Pay by Phone, call:<br />
                        <span className="number">{{ payment_support_contact_no }}</span>
                      </Trans>
                    </p>}
                </div>
              </div>}
            </Drawer>
          </div>
        </Fragment>
      </Hidden>

      {/* for desktop */}
      <Hidden smDown implementation="css">
        <Fragment>
          <div className='appbar-main'>
            <div className='logo-bg'>
              <div className='logo'>
                <Logo />
              </div>
            </div>
            <AppBar
              position="fixed"
              className={clsx(classes.appBar, {
                [classes.appBarShift]: drawerState,
                'appbar-shift': drawerState,
              })}>
              <Toolbar>
                {/* <IconButton
                  onClick={toggleDrawer}
                  edge="start"
                  color="inherit"
                  aria-label="toggle drawer">
                  <MenuIcon />
                </IconButton> */}
                <Grid container justifyContent="space-between" alignItems="center">
                  {banner.map((item) => {
                    return <Grid item md={12}>
                      <div className='banner'>
                        <p>{item.message}</p>
                      </div>
                    </Grid>
                  })}
                  <Grid item>
                    <nav aria-label="breadcrumb-nav">
                      <ul className="breadcrumb-ul">
                        {context.breadcrumb}
                      </ul>
                    </nav>
                  </Grid>
                  <Grid item>
                    <div className="logout-dropdown">
                      <div className={classes.sectionDesktop}>
                        <div data-testid="header_click_dropdown" onClick={(event) => {
                          setProfileDropMenuState(true)
                          setProfileDropMenuAnchor(event.currentTarget)
                        }}>
                          <IconButton
                            edge="end"
                            aria-label="account of current user"
                            aria-controls={menuId}
                            aria-haspopup="true"
                            color="inherit"
                            className={classes.mobileMenuRightToplink}>
                            <Avatar className={classes.avatar} src={context.user.profile_pic}>{context.user.initials}</Avatar>
                          </IconButton>
                          <Typography className='username-tx' variant="inherit">
                            {context.user.first_name} {context.user.last_name}
                          </Typography>
                        </div>
                        {/* right top dropdown - */}
                        <Popper className='setting-dropdown' open={profileDropMenuState} anchorEl={profileDropMenuAnchor} transition disablePortal style={{ zIndex: 1 }}>
                          {({ TransitionProps, placement }) => (
                            <Grow
                              {...TransitionProps}
                              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
                              <Paper>
                                <ClickAwayListener onClickAway={() => setProfileDropMenuState(false)}>
                                  <MenuList autoFocusItem={true}>
                                    <MenuItem>
                                      <NavLink to='/profile' className={classes.mobileMenuRightToplink} data-testid="header_profile">
                                        <span><i className="icon dashboardicon-personal-info"></i> Profile</span>
                                      </NavLink>
                                    </MenuItem>
                                    <MenuItem>
                                      <NavLink to='/profile/password' className={classes.mobileMenuRightToplink} data-testid="header_settings">
                                        <span><i className="icon dashboardicon-settings"></i> Settings</span>
                                      </NavLink>
                                    </MenuItem>
                                    <MenuItem>
                                      <NavLink to='/auth/logout' className={classes.mobileMenuRightToplink} data-testid="header_logout">
                                        <span><i className="icon dashboardicon-logout"></i> Logout</span>
                                      </NavLink>
                                    </MenuItem>
                                  </MenuList>
                                </ClickAwayListener>
                              </Paper>
                            </Grow>
                          )}
                        </Popper>

                      </div>
                    </div>
                  </Grid>
                </Grid>

              </Toolbar>
              <Grid container>
                <Grid item md={12}>
                  <Typography className='page-title' variant="h6" noWrap>{context.title}</Typography>
                </Grid>
              </Grid>
            </AppBar>

          </div>
          <Drawer
            variant="permanent"
            className={clsx({
              [classes.drawerOpen]: drawerState,
              [classes.drawerClose]: !drawerState,
              'menu-drawer-open': drawerState,
              'menu-drawer-close': !drawerState,
            })}
            classes={{
              paper: clsx({
                [classes.drawerOpen]: drawerState,
                [classes.drawerClose]: !drawerState,
                'menu-drawer-open': drawerState,
                'menu-drawer-close': !drawerState,
              }),
            }}>
            <div className={classes.toolbar}>
              {(units && units.length > 1) && <React.Fragment>
                <Grid container justify="center" alignItems="flex-start" spacing={3}>
                  <Grid item md={10}>
                    <div className="sel-units-bg">
                      <FormControl className="sel-units">
                        <InputLabel id="units">Selected Unit</InputLabel>
                        <Select
                          labelId="units"
                          id="unit-select"
                          value={lease.lease_id}
                          label="Select Units"
                          onChange={(e) => switchUnit(e.target.value)}
                        >
                          {units.map((item, index) =>
                            <MenuItem key={index} value={item.lease_id}>
                              {["NONE", "SPECTRA"].includes(item.pms_type) ?
                                `${item.lease_status} - ${item.lease_uid} - ${item.property_name}` :
                                `${item.lease_status} - ${item.building_uid}/${item.unit_uid}/${item.lease_uid} - ${item.property_name}`}
                            </MenuItem>)}
                        </Select>
                      </FormControl>
                    </div>
                  </Grid>
                </Grid>
              </React.Fragment>}

              {/* <div className='logo'>
                <img src='/images/logo.jpg' alt='Classic' />
              </div> */}
              <IconButton onClick={toggleDrawer} hidden>
                {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
              </IconButton>
            </div>

            <div className="menu-overflow">
              {list()}
              {(context.portalData && context.portalData.property_contact) && <div className="need-help">
                <div className="need-help-right-text">
                  {context.portalData.show_contact_no && <p>
                    <Trans ns="tenant_dashboard" i18nKey="sidebar.footer.need_help">Need Help? Call your property manager <br />
                      <span className="number"> {{ property_contact }}</span>
                    </Trans>
                  </p>}
                  {(blocked_modules && !blocked_modules.includes('MAKE_PAYMENTS')) && payment_support_contact_no &&
                    <p>
                      <Trans ns="tenant_dashboard" i18nKey="sidebar.footer.support_info">
                        For Help Making a Payment / Pay by Phone, call:<br />
                        <span className="number">{{ payment_support_contact_no }}</span>
                      </Trans>
                    </p>}

                </div>
              </div>}
            </div>
          </Drawer>
        </Fragment>
      </Hidden>

    </React.Fragment>
  )
}