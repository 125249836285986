import http from "../utils/http";

const storageData = JSON.parse(localStorage.getItem('column_settings_14jan2021'))
const data = storageData ? storageData : { hidden: [], width: [] };

export function columnsReducer(state = { init: false, data: data }, action) {
  switch (action.type) {
    case 'LOAD_COLUMN_SETTINGS': {
      return { ...state, ...action.payload }
    }

    case 'UPDATE_HIDDENCOLUMN_SETTINGS': {
      const { key, value } = action.payload

      const newState = {
        ...state,
        data: {
          ...state.data,
          hidden: {
            ...state.data.hidden,
            [key]: value
          }
        }
      }

      // save to localstorage and database
      updateConfig(newState.data);

      return newState;
    }

    case 'UPDATE_COLUMNWIDTH_SETTINGS': {
      const { key, value } = action.payload

      const newState = {
        ...state,
        data: {
          ...state.data,
          width: {
            ...state.data.width,
            [key]: Object.assign({}, state.data.width[key], value)
          }
        }
      }

      // save to localstorage and database
      updateConfig(newState.data)

      return newState;
    }

    default:
      return state;
  }
}

const updateConfig = async (data) => {
  // update in LocalStorage
  updateLocalStorage(data)

  // update into DB
  await updateDb(data)
}

/**
 * @summary - update into localStorage
 * @param  data table_preference
 */
const updateLocalStorage = data => {
  localStorage.setItem('column_settings_14jan2021', JSON.stringify(data))
}

/**
 * @summary - update localstore data into user_details
 * @param  data - table_preference
 */
const updateDb = async (data) => {
  await http.post(`user_details`, {
    table_preference: data
  })
}

export function preLoadColumnSettings(force = false) {
  return async (dispatch, getState) => {
    // preloaded? ignore this
    if (getState().columns.init === true && !force) {
      return Promise.resolve()
    }
    // return Promise.resolve()

    const data = await getData();
    updateLocalStorage(data); // update localStorage
    return dispatch({
      type: 'LOAD_COLUMN_SETTINGS',
      payload: {
        init: true,
        data: data
      }
    });
  }
}


/**
 * @summary - get data from db? 
 *  if not found use localStorage? default data
 */
const getData = async () => {
  // get data from DB
  const response = await http.get(`user_details`);
  if (response.data) {
    return response.data.table_preference
  }
  return data // localStorage ? default
}