import React from 'react'
// import { TextField } from '@material-ui/core';
import { useField } from 'formik';
import { Trans } from 'react-i18next';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment';
// import date from '../../utils/date'
import { useState } from 'react'

const FormikDateField = (props) => {
  const [field, meta, helpers] = useField(props);
  const [value, setValue] = useState(field.value ? new Date(field.value) : null);

  // initially value is null and passed value in field then set as autofill
  if (value === null && field.value !== null && field.value !== '') {
    setValue(field.value)
  }

  if (value === null) {
    field.value = ''
  }
  const errorText = meta.error && meta.touched ? meta.error : "";

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <KeyboardDatePicker
        allowKeyboardControl={true}
        // {...field}
        {...props}
        helperText={<Trans ns={props.ns} i18nKey={errorText}></Trans>}
        error={!!errorText}
        type="text"
        emptyLabel={'mm/dd/yyyy'} // for placeholder
        placeholder={'mm/dd/yyyy'} // for placeholder
        variant="inline"
        format="MM/DD/yyyy"
        value={value}
        margin="normal"
        className={errorText ? 'date-error' : ''}
        // clearable={true} //console error: Received `true` for a non-boolean attribute `clearable`.
        onChange={(dateObj, value) => {
          field.value = value
          if (dateObj) {
            field.value = dateObj.format('YYYY-MM-DD')
            helpers.setValue(dateObj.format('YYYY-MM-DD'))
            setValue(dateObj)
          } else {
            helpers.setValue(null)
            setValue(null)
          }
        }}
        onBlur={event => field.onBlur(event)}
        autoOk />
    </MuiPickersUtilsProvider>
  );
};

export default FormikDateField;