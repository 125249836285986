import React from 'react';
import http from '../../utils/http';
import { AuthContext } from '../../utils/AppContext';
import RawHtml from '../Shared/RawHtml';


export default class ViewPage extends React.Component {
  static contextType = AuthContext
  constructor(props) {
    super(props)

    this.state = {
      page_content: null
    }
  }

  // GET API
  getData = async () => {
    const url = `/pages/slug/${this.props.match.params.idx}`

    try {
      const response = await http.get(url)

      this.context.setTitle(response.data.page_title)
      this.setState(prevState => ({ ...prevState, page_content: response.data.page_content }))
    } catch (err) {
      this.context.snackbar(err.data, {
        variant: 'error'
      })
    }
  }

  componentDidMount() {
    this.getData()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.idx !== this.props.match.params.idx) {
      // call method to fetch data
      this.getData()
    }
  }
  render() {
    const slug = this.props.match.params.idx ? this.props.match.params.idx : 'unknown'

    return (
      <React.Fragment>
        {this.state.page_content === null && <div>Loading...</div>}
        <div className='static-page'>
          <div className={`slug-${slug}`}>
            <RawHtml>
              {this.state.page_content}
            </RawHtml>
          </div>
        </div>
      </React.Fragment >
    );
  }
}