import React from 'react'
import http from '../../../utils/http';
import { getQuery, toQuery } from '../../../utils/searchParams';
import date from '../../../utils/date';
import XGridTable, { getServerSidePaginationProps, getServerSideSortProps } from '../../Shared/XGridTable';
import DateFormat from '../../../utils/DateFormat';
import {
  TextField,
  InputAdornment,
  FormControl,
  Select,
  MenuItem,
  Box,
  Grid,
  InputLabel,
  Button,
  Tooltip,
  IconButton,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  ListItemIcon,
  Typography
} from '@material-ui/core';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';

class ServerSide extends React.Component {
  constructor(props) {
    super(props)

    const queryParams = getQuery(this.props.location.search)

    const filterParams = {
      ...this.defaultFilterParams,
      ...queryParams
    }

    this.state = {
      loading: true,
      rows: {
        data: [],
        meta: {}
      },
      filterParams
    }
  }

  defaultFilterParams = {
    orderBy: 'id',
    sortBy: 'desc',
    page: 1,
    perPage: 15,
    q: '',
    is_verified: '',
    ...this.setDate(),
  }

  // from/to date
  setDate() {
    const today = new Date()
    const toDate = date('Y-m-d', today.getTime());

    // before 25 years
    const beforeDate = new Date(today.getTime() - (9125 * 24 * 60 * 60 * 1000))
    const fromDate = date('Y-m-d', beforeDate.getTime())

    return { toDt: toDate, fromDt: fromDate }
  }

  componentDidMount() {
    this.getData(this.state.filterParams);
  }

  getData = (params) => {
    http.get('https://faker.immensotech.com/people', {
      params: params ? params : {}
    }).then(response => {
      this.setState(prevState => {
        return {
          ...prevState,
          loading: false,
          rows: response.data,
          filterParams: params
        }
      })
    })
  }

  setDropMenuListingAnchorEl = (param) => {
    this.setState(prevState => ({ ...prevState, dropMenuListingEl: param }))
  }

  setDropMenuListingMenuOpen = (param) => {
    this.setState(prevState => ({ ...prevState, dropMenuListingOpen: param }))
  }

  handleAction = (action, row) => {
    alert(`${action}: ${row.id}`)

    switch (action) {
      case 'EDIT':
        // this.editRecord(row)
        break;
      case 'DELETE':
        // this.deleteItems([row.id])
        break;
      default:
        break;
    }
  }

  getColumns = () => {
    return [
      {
        field: 'id',
        headerName: '#',
        minWidth: 50,
        hideable: false
      },
      {
        field: 'first_name',
        headerName: 'First Name',
        minWidth: 150,
        hideable: false
      },
      {
        field: 'last_name',
        headerName: 'Last Name',
        minWidth: 150
      },
      {
        field: 'email',
        headerName: 'Email',
        minWidth: 300
      },
      {
        field: 'is_verified',
        headerName: 'Verified',
        minWidth: 150
      },
      {
        field: 'date_of_birth',
        headerName: 'Date Of Birth',
        minWidth: 200,
        renderCell: params => <DateFormat>{params.value}</DateFormat>
      },
      {
        field: 'action',
        headerName: 'Action',
        minWidth: 150,
        sortable: false,
        hideable: false,
        resizable: false,
        renderCell: params => <ActionIconButton
          params={params}
          dropMenuListingOpen={this.state.dropMenuListingOpen}
          dropMenuListingEl={this.state.dropMenuListingEl}
          setDropMenuListingAnchorEl={this.setDropMenuListingAnchorEl}
          setDropMenuListingMenuOpen={this.setDropMenuListingMenuOpen}
          handleAction={this.handleAction} />
      }
    ]
  }

  handleSort = (orderBy) => {
    // prevent multiple clicks.
    if (this.state.loading) {
      return;
    }

    const filterParams = this.state.filterParams;

    let sortBy = 'asc';
    // order change on same column? toggle current order then
    if (filterParams.orderBy === orderBy) {
      sortBy = filterParams.sortBy === 'asc' ? 'desc' : 'asc'
    }

    const ordering = { orderBy, sortBy }
    const newParams = { ...filterParams, ...ordering, page: 1 }

    this.props.history.push(toQuery(newParams));

    // wait for url to be changed
    this.urlChanged();
  }

  urlChanged = () => {
    // delay for props to update.
    this.setState(prevState => {
      return { ...prevState, loading: true }
    })
    setTimeout(() => {
      this.getData(this.getFilterParams())
    }, 100);
  }

  getFilterParams = () => {
    const queryParams = getQuery(this.props.location.search)
    const filterParams = {
      ...this.state.filterParams,
      ...queryParams
    }
    return filterParams;
  }

  onChangeQ = (event) => {
    const filterParams = this.state.filterParams;
    const name = event.target.name
    filterParams[name] = event.target.value;
    filterParams.page = 1;

    this.setState(prevState => {
      return { ...prevState, ...filterParams }
    })
  }

  onEnterQ = (event) => {
    if (event.keyCode === 13) {
      const filterParams = this.state.filterParams;
      filterParams.page = 1;
      this.props.history.push(toQuery(filterParams));
      this.urlChanged()
    }
  }


  render() {
    // Define rows
    const { rows, loading, filterParams } = this.state
    const { data, meta } = rows

    // Define columns
    const columns = this.getColumns()

    return (
      <React.Fragment>
        <div className='content-wrapper-inner'>
          <Box p={3} className='filter-area'>
            <Grid container justify="space-between" alignItems="center">
              <Grid item>
                <Grid container justify="space-between" alignItems="center" spacing={1}>
                  <Grid item>
                    <FormControl className="filter-input">
                      <TextField
                        value={filterParams.q}
                        name="q"
                        InputProps={{
                          endAdornment:
                            <InputAdornment position="end">
                              <i className='icon dashboardicon-search'></i>
                            </InputAdornment>
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={this.onChangeQ}
                        onKeyDown={this.onEnterQ}
                        placeholder="Search" />
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <FormControl className="select-box">
                      <InputLabel shrink>Verified</InputLabel>
                      <Select
                        value={filterParams.is_verified}
                        name='is_verified'
                        onChange={(event) => {
                          this.onChangeQ(event)
                          // update Url
                          this.props.history.push(toQuery(this.state.filterParams));
                          this.urlChanged()
                        }}
                        displayEmpty>
                        <MenuItem value=''>Any</MenuItem>
                        {['YES', 'NO'].map((item, index) => (
                          <MenuItem value={item} key={index}>{item}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <div className='date-filter'>
                      <TextField
                        label="From Dt."
                        placeholder="From Dt."
                        value={filterParams.fromDt}
                        name="fromDt"
                        type="date"
                        onChange={this.onChangeQ} />
                    </div>
                  </Grid>
                  <Grid item>
                    <div className='date-filter'>
                      <TextField
                        label="To Dt."
                        placeholder="To Dt."
                        value={filterParams.toDt}
                        name="toDt"
                        type="date"
                        onChange={this.onChangeQ} />
                    </div>
                  </Grid>
                  <Grid className='filter-input serverlog-top-field' item>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        const filterParams = this.state.filterParams;
                        this.props.history.push(toQuery(filterParams));
                        this.urlChanged()
                      }}
                      className='add-butn'>
                      Submit
                    </Button>
                  </Grid>
                  <Grid item>
                    <Tooltip title="Reset">
                      <IconButton className='reset' color="inherit" aria-label="refresh"
                        onClick={() => {
                          this.props.history.push(toQuery(this.defaultFilterParams));
                          this.urlChanged()
                        }}>
                        <RotateLeftIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <XGridTable
            tableId="x_grid_server"
            loading={loading}
            rows={data}
            columns={columns}
            {...getServerSideSortProps(this.handleSort, filterParams)}
            {...getServerSidePaginationProps(meta, filterParams, this.props, this.urlChanged)} />
        </div>
      </React.Fragment>
    )
  }
}

export default ServerSide


function ActionIconButton(props) {
  const {
    params,
    dropMenuListingOpen,
    dropMenuListingEl,
    setDropMenuListingAnchorEl,
    setDropMenuListingMenuOpen,
    handleAction } = props
  return (
    <React.Fragment>
      <IconButton
        aria-label="more"
        aria-controls={dropMenuListingOpen ? `drop-menu-${params.id}` : undefined}
        aria-haspopup="true"
        onClick={event => {
          setDropMenuListingAnchorEl(event.currentTarget);
          setDropMenuListingMenuOpen(params.id);
        }}>
        <i className='icon dashboardicon-action'></i>
      </IconButton>
      <Popper
        className="action-dropdown"
        open={dropMenuListingOpen === params.id}
        anchorEl={dropMenuListingEl} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
            <Paper>
              <ClickAwayListener onClickAway={() => setDropMenuListingMenuOpen(0)}>
                <MenuList autoFocusItem={true} id={`drop-menu-${params.id}`}>
                  <MenuItem onClick={() => handleAction('EDIT', params.row)}>
                    <ListItemIcon>
                      <i className='icon dashboardicon-edit'></i>
                    </ListItemIcon>
                    <Typography variant="inherit">
                      Edit
                    </Typography>
                  </MenuItem>
                  <MenuItem onClick={() => handleAction('DELETE', params.row)}>
                    <ListItemIcon>
                      <i className='icon dashboardicon-delete'></i>
                    </ListItemIcon>
                    <Typography variant="inherit">
                      Delete
                    </Typography>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  )
}