import React, { Component } from 'react'
import ReactDOM from 'react-dom';
import { Dialog, DialogTitle, DialogContentText, DialogActions, DialogContent, Button, ThemeProvider } from '@material-ui/core'
import { Trans } from 'react-i18next';
import { theme } from './theme';

let confirmResolver;

class ConfirmDialog extends Component {
  constructor() {
    super();

    this.state = {
      isOpen: false,
    };
  }

  handleCancel = () => {
    this.setState({ isOpen: false });
    confirmResolver(false);
  }

  handleConfirm = () => {
    this.setState({ isOpen: false });
    confirmResolver(true);
  }

  show(params) {
    const { title, message, ok, cancel } = params
    this.setState({
      isOpen: true,
      title: title || 'Confirm',
      message: message || 'Are you sure?',
      okBtn: ok || 'OK',
      cancelBtn: cancel || 'Cancel',
    });
    return new Promise((res) => {
      confirmResolver = res;
    });
  }

  static create() {
    const containerElement = document.createElement('div');
    document.body.appendChild(containerElement);
    return ReactDOM.render(<ConfirmDialog />, containerElement);
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <Dialog open={this.state.isOpen} aria-labelledby="form-dialog-title" maxWidth={'xs'} fullWidth={true}>
          <DialogTitle id="form-dialog-title">{this.state.title}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <span dangerouslySetInnerHTML={{ __html: this.state.message }}></span>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={this.handleConfirm} autoFocus><Trans i18nKey={this.state.okBtn}></Trans></Button>
            <Button color="primary" onClick={this.handleCancel}><Trans i18nKey={this.state.cancelBtn}></Trans></Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    )
  }
}

let alertResolver;

class AlertDialog extends Component {
  constructor() {
    super();

    this.state = {
      isOpen: false,
    };

    this.escapeListener = this.escapeListener.bind(this)
  }

  escapeListener(e) {
    if (e.code === 'Escape') {
      this.handleConfirm()
    }
  }

  addEscListener = () => {
    document.addEventListener('keydown', this.escapeListener, false);
  }

  removeEscListener = () => {
    document.removeEventListener('keydown', this.escapeListener, false);
  }

  handleConfirm = () => {
    this.removeEscListener()
    this.setState({ isOpen: false });
    alertResolver(true);
  }

  show(params) {
    this.addEscListener()
    const { title, message, ok } = params
    this.setState({
      isOpen: true,
      title: title || 'Alert',
      message: message || 'Attention!',
      okBtn: ok || 'OK',
    });
    return new Promise((res) => {
      alertResolver = res;
    });
  }

  static create() {
    const containerElement = document.createElement('div');
    document.body.appendChild(containerElement);
    return ReactDOM.render(<AlertDialog />, containerElement);
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <Dialog open={this.state.isOpen} aria-labelledby="form-dialog-title" maxWidth={'xs'} fullWidth={true}>
          <DialogTitle id="form-dialog-title">{this.state.title}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <span dangerouslySetInnerHTML={{ __html: this.state.message }}></span>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={this.handleConfirm} autoFocus><Trans i18nKey={this.state.okBtn}></Trans></Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    )
  }
}

const ConfirmBox = ConfirmDialog.create();
const AlertBox = AlertDialog.create();

export { ConfirmBox, AlertBox }