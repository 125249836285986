import yupGeneric from '../../../utils/yupGeneric'
import * as yup from 'yup'
import { getUnmaskedNumber } from '../../Shared/FormikMaskedInput'

import formTabs from './formTabs'

/* ---------------------------------- DYNAMIC VALIDATION LOGIC STARTS ---------------------------------- */
export const formValidationFields = {
  /* transunion checkbox */
  transunion_terms_of_service: yup.boolean()
    .typeError(yupGeneric)
    .required()
    .test('transunion_terms_of_service',
      yupGeneric,
      (value) => value,
    ),

  /* applicant section */
  applicants: yup.object({
    first_name: yup.string()
      .typeError(yupGeneric)
      .min(2, yupGeneric)
      .max(50, yupGeneric)
      .required(yupGeneric),
    last_name: yup.string()
      .typeError(yupGeneric)
      .min(2, yupGeneric)
      .max(50, yupGeneric)
      .required(yupGeneric),
    middle_name: yup.string()
      .typeError(yupGeneric)
      .min(1, yupGeneric)
      .max(1, yupGeneric)
      .optional(yupGeneric),
    email: yup.string()
      .typeError(yupGeneric)
      .max(50, yupGeneric)
      .required(yupGeneric)
      .email(yupGeneric),
    contact_no: yup.string()
      .test('Contact no',
        "applicants.contact_no.min",
        (value) => {
          if (!value) {
            return false;
          }
          const clean = getUnmaskedNumber(value)
          if (!clean) {
            return false;
          }
          // length check
          return clean.length === 10
        },
      )
      .required(yupGeneric),
    contact_no_2: yup.string().nullable()
      .test('Contact no',
        "applicants.contact_no_2.min",
        (value) => {
          if (!value) {
            return true;
          }
          const clean = getUnmaskedNumber(value)
          if (!clean) {
            return true;
          }
          // length check
          return clean.length === 10
        },
      ),
    contact_no_3: yup.string().nullable()
      .optional()
      .test('Contact no',
        "applicants.contact_no_3.min",
        (value) => {
          if (!value) {
            return true;
          }
          const clean = getUnmaskedNumber(value)
          if (!clean) {
            return true;
          }
          // length check
          return clean.length === 10
        },
      ),
    gender: yup.string()
      .required(yupGeneric),
  }),

  /* applicant screening info */
  applicant_screening_info: yup.object({
    ssn: yup.string()
      .typeError(yupGeneric)
      .required(yupGeneric)
      .test('ssn',
        "ssn.min",
        (value) => {
          if (!value) {
            return true;
          }
          // Convert to plain ssn
          return getUnmaskedNumber(value).length === 9
        },
      ),
    date_of_birth: yup.string()
      .typeError(yupGeneric)
      .length(10)
      .required(yupGeneric),
    dl_number: yup.string()
      .typeError(yupGeneric)
      .max(15, yupGeneric),
    annual_income: yup.string().nullable(),
    maritial_status: yup.string()
      .required(yupGeneric),
  }),

  /* applicant address section */
  applicant_addresses: yup.array()
    .of(yup.object({
      apartment_no: yup.string()
        .typeError(yupGeneric)
        .max(20, yupGeneric),
      street_no: yup.string()
        .typeError(yupGeneric)
        .max(20, yupGeneric),
      street_name: yup.string()
        .typeError(yupGeneric)
        .max(50, yupGeneric)
        .required(yupGeneric),
      street_type_id: yup.string()
        .typeError(yupGeneric)
        .required(yupGeneric),
      city: yup.string()
        .typeError(yupGeneric)
        .max(50, yupGeneric)
        .required(yupGeneric),
      state_id: yup.string()
        .typeError(yupGeneric)
        .required(yupGeneric),
      zip_code: yup.string()
        .typeError(yupGeneric)
        .matches(/^[0-9]+$/, yupGeneric) // only number
        .min(5, yupGeneric)
        .max(5, yupGeneric)
        .length(5, yupGeneric)
        .required(yupGeneric),
      reason_for_moving: yup.string()
        .typeError(yupGeneric)
        .max(50, yupGeneric),
      move_in_date: yup.string()
        .typeError(yupGeneric)
        .length(10),
      lease_expiration_date: yup.string()
        .typeError(yupGeneric)
        .length(10),
      landlord_name: yup.string()
        .typeError(yupGeneric)
        .max(50, yupGeneric)
        .when('address_type', {
          is: 'OWN',
          then: yup.string().optional(),
          otherwise: yup.string().required(yupGeneric)
        }),
      landlord_contact_no: yup.string()
        .when('address_type', {
          is: 'OWN',
          then: yup.string().optional(),
          otherwise: yup.string().required(yupGeneric)
        })
        .test('Landlord contact no',
          "applicant_addresses.landlord_contact_no.min",
          (value) => {
            if (!value) {
              // empty allowed, handled in when test
              return true;
            }
            const clean = getUnmaskedNumber(value)
            if (!clean) {
              return false;
            }
            // length check
            return clean.length === 10
          },
        ),
      landlord_contact_no_2: yup.string().nullable()
        .test('Landlord contact no',
          "applicant_addresses.landlord_contact_no_2.min",
          (value) => {
            if (!value) {
              return true;
            }
            const clean = getUnmaskedNumber(value)
            if (!clean) {
              return true;
            }
            // length check
            return clean.length === 10
          },
        ),
      landlord_contact_no_3: yup.string().nullable()
        .test('Landlord contact no',
          "applicant_addresses.landlord_contact_no_3.min",
          (value) => {
            if (!value) {
              return true;
            }
            const clean = getUnmaskedNumber(value)
            if (!clean) {
              return true;
            }
            // length check
            return clean.length === 10
          },
        ),
      landlord_fax: yup.string()
        .typeError(yupGeneric)
        .max(20, yupGeneric),
      landlord_email: yup.string()
        .typeError(yupGeneric)
        .max(50, yupGeneric)
        .email(yupGeneric),
      is_current: yup.boolean()
        .typeError(yupGeneric)
        .required(yupGeneric)
      ,
      address_type: yup.string().required(yupGeneric)
    })).test('atleast one current', 'applicant_address.is_current', (values) => {
      const is_currents = values.map((item) => item.is_current);
      return is_currents.includes(true);
    }),

  /* employer section */
  applicant_employers: yup.array()
    .of(yup.object({
      business_name: yup.string()
        .typeError(yupGeneric)
        .max(50, yupGeneric),
      address_line_1: yup.string()
        .typeError(yupGeneric)
        .min(4, yupGeneric)
        .max(50, yupGeneric)
        .required(yupGeneric),
      address_line_2: yup.string()
        .typeError(yupGeneric)
        .max(50, yupGeneric)
        .optional(),
      city: yup.string()
        .typeError(yupGeneric)
        .max(50, yupGeneric)
        .required(yupGeneric),
      state_id: yup.string()
        .typeError(yupGeneric)
        .required(yupGeneric),
      zip_code: yup.string()
        .typeError(yupGeneric)
        .matches(/^[0-9]+$/, yupGeneric) // only number
        .min(5, yupGeneric)
        .max(5, yupGeneric)
        .length(5, yupGeneric)
        .required(yupGeneric),
      position: yup.string()
        .typeError(yupGeneric)
        .max(50, yupGeneric)
        .required(yupGeneric),
      income: yup.string()
        .typeError(yupGeneric)
        .max(10, yupGeneric)
        .required(yupGeneric),
      reason_for_leaving: yup.string()
        .typeError(yupGeneric)
        .max(50, yupGeneric)
        .when('is_current', {
          is: false,
          then: yup.string().required(yupGeneric)
        }),
      supervisor_name: yup.string()
        .typeError(yupGeneric)
        .when('self_employed', {
          is: true,
          then: yup.string().optional(),
          otherwise: yup.string().max(50).required(yupGeneric)
        }),
      supervisor_contact_no: yup.string()
        .when('self_employed', {
          is: true,
          then: yup.string().optional(),
          otherwise: yup.string().required(yupGeneric)
        })
        .test('Supervisor contact no',
          "applicant_employers.supervisor_contact_no.min",
          (value) => {
            if (!value) {
              return true;
            }
            const clean = getUnmaskedNumber(value)
            if (!clean) {
              return true;
            }
            // length check
            return clean.length === 10
          },
        ),
      supervisor_contact_no_2: yup.string().nullable()
        .test('Supervisor contact no',
          "applicant_employers.supervisor_contact_no_2.min",
          (value) => {
            if (!value) {
              return true;
            }
            const clean = getUnmaskedNumber(value)
            if (!clean) {
              return true;
            }
            // length check
            return clean.length === 10
          },
        ),
      supervisor_contact_no_3: yup.string().nullable()
        .test('Supervisor contact no',
          "applicant_employers.supervisor_contact_no_3.min",
          (value) => {
            if (!value) {
              return true;
            }
            const clean = getUnmaskedNumber(value)
            if (!clean) {
              return true;
            }
            // length check
            return clean.length === 10
          },
        ),
      supervisor_email: yup.string()
        .typeError(yupGeneric)
        .max(50, yupGeneric)
        .email(yupGeneric),
      start_date: yup.string()
        .typeError(yupGeneric)
        .required(yupGeneric)
        .length(10),
      end_date: yup.string()
        .typeError(yupGeneric)
        .length(10)
        .when('is_current', {
          is: false,
          then: yup.string().required(yupGeneric)
        }),
      self_employed: yup.boolean()
        .typeError(yupGeneric),
      is_current: yup.boolean()
        .typeError(yupGeneric)
    })),

  /* other income section */
  applicant_other_incomes: yup.array()
    .when('no_other_income', {
      is: true,
      then: yup.array().optional(),
      otherwise: yup.array()
        .of(yup.object({
          source: yup.string()
            .required(yupGeneric)
            .max(50, yupGeneric)
            .typeError(yupGeneric),
          amount: yup.string()
            .required(yupGeneric)
            .max(10, yupGeneric)
            .typeError(yupGeneric),
          income_frequency: yup.string()
            .required(yupGeneric)
            .typeError(yupGeneric)
            .optional()
        }))
    }),

  /* pets section */
  applicant_pets: yup.array()
    .when('no_pets', {
      is: true,
      then: yup.array().optional(),
      otherwise: yup.array()
        .of(yup.object({
          type: yup.string()
            .required(yupGeneric)
            .max(30, yupGeneric)
            .typeError(yupGeneric),
          name: yup.string()
            .max(30, yupGeneric)
            .typeError(yupGeneric),
          coloration: yup.string()
            .max(30, yupGeneric)
            .typeError(yupGeneric),
          age: yup.number()
            .max(150, yupGeneric)
            .typeError(yupGeneric),
          weight: yup.number()
            .typeError(yupGeneric),
          weight_unit: yup.string()
            .typeError(yupGeneric),
          sex: yup.string()
            .typeError(yupGeneric),
          is_fixed: yup.boolean()
            .typeError(yupGeneric),
        }))
    }),

  /* vehicle section */
  applicant_vehicles: yup.array()
    .of(yup.object({
      make: yup.string()
        .max(20, yupGeneric)
        .typeError(yupGeneric)
        .required(yupGeneric),
      model: yup.string()
        .max(20, yupGeneric)
        .typeError(yupGeneric)
        .required(yupGeneric),
      year: yup.string()
        .required(yupGeneric)
        .max(4, yupGeneric)
        .test('Year',
          "applicant_vehicles.year.regex",
          (value) => {
            if (!value) {
              return false
            }
            // "19__" -> "19"
            const year = `${parseInt(value)}`
            return year.length === 4
          },
        )
        .typeError(yupGeneric),
      license_plate_number: yup.string()
        .max(15, yupGeneric)
        .typeError(yupGeneric),
    })),

  /* references section */
  applicant_references: yup.array()
    .of(yup.object({
      first_name: yup.string()
        .typeError(yupGeneric)
        .min(2, yupGeneric)
        .max(50, yupGeneric)
        .required(yupGeneric),
      last_name: yup.string()
        .typeError(yupGeneric)
        .min(2, yupGeneric)
        .max(50, yupGeneric)
        .required(yupGeneric),
      contact_no: yup.string()
        .required(yupGeneric)
        .test('Contact no',
          "applicant_references.contact_no.min",
          (value) => {
            if (!value) {
              return true;
            }
            const clean = getUnmaskedNumber(value)
            if (!clean) {
              return true;
            }
            // length check
            return clean.length === 10
          },
        ),
      email: yup.string()
        .typeError(yupGeneric)
        .max(50, yupGeneric)
        .email(yupGeneric),
      relationship: yup.string()
        .typeError(yupGeneric)
        .required(yupGeneric),
    })),

  /* emergency contacts section */
  applicant_emergency_contacts: yup.array()
    .of(yup.object({
      first_name: yup.string()
        .typeError(yupGeneric)
        .min(2, yupGeneric)
        .max(50, yupGeneric)
        .required(yupGeneric),
      last_name: yup.string()
        .typeError(yupGeneric)
        .min(2, yupGeneric)
        .max(50, yupGeneric)
        .required(yupGeneric),
      contact_no: yup.string()
        .required(yupGeneric)
        .test('Contact no',
          "applicant_emergency_contacts.contact_no.min",
          (value) => {
            if (!value) {
              return true;
            }
            const clean = getUnmaskedNumber(value)
            if (!clean) {
              return true;
            }
            // length check
            return clean.length === 10
          },
        ),
      email: yup.string()
        .typeError(yupGeneric)
        .max(50, yupGeneric)
        .email(yupGeneric),
      address_line_1: yup.string()
        .typeError(yupGeneric)
        .min(4, yupGeneric)
        .max(50, yupGeneric)
        .required(yupGeneric),
      address_line_2: yup.string()
        .typeError(yupGeneric)
        .max(50, yupGeneric)
        .optional(),
      city: yup.string()
        .typeError(yupGeneric)
        .max(50, yupGeneric)
        .required(yupGeneric),
      state_id: yup.string()
        .typeError(yupGeneric)
        .required(yupGeneric),
      zip_code: yup.string()
        .typeError(yupGeneric)
        .matches(/^[0-9]+$/, yupGeneric) // only number
        .min(5, yupGeneric)
        .max(5, yupGeneric)
        .length(5, yupGeneric)
        .required(yupGeneric),
      relationship: yup.string()
        .typeError(yupGeneric)
        .required(yupGeneric),
    })),

  /* signature_n_approval ( new form section in last tab for sign,radios,checkboxes ) */
  signature_n_approval: yup.object({
    // 1. sign
    application_consent_to_share_data: yup.boolean()
      .typeError(yupGeneric)
      .required(yupGeneric)
      .test('application_terms_and_condition',
        yupGeneric,
        (value) => value,
      ),
    signature_data: yup.object({
      type: yup.string().required(yupGeneric),
      content: yup.string().required(yupGeneric)
    }).required(yupGeneric),
    // 2. data consent
    credit_data_approval: yup.string()
      .required(yupGeneric)
      .test('credit_data_approval',
        yupGeneric,
        (value) => {
          if (!value) {
            return false
          }

          return value === '1'
        }),
    // 3. terms cond
    application_terms_and_condition: yup.boolean()
      .typeError(yupGeneric)
      .required(yupGeneric)
      .test('application_terms_and_condition',
        yupGeneric,
        (value) => value,
      )
  }),
}

export const getFormValidations = (selectedTab) => {

  let tabIndex = formTabs.findIndex(item => item.tabId === selectedTab)
  if (tabIndex < 0) {
    tabIndex = 0
  }
  const forms = formTabs[tabIndex].forms

  // {} with reqd validation sections
  const obj = {}
  forms.forEach(form => {
    obj[form] = formValidationFields[form]
  });

  return yup.object(obj)
}