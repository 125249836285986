import React from 'react'
import css from '../Application.module.css'
import { withTranslation, Trans } from 'react-i18next'
import { compose } from 'recompose'
import { FieldArray } from 'formik'

import FormikTextField from '../../../Shared/FormikTextField'
import ApplicantFiles from '../../../Shared/ApplicantFiles';

import { APPLICANT_PET } from '../ApplicantHelpers'


import {
  Typography,
  Grid,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  Button,
  Divider,
  Box
} from '@material-ui/core'
class Pet extends React.Component {
  handleApplicantPetsFilesChange = async index => {
    const { handleFileUpload, props } = this.props
    // get current uploaded files
    const files = await handleFileUpload('pets', index)

    // add existing files to current files if present
    if (props.values.applicant_pets[index].files && !!props.values.applicant_pets[index].files.length) {
      files.push(...props.values.applicant_pets[index].files)
    }

    // set files in key
    props.setFieldValue(`applicant_pets[${index}].files`, files)
  }

  render() {
    const {
      t,
      deleteRecord,
      classes,
      props,
      applicantPetsFilesRef
    } = this.props

    const { no_pets } = props.values;

    return (
      <React.Fragment>
        <div className={`${css['form-grp']} form-wrap`}>
          <div className={css['heading']}>
            <Typography component="h1">
              <Trans ns="application" i18nKey='pet.header'>Pet</Trans>
            </Typography>
          </div>
          <Grid container justify='flex-start' alignItems='center' >
            {/* No pets  */}
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
              <div className={css['checkbox']}>
                <FormControlLabel
                  labelPlacement="end"
                  label={<Trans ns='application' i18nKey="label.no_pets">No Pet</Trans>}
                  control={<Checkbox
                    checked={no_pets}
                    name="no_pets"
                    onChange={(_event, newValue) => {
                      props.setFieldValue('no_pets', newValue)

                      props.setFieldValue('applicant_pets', newValue === true ? [] : [APPLICANT_PET])
                    }}
                    color="primary"
                  />} />
              </div>
            </Grid>
          </Grid>
        </div>

        <FieldArray name="applicant_pets">
          {({ push, form, remove }) => {
            const { values } = form
            const { applicant_pets } = values

            return (
              <React.Fragment>
                {!no_pets &&
                  <div className={`${css['form-grp']} form-wrap`}>
                    {applicant_pets.map((_applicant_pet, index) => {
                      return (
                        <React.Fragment key={index}>
                          <div className={css['spacing-bottom']}>

                            <div className={css['heading']}>
                              <Grid container justify='space-between' alignItems='center'>
                                <Grid item>
                                  <Typography component="h1">
                                    <Trans ns="application" i18nKey={`applicant_pets.${index}.header`}>{`Pet #${index + 1}`}</Trans>
                                  </Typography>
                                </Grid>
                                {(index > 0) && <Grid item>
                                  <Button className={css['remove-button']} variant='contained' color='primary'
                                    onClick={() => {
                                      if (typeof applicant_pets[index] !== 'undefined') {
                                        // db wala record..
                                        deleteRecord('applicant_pets', applicant_pets[index].id)
                                      }
                                      remove(index)
                                    }}>
                                    <Trans ns='application' i18nKey='button.remove.pets'>- Remove Pet</Trans>
                                  </Button>
                                </Grid>}
                              </Grid>
                            </div>

                            <Grid container alignItems='center' spacing={3}>
                              {/* type input text */}
                              <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                <FormikTextField
                                  required
                                  ns="application"
                                  name={`applicant_pets[${index}].type`}
                                  type="text"
                                  variant="outlined"
                                  label={<Trans ns='application' i18nKey="label.pet_breed">Breed</Trans>}
                                  placeholder={t('application:pet_breed', 'Breed')}
                                  fullWidth
                                  className={classes.input}
                                  autoComplete="off" />
                              </Grid>
                              {/* name input text */}
                              <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                <FormikTextField
                                  ns="application"
                                  name={`applicant_pets[${index}].name`}
                                  type="text"
                                  variant="outlined"
                                  label={<Trans ns='application' i18nKey="label.pet_name">Name</Trans>}
                                  placeholder={t('application:pet_name', 'Name')}
                                  fullWidth
                                  className={classes.input}
                                  autoComplete="off" />
                              </Grid>
                              {/* coloration input text */}
                              <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                <FormikTextField
                                  ns="application"
                                  name={`applicant_pets[${index}].coloration`}
                                  type="text"
                                  variant="outlined"
                                  label={<Trans ns='application' i18nKey="label.pet_coloration">Coloration</Trans>}
                                  placeholder={t('application:pet_coloration', 'Coloration')}
                                  fullWidth
                                  className={classes.input}
                                  autoComplete="off" />
                              </Grid>
                              {/* age input text */}
                              <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                <FormikTextField
                                  ns="application"
                                  name={`applicant_pets[${index}].age`}
                                  type="text"
                                  variant="outlined"
                                  label={<Trans ns='application' i18nKey="label.pet_age">Age</Trans>}
                                  placeholder={t('application:pet_age', 'Age')}
                                  fullWidth
                                  className={classes.input}
                                  autoComplete="off" />
                              </Grid>
                              {/* weight input text */}
                              <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                <FormikTextField
                                  ns="application"
                                  name={`applicant_pets[${index}].weight`}
                                  type="text"
                                  variant="outlined"
                                  label={<Trans ns='application' i18nKey="label.pet_weight">Weight</Trans>}
                                  placeholder={t('application:pet_weight', 'Weight')}
                                  fullWidth
                                  className={classes.input}
                                  autoComplete="off" />
                              </Grid>
                              {/* weight unit radio */}
                              <Grid item xs={12} sm={12} md='auto' lg='auto' xl='auto'>
                                <div className={css['radio-btn']}>
                                  <FormControl>
                                    <FormLabel>
                                      <Trans ns='application' i18nKey="label.weight_unit">
                                        Weight unit
                                      </Trans>
                                    </FormLabel>
                                    <RadioGroup
                                      row
                                      aria-label="position"
                                      name={`applicant_pets[${index}].weight_unit`}
                                      value={applicant_pets[index].weight_unit}
                                      onChange={(_event, newValue) => {
                                        props.setFieldValue(`applicant_pets[${index}].weight_unit`, newValue)
                                      }}>
                                      <FormControlLabel value="KG" control={<Radio color="primary" />} label={<Trans ns='application' i18nKey="label.kg">KG</Trans>} />
                                      <FormControlLabel value="LBS" control={<Radio color="primary" />} label={<Trans ns='application' i18nKey="label.lbs">LBS</Trans>} />
                                    </RadioGroup>
                                  </FormControl>
                                </div>
                              </Grid>
                            </Grid>

                            <Grid container alignItems='center' spacing={3} className={css['container-spacing-3']}>
                              {/* is fixed checkbox */}
                              <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                                <div className={css['checkbox']}>
                                  <FormControlLabel
                                    labelPlacement="end"
                                    label={<Trans ns='application' i18nKey="label.is_fixed">Is Fixed?</Trans>}
                                    control={<Checkbox
                                      checked={applicant_pets[index].is_fixed}
                                      name={`applicant_pets[${index}].is_fixed`}
                                      onChange={(_event, newValue) => {
                                        props.setFieldValue(`applicant_pets[${index}].is_fixed`, newValue)
                                      }}
                                      color="primary"
                                    />} />
                                </div>
                              </Grid>
                              {/* sex radio */}
                              <Grid item xs={12} sm={12} md='auto' lg='auto' xl='auto'>
                                <div className={css['radio-btn']}>
                                  <FormControl>
                                    <FormLabel>
                                      <Trans ns='application' i18nKey="label.sex">
                                        Sex
                                      </Trans>
                                    </FormLabel>
                                    <RadioGroup
                                      row
                                      aria-label="position"
                                      name={`applicant_pets[${index}].sex`}
                                      value={applicant_pets[index].sex}
                                      onChange={(_event, newValue) => {
                                        props.setFieldValue(`applicant_pets[${index}].sex`, newValue)
                                      }}>
                                      <FormControlLabel value="MALE" control={<Radio color="primary" />} label={<Trans ns='application' i18nKey="label.male">Male</Trans>} />
                                      <FormControlLabel value="FEMALE" control={<Radio color="primary" />} label={<Trans ns='application' i18nKey="label.female">Female</Trans>} />
                                    </RadioGroup>
                                  </FormControl>
                                </div>
                              </Grid>
                            </Grid>

                            {/* upload files */}
                            <div className={css['upload-files-section']}>
                              <Grid container justify='flex-start' alignItems='center' className={css['container-spacing-3']}>
                                <Grid item>
                                  <Box className={css['sub-heading']} mr={2}><Trans ns='application' i18nKey="label.pet_images">Pet Images</Trans></Box>
                                </Grid>
                                <Grid item>
                                  <Button className={css['upload-files-button']}
                                    onClick={() => document.getElementById(`applicant_pets[${index}].files`).click()}>
                                    <Trans ns='application' i18nKey='button.pets.uploadfiles'>+ Upload Files</Trans>
                                  </Button>
                                  <input
                                    ref={applicantPetsFilesRef[index]}
                                    type="file"
                                    id={`applicant_pets[${index}].files`}
                                    onChange={() => this.handleApplicantPetsFilesChange(index)}
                                    multiple
                                    hidden
                                    accept="image/*" />
                                </Grid>
                              </Grid>
                            </div>
                            {/* display uploaded files */}
                            {(props.values.applicant_pets[index].files && !!props.values.applicant_pets[index].files.length) && <React.Fragment>
                              <ApplicantFiles files={props.values.applicant_pets[index].files} />
                            </React.Fragment>}

                            <Grid container justify='flex-end' alignItems='center' spacing={2} className={css['container-spacing-3']}>
                              {(applicant_pets.length === index + 1) && <Grid item>
                                <Button className={css['add-more-button']} variant='contained' color='primary'
                                  onClick={() => push(APPLICANT_PET)}>
                                  <Trans ns='application' i18nKey='button.add.pets'>+ Add Pet</Trans>
                                </Button>
                              </Grid>}
                            </Grid>

                            <Divider className={css['form-divider']} />

                          </div>
                        </React.Fragment>
                      )
                    })}
                  </div>
                }
                {no_pets && <Divider className={css['form-divider']} />}
              </React.Fragment>
            )
          }}
        </FieldArray>
      </React.Fragment>
    )
  }
}

export default compose(
  withTranslation('application')
)(Pet)