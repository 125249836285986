import http from "../utils/http";

export function stateListReducer(state = { init: false, data: [] }, action) {
  switch (action.type) {
    case 'LOAD_STATE_LIST': {
      return { ...state, ...action.payload }
    }
    default:
      return state;
  }
}

export function preLoadStateList() {
  return async (dispatch, getState) => {
    // preloaded? ignore this
    if (getState().state_list.init === true) {
      return Promise.resolve()
    }

    const response = await http.get(`/states`);

    return dispatch({
      type: 'LOAD_STATE_LIST',
      payload: {
        init: true,
        data: response.data
      }
    });
  }
}