import React from 'react'
import InputMask from 'react-input-mask';

/**
 *
 * @param {*} props
 * @returns InputMask
 * @summary
 * <MaskedInput
    required
    name=''
    type='text'
    label='SSN'
    mask_type=(phone / SSN) -> (999) 999-9999 / (999)-(99)-(9999)
    />
 */
const MaskedInput = (props) => {
  return (
    <React.Fragment>
      <div className={'masked-phone-number'}>
        <label>{props.label}</label>
        <InputMask
          {...props}
          mask={props.mask_type}
          alwaysShowMask={true}
          className={'masked-phone-number'}
          maskChar={'\u2000'}
        />
      </div>
    </React.Fragment>
  )
}

export default MaskedInput;