import React from 'react'
import { Trans, withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import http from '../../../../utils/http';

import moneyFormat from '../../../../utils/moneyFormat';

import PlacePayApplication from '../../../Shared/PlacePayApplication';
import FormikTextField from '../../../Shared/FormikTextField';
import FormikSelect from '../../../Shared/FormikSelect';
import ProgressButtonSimple from '../../../Shared/ProgressButtonSimple';
import { MonthNamesWithNos } from '../../../Shared/MonthNamesWithNos'

import css from '../GuestcardContactForm/GuestcardContactForm.module.css'
import ProcessPreApplicationPaymentConfirmDialog from './ProcessPreApplicationPaymentConfirmDialog';
import { Table, TableBody, TableCell, TableContainer, TableRow, Container } from '@material-ui/core';


import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
  Box,
  MenuItem,
  FormLabel,
  Tooltip,
  Button
} from '@material-ui/core';
import FormikAutoComplete from '../../../Shared/FormikAutoComplete';

// payment
export const CARD_FORM_DATA = {
  card_type: null,
  card_no: '',
  expiry_month: '',
  expiry_year: '',
  cvv: '',
  account_holders_name: '',
}

export const CHECK_FORM_DATA = {
  routing_number: '',
  account_number: '',
  account_holders_name: '',
  account_type: 'Checking', // Checking and Savings
}

/**
 * merchant type is APPLICATION_FEE for pre application payment
 */
const MERCHANT_TYPE = 'APPLICATION_FEE'

class MakePaymentForm extends React.Component {
  constructor(props) {
    super(props)

    this.cardAutofill = {
      card_no: '4100000000000001',
      expiry_month: '12',
      expiry_year: '2025',
      cvv: '4585',
      account_holders_name: 'Developer Tenant', // required for revo pay
    }

    this.checkAutofill = {
      routing_number: '021200025',
      account_number: '123123124',
      account_holders_name: 'Developer Tenant',
      account_type: 'Checking', // Checking and Savings
    }

    /**
     * shall have { 4111 : 'CREDIT', 4000 : 'DEBIT' .. }
     * keep updating everytime API is called, before calling API, check if we already have type in this variable
     */
    this.cardTypeFromAPI = {}

    this.state = {
      confirm_process_payment_dialog_open: false,
      is_fees_fetched: false,
      payment_gateways: {},
      requestInProcessCardNo: null, // updated to latest card request that's going on (to avoid multiple API requests)
      property_id: '',
      card_type: null // CREDIT/DEBIT
    }
  }

  componentDidMount() {
    if (process.env.REACT_APP_ENV !== 'production') {
      console.table({
        'card-number-1': '4100000000000001',
        'card-number-2': '4242424242424242',
        'card-number-3': '5555555555554444',
        'routing-number': '021200025',
        'account-number': '123123124',
        'account-holder-name': 'Developer Tenant'
      })
    }
    this.setState(prevState => ({
      ...prevState,
      payment_gateways: this.props.data.payment_gateways,
      property_id: this.props.data.property_id
    }))
  }

  confirmProcessPayment = formikProps => {
    // set error messages if any - do not submit if invalid
    if (typeof formikProps.errors.make_payment_form !== 'undefined' && (Object.keys(formikProps.errors.make_payment_form).length > 0)) {
      // show validation error message manually
      for (const name in formikProps.errors.make_payment_form) {
        formikProps.setFieldTouched(`make_payment_form.${name}`, true)
      }

      return;
    }

    // show confirm box
    this.toggleProcessPaymentConfirmDialog(true)
  }

  toggleProcessPaymentConfirmDialog = toggle => {
    this.setState(prevState => {
      return {
        ...prevState,
        confirm_process_payment_dialog_open: toggle
      }
    })
  }

  /**
   * to get card type,
   * to be called after change/blur of each of the required fields
   */
  getCardType = (values) => {
    const { requestInProcessCardNo, payment_gateways, property_id } = this.state

    let { card_no, expiry_month, expiry_year, cvv, account_holders_name, address1, city, state_code, zip_code, payment_type } = values.make_payment_form
    const address_line_1 = address1

    // already proceesing this card's info - no need for another api req
    if (requestInProcessCardNo === card_no) {
      return
    }

    // this card type already fetched n saved (or state already has this card's type) - no need for another api req
    if (this.cardTypeFromAPI[card_no] && this.state.card_type === this.cardTypeFromAPI[card_no]) {
      return
    }

    if (payment_type === "CARD" && payment_gateways.CARD.payment_gateway !== 'PRIORITY_PASSPORT') {
      this.setState(prevState => ({
        ...prevState,
        requestInProcessCardNo: null,
        card_type: 'CREDIT'
      }))

      return
    }

    // check all filled? then only proceed
    state_code = (state_code) ? state_code.id : ''
    if (card_no === ''
      || account_holders_name === ''
      || expiry_month === ''
      || expiry_year === ''
      || cvv === ''
      || address_line_1 === ''
      || city === ''
      || state_code === ''
      || zip_code === '') {
      return false
    }

    // update state key with latest request card no
    this.setState(prevState => ({
      ...prevState,
      requestInProcessCardNo: card_no
    }))

    const data = { card_no, expiry_month, expiry_year, cvv, account_holders_name, address_line_1, city, state_code, zip_code, property_id }

    http.post(`applicant/card_type`, data)
      .then(response => {

        const card_type = response.data.cardType

        // save in state card_type and update this.cardTypeFromAPI
        this.setState(prevState => ({
          ...prevState,
          requestInProcessCardNo: null,
          card_type,
          is_fees_fetched: true
        }))

        this.cardTypeFromAPI[card_no] = card_type
      }, error => {
        this.context.snackbar(<Trans ns='payments' i18nKey={error.data}>{error.data}</Trans>, {
          variant: 'error'
        })

        // if error, considering as credit card case
        this.setState(prevState => ({
          ...prevState,
          requestInProcessCardNo: null,
          card_type: 'CREDIT'
        }))
      })
  }

  render() {
    const { data, state_list, applicant_id } = this.props
    const { formikProps, unit_id, pre_app_fee_payments, merchant_type, user, payment_gateways } = data
    const { values } = formikProps
    const { make_payment_form, guestcard_contact_form } = values
    const { payment_type } = make_payment_form

    const { confirm_process_payment_dialog_open, requestInProcessCardNo, card_type } = this.state;

    let amount = 0
    pre_app_fee_payments.map(item => amount = amount + parseFloat(item.charge_amount))

    const { t } = this.props

    function addYears(date, n) {
      return new Date(date.setFullYear(date.getFullYear() + n));
    }
    let years = []
    for (let index = 0; index < 11; index++) {
      years.push(addYears(new Date(), index).getFullYear())
    }

    let monthNames = { ...MonthNamesWithNos }
    const current_year = new Date().getFullYear()
    // selected year == current year
    if (make_payment_form.expiry_year && parseInt(make_payment_form.expiry_year) === current_year) {
      // getMonth return Jan = 0, Feb = 1,... Dec = 11
      const currentMonth = (new Date().getMonth()) + 1
      // remove jan to current month from object
      for (let index = 0; index <= currentMonth; index++) {
        delete monthNames[index]
      }
      // select less than current month
      if (make_payment_form.expiry_month && parseInt(make_payment_form.expiry_month) <= currentMonth) {
        make_payment_form.expiry_month = ''
      }
    }
    // selected year != current year
    if (make_payment_form.expiry_year && parseInt(make_payment_form.expiry_year) !== current_year) {
      monthNames = { ...MonthNamesWithNos }
    }

    if (Object.keys(payment_gateways).length === 0) {
      return (
        <React.Fragment>
          <Box>
            <Grid item md={12}>
              <div>
                Loading...
              </div>
            </Grid>
          </Box>
        </React.Fragment>
      )
    }

    /** payment gateway data */
    const payment_type_data = payment_gateways[payment_type]
    const merchant_type_data = payment_type_data.merchant_types[MERCHANT_TYPE]
    const payment_gateway = payment_type_data.payment_gateway
    /** payment gateway data */

    /** fee calculation start */
    let fee_type = merchant_type_data.fee_type // percent | flat | both

    //will be treated as flat amount, if fee type is both
    let fee_amount = merchant_type_data.fee_amount // fee amount
    let percent_fee_amount = merchant_type_data.fee_percent_amount
    let fee_calculation = fee_amount
    let debit_fee_amount = 0
    let debit_percent_fee_amount = 0
    let debit_fee_type = 'flat'

    if (merchant_type_data) {
      debit_fee_amount = merchant_type_data.debit_fee_amount
      debit_percent_fee_amount = merchant_type_data.debit_fee_percent_amount
      debit_fee_type = merchant_type_data.debit_fee_type
    }

    if (fee_type === 'percent') {
      fee_calculation = `${fee_amount}%`
      fee_amount = (amount * fee_amount) / 100
    }

    if (fee_type === 'flat') {
      fee_calculation = moneyFormat(fee_amount)
    }

    if (fee_type === 'both') {
      fee_calculation = `${percent_fee_amount}% + ${moneyFormat(fee_amount)}`
      const percent_fee = (amount * percent_fee_amount) / 100
      fee_amount += percent_fee
    }

    // card + debit? show debit fee
    if (payment_type === 'CARD' && card_type === 'DEBIT') {
      fee_amount = debit_fee_amount

      if (debit_fee_type === 'percent') {
        fee_calculation = `${fee_amount}%`
        fee_amount = (amount * fee_amount) / 100
      }

      if (debit_fee_type === 'flat') {
        fee_calculation = moneyFormat(fee_amount)
      }

      if (debit_fee_type === 'both') {
        fee_calculation = `${debit_percent_fee_amount}% + ${moneyFormat(fee_amount)}`
        const percent_fee = (amount * debit_percent_fee_amount) / 100
        fee_amount += percent_fee
      }
    }

    // if null (debit card fee might not be set)
    fee_amount = (fee_amount) ? fee_amount : 0

    // we've toFixd(2) logic in all gateways so showing same here
    fee_amount = parseFloat(fee_amount.toFixed(2))

    const total_amount = parseFloat(amount) + parseFloat(fee_amount)
    /** fee calculation end */


    return (
      <React.Fragment>
        <div className={css['guestcard-contact-form']}>
          <div className='form-wrap'>
            <Container component="main" className={css['component-wrapper']}>
              <h2><Trans ns="deposits" i18nKey="invoices.heading">Holding Deposit Payment</Trans></h2>
              <TableContainer>
                <Table className={css['application-fees-table']}>
                  <TableBody>
                    {pre_app_fee_payments.map((item, index) => {
                      return <TableRow key={index}>
                        <TableCell className={css['capitalize-text']}>{item.description ? item.description : item.charge_code.toLowerCase()}</TableCell>
                        <TableCell className={css['amount-col']}>{moneyFormat(item.charge_amount)}</TableCell>
                      </TableRow>
                    })}
                    {/* Fee Charge */}
                    <TableRow>
                      <TableCell className={css['empty-col']}>
                        {!requestInProcessCardNo ? `Processing Fee (${fee_calculation})` : <h4 className={css['fetching']}>
                          <Trans ns='payments' i18nKey='info.fetching_fees'>
                            Fetching fees...
                          </Trans>
                        </h4>}
                      </TableCell>
                      <TableCell className={css['total-row']}>{!requestInProcessCardNo ? moneyFormat(fee_amount) : '...'}</TableCell>

                    </TableRow>
                    {/* Total */}
                    <TableRow>
                      <TableCell className={css['empty-col']}><b>Total</b></TableCell>
                      <TableCell className={css['total-row']}><b className={css['amount']}>{!requestInProcessCardNo ? moneyFormat(total_amount) : '...'}</b></TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Container>

            <br />
            <br />

            <Container component="main" className={css['component-wrapper']}>
              {/* payment type section */}
              <div className='form-field'>
                <Grid container justifyContent='flex-start' alignItems="center" spacing={2}>
                  <Grid item>
                    <Grid item>
                      <h3>
                        <Trans ns='application' i18nKey='application.form.heading.payment_type'>
                          Enter your payment details :
                        </Trans>
                      </h3>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container justifyContent='flex-start' alignItems="center" spacing={2}>
                  <Grid item>
                    <div className="radio-butn">
                      <FormControl component="fieldset">
                        <RadioGroup
                          row
                          aria-label="payment_type"
                          name="make_payment_form.payment_type"
                          value={make_payment_form.payment_type}
                          onChange={(_event, newValue) => {
                            formikProps.setValues({
                              ...formikProps.values,
                              make_payment_form: {
                                ...formikProps.values.make_payment_form,
                                payment_type: newValue,
                                ...CARD_FORM_DATA,
                                ...CHECK_FORM_DATA
                              },
                              card_type: null
                            })
                          }}>

                          <Grid container alignItems="center" spacing={2}>
                            <Grid item>
                              <FormControlLabel className={make_payment_form.payment_type === 'CARD' ? 'checked-amount-radio' : ''} value="CARD" control={<Radio color="primary" />} label="Card" />
                            </Grid>
                            {/* for time being only card rakha h */}
                            {false && <Grid item>
                              <FormControlLabel className={make_payment_form.payment_type === 'CHECKING' ? 'checked-amount-radio' : ''} value="CHECKING" control={<Radio color="primary" />} label="Check" />
                            </Grid>}
                            {payment_gateway !== 'PLACEPAY' && <React.Fragment>
                              <Grid item>
                                {process.env.NODE_ENV === 'development' && <React.Fragment>
                                  <Tooltip title={<Trans ns='application' i18nKey='application.form.autofill.tooltip'>Click here to autofill</Trans>}>
                                    <Button
                                      data-testid="payments_autofill"
                                      color="primary"
                                      size='large'
                                      className={css['autofill-button']}
                                      onClick={() => {
                                        if (payment_type === 'CARD') {
                                          const newValues = {
                                            ...formikProps.values,
                                            make_payment_form: {
                                              ...formikProps.values.make_payment_form,
                                              ...this.cardAutofill
                                            }
                                          }
                                          formikProps.setValues({
                                            ...formikProps.values,
                                            make_payment_form: {
                                              ...formikProps.values.make_payment_form,
                                              ...this.cardAutofill
                                            }
                                          })
                                          this.getCardType(newValues)
                                        }

                                        if (payment_type === 'CHECKING') {
                                          formikProps.setValues({
                                            ...formikProps.values,
                                            make_payment_form: {
                                              ...formikProps.values.make_payment_form,
                                              ...this.checkAutofill
                                            }
                                          })
                                        }
                                      }}>
                                      <Trans ns='application' i18nKey='form.autofill.label'>Autofill</Trans>
                                    </Button>

                                  </Tooltip>
                                </React.Fragment>}
                              </Grid>
                            </React.Fragment>}
                          </Grid>
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </Grid>
                </Grid>
              </div>

              {/* show make payment form, address, fee section only if payment gateway is one of the 'PRIORITYPAYMENTS', 'REVOPAY', 'NMI' */}
              {(payment_gateway !== 'PLACEPAY') && <React.Fragment>

                {/* payment type CARD section */}
                {(payment_type === 'CARD') && <React.Fragment>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid container alignItems="flex-start" spacing={2}>
                      <Grid item xs={12} sm={12} md={12}>
                        <div className={`${css['content-space']} ${css['payment-input']}`}>
                          <Grid container alignItems="flex-start" spacing={2}>
                            <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                              <FormikTextField
                                data-testid="payments_card_card_no"
                                label={t('application:form.card_no', 'Card Number')}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                ns='application'
                                name="make_payment_form.card_no"
                                required
                                type="year"
                                placeholder={t('application:form.card_no', 'Card Number')}
                                fullWidth
                                variant="outlined"
                                autoComplete="off"
                                onBlur={() =>
                                  this.getCardType(values)
                                }
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={5} xl={3}>
                              <FormikTextField
                                data-testid="payments_card_account_holders_name"
                                label={t('application:form.account_holders_name', 'Account Holder\'s Name')}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                ns='application'
                                name="make_payment_form.account_holders_name"
                                required
                                type="year"
                                placeholder={t('application:form.account_holders_name', 'Account Holder\'s Name')}
                                fullWidth
                                variant="outlined"
                                autoComplete="off"
                                onBlur={() => this.getCardType(values)}
                              />
                            </Grid>
                          </Grid>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <div className={css['content-space']}>
                          <Grid container alignItems="flex-start" spacing={2}>
                            <Grid item xs={12} sm={4} md={4} lg={3} xl={2}>
                              <div className={`${css['make-payment-expiry-month']} form-select-box`}>
                                <FormikSelect
                                  data-testid="payments_card_expiry_month"
                                  name='make_payment_form.expiry_month'
                                  label={t('payments:form.expiry_month', 'Expiry Month')}
                                  variant="outlined"
                                  required
                                  fullWidth
                                  autoWidth={true}
                                  onBlur={() => this.getCardType(values)}>
                                  {Object.keys(monthNames).map((month) => <MenuItem value={month} key={month}>{monthNames[month]}</MenuItem>)}
                                </FormikSelect>
                              </div>
                            </Grid>
                            <Grid item xs={12} sm={4} md={4} lg={3} xl={2}>
                              <div className={`${css['make-payment-expiry-month']} form-select-box`}>
                                <FormikSelect
                                  data-testid="payments_card_expiry_year"
                                  name='make_payment_form.expiry_year'
                                  label={t('payments:form.expiry_year', 'Expiry Year')}
                                  variant="outlined"
                                  required
                                  fullWidth
                                  autoWidth={true}
                                  onBlur={() => this.getCardType(values)}>
                                  {years.map(item => <MenuItem value={item} key={item}>{item}</MenuItem>)}
                                </FormikSelect>
                              </div>
                            </Grid>
                            <Grid item xs={12} sm={4} md={4} lg={3} xl={2}>
                              <FormikTextField
                                data-testid="payments_card_cvv"
                                label={t('application:form.cvv', 'CVV')}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                ns='application'
                                name="make_payment_form.cvv"
                                required
                                type="year"
                                placeholder={t('application:form.cvv', 'CVV')}
                                fullWidth
                                variant="outlined"
                                autoComplete="off"
                                onBlur={() => this.getCardType(values)} />
                            </Grid>
                          </Grid>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </React.Fragment>}


                {/* payment type CHECKING section */}
                {(payment_type === 'CHECKING') && <React.Fragment>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div className={`${css['content-space']} ${css['payment-input']} form-field`}>
                      <Grid container alignItems="flex-start" spacing={2}>
                        <Grid item xs={12} sm={6} md={6} lg={3} xl={2}>
                          <FormikTextField
                            data-testid="payments_check_routing_number"
                            label={t('application:form.routing_number', 'Routing Number')}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            ns='application'
                            name="make_payment_form.routing_number"
                            required
                            type="year"
                            placeholder={t('application:form.routing_number', 'Routing Number')}
                            fullWidth
                            variant="outlined"
                            autoComplete="off" />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={3} xl={2}>
                          <FormikTextField
                            data-testid="payments_check_account_number"
                            label={t('application:form.account_number', 'Account Number')}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            ns='application'
                            name="make_payment_form.account_number"
                            required
                            type="year"
                            placeholder={t('application:form.account_number', 'Account Number')}
                            fullWidth
                            variant="outlined"
                            autoComplete="off" />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={3} xl={2}>
                          <FormikTextField
                            data-testid="payments_check_account_holders_name"
                            label={t('application:form.account_holders_name', 'Account Holder\'s Name')}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            ns='application'
                            name="make_payment_form.account_holders_name"
                            required
                            type="year"
                            placeholder={t('application:form.account_holders_name', 'Account Holder\'s Name')}
                            fullWidth
                            variant="outlined"
                            autoComplete="off" />
                        </Grid>
                      </Grid>
                    </div>

                    <div className={css['content-space']}>
                      <Grid container alignItems="flex-start" spacing={2}>
                        <Grid item xs={12} sm={12} md={12}>
                          <div className={`${css['account-type-heading']} radio-butn`}>
                            <FormControl component="fieldset">
                              <FormLabel component="legend">
                                <Trans ns='application' i18nKey='form.account_type.formlabel'>Account Type</Trans>
                              </FormLabel>
                              <RadioGroup
                                row
                                aria-label="account_type"
                                name="make_payment_form.account_type"
                                value={make_payment_form.account_type}
                                onChange={(_event, newValue) => formikProps.setFieldValue('make_payment_form.account_type', newValue)}>
                                <Grid container alignItems="center" spacing={2}>
                                  <Grid item>
                                    <FormControlLabel value="Checking" className={make_payment_form.account_type === 'Checking' ? 'checked-amount-radio' : ''} control={<Radio color="primary" data-testid="payments_check_account_type_checking" />} label="Checking" />
                                  </Grid>
                                  <Grid item>
                                    <FormControlLabel value="Savings" className={make_payment_form.account_type === 'Savings' ? 'checked-amount-radio' : ''} control={<Radio color="primary" data-testid="payments_check_account_type_savings" />} label="Savings" />
                                  </Grid>
                                </Grid>
                              </RadioGroup>
                            </FormControl>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                </React.Fragment>}

              </React.Fragment>}

              {(payment_gateway !== 'PLACEPAY') && <React.Fragment>
                <div className='form-field'>
                  <Grid container justifyContent='flex-start' alignItems="center" spacing={2}>
                    <Grid item>
                      <Grid item>
                        <h3>
                          <Trans ns='application' i18nKey='application.form.heading.address'>
                            Your Address
                          </Trans>
                        </h3>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* address fields */}
                  <Grid container alignItems="flex-start" spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <div className={css['content-space']}>
                        <Grid container alignItems="flex-start" spacing={2}>
                          <Grid item xs={12} sm={6} md={6} lg={5} xl={3}>
                            <FormikTextField
                              data-testid="payments_address_line_1"
                              label={t('application:form.address1', 'Address Line1')}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              ns='application'
                              name="make_payment_form.address1"
                              required
                              type="text"
                              placeholder={t('application:form.address1', 'Address Line1')}
                              fullWidth
                              variant="outlined"
                              autoComplete="off"
                              onBlur={() => this.getCardType(values)} />
                          </Grid>
                          <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                            <FormikTextField
                              data-testid="payments_address_line_2"
                              label={t('application:form.address2', 'Address Line2')}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              ns='application'
                              name="make_payment_form.address2"
                              type="text"
                              placeholder={t('application:form.address2', 'Address Line2')}
                              fullWidth
                              variant="outlined"
                              autoComplete="off" />
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <div className={css['content-space']}>
                        <Grid container alignItems="flex-start" spacing={2}>
                          <Grid item xs={12} sm={4} md={4} lg={3} xl={2}>
                            <FormikTextField
                              data-testid="payments_city"
                              label={t('application:form.city', 'City')}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              ns='application'
                              name="make_payment_form.city"
                              type="text"
                              placeholder={t('application:form.city', 'City')}
                              fullWidth
                              variant="outlined"
                              autoComplete="off"
                              onBlur={() => this.getCardType(values)} />
                          </Grid>
                          <Grid item xs={12} sm={4} md={4} lg={3} xl={2}>
                            <div>
                              <FormikAutoComplete
                                className={css['state-box']}
                                required
                                disableAdd={true}
                                name="make_payment_form.state_code"
                                label={t('application:form.state_code', 'State Code')}
                                options={Object.keys(state_list).map(item => ({ id: item, label: state_list[item] }))}
                                onChange={(_event, newValue) => {
                                  formikProps.setFieldValue('make_payment_form.state_code', newValue)
                                  this.getCardType(values)
                                }}
                                ns='application' />
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={4} md={4} lg={3} xl={2}>
                            <FormikTextField
                              data-testid="payments_zip_code"
                              label={t('application:form.zip_code', 'Zip Code')}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              ns='application'
                              name="make_payment_form.zip_code"
                              required
                              type="text"
                              placeholder={t('application:form.zip_code', 'Zip Code')}
                              fullWidth
                              variant="outlined"
                              autoComplete="off"
                              onBlur={(event) => {
                                let zip_code = event.target.value.toString()

                                // prefix 0's | 10 -> 00010
                                if (zip_code.length > 0 && zip_code.length < 5) {
                                  zip_code = ("00000" + zip_code).substring(zip_code.length);
                                  formikProps.setFieldValue('make_payment_form.zip_code', zip_code);
                                } else {
                                  // show error
                                  formikProps.setFieldTouched('make_payment_form.zip_code', true)
                                }
                                this.getCardType(values)
                              }} />
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>

                </div>

                {/* progress button - make payment */}
                <Grid container justifyContent="center" alignItems="center" spacing={1}>
                  <Grid item>
                    <ProgressButtonSimple
                      data-testid="payments_submit"
                      type="button"
                      loading={formikProps.isSubmitting ? "1" : "0"}
                      color="primary"
                      disabled={formikProps.isSubmitting}
                      className={`${css["submit-butn"]} form-button`}
                      onClick={() => this.confirmProcessPayment(formikProps)}>
                      <Trans ns='application' i18nKey="application.form.button.make_payment">Make Payment</Trans>
                    </ProgressButtonSimple>
                  </Grid>
                </Grid>


              </React.Fragment>}
            </Container>

            <Grid container justifyContent='flex-start' alignItems="center" spacing={2}>
              <Grid item>
                {(payment_gateway === 'PLACEPAY') && <React.Fragment>
                  <PlacePayApplication
                    color="primary"
                    className={`${css["submit-butn"]} form-button`}
                    onPaid={(placepay_transaction_id) => {
                      formikProps.setFieldValue("make_payment_form.placepay_transaction_id", placepay_transaction_id)

                      // trigger submit manually
                      formikProps.submitForm()
                    }}
                    data={{
                      invoice_type: 'PRE_APPLICATION_FEES',
                      applicant_id,
                      unit_id,
                      amount,
                      payment_type,
                      merchant_type,
                      user
                    }}>
                    <Trans ns='application' i18nKey="application.form.button.placepay">PlacePay Payment</Trans>
                  </PlacePayApplication>
                </React.Fragment>}
              </Grid>
            </Grid>

          </div>
        </div>


        {/* process payment confirm box */}
        {confirm_process_payment_dialog_open && <React.Fragment>
          <ProcessPreApplicationPaymentConfirmDialog
            dialogData={{
              payment_type,
              amount,
              fee_amount,
              total_amount,
              first_name: guestcard_contact_form.first_name,
              last_name: guestcard_contact_form.last_name,
              card_type: card_type
            }}
            open={confirm_process_payment_dialog_open}
            onClose={() => this.toggleProcessPaymentConfirmDialog(false)}
            onSuccess={() => {
              this.toggleProcessPaymentConfirmDialog(false)
              setTimeout(() => {
                formikProps.values.make_payment_form.card_type = card_type
                formikProps.submitForm()
              }, 200);
            }} />
        </React.Fragment>}

      </React.Fragment>
    )
  }
}



export default compose(
  withTranslation('application'),
  connect(
    state => ({
      state_list: state.state_list.data
    }),
  )
)(MakePaymentForm);