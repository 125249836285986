import React from 'react'
import { compose } from 'recompose';
import { Trans, withTranslation } from 'react-i18next';

import { getQuery } from '../../utils/searchParams'
import http from '../../utils/http';
import { AuthContext } from '../../utils/AppContext';
import moneyFormat from '../../utils/moneyFormat';

import { toPhoneNumberMasked } from '../Shared/FormikMaskedInput';

import Esignature from '../Esign/Esignature';
import css from './ApplicantInfo.module.css'

class ApplicantInfo extends React.Component {
  static contextType = AuthContext
  constructor(props) {
    super(props)

    const getParams = getQuery(this.props.location.search)
    const token = getParams.access_token
    if (!token) {
      // @todo - what to do if no token?
      // this.props.history.push('/404')
    }

    this.state = {
      token,
      applicant_id: props.match.params.applicant_id,
      forbidden: false,
      applicants: {
        id: '',
        property_id: '',
        property_name: '',
        first_name: '',
        middle_name: '',
        last_name: '',
        email: '',
        contact_no: '',
        ssn: '',
        dl_number: '',
        dob: '',
        signature_date: null,
        signature_ip: null,
        signature_data: null,
      },
      applicant_screening_info: {},
      applicant_addresses: [],
      applicant_employers: [],
      applicant_other_incomes: [],
      applicant_pets: [],
      applicant_vehicles: [],
      applicant_references: [],
      applicant_emergency_contacts: [],
    }
  }

  componentDidMount() {
    this.context.setTitle('Applicant Details');
    this.context.backdrop(true)

    this.getApplicantData();
  }

  // api get applicant data, DO NOT RENAME TO getData(), was causing multiple requests
  getApplicantData = () => {
    const { token } = this.state
    http.interceptors.request.use(config => {
      config.headers['token'] = token
      return config
    })

    http.get(`/applicant/application`, {
      headers: { token: this.state.token }
    })
      .then(response => {
        this.setState(prevState => ({
          ...prevState,
          ...response.data,
        }))
      }, error => {
        this.context.snackbar(<Trans ns='applicants' i18nKey='applicants.error'>{error.data}</Trans>, {
          variant: 'error'
        })
        if (error.status === 403) {
          this.setState(prevState => ({ ...prevState, forbidden: true }))
        }
      }).finally(() => this.context.backdrop(false))
  }

  // capital first alphabet and splitting with spaces
  capitalise = (text) => {

    if (!text) {
      return text
    }

    return text.split('_').map((str) => {
      str = str.toLowerCase()
      return str.charAt(0).toUpperCase() + str.slice(1)
    }).join(' ')
  }

  render() {
    const {
      forbidden,
      applicants,
      applicant_screening_info,
      applicant_addresses,
      applicant_employers,
      applicant_other_incomes,
      applicant_pets,
      applicant_vehicles,
      applicant_references,
      applicant_emergency_contacts,
    } = this.state;

    const signature = {
      signature_data: applicants.signature_data,
      signature_date: applicants.signature_date,
      signature_ip: applicants.signature_ip,
      full_name: `${applicants.first_name} ${applicants.last_name}`
    }

    if (forbidden) {
      return (
        <div className={`content-wrapper-inner`}>
          <div className="access-denied-wrapper">
            <h5>
              <Trans ns='access_denied' i18nKey="access_denied.msg">Access Denied</Trans>
            </h5>
          </div>
        </div>
      )
    }

    return (
      <React.Fragment>
        <div className={css.pdf_wrapper}>

          {/* 1. APPLICANT INFO */}
          <div className={css.applicant_info}>
            <div className={css.heading}>
              <h4>Applicant Info</h4>
            </div>
            {/* Applicant */}
            <div className={`${css.field}`}>
              <div className={`${css.label}`}><Trans ns='applicants' i18nKey='label.applicant'>Applicant</Trans></div>
              <div className={`${css.info}`}>{applicants.first_name} {applicants.middle_name} {applicants.last_name}</div>
            </div>
            {/* Contact */}
            <div className={`${css.field}`}>
              <div className={`${css.label}`}><Trans ns='applicants' i18nKey='label.contact_no'>Contact</Trans></div>
              <div className={`${css.info}`}>{toPhoneNumberMasked(applicants.contact_no)}</div>
            </div>
            {/* Email */}
            <div className={`${css.field}`}>
              <div className={`${css.label}`}><Trans ns='applicants' i18nKey='label.email'>Email</Trans></div>
              <div className={`${css.info}`}>{applicants.email}</div>
            </div>
            {/* DOB */}
            <div className={`${css.field}`}>
              <div className={`${css.label}`}><Trans ns='applicants' i18nKey='label.dob'>DOB</Trans></div>
              <div className={`${css.info}`}>{applicant_screening_info.date_of_birth}</div>
            </div>
            {/* SSN */}
            <div className={`${css.field}`}>
              <div className={`${css.label}`}><Trans ns='applicants' i18nKey='label.ssn'>SSN</Trans></div>
              <div className={`${css.info}`}>{applicant_screening_info.ssn}</div>
            </div>
            {/* DL number */}
            <div className={`${css.field}`}>
              <div className={`${css.label}`}><Trans ns='applicants' i18nKey='label.dl_number'>DL number</Trans></div>
              <div className={`${css.info}`}>{applicant_screening_info.dl_number}</div>
            </div>
            {/* Annual Income */}
            <div className={`${css.field}`}>
              <div className={`${css.label}`}><Trans ns='applicants' i18nKey='label.annual_income'>Annual Income</Trans></div>
              <div className={`${css.info}`}>{moneyFormat(applicant_screening_info.annual_income)}</div>
            </div>
            {/* Gender */}
            <div className={`${css.field}`}>
              <div className={`${css.label}`}><Trans ns='applicants' i18nKey='label.gender'>Gender</Trans></div>
              <div className={`${css.info}`}>{this.capitalise(applicants.gender)}</div>
            </div>
            {/* Maritial Status */}
            <div className={`${css.field}`}>
              <div className={`${css.label}`}><Trans ns='applicants' i18nKey='label.maritial_status'>Maritial Status</Trans></div>
              <div className={`${css.info}`}>{this.capitalise(applicant_screening_info.maritial_status)}</div>
            </div>
          </div>

          <hr className={css.divider} />

          {/* 2. ADDRESSES */}
          <div className={css.addresses}>
            {applicant_addresses.map((address, index) => {
              return (
                <React.Fragment key={index}>
                  <div className={css.heading}>
                    <h4>
                      {/* 1st entry -> Current Address */}
                      {(index === 0) && <Trans ns="applicants" i18nKey={`applicant_addresses.packets.current_address.header`}>Current Address</Trans>}

                      {/* entries after 1st -> Previous Address */}
                      {(index > 0) && <Trans ns="applicants" i18nKey={`applicant_addresses.packets.previous_address.${index}.header`}>{`Previous Address #${index}`}</Trans>}
                    </h4>
                  </div>

                  {/* show landlord fields for non-own types */}
                  {(address.address_type !== 'OWN') && <>
                    {/* Landlord name */}
                    <div className={`${css.field}`}>
                      <div className={`${css.label}`}><Trans ns='applicants' i18nKey='label.landlord_name'>Landlord name</Trans></div>
                      <div className={`${css.info}`}>{address.landlord_name}</div>
                    </div>
                    {/* Contact */}
                    <div className={`${css.field}`}>
                      <div className={`${css.label}`}><Trans ns='applicants' i18nKey='label.contact_no'>Contact</Trans></div>
                      <div className={`${css.info}`}>{toPhoneNumberMasked(address.landlord_contact_no)}</div>
                    </div>
                    {/* Email */}
                    <div className={`${css.field}`}>
                      <div className={`${css.label}`}><Trans ns='applicants' i18nKey='label.email'>Email</Trans></div>
                      <div className={`${css.info}`}>{address.landlord_email}</div>
                    </div>
                    {/* Fax */}
                    <div className={`${css.field}`}>
                      <div className={`${css.label}`}><Trans ns='applicants' i18nKey='label.fax'>Fax</Trans></div>
                      <div className={`${css.info}`}>{address.landlord_fax}</div>
                    </div>
                  </>}

                  {/* Apartment */}
                  <div className={`${css.field}`}>
                    <div className={`${css.label}`}><Trans ns='applicants' i18nKey='label.apartment'>Apartment</Trans></div>
                    <div className={`${css.info}`}>{address.apartment_no}</div>
                  </div>
                  {/* Street */}
                  <div className={`${css.field}`}>
                    <div className={`${css.label}`}><Trans ns='applicants' i18nKey='label.street'>Street</Trans></div>
                    <div className={`${css.info}`}>{address.street_no}</div>
                  </div>
                  {/* Street Name */}
                  <div className={`${css.field}`}>
                    <div className={`${css.label}`}><Trans ns='applicants' i18nKey='label.street_name'>Street Name</Trans></div>
                    <div className={`${css.info}`}>{address.street_name}</div>
                  </div>
                  {/* Street Type */}
                  <div className={`${css.field}`}>
                    <div className={`${css.label}`}><Trans ns='applicants' i18nKey='label.street_type'>Street Type</Trans></div>
                    <div className={`${css.info}`}>{address.street_type}</div>
                  </div>
                  {/* City */}
                  <div className={`${css.field}`}>
                    <div className={`${css.label}`}><Trans ns='applicants' i18nKey='label.city'>City</Trans></div>
                    <div className={`${css.info}`}>{address.city}</div>
                  </div>
                  {/* State Code */}
                  <div className={`${css.field}`}>
                    <div className={`${css.label}`}><Trans ns='applicants' i18nKey='label.state_code'>State Code</Trans></div>
                    <div className={`${css.info}`}>{address.state_code}</div>
                  </div>
                  {/* Zip */}
                  <div className={`${css.field}`}>
                    <div className={`${css.label}`}><Trans ns='applicants' i18nKey='label.zip_code'>Zip</Trans></div>
                    <div className={`${css.info}`}>{address.zip_code}</div>
                  </div>
                  {/* Reason */}
                  <div className={`${css.field}`}>
                    <div className={`${css.label}`}><Trans ns='applicants' i18nKey='label.reason_for_moving'>Reason</Trans></div>
                    <div className={`${css.info}`}>{address.reason_for_moving}</div>
                  </div>
                  {/* Address Type */}
                  <div className={`${css.field}`}>
                    <div className={`${css.label}`}><Trans ns='applicants' i18nKey='label.address_type'>Address Type</Trans></div>
                    <div className={`${css.info}`}>{this.capitalise(address.address_type)}</div>
                  </div>
                </React.Fragment>
              )
            })}
          </div>

          <hr className={css.divider} />

          {/* 2. EMPLOYERS */}
          <div className={css.employers}>
            {applicant_employers.map((employer, index) => {
              return (
                <React.Fragment key={index}>
                  <div className={css.heading}>
                    <h4>
                      {/* 1st entry -> Current Employer */}
                      {(index === 0) && <Trans ns="applicants" i18nKey={`applicant_employers.packets.current_employer.header`}>Current Employer</Trans>}

                      {/* entries after 1st -> Previous Employer */}
                      {(index > 0) && <Trans ns="applicants" i18nKey={`applicant_employers.packets.previous_employer.${index}.header`}>{`Previous Employer #${index}`}</Trans>}
                    </h4>
                  </div>

                  {/* supervisor fields only if not self emloyed */}
                  {(!employer.self_employed) && <>
                    {/* Supervisor name */}
                    <div className={`${css.field}`}>
                      <div className={`${css.label}`}><Trans ns='applicants' i18nKey='label.supervisor_name'>Supervisor name</Trans></div>
                      <div className={`${css.info}`}>{employer.supervisor_name}</div>
                    </div>
                    {/* Supervisor contact */}
                    <div className={`${css.field}`}>
                      <div className={`${css.label}`}><Trans ns='applicants' i18nKey='label.supervisor_contact_no'>Supervisor contact</Trans></div>
                      <div className={`${css.info}`}>{toPhoneNumberMasked(employer.supervisor_contact_no)}</div>
                    </div>
                    {/* Supervisor email */}
                    <div className={`${css.field}`}>
                      <div className={`${css.label}`}><Trans ns='applicants' i18nKey='label.supervisor_email'>Supervisor email</Trans></div>
                      <div className={`${css.info}`}>{employer.supervisor_email}</div>
                    </div>
                    {/* Business Name */}
                    <div className={`${css.field}`}>
                      <div className={`${css.label}`}><Trans ns='applicants' i18nKey='label.business_name'>Business Name</Trans></div>
                      <div className={`${css.info}`}>{employer.business_name}</div>
                    </div>
                  </>}

                  {/* Income */}
                  <div className={`${css.field}`}>
                    <div className={`${css.label}`}><Trans ns='applicants' i18nKey='label.income'>Income</Trans></div>
                    <div className={`${css.info}`}>{employer.income}</div>
                  </div>
                  {/* Address Line 1 */}
                  <div className={`${css.field}`}>
                    <div className={`${css.label}`}><Trans ns='applicants' i18nKey='label.address_line_1'>Address Line 1</Trans></div>
                    <div className={`${css.info}`}>{employer.address_line_1}</div>
                  </div>
                  {/* Address Line 2 */}
                  <div className={`${css.field}`}>
                    <div className={`${css.label}`}><Trans ns='applicants' i18nKey='label.address_line_2'>Address Line 2</Trans></div>
                    <div className={`${css.info}`}>{employer.address_line_2}</div>
                  </div>
                  {/* City */}
                  <div className={`${css.field}`}>
                    <div className={`${css.label}`}><Trans ns='applicants' i18nKey='label.city'>City</Trans></div>
                    <div className={`${css.info}`}>{employer.city}</div>
                  </div>
                  {/* State Code */}
                  <div className={`${css.field}`}>
                    <div className={`${css.label}`}><Trans ns='applicants' i18nKey='label.state_code'>State Code</Trans></div>
                    <div className={`${css.info}`}>{employer.state_code}</div>
                  </div>
                  {/* Zip */}
                  <div className={`${css.field}`}>
                    <div className={`${css.label}`}><Trans ns='applicants' i18nKey='label.zip_code'>Zip</Trans></div>
                    <div className={`${css.info}`}>{employer.zip_code}</div>
                  </div>
                  {/* Position */}
                  <div className={`${css.field}`}>
                    <div className={`${css.label}`}><Trans ns='applicants' i18nKey='label.employer_position'>Position</Trans></div>
                    <div className={`${css.info}`}>{employer.position}</div>
                  </div>
                  {/* Reason */}
                  {(index > 0) && <div className={`${css.field}`}>
                    <div className={`${css.label}`}><Trans ns='applicants' i18nKey='label.reason_for_leaving'>Reason</Trans></div>
                    <div className={`${css.info}`}>{employer.reason_for_leaving}</div>
                  </div>}
                  {/* Income Frequency */}
                  <div className={`${css.field}`}>
                    <div className={`${css.label}`}><Trans ns='applicants' i18nKey='label.income_frequency'>Income Frequency</Trans></div>
                    <div className={`${css.info}`}>{this.capitalise(employer.income_frequency)}</div>
                  </div>
                  {/* Employer Type */}
                  <div className={`${css.field}`}>
                    <div className={`${css.label}`}><Trans ns='applicants' i18nKey='label.employer_type'>Employer Type</Trans></div>
                    <div className={`${css.info}`}>{this.capitalise(employer.type)}</div>
                  </div>
                  {/* Self Employed? */}
                  <div className={`${css.field}`}>
                    <div className={`${css.label}`}><Trans ns='applicants' i18nKey='label.self_employed'>Self Employed?</Trans></div>
                    <div className={`${css.info}`}>{(employer.self_employed) ? 'Yes' : 'No'}</div>
                  </div>
                </React.Fragment>
              )
            })}
          </div>

          <hr className={css.divider} />

          {/* 3. OTHER INCOMES */}
          <div className={css.other_incomes}>

            {!applicant_other_incomes.length &&
              <React.Fragment>
                <div className={css.heading}>
                  <h4>Other Income</h4>
                </div>
                <div>No Other Income</div>
              </React.Fragment>
            }

            {applicant_other_incomes.map((other, index) => {
              return (
                <React.Fragment key={index}>
                  <div className={css.heading}>
                    <h4>Other Income {index + 1}</h4>
                  </div>
                  {/* Source */}
                  <div className={`${css.field}`}>
                    <div className={`${css.label}`}><Trans ns='applicants' i18nKey='label.source'>Source</Trans></div>
                    <div className={`${css.info}`}>{other.source}</div>
                  </div>
                  {/* Amount */}
                  <div className={`${css.field}`}>
                    <div className={`${css.label}`}><Trans ns='applicants' i18nKey='label.amount'>Amount</Trans></div>
                    <div className={`${css.info}`}>{moneyFormat(other.amount)}</div>
                  </div>
                  {/* Frequency */}
                  <div className={`${css.field}`}>
                    <div className={`${css.label}`}><Trans ns='applicants' i18nKey='label.income_frequency'>Frequency</Trans></div>
                    <div className={`${css.info}`}>{this.capitalise(other.income_frequency)}</div>
                  </div>
                </React.Fragment>
              )
            })}
          </div>

          <hr className={css.divider} />

          {/* 3. PETS */}
          <div className={css.pets}>

            {!applicant_pets.length &&
              <React.Fragment>
                <div className={css.heading}>
                  <h4>Pet</h4>
                </div>
                <div>No Pet</div>
              </React.Fragment>
            }

            {applicant_pets.map((pet, index) => {
              return (
                <React.Fragment key={index}>
                  <div className={css.heading}>
                    <h4>Pet {index + 1}</h4>
                  </div>
                  {/* Type */}
                  <div className={`${css.field}`}>
                    <div className={`${css.label}`}><Trans ns='applicants' i18nKey='label.pet_breed'>Breed</Trans></div>
                    <div className={`${css.info}`}>{pet.type}</div>
                  </div>
                  {/* Name */}
                  <div className={`${css.field}`}>
                    <div className={`${css.label}`}><Trans ns='applicants' i18nKey='label.pet_name'>Name</Trans></div>
                    <div className={`${css.info}`}>{pet.name}</div>
                  </div>
                  {/* Coloration */}
                  <div className={`${css.field}`}>
                    <div className={`${css.label}`}><Trans ns='applicants' i18nKey='label.pet_coloration'>Coloration</Trans></div>
                    <div className={`${css.info}`}>{pet.coloration}</div>
                  </div>
                  {/* Age */}
                  <div className={`${css.field}`}>
                    <div className={`${css.label}`}><Trans ns='applicants' i18nKey='label.pet_age'>Age</Trans></div>
                    <div className={`${css.info}`}>{pet.age}</div>
                  </div>
                  {/* Weight */}
                  <div className={`${css.field}`}>
                    <div className={`${css.label}`}><Trans ns='applicants' i18nKey='label.pet_weight'>Weight</Trans></div>
                    <div className={`${css.info}`}>{pet.weight}</div>
                  </div>
                  {/* Weight Unit */}
                  <div className={`${css.field}`}>
                    <div className={`${css.label}`}><Trans ns='applicants' i18nKey='label.pet_weight_unit'>Weight Unit</Trans></div>
                    <div className={`${css.info}`}>{pet.weight_unit.toLowerCase()}</div>
                  </div>
                  {/* Sex */}
                  <div className={`${css.field}`}>
                    <div className={`${css.label}`}><Trans ns='applicants' i18nKey='label.pet_sex'>Sex</Trans></div>
                    <div className={`${css.info}`}>{this.capitalise(pet.sex)}</div>
                  </div>
                </React.Fragment>
              )
            })}
          </div>

          <hr className={css.divider} />

          {/* 4. VEHICLES */}
          <div className={css.vehicles}>

            {!applicant_vehicles.length &&
              <React.Fragment>
                <div className={css.heading}>
                  <h4>Vehicle</h4>
                </div>
                <div>No Vehicle</div>
              </React.Fragment>
            }


            {applicant_vehicles.map((vehicle, index) => {
              return (
                <React.Fragment key={index}>
                  <div className={css.heading}>
                    <h4>Vehicle {index + 1}</h4>
                  </div>
                  {/* Make */}
                  <div className={`${css.field}`}>
                    <div className={`${css.label}`}><Trans ns='applicants' i18nKey='label.vehicle_make'>Make</Trans></div>
                    <div className={`${css.info}`}>{vehicle.make}</div>
                  </div>
                  {/* Model */}
                  <div className={`${css.field}`}>
                    <div className={`${css.label}`}><Trans ns='applicants' i18nKey='label.vehicle_model'>Model</Trans></div>
                    <div className={`${css.info}`}>{vehicle.model}</div>
                  </div>
                  {/* Year */}
                  <div className={`${css.field}`}>
                    <div className={`${css.label}`}><Trans ns='applicants' i18nKey='label.vehicle_year'>Year</Trans></div>
                    <div className={`${css.info}`}>{vehicle.year}</div>
                  </div>
                  {/* License Plate Number */}
                  <div className={`${css.field}`}>
                    <div className={`${css.label}`}><Trans ns='applicants' i18nKey='label.license_plate_number'>License Plate Number</Trans></div>
                    <div className={`${css.info}`}>{vehicle.license_plate_number}</div>
                  </div>
                </React.Fragment>
              )
            })}
          </div>

          <hr className={css.divider} />

          {/* 4. REFERENCES */}
          <div className={css.references}>
            {applicant_references.map((reference, index) => {
              return (
                <React.Fragment key={index}>
                  <div className={css.heading}>
                    <h4>Reference {index + 1}</h4>
                  </div>
                  {/* Name */}
                  <div className={`${css.field}`}>
                    <div className={`${css.label}`}><Trans ns='applicants' i18nKey='label.reference_name'>Name</Trans></div>
                    <div className={`${css.info}`}>{reference.first_name} {reference.last_name}</div>
                  </div>
                  {/* Contact */}
                  <div className={`${css.field}`}>
                    <div className={`${css.label}`}><Trans ns='applicants' i18nKey='label.contact_no'>Contact</Trans></div>
                    <div className={`${css.info}`}>{toPhoneNumberMasked(reference.contact_no)}</div>
                  </div>
                  {/* Email */}
                  <div className={`${css.field}`}>
                    <div className={`${css.label}`}><Trans ns='applicants' i18nKey='label.email'>Email</Trans></div>
                    <div className={`${css.info}`}>{reference.email}</div>
                  </div>
                  {/* Relationship */}
                  <div className={`${css.field}`}>
                    <div className={`${css.label}`}><Trans ns='applicants' i18nKey='label.relationship'>Relationship</Trans></div>
                    <div className={`${css.info}`}>{this.capitalise(reference.relationship)}</div>
                  </div>
                </React.Fragment>
              )
            })}
          </div>

          <hr className={css.divider} />

          {/* 4. EMERGENCY CONTACTS */}
          <div className={css.emergency_contacts}>
            {applicant_emergency_contacts.map((emContact, index) => {
              return (
                <React.Fragment key={index}>
                  <div className={css.heading}>
                    <h4>Emergency Contact {index + 1}</h4>
                  </div>
                  {/* Name */}
                  <div className={`${css.field}`}>
                    <div className={`${css.label}`}><Trans ns='applicants' i18nKey='label.emergency_contact_name'>Name</Trans></div>
                    <div className={`${css.info}`}>{emContact.first_name} {emContact.last_name}</div>
                  </div>
                  {/* Contact */}
                  <div className={`${css.field}`}>
                    <div className={`${css.label}`}><Trans ns='applicants' i18nKey='label.contact_no'>Contact</Trans></div>
                    <div className={`${css.info}`}>{toPhoneNumberMasked(emContact.contact_no)}</div>
                  </div>
                  {/* Email */}
                  <div className={`${css.field}`}>
                    <div className={`${css.label}`}><Trans ns='applicants' i18nKey='label.email'>Email</Trans></div>
                    <div className={`${css.info}`}>{emContact.email}</div>
                  </div>
                  {/* Address Line 1 */}
                  <div className={`${css.field}`}>
                    <div className={`${css.label}`}><Trans ns='applicants' i18nKey='label.address_line_1'>Address Line 1</Trans></div>
                    <div className={`${css.info}`}>{emContact.address_line_1}</div>
                  </div>
                  {/* Address Line 2 */}
                  <div className={`${css.field}`}>
                    <div className={`${css.label}`}><Trans ns='applicants' i18nKey='label.address_line_2'>Address Line 2</Trans></div>
                    <div className={`${css.info}`}>{emContact.address_line_2}</div>
                  </div>
                  {/* City */}
                  <div className={`${css.field}`}>
                    <div className={`${css.label}`}><Trans ns='applicants' i18nKey='label.city'>City</Trans></div>
                    <div className={`${css.info}`}>{emContact.city}</div>
                  </div>
                  {/* State Code */}
                  <div className={`${css.field}`}>
                    <div className={`${css.label}`}><Trans ns='applicants' i18nKey='label.state_code'>State Code</Trans></div>
                    <div className={`${css.info}`}>{emContact.state_code}</div>
                  </div>
                  {/* Zip */}
                  <div className={`${css.field}`}>
                    <div className={`${css.label}`}><Trans ns='applicants' i18nKey='label.zip_code'>Zip</Trans></div>
                    <div className={`${css.info}`}>{emContact.zip_code}</div>
                  </div>
                  {/* Relationship */}
                  <div className={`${css.field}`}>
                    <div className={`${css.label}`}><Trans ns='applicants' i18nKey='label.relationship'>Relationship</Trans></div>
                    <div className={`${css.info}`}>{this.capitalise(emContact.relationship)}</div>
                  </div>
                </React.Fragment>
              )
            })}
          </div>

          <hr className={css.divider} />

          {/* SIGNATURE */}
          {(signature && signature.signature_data) &&
            <div className={css.signature_box}>
              <div className={css.signature_content}>
                <Trans ns='application' i18nKey="label.application_consent_to_share_data">I hereby give my consent to share my data for landlord and employer verifications</Trans>
                {/* common component */}
                <Esignature signature_data={{
                  type: signature.signature_data.type,
                  full_name: signature.full_name,
                  content: signature.signature_data.content,
                  signature_date: signature.signature_date,
                  signature_ip: signature.signature_ip
                }} />
              </div>
            </div>
          }

        </div>
      </React.Fragment>
    )
  }
}

export default compose(
  withTranslation('applicants')
)(ApplicantInfo)