import React from 'react'
import { compose } from 'recompose';
import { Trans, withTranslation } from 'react-i18next';
import {
  Box,
  Grid,
  Typography
} from '@material-ui/core'

class PropertyNotFound extends React.Component {
  render() {
    let { errorKey } = this.props;
    errorKey = typeof errorKey === 'undefined' ? 'property.notfound' : `property.${errorKey}`

    return (
      <div className="property-notsubscribed-wrapper">
        <Box>
          <Grid container justify="center" >
            <Grid item md={12}>
              <Typography component="h1" variant="h5">
                <Trans ns='property.notfound' i18nKey={`${errorKey}.heading`}>{errorKey}</Trans>
              </Typography>
            </Grid>
            <Grid item md={12}>
              <Typography >
                <Trans ns='property.notfound' i18nKey={`${errorKey}.description`}>
                  The service you are looking for is not available.
                  Please contact your property office for further information.
                </Trans>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </div >
    )
  }
}

export default compose(
  withTranslation('property.notfound')
)(PropertyNotFound);