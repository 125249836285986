import React, { useRef, useEffect } from 'react'

/**
 * Methods available
 * - clearScreen
 * - setColor
 * - getPixelsCount
 * - getImage
 * 
 * 
 * <Canvas ref={this.canvasRef} className={'myClassName'} />
 * 
 * this.canvasRef = React.createRef();
 * 
 * this.canvasRef.current.setColor('red')
 */
const Canvas = React.forwardRef((props, ref) => {
  const canvasRef = useRef(null)
  const [color, setColor] = React.useState('blue');
  const thickness = 3;

  React.useImperativeHandle(ref, () => ({
    clearScreen() {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");

      ctx.clearRect(0, 0, canvas.width, canvas.height);
    },
    setColor(color) {
      setColor(color)
    },
    getPixelsCount() {
      return calculatePixels()
    },
    getImage() {
      return canvasRef.current.toDataURL("image/png");
    }
  }));


  useEffect(() => {
    const canvas = canvasRef.current

    const mousemove = (e) => {
      findxy('move', e)
    };
    const mousedown = (e) => {
      findxy('down', e)
    };
    const mouseup = (e) => {
      findxy('up', e)
    };
    const mouseout = (e) => {
      findxy('out', e)
    };
    const touchstart = (e) => {
      e.preventDefault()
      var touch = e.touches[0];
      var mouseEvent = new MouseEvent("mousedown", {
        clientX: touch.clientX,
        clientY: touch.clientY
      });
      canvas.dispatchEvent(mouseEvent);
    }
    const touchend = (e) => {
      e.preventDefault()
      var mouseEvent = new MouseEvent("mouseup", {});
      canvas.dispatchEvent(mouseEvent);
    }
    const touchmove = (e) => {
      e.preventDefault()
      var touch = e.touches[0];
      var mouseEvent = new MouseEvent("mousemove", {
        clientX: touch.clientX,
        clientY: touch.clientY
      });
      canvas.dispatchEvent(mouseEvent);
    }

    // Set up touch events for mobile, etc
    canvas.addEventListener("touchstart", touchstart, false);
    canvas.addEventListener("touchend", touchend, false);
    canvas.addEventListener("touchmove", touchmove, false);

    canvas.addEventListener("mousemove", mousemove, false);
    canvas.addEventListener("touchmove", mousemove, false);
    canvas.addEventListener("mousedown", mousedown, false);
    canvas.addEventListener("mouseup", mouseup, false);
    canvas.addEventListener("mouseout", mouseout, false);

    return () => {
      console.log('Remove Listeners')
      canvas.removeEventListener("touchstart", touchstart, false);
      canvas.removeEventListener("touchend", touchend, false);
      canvas.removeEventListener("touchmove", touchmove, false);

      canvas.removeEventListener("mousemove", mousemove, false);
      canvas.removeEventListener("touchmove", mousemove, false);
      canvas.removeEventListener("mousedown", mousedown, false);
      canvas.removeEventListener("mouseup", mouseup, false);
      canvas.removeEventListener("mouseout", mouseout, false);
    }
    // eslint-disable-next-line
  }, [color])

  let flag = false,
    prevX = 0,
    currX = 0,
    prevY = 0,
    currY = 0,
    dot_flag = false;

  const getMousePos = (canvas, event) => {
    var rect = canvas.getBoundingClientRect();

    const eventX = (event.touches && event.touches[0] && event.touches[0].clientX) ? event.touches[0].clientX : event.clientX;
    const eventY = (event.touches && event.touches[0] && event.touches[0].clientY) ? event.touches[0].clientY : event.clientY;

    return {
      x: (eventX - rect.left) * (canvas.width / canvas.getBoundingClientRect().width),
      y: (eventY - rect.top) * (canvas.height / canvas.getBoundingClientRect().height),
    };
  }

  const findxy = (direction, event) => {
    const canvas = canvasRef.current;
    window.canvas = canvas
    window.clickEvent = event
    const ctx = canvas.getContext("2d");

    if (direction === 'down') {
      prevX = currX;
      prevY = currY;
      const pos = getMousePos(canvas, event);
      currX = pos.x
      currY = pos.y

      flag = true;
      dot_flag = true;
      if (dot_flag) {
        ctx.beginPath();
        ctx.fillStyle = color;
        ctx.fillRect(currX, currY, thickness, thickness);
        ctx.closePath();
        dot_flag = false;
      }
    }
    if (direction === 'up' || direction === "out") {
      flag = false;
    }
    if (direction === 'move') {
      if (flag) {
        prevX = currX;
        prevY = currY;
        const pos = getMousePos(canvas, event);
        currX = pos.x
        currY = pos.y
        draw();
      }
    }
  }

  const draw = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    ctx.beginPath();
    ctx.moveTo(prevX, prevY);
    ctx.lineTo(currX, currY);
    ctx.strokeStyle = color;
    ctx.lineWidth = thickness;
    ctx.stroke();
    ctx.closePath();
  }

  const calculatePixels = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    var sw = canvas.width
    var sh = canvas.height
    var d = ctx.getImageData(0, 0, sw, sh);

    var len = d.data.length;
    var pixels = 0;
    for (var i = 0; i < len; i++) {
      if (!d.data[i]) {
      } else if (d.data[i]) {
        pixels++;
        if (pixels > 500) {
          // break here, lets stop looping if its more than 500 for now
          break;
        }
      }
    }

    return pixels;
  }

  const canvasWidth = '100%';
  const canvasHeight = '100%';

  const style = {
    width: canvasWidth,
    height: canvasHeight
  }

  return <>
    <canvas
      ref={canvasRef}
      style={style}
      className={`${props.className ? props.className : ''}`} />
  </>
})

export default Canvas;

export const fontFamilies = [
  'Hurricane',
  'Satisfy',
  'Caveat',
  'Homemade Apple',
  'Vujahday Script',
  'Cedarville Cursive'
];
