import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { AuthConsumer } from './AppContext'
// bootstrap
// import 'bootstrap/dist/css/bootstrap.min.css';
import PropertyNotFound from '../components/Shared/PropertyNotFound'

// Redirect to / if they are logged in
const AuthRoute = ({ component: Component, name, ...rest }) => (
  <AuthConsumer>
    {({ isAuth, portalData }) => {
      // default after login
      let redirectPath = '/tenant';

      // console.log('AuthRoute');
      if (isAuth) {
        const redirect = new URLSearchParams(rest.location.search).get('redirect');
        if (redirect) {
          redirectPath = redirect
        }
      }

      return (
        <Route
          render={props => {
            const root = document.getElementById('resport-resident-app')
            const routePattern = props.match.path.replace(/([^a-z])/ig, '-').replace(/([-]{2,})/g, '-').replace(/^-+|-+$/g, '')
            // const routeUrl = props.match.url.replace(/([^a-z])/ig, '-').replace(/([-]{2,})/g, '-').replace(/^-+|-+$/g, '')

            root.setAttribute('data-route-pattern', routePattern);
            // root.setAttribute('data-route-url', routeUrl);

            // If property not active
            if (portalData.subscription_active === false) {
              return <PropertyNotFound errorKey="subscription_inactive" />
            }

            // If property soft deleted
            if (portalData.property_deleted) {
              return <PropertyNotFound errorKey="property_deleted" />
            }

            // token expire issue
            if (name === 'lease_impersonate') {
              return <Component {...props} />
            }

            return !isAuth ? <Component {...props} /> : <Redirect to={redirectPath} />
          }
          }
          {...rest}
        />
      )
    }}
  </AuthConsumer>
)

export { AuthRoute }
