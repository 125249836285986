import React from 'react'
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { preLoadColumnSettings } from '../../store/columnsReducer';
import { toQuery } from '../../utils/searchParams';
import {
  LicenseInfo,
  DataGridPro,
  GridColumnMenuContainer,
  HideGridColMenuItem,
  GridColumnsMenuItem,
  useGridApiContext
} from '@mui/x-data-grid-pro';

import {
  FormControl,
  FormGroup,
  FormControlLabel,
  Switch,
  Grid,
  Box
} from '@material-ui/core';

LicenseInfo.setLicenseKey(process.env.REACT_APP_XGRID_LICENSE_KEY);

/**
 * per page constant
 */
const ROWS_PER_PAGE_OPTIONS = [15, 30, 50, 100]

/**
 * XGridTable
 */
class XGridTable extends React.Component {
  constructor(props) {
    super(props);
    this.apiRef = React.createRef();
  }


  /**
   * Callback fired when a click event comes from a column header element.
   * @param {*} params GridColumnHeaderParams
   */
  handleColumnHeaderClick = (params, event) => {
    // todo - do not sort on resize
    const classList = event.target?.className ?? '';
    if (typeof classList === 'string') {
      console.log('Should Sort', classList.includes("MuiDataGrid-columnHeaderTitle"))
      if (!classList.includes("MuiDataGrid-columnHeaderTitle")) {
        return false
      }
    }

    const sortable = ((typeof params.colDef.sortable === 'undefined') || (typeof params.colDef.sortable !== 'undefined' && params.colDef.sortable === true))
    if (!sortable) {
      return false
    }

    if (params.field === 'action') {
      return false
    }

    // call callback if available
    if (typeof this.props.handleSort !== 'undefined') {
      return this.props.handleSort(params.field)
    }

    // do something else..
  }

  /**
   * Callback fired when the width of a column is changed.
   * @param {*} params GridColumnResizeParams
   */
  handleColumnWidthChange = (params) => {
    const { dispatch, tableId, columns } = this.props

    const value = {}
    columns.map(item => value[item.field] = item.width)
    value[params.colDef.field] = params.width

    dispatch({
      type: 'UPDATE_COLUMNWIDTH_SETTINGS',
      payload: {
        key: tableId,
        value
      }
    })
  }

  /**
   * Callback fired when a column visibility changes.
   * @param {*} params 
   */
  handleColumnVisibilityChange = (params) => {
    const allColumns = this.apiRef.current.getAllColumns()

    let nonHideableColumns = []
    allColumns.map(column => {
      if (typeof column.hideable !== 'undefined' && column.hideable === false) {
        if (!nonHideableColumns.includes(column.field)) {
          nonHideableColumns.push(column.field)
        }
      }
      return false
    })

    if (nonHideableColumns.length === 0) {
      nonHideableColumns = [allColumns[0].field]
    }

    const visibleColumns = this.apiRef.current.getVisibleColumns().map(visibleColumn => visibleColumn.field)

    // trying to hide non hideable columns, prevent that
    if (!params.isVisible) {
      if (nonHideableColumns.includes(params.field)) {
        this.apiRef.current.setColumnVisibility(params.field, true)
        return false
      }
    }

    // trying to hide checkbox columns, prevent that
    if (params.field === '__check__') {
      if (!params.isVisible) {
        this.apiRef.current.setColumnVisibility(params.field, true)
        return false
      }
    }


    if (params.isVisible) {
      // unhide
      if (!visibleColumns.includes(params.field)) {
        visibleColumns.push(params.field)
      }
    } else {
      // hide
      if (visibleColumns.includes(params.field)) {
        const index = visibleColumns.indexOf(params.field)
        if (index > -1) {
          visibleColumns.splice(visibleColumns.indexOf(params.field), 1)
        }
      }
    }

    const hiddenColumns = []
    allColumns.map(column => {
      if (!visibleColumns.includes(column.field)) {
        if (!hiddenColumns.includes(column.field)) {
          hiddenColumns.push(column.field)
        }
      }
      return false
    })

    const {
      dispatch,
      table_preference,
      tableId
    } = this.props

    const { hidden } = table_preference

    const storedHiddenColumns = hidden[tableId] ? hidden[tableId] : []

    const isequal = hiddenColumns.length === storedHiddenColumns.length && hiddenColumns.every((value, index) => value === storedHiddenColumns[index])

    if (isequal) {
      return false
    }

    dispatch({
      type: 'UPDATE_HIDDENCOLUMN_SETTINGS',
      payload: {
        key: tableId,
        value: hiddenColumns
      }
    })
  }


  render() {
    const {
      columns,
      table_preference,
      tableId,
      t
    } = this.props

    const stateColumns = columns;
    // const stateColumns = this.state.columns;

    /**
     * columns are in state
     * initially empty array
     */
    if (stateColumns.length === 0) {
      return '';
    }


    if (!tableId) {
      console.error('tableId missing')
    }

    const { width, hidden } = table_preference

    // set width
    const tableWidth = width[tableId] ? width[tableId] : {}
    columns.map(item => item['width'] = tableWidth[item.field] ? tableWidth[item.field] : item.minWidth)

    // set hidden
    const tableHiddenColumns = hidden[tableId] ? hidden[tableId] : []
    columns.map(item => item['hide'] = tableHiddenColumns.includes(item.field))

    let desktop_header_height = 0;
    let mobile_header_height = 0;

    const desktop_header = document.getElementById('desktop_header');
    if (desktop_header) {
      desktop_header_height = desktop_header.offsetHeight;
    }

    const mobile_header = document.getElementById('mobile_header');
    if (mobile_header) {
      mobile_header_height = mobile_header.offsetHeight;
    }

    const window_height = window.innerHeight;

    const device_height = Math.min(window_height - (desktop_header_height || mobile_header_height),
      (64 + (this.props.hideFooter ? 0 : 53) + (this.props.rows.length * 53)));  // changed from 109 to 165 to accomodate action popup

    const parent_height = (this.props.rows.length === 0) ? 200 : device_height;

    // do not remove for now
    console.table({
      tableId: this.props.tableId,
      desktop_header_height,
      mobile_header_height,
      "window_height (Math.min)": window_height,
      device_height,
      parent_height
    })

    return (
      <React.Fragment>
        <div style={{ height: parent_height }} className='datagridpro'>
          <DataGridPro
            apiRef={{ current: this.apiRef.current ? this.apiRef.current : {} }}
            className={`${tableId}-listing x-grid-table-listing`} // classname to add on the outer container
            // autoHeight // grid height is dynamic and follow the number of rows in the grid // had to comment this to make mui sticky header work
            getRowSpacing={() => { return { top: 3 } }}
            rowHeight={50}
            disableColumnReorder // reordering columns is disabled
            disableSelectionOnClick // selection on click on a row or cell is disabled
            sortingOrder={['desc', 'asc']} // order of the sorting sequence
            onColumnHeaderClick={this.handleColumnHeaderClick}
            onColumnWidthChange={this.handleColumnWidthChange}
            onColumnVisibilityChange={this.handleColumnVisibilityChange}
            pagination={this.props.rows.length > 0}
            components={{
              ColumnMenu: ColumnMenuComponent,
              ColumnsPanel: ColumnsPanelComponent
            }}
            localeText={{
              columnMenuShowColumns: t('x_grid:columnMenuShowColumns', 'Show columns'),
              columnHeaderSortIconLabel: t('x_grid:columnHeaderSortIconLabel', 'Sort')
            }}
            {...this.props}
            columns={stateColumns}
          />
        </div>
      </React.Fragment>
    )
  }
}

/**
 * custom column panel to show only hideable colums
 * @returns ColumnsPanelComponent
 */
function ColumnsPanelComponent() {
  // const props = useGridSlotComponentProps();
  // const { apiRef } = props
  const apiRef = useGridApiContext();
  const allColumns = apiRef.current.getAllColumns()
  const visibleColumns = apiRef.current.getVisibleColumns().map(visibleColumn => visibleColumn.field)

  return (
    <React.Fragment>
      <div className="x-grid-column-panel">
        <Box p={3} className='filter-area'>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <FormControl component="fieldset">
                <FormGroup>
                  {allColumns.map((col, index) => {
                    const field = col.field
                    const visible = visibleColumns.includes(field)
                    let disabled = false
                    let show = true
                    if (typeof col.hideable !== 'undefined' && col.hideable === false) {
                      disabled = true
                    }
                    if (['__check__', 'action'].includes(field)) {
                      show = false
                    }
                    return (
                      <React.Fragment key={index}>
                        {show && <FormControlLabel
                          disabled={disabled}
                          control={<Switch
                            size="small"
                            color="primary"
                            checked={visible}
                            onChange={() => apiRef.current.setColumnVisibility(field, !visible)}
                            name={col.headerName} />}
                          label={col.headerName} />}
                      </React.Fragment>
                    )
                  })}
                </FormGroup>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </div>
    </React.Fragment>
  )
}

/**
 * custom menu component to only show "Hide" and "Show columns" menu
 * @param {*} props 
 * @returns ColumnMenuComponent
 */
function ColumnMenuComponent(props) {
  const { hideMenu, currentColumn, ...other } = props;
  const showHideGridColMenuItem = (typeof currentColumn.hideable === 'undefined' || (typeof currentColumn.hideable !== 'undefined' && currentColumn.hideable === true))

  return (
    <React.Fragment>
      <div className="x-grid-menu">
        <GridColumnMenuContainer hideMenu={hideMenu} currentColumn={currentColumn} {...other}>
          {showHideGridColMenuItem && <HideGridColMenuItem onClick={hideMenu} column={currentColumn} />}
          <GridColumnsMenuItem onClick={hideMenu} column={currentColumn} />
        </GridColumnMenuContainer>
      </div>
    </React.Fragment>
  )
}


/**
 * Call this function to get sort props for server side sort
 * @param {*} handleSort function
 * @param {*} filterParams filter params from state
 * @returns sort props
 */
export const getServerSideSortProps = (handleSort, filterParams) => {
  return {
    sortingMode: 'server',
    handleSort: handleSort,
    sortModel: [
      {
        field: filterParams.orderBy,
        sort: filterParams.sortBy,
      }
    ]
  }
}


/**
 * Call this function to get pagination props for server side pagination
 * @param {*} meta pagination meta
 * @param {*} filterParams filter params from state
 * @param {*} props component props
 * @param {*} urlChanged function
 * @returns pagination props
 */
export const getServerSidePaginationProps = (meta, filterParams, props, urlChanged) => {
  return {
    paginationMode: 'server',
    pageSize: meta.per_page,
    rowCount: meta.total ? meta.total : 0,
    page: meta.current_page ? meta.current_page - 1 : 1,
    rowsPerPageOptions: ROWS_PER_PAGE_OPTIONS,
    onPageChange: pageNum => {
      const params = { page: pageNum + 1 }
      const newParams = { ...filterParams, ...params }

      props.history.push(toQuery(newParams));
      urlChanged();
    },
    onPageSizeChange: newPageSize => {
      const params = { page: 1, perPage: newPageSize }
      const newParams = { ...filterParams, ...params }

      props.history.push(toQuery(newParams));
      urlChanged();
    }
  }
}

/**
 * this function in used in client side filter q
 * @param {*} value 
 * @returns string without special characters
 * ___
 * - [Reference](https://material-ui.com/components/data-grid/filtering/#quick-filter)
 */
export const escapeRegExp = value => value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');

/** 
 * The `XGridTable` is a wrapper for the material-ui's XGrid component.
 * 
 * ### Client side listing
 * ```
 * <XGridTable
 *  tableId="x_grid_client"
 *  loading={loading}
 *  rows={rows}
 *  columns={columns} />
 * ```
 *
 * ### Server side listing
 * ```
 * <XGridTable
 *  tableId="x_grid_server"
 *  loading={loading}
 *  rows={data}
 *  columns={columns}
 *  {...getServerSideSortProps(this.handleSort, filterParams)}
 *  {...getServerSidePaginationProps(meta, filterParams, this.props, this.urlChanged)} />
 * ```
 *
 * ___
 *  If you wish to add the translation, you can use translation keys from:
 * -   [localeTextConstants.ts](https://github.com/mui-org/material-ui-x/blob/HEAD/packages/grid/_modules_/grid/constants/localeTextConstants.ts)
 * ___
 * Demos:
 *
 * - [XGrid](https://material-ui.com/components/data-grid/)
 * 
 * API:
 *
 * - [XGrid API](https://material-ui.com/api/data-grid/x-grid/)
 */
export default compose(
  withTranslation('x_grid'),
  connect(state => ({
    table_preference: state.columns.data ? state.columns.data : null
  }), dispatch => {
    dispatch(preLoadColumnSettings());
    return { dispatch }
  })
)(XGridTable)