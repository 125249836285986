import axios from 'axios'
import config from './config';
// import { toast } from 'react-toastify';

const http = axios.create({
  baseURL: config.endPoint,
  headers: {
    // "content-type": "application/x-www-form-urlencoded"
    "content-type": "application/json"
  }
});

http.interceptors.request.use(config => {
  if (localStorage.user) {
    const user = JSON.parse(localStorage.user);
    if (user && user.token) {
      if (typeof config.headers['token'] === 'undefined') { // for lease impersonate issue
        config.headers['token'] = user.token;
      }
    }
  }
  return config;
})

http.interceptors.response.use(response => {
  // console.log('SUCCESS', response);
  return response;
}, error => {
  let response = error.response;
  // console.log('FAILED', JSON.stringify(error), response);
  if (!response) {
    response = {
      status: 0,
      data: error.message
    }
  }

  if (localStorage.user && response.status === 401) {
    /* toast.error('Your session expired. You will now be redirected to login page', {
      autoClose: 10000,
      onClick: sessionExpiredCallback,
      onClose: sessionExpiredCallback
    }); */
    sessionExpiredCallback();
  }
  if (localStorage.user && response.status === 403) {
    console.log('403 API call.')
  }

  return Promise.reject(response);
});

const sessionExpiredCallback = () => {
  console.log('sessionExpiredCallback called')
  localStorage.removeItem('user');

  // when website opening from react native mobile app, send event to mobile for logout
  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(JSON.stringify({ action: 'logout' }));
  }
  window.location.reload();
};

export default http;
