import React, { Component, Fragment } from 'react'
import { Button } from '@material-ui/core';

class FacebookLogin extends Component {

  constructor(props) {
    super(props)
    this.state = {
      loading: true,
    }
  }


  componentDidMount() {
    const appId = this.props.appId;
    // load the js file
    const scriptTag = document.createElement("script");
    scriptTag.async = true;
    scriptTag.defer = true;
    scriptTag.crossorigin = 'anonymous';
    scriptTag.src = `https://connect.facebook.net/en_GB/sdk.js#xfbml=1&version=v7.0&appId=${appId}&autoLogAppEvents=1`;
    document.head.appendChild(scriptTag);
    scriptTag.onload = () => {
      this.setState(prevState => {
        prevState.loading = false;
        return prevState;
      })
    }
  }

  onClick =  () => {
    window.FB.login((response) => {
      console.log(response)
      if (response.authResponse && response.status === 'connected') {
        // login
        const type = 'facebook';
        const info = {
          access_token: response.authResponse.accessToken,
          user_id: response.authResponse.userID
        }

        this.props.onCallback(type, info);
      }
    });
  }

  render() {
    const { onCallback, appId, ...buttonProps } = this.props

    return (
      <Fragment>
        <Button hidden={this.state.loading} onClick={this.onClick} variant='contained' color='primary'  {...buttonProps}>{this.props.label ? this.props.label : 'Login with Facebook'}</Button>
      </Fragment>
    )
  }
}


export default FacebookLogin;