import React from "react";

import css from '../Applicant/ApplicationForm/Application.module.css'

import DateFormat from "../../utils/DateFormat";

import {
  Grid,
  IconButton,
  Link
} from '@material-ui/core'
import VisibilityIcon from '@material-ui/icons/Visibility';

class ApplicantFiles extends React.Component {
  render() {
    const { files } = this.props

    return (
      <React.Fragment>
        <Grid container className={css['uploaded-files-section']}>
          <Grid item>
            {files.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <div className={css['uploaded-file']}>
                    {/* File name */}
                    <span className={css['file-name']}>{item.file_name} </span>
                    {/* Date - Time */}
                    {item.created_at && <span className={css['date-time']}> - <DateFormat format="m/d/Y h:i A">{item.created_at}</DateFormat></span>}
                    {/* View */}
                    <Link className={css['view-icon']} href={item.file_path} target="_blank" rel="noopener noreferrer">
                      <IconButton className={css['file-view-icon']}>
                        <VisibilityIcon />
                      </IconButton>
                    </Link>
                  </div>
                </React.Fragment>
              )
            })}
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }
}

export default ApplicantFiles