import React from 'react'
import { AuthContext } from '../../utils/AppContext'
import { Trans, withTranslation } from 'react-i18next'
import { compose } from 'recompose'

import http from '../../utils/http';
import { getQuery } from '../../utils/searchParams';
import css from './ApplicantSign.module.css'
import SignatureDialog from '../Applicant/ApplicationForm/Form/SignatureDialog';
import { Button, Grid, Typography } from '@material-ui/core';
import Esignature from '../Esign/Esignature';

import RawHtml from '../Shared/RawHtml';

class ApplicantSign extends React.Component {
  static contextType = AuthContext
  constructor(props) {
    super(props)

    const getParams = getQuery(this.props.location.search)
    const token = getParams.access_token

    if (!token) {
      // @todo - what to do if no token?
      this.props.history.push('/404')
    }

    this.state = {
      lease_html: '',
      property_id: '',
      signDialog: false,
      signature: null,
      token: token,
      applicant: null,
      dueInvoices: null,
      examCompleted: null,
      all_signature: [],
      broker_signature: {},
      submit: false
    }
  }

  componentDidMount() {
    this.context.setTitle('Lease Agreement')
    this.context.backdrop(true)

    if (this.state.token) {
      http.interceptors.request.use(config => {
        config.headers['token'] = this.state.token
        return config
      })
    }

    this.getData()
  }

  getData = () => {

    const token = this.state.token

    /* preview code start */
    if (token.includes('preview-lease') === true) {

      /*
          preview case-
          - when preview btn clicked from proeprty edit
          - link not added in resport yet, code ready here, if needed just add link in prperty edit
          - link is like "applicant/lease/preview?access_token=preview-lease-57" ( 57 is property id )
      */

      const property_id = token.replace("preview-lease-", "")

      // set mock data for preview
      this.setState(prevState => ({
        ...prevState,
        applicant: {
          id: 1,
          application_id: 1,
          first_name: "Jacfirst",
          last_name: "Beclast",
          decision: "APPROVED"
        },
        property_id,
        dueInvoices: 0,
        duePostInvoices: 0,
        examCompleted: true,
        all_signature: [
          {
            applicant_type: "PRIMARY",
            full_name: "Jacfirst Beclast",
            id: 1,
            signature_data: {
              type: "type",
              content: "Cedarville Cursive"
            },
            signature_date: Date.now(),
            signature_ip: "192.168.1.1"
          },
          {
            applicant_type: "CO-APPLICANT",
            full_name: "Hank Mess",
            id: 2,
            signature_data: {
              type: "type",
              content: "Satisfy"
            },
            signature_date: Date.now(),
            signature_ip: "192.168.1.1"
          }
        ]
      }))

      // fetch lease content by property id
      this.getPropertyLeaseHtml(property_id, true)

      return
    }
    /* preview code ends */

    // applicant token lease api call
    http.get('/applicant/lease', {
      headers: { token }
    }).then(response => {
      // set prop id
      const { applicant, examCompleted, all_signature, dueInvoices, duePostInvoices, property_id, broker_signature } = response.data

      this.setState(prev => ({
        ...prev,
        applicant, examCompleted, all_signature, dueInvoices, duePostInvoices, property_id, broker_signature
      }))

      // fetch lease content by property id
      this.getPropertyLeaseHtml(property_id)

    }, error => {
      // something went wrong - take user to application form
      this.props.history.push(`/auth/applicant?access_token=${this.state.token}`)
    }).finally(() => this.context.backdrop(false))
  }

  getPropertyLeaseHtml = (property_id, preview = false) => {

    let url = `/properties/lease/${property_id}`

    if (preview) {
      url += '?preview=true'
    } else {
      url += `?application_id=${this.state.applicant.application_id}`
    }

    http.get(url)
      .then(response => {
        this.setState(prev => ({ ...prev, lease_html: response.data }))
      }).finally(() => this.context.backdrop(false))
  }

  getDueInvoice = () => {
    return this.state.dueInvoices;
  }

  // clear timer for duplicate
  timer = null;

  // redirect to form
  redirect = (to) => {
    clearTimeout(this.timer);

    this.timer = setTimeout(() => {
      // console.log('Redirect to form')
      this.props.history.push(`/applicant/${to}?access_token=${this.state.token}`)
    }, 3000)
  }

  render() {
    const { applicant, examCompleted, signature, submit, all_signature, lease_html, broker_signature, duePostInvoices } = this.state;

    // first due invoice
    const dueInvoice = this.getDueInvoice();

    // if no due invoice after fetching, redirect to exam
    if (applicant) {
      if (dueInvoice) {
        this.redirect('payment')
      } else if (duePostInvoices) {
        this.redirect('deposit')
      } else if (!examCompleted) {
        this.redirect('exam')
      } else if (applicant.decision !== 'APPROVED') {
        this.redirect('finish')
      } else if (applicant.lease_agreement) {
        this.redirect('signed')
      }
    }

    return <div className={css['body']}>
      <div className={css['container']}>
        <div className={css['content']}>
          <RawHtml>{lease_html}</RawHtml>
        </div>

        <div className={css['signature-main']}>

          {broker_signature.id && <table>
            <tbody>
              <tr>
                <td>
                  <Typography>
                    {broker_signature.full_name} <span>[Agent]</span>
                  </Typography>

                  <div className={css['signature-area']}>
                    <Esignature signature_data={{
                      type: broker_signature.signature_data.type,
                      full_name: broker_signature.full_name,
                      content: broker_signature.signature_data.content,
                      signature_date: broker_signature.signature_date,
                      signature_ip: broker_signature.signature_ip
                    }} />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>}

          <hr></hr>

          <table>
            <tbody>
              <tr>
                {all_signature.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      <td>
                        <Typography>
                          {item.full_name} <span>[{item.applicant_type}]</span>
                        </Typography>
                        <div className={css['signature-area']}>
                          {/* enable sign */}
                          {(item.id === applicant.id && item.signature_data === null) &&
                            <div className={css["signature-after-exam"]} onClick={() => { this.setState({ signDialog: true }) }}>
                              <div className={css["signature-line"]}>Sign here...</div>
                            </div>}

                          {/* singature box print */}
                          {(item.signature_data) &&
                            <Esignature signature_data={{
                              type: item.signature_data.type,
                              full_name: item.full_name,
                              content: item.signature_data.content,
                              signature_date: item.signature_date,
                              signature_ip: item.signature_ip
                            }} />}

                          {/* disable sign for others */}
                          {(item.id !== applicant.id && item.signature_data === null) &&
                            <div className={`${css['signature-after-exam']} ${css['disable-esign']}`} >
                              <div className={css["signature-line"]} onClick={() => { this.setState({ signDialog: true }) }}>Sign here...</div>
                            </div>}
                        </div>
                      </td>
                    </React.Fragment>
                  )
                })}
              </tr>
            </tbody>
          </table>
        </div>

        {submit && <Grid container justify='center' alignItems='center' className={css['submit-btn-container']} id="signed-area">
          <Grid item xs={12} md={3}>
            <Button color="primary" variant='contained' onClick={() => {
              this.context.backdrop(true)
              http.post('/applicant/lease', signature).then(() => {
                this.setState({ submit: false })

                // signature saved? redirect after 2s
                setTimeout(() => {
                  this.redirect('signed')
                }, 1000);
              }, error => {
                this.context.snackbar(<Trans ns='application' i18nKey={error.data}>{error.data}</Trans>, {
                  variant: 'error'
                })
              }).finally(() => this.context.backdrop(false))
            }}>Submit</Button>
          </Grid>
        </Grid>}
      </div>

      {
        this.state.signDialog && <SignatureDialog
          applicantName={`${applicant.first_name} ${applicant.last_name}`}
          onSign={(data) => {

            all_signature.map(item => {
              if (item.id === applicant.id) {
                item.signature_date = data.timeStamp
                item.signature_ip = data.ipAddress
                item.signature_data = {
                  type: data.type,
                  content: data.image ? data.image : data.font
                }
              }

              return item
            })

            this.setState(prev => ({
              ...prev,
              all_signature,
              signature: {
                signature_date: data.timeStamp,
                signature_ip: data.ipAddress,
                signature_data: {
                  type: data.type,
                  content: data.image ? data.image : data.font
                }
              },
              submit: true,
              signDialog: false
            }))

            // Send to API and redirect in 3 seconds
            // this.redirect('signed')
          }}
          onCancel={() => {
            all_signature.map(item => {
              if (item.id === applicant.id) {
                item.signature_date = null
                item.signature_ip = null
                item.signature_data = null
              }
              return item
            })

            this.setState(prev => ({
              ...prev,
              all_signature,
              signature: null,
              submit: false,
              signDialog: false,
            }))
          }} />
      }
    </div >
  }
}

export default compose(
  withTranslation('application')
)(ApplicantSign)

/*
https://stackoverflow.com/questions/1664049/can-i-force-a-page-break-in-html-printing
 */