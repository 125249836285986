import React, { Fragment, useEffect, useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid, Link as MLink, Typography, CssBaseline, InputAdornment, IconButton } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import FormikTextField from '../../Shared/FormikTextField'
import { Form, Formik } from 'formik';
import * as yup from "yup";
import config from '../../../utils/config';
import http from '../../../utils/http';
import { getQuery } from '../../../utils/searchParams';
import { AuthContext } from '../../../utils/AppContext'
import { useSnackbar } from 'notistack';
import ProgressButton from '../../Shared/ProgressButton';
import { Trans, useTranslation } from 'react-i18next';
import LabelCheckbox from '../../Shared/LabelCheckbox';
import FormikAutoComplete from '../../Shared/FormikAutoComplete'
import FacebookLogin from './FacebookLogin';
import GoogleLogin from './GoogleLogin';
import css from './Login.module.css';
import Logo from '../../Shared/Logo';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(1, 0, 2),
  },
  input: {
    margin: theme.spacing(1, 0, 2),
  },
}));


export default function Login(props) {
  const context = useContext(AuthContext)
  const { t } = useTranslation('login')
  const { enqueueSnackbar } = useSnackbar()
  const [social, setState] = useState({
    googleId: false,
    facebookId: false,
    loginProcessing: true
  })

  useEffect(() => {

    http.get(`/configs`).then(
      response => {
        setState({
          facebookId: response.data.facebook_id,
          googleId: response.data.google_id,
        })
      }
    ).finally(() => config.title('Login'))

    if (process.env.REACT_APP_ENV !== 'production') {
      console.table({
        'single-unit': 'developer+tenant-tampa1@conspecture.com',
        'multiple-unit': 'developer+tenant-tampa-multiple@conspecture.com',
      })
    }

  }, [setState]);

  const classes = useStyles();

  // host resolved data
  let property_id = ''
  let properties = context.portalData.properties ? context.portalData.properties : []
  properties = properties.filter(item => item.subscription_active === true)

  // property
  if (context.portalData.property_id) {
    property_id = context.portalData.property_id
  }

  // company
  if (properties.length === 1) {
    property_id = properties[0].property_id
  }

  const formData = {
    email: process.env.REACT_APP_ENV === 'development' ? 'developer+tenant-tampa1@conspecture.com' : '',
    password: process.env.REACT_APP_ENV === 'development' ? '123456' : '',
    property_id: property_id
  };


  /**
   * check if data is exists in session storage
   * if yes then put it in formdata
   */
  const login_session = sessionStorage.getItem('login_session_09nov2021') ? JSON.parse(sessionStorage.getItem('login_session_09nov2021')) : {}
  if (typeof login_session.email !== 'undefined' && login_session.email !== '') {
    formData.email = login_session.email
  }
  if (typeof login_session.password !== 'undefined' && login_session.password !== '') {
    formData.password = login_session.password
  }
  if (typeof login_session.property_id !== 'undefined' && login_session.property_id !== '') {
    // only if multiple properties (company host)
    if (properties.length > 1) {
      const property = properties.find(item => item.property_id === login_session.property_id)
      if (property) {
        // label = Copper Creek Apartments - 197
        formData.property_id = { id: property.property_id, label: `${property.property_name} - ${property.property_id}` }
      }
    } else {
      formData.property_id = login_session.property_id
    }
  }



  const queryParams = getQuery(props.location.search);
  if (queryParams) {
    if (queryParams.email) {
      formData.email = queryParams.email
    }
    if (queryParams.password) {
      formData.password = queryParams.password
    }
  }

  const formValidation = yup.object({
    email: yup
      .string()
      .required('email.required')
      .email('email.invalid'),
    password: yup
      .string()
      .required('password.required'),
    property_id: (properties.length > 1) ? yup
      .object()
      .typeError('property_id.number')
      .optional() :
      yup
        .number()
        .typeError('property_id.number')
        .required('property_id.required')
  });

  const [validationState, setValidationState] = useState({ message: '', type: 'error' });

  const loginSubmit = async (values, actions) => {
    try {
      // formik autocomplete value is object
      let data = Object.assign({}, values)
      data.property_id = typeof data.property_id === 'object' ? data.property_id.id : data.property_id

      // legacy landing case - no prop selection shown to user so passing company id
      if (!data.property_id) {
        data.company_id = context.portalData.company_id
      }

      const response = await http.post('tenant/auth/login', data);

      enqueueSnackbar(<Trans ns="login" i18nKey="loggedin">Logged in successfully</Trans>, {
        variant: 'success',
      })

      // login user
      context.login(response.data);

      // fetch notification count
      context.setNotificationCount()

    } catch (error) {
      setValidationState({
        type: 'error',
        message: error.data
      });
      actions.setSubmitting(false);
    }
  }

  const socialCallback = (type, info) => {
    setState(prevState => {
      return { ...prevState, loginProcessing: true }
    })
    http.post(`/auth/login/social`, {
      type: type,
      info: info
    }).then(response => {
      enqueueSnackbar(<Trans ns="login" i18nKey="loggedin">Logged in successfully</Trans>, {
        variant: 'success',
      })
      context.login(response.data);
    }, error => {
      enqueueSnackbar(<Trans ns="login" i18nKey={error.data}>{error.data}</Trans>, {
        variant: 'error',
      })

      setState(prevState => {
        return { ...prevState, loginProcessing: false }
      })
    })
  }

  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className={css['login-wrap']}>
      <div className={css['login-main']}>
        <Fragment>
          <div className={css['login-logo']}>
            <Logo />
          </div>
          <Container className='login-contain-area' component="main">
            <CssBaseline />

            <div className={classes.paper}>
              {/* <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar> */}

              <Typography component="h1" variant="h5">
                <Trans ns="login" i18nKey='header'>Sign In</Trans>
              </Typography>
              {validationState.message && <Alert severity={validationState.type}><Trans ns="login" i18nKey={validationState.message}></Trans></Alert>}
              <Formik
                initialValues={formData}
                enableReinitialize={true}
                validationSchema={formValidation}
                validateOnMount={true}
                onSubmit={loginSubmit}>{props => {
                  // set in session storage
                  const session_data = Object.assign({}, props.values)
                  session_data.property_id = (typeof session_data.property_id === 'object' && session_data.property_id) ? session_data.property_id.id : session_data.property_id

                  sessionStorage.setItem('login_session_09nov2021', JSON.stringify(session_data))

                  return (
                    <Form className={`${classes.form} form-wrap`}>
                      <FormikTextField
                        data-testid="login_email"
                        ns="login"
                        name="email"
                        type="email"
                        variant="outlined"
                        label="Email"
                        placeholder={t('login:email')}
                        fullWidth
                        className={classes.input}
                        autoComplete="off" />

                      <FormikTextField
                        data-testid="login_password"
                        ns="login"
                        name="password"
                        type={showPassword ? 'text' : 'password'}
                        placeholder={t('login:password')}
                        variant="outlined"
                        label="Password"
                        id="outlined-secondary"
                        fullWidth
                        className={`${classes.input} ${css['password-icon']}`}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">
                            <IconButton onClick={() => setShowPassword(!showPassword)}>
                              {showPassword ? <i className='icon dashboardicon-view'></i> : <i className='icon dashboardicon-disable-icon'></i>}
                            </IconButton>
                          </InputAdornment>
                        }} />

                      { /* if multiple properties then show dropdown */}
                      {/* legacy landing does not want property selection */}
                      {false && (properties.length > 1) && <React.Fragment>
                        <FormikAutoComplete
                          data-testid="login_property_id_multiple"
                          disableAdd={true}
                          name="property_id"
                          className={css['property-ids']}
                          label={t('login:property_id', "Property ID")}
                          options={properties.map(property => ({ id: property.property_id, label: `${property.property_name} - ${property.property_id}` }))}
                          onChange={(_event, newValue) => props.setFieldValue('property_id', newValue)}
                          ns='login' />
                      </React.Fragment>}

                      {/* show property_id input box only when we could not resolve portal data from host */}
                      {(!property_id && properties.length <= 1) && <React.Fragment>
                        <FormikTextField
                          data-testid="login_property_id_single"
                          ns="login"
                          name="property_id"
                          type="text"
                          variant="outlined"
                          label="Property ID"
                          placeholder={t('login:property_id', "Property ID")}
                          fullWidth
                          className={classes.input}
                          autoComplete="off" />
                      </React.Fragment>}


                      <div hidden>
                        <LabelCheckbox name="remember_me" color="primary" label="Remember Me" />
                      </div>
                      <Grid container justify='space-between'>
                        <Grid item>
                          <MLink className={`${css['forgot-password']} ${css['mb-forgot-password']}`} to="/auth/forgotPassword" variant="body2" component={Link} data-testid="login_forgot_password">
                            <Trans ns="login" i18nKey='forgotpass.txt'>Forgot Password?</Trans>
                          </MLink>
                        </Grid>
                        <Grid item>
                          <ProgressButton
                            data-testid="login_submit"
                            type="submit"
                            fullWidth
                            loading={social.loginProcessing ? "1" : "0"}
                            variant="contained"
                            color="primary"
                            disabled={!props.isValid || props.isSubmitting || social.loginProcessing}
                            className={`${classes.content} form-button`}>
                            <Trans ns="login" i18nKey="button.signin">Sign In</Trans></ProgressButton>
                        </Grid>
                      </Grid>

                      {/* social present? OR bar + buttons */}
                      {(social.facebookId || social.googleId) &&
                        <div className={css['login-with-or']}>
                          <div className={css['login-with-social']}>
                            <Grid container spacing={1} justify='space-between'>
                              {social.facebookId && <Grid item>
                                <FacebookLogin className={css['login-with-facebook']} onCallback={socialCallback} appId={social.facebookId} fullWidth={true} />
                              </Grid>}
                              {social.googleId && <Grid item>
                                <GoogleLogin className={`${css['login-with-google']}`} onCallback={socialCallback} appId={social.googleId} fullWidth={true} />
                              </Grid>}
                            </Grid>
                          </div>
                        </div>
                      }

                      {/* bar needed when social wala OR bar not present */}
                      {!(social.facebookId || social.googleId) && <div className={`${css['divided-line']}`}></div>}

                      <div className={`${css['last-section']}`}>
                        <Grid container justify='space-between'>
                          <Grid item xs={6}>
                            <MLink className={`${css['forgot-password']} ${css['creat-an-acc']}`} to="/auth/register" variant="body2" component={Link}><Trans ns="login" i18nKey='register.text'>Create an account</Trans></MLink>
                          </Grid>
                          <Grid item xs={6}>
                            <MLink className={`${css['forgot-password']} ${css['applicant-login']}`} to="/auth/applicant-login" variant="body2" component={Link}><Trans ns="login" i18nKey='login.applicant'>Login as Applicant</Trans></MLink>
                          </Grid>
                        </Grid>
                      </div>
                    </Form>
                  )
                }}
              </Formik>
            </div>
          </Container>
        </Fragment>
      </div>
    </div>
  )
}
