import React from 'react'
import { useField } from 'formik';
import InputMask from 'react-input-mask';
import { FormHelperText } from '@material-ui/core'
import { Trans } from 'react-i18next';

/**
 *
 * @param {*} props
 * @returns InputMask
 * @summary
 * <FormikMaskedInput
    required
    name=''
    label='SSN'
    type='text'
    mask_type=(phone / SSN) -> (999) 999-9999 / (999)-(99)-(9999)
    />
 */
const FormikMaskedInput = (props) => {
  const [field, meta] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : "";

  return (
    <React.Fragment>
      <div className={'masked-phone-number'}>
        <label>{props.label}<span className={errorText ? 'error-astrik' : 'astrik'}>{(props.label && props.required) ? '*' : ''}</span></label>
        <InputMask
          {...field} {...props}
          className={errorText ? 'masked-phone-number masked-error' : 'masked-phone-number'}
          mask={props.mask_type}
          alwaysShowMask={true}
          maskChar={'\u2000'}
        />
        <FormHelperText error={!!errorText}><Trans ns={props.ns} i18nKey={errorText}></Trans></FormHelperText>
      </div>
    </React.Fragment>
  )
}

/**
 * Convert to plain number
 */
export const getUnmaskedNumber = (value) => {
  return value ? value.replace(/\D+/g, '') : value
}

/**
 * Mask phone number for display
 */
export const toPhoneNumberMasked = (value) => {
  if (!value) {
    return ' - '
  }

  // "(123)(123)(1234)"
  const matches = value.match(/^(\d{3})(\d{3})(\d{4})$/)
  if (matches && matches) {
    return `(${matches[1]}) ${matches[2]}-${matches[3]}`
  }
  return value;
}

export default FormikMaskedInput;