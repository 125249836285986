import React from 'react'
import { useField } from 'formik';
import { Trans } from 'react-i18next';
import { FormControl, InputLabel, FormHelperText, Select, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));



const FormikSelect = (props) => {
  const classes = useStyles()
  const [field, meta] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : "";
  return (
    <FormControl className={classes.formControl}>
      {props.label ? <InputLabel shrink={true}>{props.label}<span className={errorText ? 'error-astrik' : 'astrik'}>{props.required ? '*' : ''}</span></InputLabel> : ''}
      <Select
        {...field} {...props}
        className={errorText ? 'select-error' : ''}>
        {props.children}
      </Select>
      <FormHelperText error={!!errorText}><Trans ns={props.ns} i18nKey={errorText}></Trans></FormHelperText>
    </FormControl>
  )
};

export default FormikSelect;