import React, { Fragment } from 'react'
import date from './date'
import { Tooltip } from '@material-ui/core';

const DateFormat = ({ format, children, userObj, userName }) => {

  if (!format) {
    format = 'm/d/Y'
  }
  let timestamp = parseInt(children)
  if (timestamp < 86400000) {
    const dateObj = new Date(children.toString().replace(' ', 'T'))
    if (!isNaN(dateObj)) {
      timestamp = dateObj.getTime() - dateObj.getTimezoneOffset() * -60000
    } else {
      console.log('DateFormat', children)
      timestamp = 0; // not sure what this is.
    }
  }

  // user object
  let full_name = userObj && userObj.full_name ? userObj.full_name : false
  // user name
  full_name = userName ? userName : full_name

  // with full_name as tooltip
  if (full_name) {
    return (
      <Tooltip title={full_name}>
        <span>
          {!!timestamp ? date(format, timestamp) : ' - '}
        </span>
      </Tooltip>
    )
  }

  return (
    <Fragment>
      {!!timestamp ? date(format, timestamp) : ' - '}
    </Fragment>
  )
}

export default DateFormat;