import React from 'react'
import { Trans } from 'react-i18next'
import { compose } from 'recompose'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Link as MLink } from '@material-ui/core'

import { AuthContext } from '../../utils/AppContext'
import { getQuery } from '../../utils/searchParams'
import css from './ApplicantLogin.module.css'
import {
  Typography,
  Container,
  CssBaseline,
  createStyles,
  withStyles,
} from '@material-ui/core'

class ApplicantLoginSuccess extends React.Component {
  static contextType = AuthContext

  email = ''
  constructor(props) {
    super(props)

    const getParams = getQuery(this.props.location.search)
    let getEmail = getParams.email
    if (getEmail) {
      this.email = ` ${getEmail}`
    }
  }

  componentDidMount() {
    this.context.setTitle('Applicant - Thank You')
  }


  render() {
    const { classes } = this.props
    const email = this.email

    return (
      <React.Fragment>
        <div className={css['applicant-login-wrap']}>
          <div className={css['applicant-login-container']}>
            <Container component="main">
              <CssBaseline />

              <div className={classes.paper}>
                <div className={css['thank-you']}></div>
                <Typography component="h1" variant="h1">
                  {
                    <Trans ns="login" i18nKey='applicant_login_success.header'>Thank you</Trans>
                  }
                </Typography>

                <Typography component="h5" variant="h5">

                  <Trans ns="login" i18nKey='applicant_login_success.message'>
                    {(email) ?
                      <>
                        An email with login details have been sent to <b>{{ email }}</b>
                      </>
                      :
                      <>An email with login details have been sent.</>
                    }
                  </Trans>

                </Typography>
                <Typography component="h5" variant="h5">
                  <Trans ns="login" i18nKey='applicant_login_success.message'>
                    Please check your email to find the link(s).
                  </Trans>
                </Typography>
                <Typography component="h4" variant="h4">
                  <MLink className={`${css['tenant-login']}`} to="/auth/login" variant="body2" component={Link}>
                    <Trans ns="tenant_login" i18nKey='login.txt'>Tenant Login</Trans>
                  </MLink>
                </Typography>
              </div>
            </Container>
          </div>
        </div >
      </React.Fragment >
    )
  }
}

const useStyles = createStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }
}))

export default compose(
  withStyles(useStyles),
  withTranslation('application')
)(ApplicantLoginSuccess)
