import React, { Suspense, lazy, useEffect, useState } from 'react'
import { Switch, Redirect } from 'react-router-dom'
import { AuthProvider } from './utils/AppContext'

import NotFound from './components/sample/NotFound'
import MjmlPlayground from './components/Test/PlayGround/MjmlPlayground'
import ServerSide from './components/Test/PlayGround/ServerSide'
import ClientSide from './components/Test/PlayGround/ClientSide'
import MaskedPlayground from './components/Test/PlayGround/MaskedPlayground'
import PlacePay from './components/Test/PlayGround/PlacePay'
import { Box, Button, Typography } from '@material-ui/core'
import Login from './components/Auth/Login/Login'
import Logout from './components/Logout'

import { Layout as PrivateRoute } from './components/Layouts/Private/Layout'
import { Layout as PublicRoute } from './components/Layouts/Public/Layout'
import { Layout as StaticPage } from './components/Layouts/Static/Layout'

import Dashboard from './components/Dashboard/Dashboard'

/** applicant */
import Application from './components/Applicant/ApplicationForm/ApplicationForm'
import ApplicantPayment from './components/Applicant/Payment/ApplicantPayment'
import ApplicantExam from './components/Applicant/Exam/ApplicantExam'
import ApplicationCancelled from './components/Applicant/ApplicationForm/ApplicationCancelled'
import ApplicationFormFinish from './components/Applicant/Exam/ApplicationFormFinish'
import ApplicantLoginSuccess from './components/Auth/ApplicantLoginSuccess'
import PreApplicationForm from './components/Applicant/PreApplicationForm/PreApplicationForm'
import ApplicantInfo from './components/Packets/ApplicantInfo'
/** applicant */

/* lease sign */
import ApplicantSign from './components/Applicant/ApplicantSign'
/* lease sign */

/* static page */
import ViewPage from './components/Page/ViewPage'

import './i18n';
import { useSnackbar } from 'notistack'
import { AuthRoute } from './utils/Routes'

import { Provider } from 'react-redux'
import store from './store'
import './App.css';

const preLoadComponents = [];
const ReactLazyPreload = importStatement => {
  const Component = lazy(importStatement);
  Component.preload = importStatement;
  preLoadComponents.push(Component)
  return Component;
}


// lease impersonating page
const LeaseImpersonate = ReactLazyPreload(() => import('./components/Auth/LeaseImpersonate'));

const Register = ReactLazyPreload(() => import('./components/Auth/Register'))
const Verify = ReactLazyPreload(() => import('./components/Auth/Verify'))
const ForgotPassword = ReactLazyPreload(() => import('./components/Auth/ForgotPassword'))
const ApplicantSelfInvite = ReactLazyPreload(() => import('./components/Auth/ApplicantSelfInvite'))
const ResetPassword = ReactLazyPreload(() => import('./components/Auth/ResetPassword'))

const Profile = ReactLazyPreload(() => import('./components/Profile/Profile'))

// tenant started
const Payment = ReactLazyPreload(() => import('./components/Payments/Payment'))

const Deposit = ReactLazyPreload(() => import('./components/SecurityDeposit/Deposit'))

// tenant Payment Methods started
const PaymentMethods = ReactLazyPreload(() => import('./components/PaymentMethods/PaymentMethod'))

// Transactions start
const TransactionListing = ReactLazyPreload(() => import('./components/Transactions/TransactionListing'))
const TransactionDetails = ReactLazyPreload(() => import('./components/Transactions/TransactionDetails'))

// Workorders start
const WorkorderListing = ReactLazyPreload(() => import('./components/Workorders/WorkorderListing'))
const AddWorkorder = ReactLazyPreload(() => import('./components/Workorders/AddWorkorder'))
const WorkorderDetails = ReactLazyPreload(() => import('./components/Workorders/WorkorderDetails'))

// My Emails start
const MyEmailListing = ReactLazyPreload(() => import('./components/MailLogs/MyEmailListing'))
const EmailView = ReactLazyPreload(() => import('./components/MailLogs/EmailView'))

// Messages start
const MessageListing = ReactLazyPreload(() => import('./components/Messages/MessageListing'));
const MessageDetails = ReactLazyPreload(() => import('./components/Messages/MessageDetails'));

const AutopayListing = ReactLazyPreload(() => import('./components/Autopay/AutopayListing'))
const AddAutopay = ReactLazyPreload(() => import('./components/Autopay/AddAutopay'))
const EditAutopay = ReactLazyPreload(() => import('./components/Autopay/EditAutopay'))

// parcel
const ParcelListing = ReactLazyPreload(() => import('./components/Parcels/ParcelListing'))

//  Documents
const DocumentListing = ReactLazyPreload(() => import('./components/Documents/DocumentListing'))

// Guided Inspection
const GuidedInspectionList = ReactLazyPreload(() => import('./components/GuidedInspection/GuidedInspectionList'))

// tenant end


function App() {
  const { enqueueSnackbar } = useSnackbar();
  const [installButton, setInstallButton] = useState(false)
  // const [installIOSButton, setInstallIOSButton] = useState(false)

  const isIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
  }
  const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);

  // lazy preload everything after 5 seconds
  // useEffect(() => {
  //   if (isIos() && !isInStandaloneMode()) {
  //     setInstallIOSButton(true);
  //   }
  //   setTimeout(() => preLoadComponents.map(component => component.preload()), 5000)
  // }, [])


  window.addEventListener("beforeinstallprompt", (e) => {
    // Prevent the mini-infobar from appearing on mobile
    e.preventDefault();

    // Stash the event so it can be triggered later.
    window.deferredPrompt = e;
    console.log("Registerd event");
    // Update UI notify the user they can install the PWA
    window.localStorage.setItem("pwainstalled", "false");
    //this.showInstallPromotion();
    setInstallButton(true)
  });

  window.addEventListener("appinstalled", (evt) => {
    // Log install to analytics
    console.log("INSTALL: Success");
    window.localStorage.setItem("pwainstalled", "true");
  });

  const installPWA = () => {
    if (window.deferredPrompt) {
      console.log("inside window.deferredPromp if condition");
      window.deferredPrompt.prompt();
      window.deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          setInstallButton(false);
          console.log("User accepted the install prompt");
        } else {
          setInstallButton(false);
          console.log("User dismissed the install prompt");
        }
      });
    }
  }


  return (
    <Suspense fallback={<Loading />}>
      <Provider store={store}>
        <AuthProvider snackbar={enqueueSnackbar}>
          {installButton ? <Box className='install-app-warraper'>
            <Button onClick={() => installPWA()} className="install-app-btn" variant='contained'>Install App</Button>
            <Box onClick={() => setInstallButton(false)} className='close-icon'>
              <i className='icon dashboardicon-close'></i>
            </Box>
          </Box> : null}
          {/* {
            installIOSButton ?
              <Box className='add-to-home-screen-box'>
                <Box className='add-icon'>
                  <svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 4a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2h-6v6a1 1 0 1 1-2 0v-6H5a1 1 0 1 1 0-2h6V5a1 1 0 0 1 1-1z" fill="#FFFFFF" />
                  </svg>
                </Box>
                <Typography component="h1" variant="h5">
                  Install this webapp on your iPhone: tap
                  <span className='share-icon'>
                    <svg fill="#124c8e" width="800px" height="800px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" id="share" class="icon glyph">
                      <path d="M18,9H16a1,1,0,0,0,0,2h2v9H6V11H8A1,1,0,0,0,8,9H6a2,2,0,0,0-2,2v9a2,2,0,0,0,2,2H18a2,2,0,0,0,2-2V11A2,2,0,0,0,18,9Z"></path>
                      <path d="M8.71,6.71,11,4.41V15a1,1,0,0,0,2,0V4.41l2.29,2.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42l-4-4h0a1.15,1.15,0,0,0-.33-.21.94.94,0,0,0-.76,0,1.15,1.15,0,0,0-.33.21h0l-4,4A1,1,0,1,0,8.71,6.71Z"></path>
                    </svg>
                  </span>
                  and then Add to homescreen.
                </Typography>
                <Box onClick={() => setInstallIOSButton(false)} className='close-icon'>
                  <i className='icon dashboardicon-close'></i>
                </Box>
              </Box> : null
          } */}
          <Switch>
            <PrivateRoute exact path="/" component={() => {
              return <Redirect to="/auth/login" />
            }} />

            {/* tenant route started */}
            <PrivateRoute exact path="/tenant" component={Dashboard} />
            <PrivateRoute path="/tenant/dashboard" component={Dashboard} />

            {/* tenant payments route started */}
            <PrivateRoute path="/tenant/make-payment" component={Payment} />
            <PrivateRoute exact path="/transactions" component={TransactionListing} />
            <PrivateRoute exact path="/transactions/view/:transaction_id" component={TransactionDetails} />
            {/* tenant payments route ended */}

            {/* tenant manage payments route started */}
            <PrivateRoute path="/tenant/payment-methods" component={PaymentMethods} />

            {/* tenant workorders route started */}
            <PrivateRoute exact path="/workorders" component={WorkorderListing} />
            <PrivateRoute exact path="/workorders/add" component={AddWorkorder} />
            <PrivateRoute exact path="/workorders/:workorder_id" component={WorkorderDetails} />
            {/* tenant workorders route ended */}

            {/* my emails route started */}
            <PrivateRoute exact path="/mails" component={MyEmailListing} />
            <PrivateRoute exact path="/mails/view/:id" component={EmailView} />
            {/* my emails route ended */}

            {/* messages routes started */}
            <PrivateRoute exact path="/messages" component={MessageListing} />
            <PrivateRoute exact path="/messages/view/:message_id" component={MessageDetails} />

            {/* parcel listing */}
            <PrivateRoute exact path="/parcels" component={ParcelListing} />

            {/* Document listing */}
            <PrivateRoute exact path="/documents" component={DocumentListing} />

            {/* tenant autopay route started */}
            <PrivateRoute exact path="/tenant/autopays" component={AutopayListing} />
            <PrivateRoute exact path="/tenant/autopays/add" component={AddAutopay} />
            <PrivateRoute exact path="/tenant/autopays/edit/:autopay_id" component={EditAutopay} />
            {/* tenant autopay route ended */}

            {/* tenant guided inspection route started */}
            <PrivateRoute exact path="/tenant/guided-inspection" component={GuidedInspectionList} />
            {/* tenant guided inspection route ended */}

            {/* tenant route ended */}

            <PrivateRoute exact path="/profile" component={Profile} />
            <PrivateRoute exact path="/profile/:tab" component={Profile} />
            <PrivateRoute exact path="/.well-known/change-password" component={() => {
              // Complying to - well-known URL for changing passwords.
              window.location = '/profile/password';
              return null
            }} />

            <PrivateRoute path="/auth/logout" component={Logout} />

            <AuthRoute path="/auth/login" component={Login} />
            <AuthRoute path="/auth/register" component={Register} />
            <AuthRoute path="/auth/forgotPassword" component={ForgotPassword} />
            <AuthRoute path="/auth/applicant-login" component={ApplicantSelfInvite} />
            <PublicRoute path="/applicant-login/success" component={ApplicantLoginSuccess} />
            <AuthRoute path="/auth/resetPassword/:token" component={ResetPassword} />
            <AuthRoute exact path="/auth/verify/:token" component={Verify} />

            {/* impersonate lease */}
            <AuthRoute path="/auth/lease_impersonate" component={LeaseImpersonate} name="lease_impersonate" />

            <PublicRoute path="/playground/mjml" component={MjmlPlayground} />
            <PublicRoute path="/playground/xgrid-server" component={ServerSide} />
            <PublicRoute path="/playground/xgrid-client" component={ClientSide} />
            <PublicRoute path="/playground/masked" component={MaskedPlayground} />
            <PublicRoute path="/playground/placepay" component={PlacePay} />

            {/* Applicant */}
            <PublicRoute path="/auth/applicant/:tab?" component={Application} />
            <PublicRoute path="/applicant/payment" component={ApplicantPayment} />
            <PublicRoute path="/applicant/exam" component={ApplicantExam} />
            <PublicRoute path="/applicant/finish" component={ApplicationFormFinish} />
            <PublicRoute path="/applicant/application-cancelled" component={ApplicationCancelled} />
            <PublicRoute path="/applicant/signed" component={ApplicationFormFinish} />
            <PublicRoute path="/applicant/guestcard" component={PreApplicationForm} />
            <PublicRoute path="/applicant/deposit" component={Deposit} />

            {/* html for packet pdf */}
            <PublicRoute path="/packets/html" component={ApplicantInfo} />

            {/* lease */}
            <PublicRoute path="/applicant/lease/preview" component={ApplicantSign} />
            <AuthRoute path="/applicant/lease" component={ApplicantSign} />

            {/* Page - */}
            <StaticPage exact path="/page/:idx" component={ViewPage} />

            <PublicRoute exact path="*" component={NotFound} />
          </Switch>
        </AuthProvider>
      </Provider>
    </Suspense>
  )
}

function Loading() {
  return <div className="suspense-loading">Loading</div>
}

export default App;