import React from 'react'
import css from '../Application.module.css'
import { withTranslation, Trans } from 'react-i18next'
import { compose } from 'recompose'
import { FieldArray } from 'formik'

import FormikTextField from '../../../Shared/FormikTextField'
import FormikAutoComplete from '../../../Shared/FormikAutoComplete'
import FormikMaskedInput from '../../../Shared/FormikMaskedInput'

import {
  Typography,
  Grid,
  Button,
  Divider,
  Box
} from '@material-ui/core'
import { APPLICANT_REFERENCE } from '../ApplicantHelpers'

class Reference extends React.Component {
  render() {
    const {
      t,
      deleteRecord,
      classes,
      props
    } = this.props

    const relationship = [
      { id: 'SIBLING', label: 'Sibling' },
      { id: 'SPOUSE', label: 'Spouse' },
      { id: 'CHILD', label: 'Child' },
      { id: 'PARENT', label: 'Parent' },
      { id: 'NOT_DIRECTLY_RELATED', label: 'Not directly related' },
      { id: 'CO_SIGNER', label: 'Co-signer' },
    ]

    return (
      <React.Fragment>
        <FieldArray name="applicant_references">
          {({ push, form, remove }) => {
            const { values } = form
            const { applicant_references } = values

            return (
              <React.Fragment>
                <div className={`${css['form-grp']} form-wrap`}>
                  {applicant_references.map((_applicant_reference, index) => {
                    return (
                      <React.Fragment key={index}>
                        <div className={css['spacing-bottom']}>
                          <div className={css['heading']}>
                            <Grid container justify='space-between' alignItems='center'>
                              <Grid item>
                                <Typography component="h1">
                                  <Trans ns="application" i18nKey={`applicant_references.${index}.header`}>{`Reference #${index + 1}`}</Trans>
                                </Typography>
                              </Grid>
                              {(index > 0) && <Grid item>
                                <Button className={css['remove-button']} variant='contained' color='primary'
                                  onClick={() => {
                                    if (typeof applicant_references[index] !== 'undefined') {
                                      // db wala record..
                                      deleteRecord('applicant_references', applicant_references[index].id)
                                    }
                                    remove(index)
                                  }}>
                                  <Trans ns='application' i18nKey='button.remove.applicant_references'>- Remove Reference</Trans>
                                </Button>
                              </Grid>}
                            </Grid>
                          </div>

                          <Grid container alignItems='center' spacing={3}>
                            {/* first name input text */}
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                              <FormikTextField
                                required
                                ns="application"
                                name={`applicant_references[${index}].first_name`}
                                type="text"
                                variant="outlined"
                                label={<Trans ns='application' i18nKey="label.first_name">First Name</Trans>}
                                placeholder={t('application:first_name', 'First name')}
                                fullWidth
                                className={classes.input}
                                autoComplete="off" />
                            </Grid>
                            {/* last name input text */}
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                              <FormikTextField
                                required
                                ns="application"
                                name={`applicant_references[${index}].last_name`}
                                type="text"
                                variant="outlined"
                                label={<Trans ns='application' i18nKey="label.last_name">Last Name</Trans>}
                                placeholder={t('application:last_name', 'Last name')}
                                fullWidth
                                className={classes.input}
                                autoComplete="off" />
                            </Grid>
                            {/* contact no input text */}
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                              <FormikMaskedInput
                                required
                                name={`applicant_references[${index}].contact_no`}
                                type='text'
                                label={t('application:label.contact_no', 'Contact No.')}
                                mask_type='(999) 999-9999'
                              />
                            </Grid>
                            {/* email input text */}
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                              <FormikTextField
                                ns="application"
                                name={`applicant_references[${index}].email`}
                                type="email"
                                variant="outlined"
                                label={<Trans ns='application' i18nKey="label.email">Email</Trans>}
                                placeholder={t('application:email', 'Email')}
                                fullWidth
                                className={classes.input}
                                autoComplete="off" />
                            </Grid>
                            {/* relationship input text */}
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                              <FormikAutoComplete
                                required
                                disableAdd={true}
                                name={`applicant_references[${index}].relationship`}
                                label={<Trans ns='application' i18nKey="label.relationship">Relationship</Trans>}
                                options={relationship.map(item => ({ id: item.id, label: item.label }))}
                                onChange={(_event, newValue) => {
                                  if (typeof newValue === 'string') {
                                    // timeout to avoid instant validation of the dialog's form.
                                    setTimeout(() => {
                                      this.addAutoComplete(`applicant_references[${index}].relationship`, newValue)
                                    });
                                  } else if (newValue && newValue.inputValue) {
                                    this.addAutoComplete(`applicant_references[${index}].relationship`, newValue)
                                  } else {
                                    props.setFieldValue(`applicant_references[${index}].relationship`, newValue)
                                  }
                                }}
                                ns='application' />
                            </Grid>
                          </Grid>
                          <Grid container justify='flex-end' alignItems='center' spacing={2} className={css['container-spacing-3']}>
                            {(applicant_references.length === index + 1) && <Grid item>
                              <Box mt={1}>
                                <Button className={css['add-more-button']} variant='contained' color='primary'
                                  onClick={() => push(APPLICANT_REFERENCE)}>
                                  <Trans ns='application' i18nKey='button.add.applicant_references'>+ Add Reference</Trans>
                                </Button>
                              </Box>
                            </Grid>}
                          </Grid>
                          <Divider className={css['form-divider']} />
                        </div>
                      </React.Fragment>
                    )
                  })}
                </div>
              </React.Fragment>
            )
          }}
        </FieldArray>
      </React.Fragment>
    )
  }
}

export default compose(
  withTranslation('application')
)(Reference)