import React from 'react'
import { compose } from 'recompose';

import { Trans, withTranslation } from 'react-i18next'

import http from '../../../../utils/http'
import Canvas from '../../../Test/Canvas';
import { fontFamilies } from '../../../Test/Canvas';

import css from './SignatureDialog.module.css'

import MuiDialogTitle from '@material-ui/core/DialogTitle';
import {
  // Grid,
  // FormControlLabel,
  // Checkbox,
  Typography,
  Dialog,
  Button,
  DialogActions,
  DialogContent
} from '@material-ui/core'


class SignatureDialog extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      activeTab: 'tab-type',
      activeFont: 0,
      ipAddress: null,
      timeStamp: null
    }

    this.canvasRef = React.createRef();
  }

  componentDidMount() {
    http.get(`/applicant/ip`).then(res => {
      this.setState({ ipAddress: res.data });
    })
    this.setState({ timeStamp: Date.now() })
  }

  changeTab = (e) => {
    e.preventDefault()
    const activeTab = e.target.id;
    this.setState({ activeTab })
  }

  render() {
    const { activeTab, activeFont } = this.state
    const { applicantName, onSign, onCancel } = this.props;

    document.body.style.overflow = 'initial'; // mobile devices to persist scroll position on cancel

    return (
      <React.Fragment>
        <Dialog
          className={`${css['signature-popup']} assign-dialog-box`}
          disableEscapeKeyDown={true}
          open={true}
          maxWidth='sm'
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              onCancel()
            }
          }}
          aria-labelledby="assign-dialog-title">
          <div className={css['popup-top-area']}>
            <MuiDialogTitle disableTypography className={css.change_assign_root}>
              <Typography variant="h6" className={css.assign_dialog_title}>
                <Trans ns='application' i18nKey='application.mustread.title'>Create a Signature or Initial</Trans>
              </Typography>
            </MuiDialogTitle>
          </div>
          <DialogContent>

            <div className={css["content"]}>
              <p>Use one of the following way to create your signature initial</p>

              <ul className={css["tab-menu"]}>
                <li>
                  <a href="about:blank"
                    className={`${activeTab === 'tab-type' ? css["active"] : ''}`}
                    id="tab-type"
                    onClick={this.changeTab}>Type</a>
                </li>
                <li>
                  <a href="about:blank"
                    className={`${activeTab === 'tab-draw' ? css["active"] : ''}`}
                    id="tab-draw"
                    onClick={this.changeTab}>Draw</a>
                </li>
              </ul>

              <div className={css["tab-content"]}>
                <div className={`${css["tab-type"]} ${activeTab === 'tab-type' ? css["active"] : ''}`}>
                  <p>Choose your signature from the following list</p>
                  <div className={css["signature-font"]}>
                    {[0, 1, 2, 3, 4, 5].map(index => (
                      <button type="button"
                        key={index}
                        className={`${index === activeFont ? css['active'] : ''}`}
                        style={{ fontFamily: fontFamilies[index] }}
                        onClick={() => {
                          console.log(activeFont, index)
                          this.setState({ activeFont: index })
                        }}>{applicantName}</button>
                    ))}
                  </div>
                </div>
                <div className={`${css["tab-draw"]} ${activeTab === 'tab-draw' ? css["active"] : ''}`}>
                  <p>Draw signature in the signature pad</p>
                  <div className={css["signature-box"]}>
                    <button type="button" className={css['canvas-clear']} onClick={() => {
                      this.canvasRef.current.clearScreen()
                    }}>Clear</button>
                    <div className={css["signature"]}>
                      <Canvas ref={this.canvasRef} className={css['canvas-cls']} />
                    </div>
                  </div>
                </div>

              </div>
            </div>

          </DialogContent>
          <DialogActions>
            <Button
              className={`${css['cancel']}`}
              variant='contained'
              onClick={() => {
                onCancel()
              }}>
              Cancel
            </Button>
            <Button
              className={`${css.btn_accept} form-button`}
              variant='contained'
              onClick={() => {
                const data = {
                  type: activeTab.substr(4),
                  ipAddress: this.state.ipAddress,
                  timeStamp: this.state.timeStamp,
                };

                if (activeTab === 'tab-draw') {
                  // check draw length
                  if (this.canvasRef.current.getPixelsCount() < 500) {
                    return alert('Signature invalid or too short')
                  }
                  data.image = this.canvasRef.current.getImage()
                } else {
                  // check font n stuff
                  data.font = fontFamilies[activeFont]
                }
                onSign(data)
              }}>
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment >
    )
  }
}

export default compose(
  withTranslation('application')
)(SignatureDialog);