import React, { Component, Fragment } from 'react'
import { Button } from '@material-ui/core';

class GoogleLogin extends Component {

  constructor(props) {
    super(props)
    this.state = {
      loading: true,
    }
  }


  componentDidMount() {
    const appId = this.props.appId;
    // load the js file
    const scriptTag = document.createElement("script");
    scriptTag.async = true;
    scriptTag.defer = true;
    scriptTag.crossorigin = 'anonymous';
    scriptTag.src = `https://apis.google.com/js/platform.js`;
    document.head.appendChild(scriptTag);

    scriptTag.onload = () => {
      window.gapi.load('auth2', () => {
        this.auth2 = window.gapi.auth2.init({
          client_id: appId
        });

        this.setState(prevState => {
          prevState.loading = false;
          return prevState;
        });
      })
    }
  }

  onClick =  () => {
    // Sign the user in, and then retrieve their ID.
    this.auth2.signIn().then(response => {
      const type = 'google';
      const info = {
        access_token: response.getAuthResponse().id_token,
        user_id: response.getId()
      }

      this.props.onCallback(type, info);
    }, error => {
        console.log(error.error)
    });
  }

  render() {
    const { onCallback, appId, ...buttonProps } = this.props

    return (
      <Fragment>
        <Button hidden={this.state.loading} onClick={this.onClick} variant='contained' color='primary' {...buttonProps}>{this.props.label ? this.props.label : 'Login with Google'}</Button>
      </Fragment>
    )
  }
}

export default GoogleLogin;