import React from 'react'
import { makeStyles, Button, CircularProgress } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { green } from '@material-ui/core/colors';

const buttonStyles = makeStyles((theme) => ({
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -16,
    marginLeft: -12,
  }
}));


const ProgressButton = (props) => {
  const classes = buttonStyles();
  const formikContext = useFormikContext()
  const { isSubmitting } = formikContext ? formikContext : { isSubmitting: false };
  const { loading } = props;

  return (
    <div className={`${classes.wrapper} sub-button-main`}>
      <Button {...props}>{props.children}</Button>
      {(isSubmitting || loading === "1") && <CircularProgress size={24} className={`${classes.buttonProgress} submit-progress`} />}
    </div>
  )
}

export default ProgressButton;