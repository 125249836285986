import React from 'react'
import { Trans } from 'react-i18next'
import { compose } from 'recompose'
import { withTranslation } from 'react-i18next'

import { AuthContext } from '../../../utils/AppContext'
import { getQuery } from '../../../utils/searchParams'
import css from '../ApplicationForm/Application.module.css'
import {
  Typography,
  Container,
  CssBaseline,
  createStyles,
  withStyles,
} from '@material-ui/core'

class ApplicationCancelled extends React.Component {
  static contextType = AuthContext

  constructor(props) {
    super(props)

    const getParams = getQuery(this.props.location.search)
    const token = getParams.access_token
    if (!token) {
      // @todo - what to do if no token?
      this.props.history.push('/404')
    }
  }

  componentDidMount() {
    this.context.setTitle('Applicant - Cancelled')
  }

  render() {
    const { classes } = this.props
    return (
      <React.Fragment>
        <div className={css['application-wrap']}>
          <div className={css['application-container']}>
            <Container component="main">
              <CssBaseline />

              <div className={classes.paper}>
                <div className={css['thank-you']}></div>
                <Typography component="h1" variant="h1">
                  <Trans ns="application" i18nKey='application_cancelled.header'>Application Cancelled</Trans>
                </Typography>

                <Typography component="h4" variant="h4">
                  <Trans ns="application" i18nKey='application_cancelled.message'>
                    Your application has been cancelled. You can re-apply to another unit within a month.
                  </Trans>
                </Typography>
              </div>
            </Container>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const useStyles = createStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }
}))

export default compose(
  withStyles(useStyles),
  withTranslation('application')
)(ApplicationCancelled)
