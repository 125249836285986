import moment from "moment"

const time = Date.now()
const email = `hank+${time}@envoi.ai`

const dummy = {
  address1: "2713 Kooter Lane",
  address2: "Romrog Way",
  city: "Tampa",
  comments: "What about parking?",
  contact_no: "8548585858",
  date_needed: moment().format('YYYY-MM-DD'),
  email,
  first_name: "Hank",
  last_name: "Mess",
  lease_term: "6",
  middle_name: "M",
  state_code: { id: "FL", label: "Florida" },
  zip_code: "85458"
}

export default dummy