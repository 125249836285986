import React from 'react'
import { compose } from 'recompose';
import { Trans, withTranslation } from 'react-i18next';

import moneyFormat from '../../../utils/moneyFormat';
// import { AuthContext } from '../../../utils/AppContext';

// import css from './MakePayment.module.css'
import css from '../ApplicationForm/Application.module.css'

import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button
} from '@material-ui/core';

class ProcessPaymentConfirmDialog extends React.Component {
  // static contextType = AuthContext

  render() {
    // const lease = this.context.getLease()
    const { open, onClose, onSuccess, dialogData } = this.props
    const { payment_type, amount, applicant } = dialogData


    return (
      <React.Fragment>
        <Dialog
          maxWidth="md"
          open={open}>
          <DialogTitle>
            <Trans ns='payments' i18nKey='payments.dialog.confirm_process_payment.title'>Process Payment?</Trans>
          </DialogTitle>
          <DialogContent dividers>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <h3 className={css['dialog-title']}>
                  <Trans ns='payments' i18nKey='payments.dialog.confirm_process_payment.heading'>
                    Please Confirm Payment Details:
                  </Trans>
                </h3>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                    <h3 className={css['dialog-label']}><Trans ns='payments'>Name</Trans></h3>
                    <div className={css['dialog-data']}>
                      <Typography variant="subtitle2">
                        {`${applicant.first_name} ${applicant.last_name}`}
                      </Typography>
                    </div>
                  </Grid>
                  {/* <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                    <div className={css['detail_label']}><Trans ns='payments'>Building/Unit</Trans></div>
                    <div className={css['detail_info']}>
                      <Typography variant="subtitle2">
                        {`${lease.building_uid}/${lease.unit_uid}`}
                      </Typography>
                    </div>
                  </Grid> */}
                  <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                    <h3 className={css['dialog-label']}><Trans ns='payments'>Payment Type</Trans></h3>
                    <div className={css['dialog-data']}>
                      <Typography variant="subtitle2">
                        {payment_type ? payment_type : ''}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                    <h3 className={css['dialog-label']}><Trans ns='payments'>Amount</Trans></h3>
                    <div className={css['dialog-amount']}>
                      <Typography variant="subtitle2">
                        {amount ? moneyFormat(amount) : ''}
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <div className={css['dialog-footer']}>
              <Grid container justify='flex-end' alignItems='center' spacing={2}>
                <Grid item>
                  <Button autoFocus onClick={() => { if (onClose) onClose() }} color="primary" className={css['cancel-button']}>
                    <Trans ns='payments' i18nKey='payments.dialog.confirm_process_payment.button.cancel'>
                      Cancel
                    </Trans>
                  </Button>
                </Grid>
                <Grid item>
                  <Button onClick={() => { if (onSuccess) onSuccess() }} color="primary" className={`form-button`}>
                    <Trans ns='payments' i18nKey='payments.dialog.confirm_process_payment.button.pay'>
                      Pay
                    </Trans>
                  </Button>
                </Grid>
              </Grid>
            </div>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    )
  }
}

export default compose(
  withTranslation('payments')
)(ProcessPaymentConfirmDialog);