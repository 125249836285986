const getQuery = (search) => {
  const searchParams = new URLSearchParams(search)

  const params = {}
  searchParams.forEach((value, key) => {
    if (key === 'email') {
      /* 
        - URLSearchParams() returns white space for +
        - So replacing white space with + only for email
      */
      value = value.replace(/\s/g, '+')
    }

    params[key] = decodeURIComponent(value)
  })

  return params;
}

const toQuery = (searchObj) => {
  const params = [];

  const items = Object.keys(searchObj);
  items.forEach(item => {
    params.push(`${item}=${encodeURIComponent(searchObj[item])}`)
  })

  if (params.length === 0) {
    return '';
  }

  return `?${params.join('&')}`
}

const getInitials = (first_name, last_name) => {
  return `${first_name[0]}${last_name[0]}`.toUpperCase()
}

export { getQuery, toQuery, getInitials };