import React from 'react'
import { compose } from 'recompose';
import {
  createStyles, withStyles, IconButton, Dialog, DialogTitle, DialogContent, Button, FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { Grid } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Trans, withTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import ProgressButton from '../../../Shared/ProgressButton';
import { AuthContext } from '../../../../utils/AppContext';
import yupGeneric from '../../../../utils/yupGeneric';
import * as yup from 'yup'
import FormikTextField from '../../../Shared/FormikTextField'
import FormikMaskedInput, { getUnmaskedNumber } from '../../../Shared/FormikMaskedInput'
import http from '../../../../utils/http';
import css from '../Application.module.css'
class AddCoApplicant extends React.Component {
  static contextType = AuthContext

  constructor(props) {
    super(props)

    this.state = {
      open: false,
      formData: {
        first_name: "",
        middle_name: "",
        last_name: "",
        email: "",
        contact_no: "",
        date_of_birth: "",
        is_responsible: true,
        applicant_type: props.applicant_type
      },
    }
  }

  componentDidUpdate() {
    // only open.
    if (this.props.open !== this.state.open) {
      this.setState(prevState => ({
        ...prevState, open: this.props.open,
        formData: {
          ...prevState.formData,
          applicant_type: this.props.applicant_type
        }
      }))
    }
  }

  formValidation = yup.object({
    first_name: yup.string().required().trim(),
    last_name: yup.string().required().trim(),
    email: yup.string()
      .when('is_responsible', {
        is: true,
        then: yup.string().email(yupGeneric)
          .max(50, yupGeneric)
          .required(yupGeneric)
      }),
    contact_no: yup.string()
      .when('is_responsible', {
        is: true,
        then: yup.string().test('Contact no',
          "contact_no.min",
          (value) => {
            if (!value) {
              return true;
            }
            return getUnmaskedNumber(value).length === 10
          },
        ).required(yupGeneric)
      }),
    date_of_birth: yup.string()
      .typeError(yupGeneric)
      .length(10)
      .when('is_responsible', {
        is: false,
        then: yup.string().required(yupGeneric)
      })
  })

  render() {
    const { onClose, classes, t, onSuccess } = this.props
    const { formData } = this.state

    return (

      <Dialog className='slide-right'
        fullWidth={true}
        maxWidth='sm'
        onClose={() => { if (onClose) onClose() }}
        open={this.state.open}>
        <div className='form-wrap'>
          <DialogTitle>
            {(formData.applicant_type === 'CO-APPLICANT') ? "Add Co-Applicant" : "Add Guarantor"}
            <IconButton aria-label="close" className={classes.dialogCloseButton} onClick={() => { if (onClose) onClose() }}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Formik
              initialValues={this.state.formData}
              validationSchema={this.formValidation}
              validateOnMount={true}
              onSubmit={(values, action) => {
                values.contact_no = getUnmaskedNumber(values.contact_no)

                http.post(`co-applicant`, values)
                  .then(() => {
                    if (onClose) {
                      onClose()
                    }
                    if (onSuccess) {
                      onSuccess();
                    }

                    const applicant_type = (formData.applicant_type === 'CO-APPLICANT') ? 'co_applicant' : 'guarantor'

                    this.context.snackbar(<Trans ns='applicants' i18nKey={`${applicant_type}.add.sucess`}>
                      {applicant_type.replace('_', ' ')} added sucessfully
                    </Trans>, {
                      variant: 'success'
                    })

                  }, error => {
                    this.context.snackbar(error.data, {
                      variant: 'error'
                    })
                  }).finally(() => action.setSubmitting(false))
              }}>{props => {
                return (
                  <Form className={classes.form}>
                    <Grid container spacing={2} justify="space-between">
                      {/* first name input text */}
                      <Grid item xs={12} sm={12} md={12}>
                        <FormikTextField
                          required
                          ns="co_application"
                          name="first_name"
                          type="text"
                          variant="outlined"
                          label={<Trans ns='co_application' i18nKey="label.first_name">First Name</Trans>}
                          placeholder={t('co_application:first_name', 'First name')}
                          fullWidth
                          className={classes.input}
                          autoComplete="off" />
                      </Grid>
                      {/* middle name input text */}
                      <Grid item xs={12} sm={12} md={12}>
                        <FormikTextField
                          ns="co_application"
                          name="middle_name"
                          type="text"
                          variant="outlined"
                          label={<Trans ns='co_application' i18nKey="label.middle_name">Middle Name</Trans>}
                          placeholder={t('co_application:middle_name', 'Middle name')}
                          fullWidth
                          className={classes.input}
                          autoComplete="off" />
                      </Grid>
                      {/* last name input text */}
                      <Grid item xs={12} sm={12} md={12}>
                        <FormikTextField
                          required
                          ns="co_application"
                          name="last_name"
                          type="text"
                          variant="outlined"
                          label={<Trans ns='co_application' i18nKey="label.last_name">Last Name</Trans>}
                          placeholder={t('co_application:last_name', 'Last name')}
                          fullWidth
                          className={classes.input}
                          autoComplete="off" />
                      </Grid>

                      {/* email input text */}
                      <Grid item xs={12} sm={12} md={12}>
                        <FormikTextField
                          ns="co_application"
                          name="email"
                          type="email"
                          variant="outlined"
                          label={<Trans ns='co_application' i18nKey="label.email">Email</Trans>}
                          placeholder={t('co_application:email', 'Email')}
                          fullWidth
                          className={classes.input}
                          autoComplete="off" />
                      </Grid>
                      {/* contact no input text */}
                      <Grid item xs={12} sm={12} md={12}>
                        <FormikMaskedInput
                          name='contact_no'
                          type='text'
                          label={t('co_application:label.contact_no', 'Contact No.')}
                          mask_type='(999) 999-9999'
                        />
                      </Grid>
                      {/* dob - only visible for non-responsible co-apps */}
                      {!(props.values.is_responsible) && <Grid item xs={12} sm={12} md={12}>
                        <FormikTextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          ns="co_application"
                          name="date_of_birth"
                          type="date"
                          variant="outlined"
                          label={<Trans ns='co_application' i18nKey="label.date_of_birth">DOB</Trans>}
                          placeholder={t('co_application:date_of_birth', 'DOB')}
                          fullWidth
                          className={classes.input}
                          autoComplete="off" />
                      </Grid>}
                      {/* is responsible (only for co-apps not guarantors) */}
                      {(formData.applicant_type === 'CO-APPLICANT') && <Grid item xs={12} sm={12} md={12}>
                        <div>
                          <FormControlLabel
                            labelPlacement="end"
                            label={<React.Fragment>
                              {/* <span style={{ color: 'red' }}>* </span> */}
                              <Trans ns='co_application' i18nKey="label.is_responsible"><span className={css['label-text']}>Is Responsible?</span></Trans>
                            </React.Fragment>}
                            control={<Checkbox
                              checked={props.values.is_responsible}
                              name="is_responsible"
                              onChange={(_event, newValue) => props.setFieldValue("is_responsible", newValue)}
                              color="primary"
                            />} />
                        </div>
                      </Grid>}
                    </Grid>
                    <Grid container justify="flex-end" alignItems="flex-end" className='form-button-wrap'>
                      <Grid item>
                        <Button className={`${classes.cancel} cancel-button`} variant="outlined" aria-label="cance;" onClick={() => { if (onClose) onClose() }}>Cancel</Button>
                      </Grid>
                      <Grid item>
                        <ProgressButton
                          type="submit"
                          fullWidth
                          disabled={!props.isValid || props.isSubmitting}
                          className={`${classes.submit} form-button`}>
                          <Trans ns="userlisting" i18nKey="button.adduser">Submit</Trans>
                        </ProgressButton>
                      </Grid>
                    </Grid>
                  </Form>
                )
              }}</Formik>
          </DialogContent>
        </div>
      </Dialog>

    )
  }
}

const useStyles = createStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(1, 0, 2),
  },
  input: {
    margin: theme.spacing(1, 0, 2),
  },
  dialogCloseButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

export default compose(
  withStyles(useStyles),
  withTranslation('AddCoApplicant'),
)(AddCoApplicant);