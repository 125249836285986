/* ---------------------------------- TABS CONFIG ARRAY ---------------------------------- */
const formTabs = [
  {
    tabLabel: "Personal Info",
    tabId: "applicants",
    reqdFields: 7,
    forms: ['transunion_terms_of_service', 'applicants', 'applicant_screening_info']
  },
  {
    tabLabel: "Addresses",
    tabId: "addresses",
    reqdFields: 14,
    forms: ['applicant_addresses']
  },
  {
    tabLabel: "Incomes",
    tabId: "employers_n_other_incomes",
    reqdFields: 22,
    forms: ['applicant_employers', 'applicant_other_incomes']
  },
  {
    tabLabel: "Pets, Vehicles",
    tabId: "pets_n_vehicles",
    reqdFields: 4,
    forms: ['applicant_pets', 'applicant_vehicles']
  },
  {
    tabLabel: "References",
    tabId: "references_n_emergency_contacts",
    reqdFields: 14,
    forms: ['applicant_references', 'applicant_emergency_contacts', 'signature_n_approval']
  }
]

export default formTabs